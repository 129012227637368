/** @jsxImportSource @emotion/react */
import React, { useState } from "react";

import {
  Button, Tab, Typography, Box, Table, TableContainer, TableCell, TableRow, TableBody, TableHead, Paper, Popover,
  } from '@mui/material';

import HistoryIcon from '@mui/icons-material/History';
import StyledMuiTableCell from '../../../parts/table/StyledMuiTable/StyledMuiTableCell';
import StyledMuiTableHead from '../../../parts/table/StyledMuiTable/StyledMuiTableHead';
import StyledMuiTableRow from '../../../parts/table/StyledMuiTable/StyledMuiTableRow';
import PopoverEditHistory from "./PopoverEditHistory";
import Const from "../../../../libs/Const";

export default function PanelOs3({item}) {

  function getMatchStatus(arr1, arr2) {
    return arr1.map(item => ({
      label: item,
      isMatch: arr2.includes(item)
    }));
  }

  function separateObjects(item) {
    const qualifications = [];
    const skills = [];
  
    item.forEach(data => {
      if (data.thead.startsWith('保有資格')) {
        qualifications.push(data);
      } else {
        skills.push(data);
      }
    });
  
    return {
      qualifications: qualifications,
      skills: skills
    };
  }

  function divideAcquisitionDateAsQualification(array){
    const qualificationName = array[0];
    if(Array.isArray(array) && array.length > 1){
      const acquisitionDate = array[array.length - 1];
      return {
        qualificationName: qualificationName,
        acquisitionDate: acquisitionDate,
      }
    }
    return {
      qualificationName: qualificationName,
      acquisitionDate: null,
    }
  }

  return(
    <>
      {separateObjects(item).qualifications.map((data) => {
        return (
          <StyledMuiTableRow>
            <StyledMuiTableHead align="left" sx={{ width: '25%' }} >{data.thead}</StyledMuiTableHead> 
            <StyledMuiTableCell>
              <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center', width: '80%' }}>
                  <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>
                    {divideAcquisitionDateAsQualification(data.tdata).qualificationName}
                  </Typography>
                  <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold',pl: 5 }}>
                    取得年月：&emsp;{divideAcquisitionDateAsQualification(data.tdata).acquisitionDate}
                  </Typography>
                </Box>
                {data.history ? <PopoverEditHistory data={data} /> : <Box sx={{ width: '20px' }}/>}
              </Box>
            </StyledMuiTableCell>
          </StyledMuiTableRow>
        )
      })}

      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{separateObjects(item).skills[0].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.PC_SKILL, separateObjects(item).skills[0].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.PC_SKILL, separateObjects(item).skills[0].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {separateObjects(item).skills[0].history ? <PopoverEditHistory data={separateObjects(item).skills[0]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{separateObjects(item).skills[1].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.ENGLISH_SKILL, separateObjects(item).skills[1].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.ENGLISH_SKILL, separateObjects(item).skills[1].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {separateObjects(item).skills[1].history ? <PopoverEditHistory data={separateObjects(item).skills[1]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
    </>
  )
}

