/** @jsxImportSource @emotion/react */
import { useState } from "react";
import PropTypes from "prop-types";

import {
  Tab,
  Typography,
  Box,
  Table,
  TableContainer,
  Paper,
  IconButton,
  Tooltip,
  Modal,
  // SpeedDialIcon,
  // SpeedDialAction,
} from "@mui/material";

import ModalEditEntryInfo from "./Modals/ModalEditEntryInfo";
// import ModalSendRequestEdit from "./Modals/ModalSendRequestEdit";
import ModalInputByRentPc from "./Modals/ModalInputByRentPc";

// import OutboxIcon from "@mui/icons-material/Outbox";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
// import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import LaptopIcon from "@mui/icons-material/Laptop";
import FileCopyIcon from "@mui/icons-material/FileCopy";
// import CheckCircle from "../../parts/icons/CheckCircle";
// import Incomplete from "../../parts/icons/Incomplete";

import StyledMuiTabs from "../../parts/tab/StyledMuiTabs";
import StyledMuiPaper from "../../parts/surface//StyledMuiPaper";
// import StyledSpeedDial from "../../parts/SpeedDial/StyledSpeedDial";

// import PanelBasicInfo from "./WebEntryPanels/PanelBasicInfo";

import PanelNc1 from "./WebEntryPanels/PanelNc1";
import PanelNc2 from "./WebEntryPanels/PanelNc2";
import PanelOs1 from "./WebEntryPanels/PanelOs1";
import PanelOs2 from "./WebEntryPanels/PanelOs2";
import PanelOs3 from "./WebEntryPanels/PanelOs3";
import PanelOs4 from "./WebEntryPanels/PanelOs4";
import PanelOs5 from "./WebEntryPanels/PanelOs5";
import PanelOs6 from "./WebEntryPanels/PanelOs6";

// import DummyEntryDataBasicInfo from "../../../libs/Const/DummyEntryDataBasicInfo";
import DummyEntryDataNc1 from "../../../libs/Const/DummyEntryDataNc1";
import DummyEntryDataNc2 from "../../../libs/Const/DummyEntryDataNc2";

import DummyEntryDataOs1 from "../../../libs/Const/DummyEntryDataOs1";
import DummyEntryDataOs2 from "../../../libs/Const/DummyEntryDataOs2";
import DummyEntryDataOs3 from "../../../libs/Const/DummyEntryDataOs3";
import DummyEntryDataOs4 from "../../../libs/Const/DummyEntryDataOs4";
import DummyEntryDataOs5 from "../../../libs/Const/DummyEntryDataOs5";
import DummyEntryDataOs6 from "../../../libs/Const/DummyEntryDataOs6";
import WarningIconRed from "../../parts/icons/WarningIconRed";
import WarningIconCircleRed from "../../parts/icons/WarningIconCircleRed";
import WarningIconYellow from "../../parts/icons/WarningIconYellow";

const tabHeaderNc = [
  { tabTitle: "基本情報", progress: "CAERUS未連携" },
  { tabTitle: "希望条件", progress: "済" },
  { tabTitle: "経験職種", progress: "CAERUS未連携" },
  { tabTitle: "資格", progress: null },
  { tabTitle: "社内用", progress: null },
  { tabTitle: "緊急連絡先", progress: "済" },
  { tabTitle: "給与口座", progress: "済" },
  { tabTitle: "社保・雇保", progress: "確認依頼前" },
  { tabTitle: "在留カード", progress: "確認依頼前(済)" },
];

const tabHeaderOs = [
  { tabTitle: "基本情報", progress: "CAERUS未連携" },
  { tabTitle: "希望条件", progress: "済" },
  { tabTitle: "OAスキル", progress: null },
  { tabTitle: "経験職種", progress: "CAERUS未連携" },
  { tabTitle: "資格", progress: null },
  { tabTitle: "職歴/学歴", progress: null },
  { tabTitle: "社内用", progress: null },
  { tabTitle: "緊急連絡先", progress: "済" },
  { tabTitle: "給与口座", progress: "済" },
  { tabTitle: "社保・雇保", progress: "確認依頼前" },
  { tabTitle: "在留カード", progress: "確認依頼前(済)" },
];

function getLastPartOfUrl(url) {
  const urlObj = new URL(url);
  const parts = urlObj.pathname.split("/");
  return parts[parts.length - 1];
}

const currentPageUrl = window.location.href;
const currentPageDivision = getLastPartOfUrl(currentPageUrl);

// 最後の2文字を取得する
const lastTwoChars = currentPageDivision.substring(
  currentPageDivision.length - 2
);

let division = "";
if (lastTwoChars === "nc") {
  division = "NC";
} else {
  division = "OS";
}

const tabPanelNc1 = DummyEntryDataNc1();
const tabPanelNc2 = DummyEntryDataNc2();

const tabPanelOs1 = DummyEntryDataOs1();
const tabPanelOs2 = DummyEntryDataOs2();
const tabPanelOs3 = DummyEntryDataOs3();
const tabPanelOs4 = DummyEntryDataOs4();
const tabPanelOs5 = DummyEntryDataOs5();
const tabPanelOs6 = DummyEntryDataOs6();

const tabHeader = division === "NC" ? tabHeaderNc : tabHeaderOs;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ApplicantsEntryInfo() {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const [openSpeedDial, setOpenSpeedDial] = useState(false);
  const [activeModal, setActiveModal] = useState(null);

  // const handleOpenSpeedDial = () => {
  //   setOpenSpeedDial(true);
  // };
  // const handleCloseSpeedDial = () => {
  //   setOpenSpeedDial(false);
  // };
  const handleOpenModal = (modalId) => {
    setActiveModal(modalId);
  };
  const handleCloseModal = () => {
    setActiveModal(null);
  };
  // const [conflict, setConflict] = useState(false);

  //ダミーデータを用意　※本番ではここにjsonでデータを貰う
  const tabPanelListsNc = [
    {
      id: 1,
      entryDatas: tabPanelNc1,
      component: <PanelNc1 item={tabPanelNc1} />,
      conflict: true,
    },
    {
      id: 2,
      entryDatas: tabPanelNc2,
      component: <PanelNc2 item={tabPanelNc2} />,
      conflict: false,
    },
  ];

  const tabPanelListsOs = [
    {
      id: 1,
      entryDatas: tabPanelOs1,
      component: <PanelOs1 item={tabPanelOs1} />,
      conflict: true,
    },
    {
      id: 2,
      entryDatas: tabPanelOs2,
      component: <PanelOs2 item={tabPanelOs2} />,
      conflict: false,
    },
    {
      id: 3,
      entryDatas: tabPanelOs3,
      component: <PanelOs3 item={tabPanelOs3} />,
      conflict: false,
    },
    {
      id: 4,
      entryDatas: tabPanelOs4,
      component: <PanelOs4 item={tabPanelOs4} />,
      conflict: false,
    },
    {
      id: 5,
      entryDatas: tabPanelOs5,
      component: <PanelOs5 item={tabPanelOs5} />,
      conflict: false,
    },
    {
      id: 6,
      entryDatas: tabPanelOs6,
      component: <PanelOs6 item={tabPanelOs6} />,
      conflict: false,
    },
  ];

  const tabPanelLists = division === "NC" ? tabPanelListsNc : tabPanelListsOs;

  const actions = [
    {
      icon: <FileCopyIcon />,
      name: "エントリー情報を編集する",
      id: "edit-entry-info",
      modal: (
        <ModalEditEntryInfo
          onClose={handleCloseModal}
          data={tabPanelLists}
          modalTitle="編集"
          division={division}
        />
      ),
    },
    {
      icon: <LaptopIcon />,
      name: "貸与PCで入力する",
      id: "input-by-rent-pc",
      modal: <ModalInputByRentPc onClose={handleCloseModal} />,
    },

    {
      icon: <DownloadForOfflineIcon />,
      name: "エントリー情報をダウンロードする",
      id: "download-entry-info",
      modal: (
        <ModalEditEntryInfo onClose={handleCloseModal} data={tabPanelLists} />
      ),
    },
  ];

  return (
    <StyledMuiPaper sx={{ width: "100%", height: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", flexWrap: "wrap" }}>
        <StyledMuiTabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          TabIndicatorProps={{ style: { display: "none" } }}
        >
          {tabHeader.map((head, i) => {
            return (
              <Tooltip
                title={
                  head.progress === "確認依頼前"
                    ? "応募者へ保存依頼を行ってください"
                    : head.progress === "確認依頼前(済)"
                    ? "応募者へ保存依頼を行ってください(CAERUS連携済)"
                    : head.progress === "CAERUS未連携"
                    ? "CAERUSへ連携を行ってください"
                    : ""
                }
                placement={i < 6 ? "top-start" : "bottom-start"}
              >
                <Tab
                  label={head.tabTitle}
                  {...a11yProps({ i })}
                  css={styles.MuiTab}
                  iconPosition="end"
                  icon={
                    head.progress === "確認依頼前" ? (
                      <WarningIconRed />
                    ) : head.progress === "確認依頼前(済)" ? (
                      <WarningIconCircleRed />
                    ) : head.progress === "CAERUS未連携" ? (
                      <WarningIconYellow />
                    ) : (
                      ""
                    )
                  }
                />
              </Tooltip>
            );
          })}
        </StyledMuiTabs>
      </Box>
      {tabPanelLists.map((panel, j) => {
        return (
          <TabPanel value={value} index={j}>
            <Box css={styles.titleAndEditBtn}>
              <Typography
                component="p"
                sx={{
                  display: "inline-block",
                  padding: "0 1rem",
                  fontSize: "1rem",
                  color: "#0047A4",
                  backgroundColor: "#c6e0f7",
                  height: "1.5rem",
                }}
              >
                エントリー情報
              </Typography>
              {/* <Box sx={{ position: "relative", top: "2.2rem", left: "1.5rem" }}>
                <StyledSpeedDial
                  ariaLabel="SpeedDial menu"
                  icon={<SpeedDialIcon />}
                  direction="left"
                  onClose={handleCloseSpeedDial}
                  onOpen={handleOpenSpeedDial}
                  open={openSpeedDial}
                >
                  {actions.map((action) => (
                    <SpeedDialAction
                      key={action.id}
                      icon={action.icon}
                      onClick={() => handleOpenModal(action.id)}
                      tooltipTitle={action.name}
                    />
                  ))}
                </StyledSpeedDial>
                {actions.map((action) => (
                  <Modal
                    key={action.id}
                    open={activeModal === action.id}
                    onClose={handleCloseModal}
                    aria-labelledby={`${action.id}-modal-title`}
                    aria-describedby={`${action.id}-modal-description`}
                  >
                    {action.modal}
                  </Modal>
                ))}
              </Box> */}
              <Box sx={{ paddingRight: "6px" }}>
                {actions.map((action) => {
                  return (
                    <>
                      <Tooltip title={action.name}>
                        <IconButton
                          key={action.id}
                          onClick={() => handleOpenModal(action.id)}
                          sx={{
                            width: "24px",
                            height: "24px",
                            marginLeft: "0.5rem",
                            padding: "5px",
                            background: "#fff",
                            boxShadow:
                              "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
                          }}
                        >
                          {action.icon}
                        </IconButton>
                      </Tooltip>
                      <Modal
                        key={action.id}
                        open={activeModal === action.id}
                        onClose={handleCloseModal}
                        aria-labelledby={`${action.id}-modal-title`}
                        aria-describedby={`${action.id}-modal-description`}
                      >
                        {action.modal}
                      </Modal>
                    </>
                  );
                })}
              </Box>
            </Box>
            <TableContainer component={Paper} sx={{ mt: 2, height: "67.5vh" }}>
              <Table sx={{ minWidth: 250 }} aria-label="simple table">
                {panel.component}
              </Table>
            </TableContainer>
          </TabPanel>
        );
      })}
    </StyledMuiPaper>
  );
}

const styles = {
  titleAndEditBtn: {
    display: "flex",
    justifyContent: "space-between",
  },
  btnArea: {
    background: "red",
    padding: "1% 0",
  },
  MuiTab: {
    maxWidth: "initial",
    minWidth: "initial",
    width: "16.66%",
    minHeight: "1vh",
    fontSize: "0.625rem",
    padding: "0.625rem 0",
  },
  entryAreaBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  moreHistoryBtnBlock: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  moreHistoryBtn: {
    transform: "scale(0.75)",
    transformOrigin: "center bottom",
  },
  styledIcon: {
    fontSize: "1rem",
    backgroundColor: "#1976D2",
    color: "#FFF",
    borderRadius: "4px",
    padding: "1px",
  },
};
