/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";

import {
  Modal,
  TableContainer,
  Box,
  Stack,
  Chip,
  Alert,
  AlertTitle,
  Typography,
  Grid,
  Badge,
  IconButton,
  Tooltip,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import DeleteIcon from "@mui/icons-material/Delete";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";

import ModalEditBasicInfo from "./Modals/ModalEditBasicInfo";
import ModalApplicantsHistory from "./Modals/ModalApplicantsHistory";
import ModalDeleteApplicants from "./Modals/ModalDeleteApplicants";
import PersonCircleWoman from "../../parts/icons/PersonCircleWoman";
import PersonCircleMan from "../../parts/icons/PersonCircleMan";

import StyledMuiPaper from "../../parts/surface/StyledMuiPaper";
import StyledSideToSideTable from "../../parts/table/StyledSideToSideTable/StyledSideToSideTable";
import StyledSideToSideTableCell from "../../parts/table/StyledSideToSideTable/StyledSideToSideTableCell";
import StyledSideToSideTableHead from "../../parts/table/StyledSideToSideTable/StyledSideToSideTableHead";
import StyledSideToSideTableRow from "../../parts/table/StyledSideToSideTable/StyledSideToSideTableRow";
import PopoverThreeRecord from "../../parts/Popover/PopoverThreeRecord";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 5,
    top: 1,
    // border: `2px solid ${theme.palette.background.paper}`,
    // padding: '0 4px',
  },
}));

//---------------------------------SpeedDial---------------------------------
// const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
//   position: "absolute",
//   "&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft": {
//     bottom: theme.spacing(2),
//     right: theme.spacing(2),
//   },
//   "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
//     top: theme.spacing(2),
//     left: theme.spacing(2),
//   },
//   transform: "scale(0.6)",
// }));
//---------------------------------SpeedDial---------------------------------

// const progressStatus = [
//   {
//     title: "【呼び込み中】",
//     typography: "強制クローズまで3日",
//     severity: "error",
//     fontWeightTitle: "bold",
//     fontWeightTypo: "",
//   },
//   {
//     title: "本人確認書類",
//     typography: "未対応",
//     severity: "warning",
//     fontWeightTitle: "",
//     fontWeightTypo: "bold",
//   },
//   {
//     title: "ネット予約",
//     typography: "7/28（金）10:00 新宿支店 田中 Web",
//     severity: "success",
//     fontWeightTitle: "",
//     fontWeightTypo: "bold",
//   },
// ];

const applicantBasicInfo = {
  isNew: "既存",
  seiKana: "オウボ",
  meiKana: "ハナコ",
  seiKanji: "応募",
  meiKanji: "花子",
  applicantId: "0101038453",
  caerusId: "5186248761",
  manager: "山田太郎",
  tel1: "090-1234-5678",
  tel2: "03-9876-5432",
  tel3: "011-2345-9876",
  mailAddress1: "oubo@ilovex.co.jp",
  mailAddress2: "dummy-fake@ilovex.co.jp",
  birthday: "2000/01/01",
  gender: "女性",
  currentOccupation: "会社員",
  zipcode: "111-2222",
  addressPref: "東京都",
  addressCity: "中央区",
  addressHouse: "日本橋人形町3-3-13",
  addressBuilding: "オーキッドプレイス人形町ウエスト2F",
  appliMedia: "Xeee(アルバイトNEXT)",
  branch1: "OS大宮",
  branch2: "OS新宿",
  branch3: "OS新宿",
  question: "",
  contactTime: "12:00～13:00,19:00～21:00",
  contactMethod: "電話(携帯),メール①",
};


//アイコンの色を男性女性で変更する
const personCircle =
  applicantBasicInfo.gender === "女性" ? (
    <PersonCircleWoman sx={{ width: "0rem" }} />
  ) : (
    <PersonCircleMan sx={{ width: "0rem" }} />
  );

const selectChipStyle = (newOrExi) => {
  if (newOrExi === "新規") {
    return (
      <Chip
        label={applicantBasicInfo.isNew}
        size="small"
        sx={{
          fontSize: "0.3rem",
          position: "absolute",
          right: "2rem",
          top: "-1rem",
          background: "#7EABEE",
          color: "#333333",
        }}
      />
    );
  } else if (newOrExi === "反社") {
    return (
      <Chip
        label={applicantBasicInfo.isNew}
        size="small"
        sx={{
          fontSize: "0.3rem",
          position: "absolute",
          right: "2rem",
          top: "-1rem",
          background: "#FFD966",
          color: "#333333",
        }}
      />
    );
  } else {
    return (
      <Chip
        label={applicantBasicInfo.isNew}
        size="small"
        sx={{
          fontSize: "0.3rem",
          position: "absolute",
          right: "2rem",
          top: "-1rem",
          background: "#92D050",
          color: "#333333",
        }}
      />
    );
  }
};

export default function BasicInformation() {
  // const [openSpeedDial, setOpenSpeedDial] = useState(false);
  const [activeModal, setActiveModal] = useState(null);

  // const handleOpenSpeedDial = () => {
  //   setOpenSpeedDial(true);
  // };
  // const handleCloseSpeedDial = () => {
  //   setOpenSpeedDial(false);
  // };
  const handleOpenModal = (modalId) => {
    setActiveModal(modalId);
  };
  const handleCloseModal = () => {
    setActiveModal(null);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const actions = [
    {
      icon: <EditIcon />,
      name: "応募者情報を編集する",
      id: "edit-applicantion",
      modal: (
        <ModalEditBasicInfo
          onClose={handleCloseModal}
          applicantBasicInfo={applicantBasicInfo}
        />
      ),
    },
    {
      icon: <AppRegistrationIcon />,
      name: "応募履歴を見る",
      id: "applicantion-history",
      modal: <ModalApplicantsHistory onClose={handleCloseModal} />,
    },
    {
      icon: <DeleteIcon />,
      name: "応募者を削除する",
      id: "delete-applicants",
      modal: (
        <ModalDeleteApplicants
          onClose={handleCloseModal}
          seiKanji={applicantBasicInfo.seiKanji}
          meiKanji={applicantBasicInfo.meiKanji}
        />
      ),
    },
  ];

  function calcAge(birthday) {
    // yyyy/MM/dd の形式をパース
    let birthDate = new Date(birthday);

    // 現在の日付を取得
    let today = new Date();

    // 年数を算出
    let age = today.getFullYear() - birthDate.getFullYear();

    // birthdayの月・日がまだ来ていない場合、ageを一つ減らす
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  return (
    <StyledMuiPaper
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Grid container sx={{ height: "45vh" }}>
        <Grid
          xs={4}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box>
              <Stack
                direction="column"
                sx={{ position: "relative", width: "3rem" }}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  {personCircle}
                </Box>
                {selectChipStyle(applicantBasicInfo.isNew)}
              </Stack>
            </Box>
            <Box>
              <Typography variant="caption" sx={{ fontSize: "0.5rem" }}>
                {applicantBasicInfo.seiKana}&emsp;{applicantBasicInfo.meiKana}
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: "1.1rem", fontWeight: "bold" }}
              >
                {applicantBasicInfo.seiKanji}&emsp;{applicantBasicInfo.meiKanji}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          xs={6}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            mt: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            {applicantBasicInfo.question ||
            applicantBasicInfo.contactTime ||
            applicantBasicInfo.contactMethod ? (
              <Box>
                <StyledBadge
                  // badgeContent="i"
                  variant="dot"
                  color="warning"
                  aria-owns={open ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                >
                  <LocalPhoneIcon sx={{ height: "1rem" }} />
                </StyledBadge>
                <PopoverThreeRecord
                  open={open}
                  anchorEl={anchorEl}
                  handlePopoverClose={handlePopoverClose}
                  applicantBasicInfo={applicantBasicInfo}
                />
              </Box>
            ) : (
              <Box>
                <LocalPhoneIcon sx={{ height: "1rem" }} />
              </Box>
            )}
            <Box sx={{ textAlign: "left", width: "90px" }}>
              <Typography
                variant="caption"
                component="p"
                sx={{ fontSize: "0.5rem", width: "100%", fontWeight: "bold" }}
              >
                ：{applicantBasicInfo.tel1}
              </Typography>
              <Typography
                variant="caption"
                component="p"
                sx={{ fontSize: "0.5rem", width: "100%", fontWeight: "bold" }}
              >
                ：{applicantBasicInfo.tel2}
              </Typography>
              <Typography
                variant="caption"
                component="p"
                sx={{ fontSize: "0.5rem", width: "100%", fontWeight: "bold" }}
              >
                ：{applicantBasicInfo.tel3}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Box sx={{ ml: 2 }}>
              <LocalPostOfficeIcon sx={{ height: "1rem" }} />
            </Box>
            <Box sx={{ textAlign: "left" }}>
              <Typography
                variant="caption"
                component="p"
                sx={{ fontSize: "0.5rem", fontWeight: "bold" }}
              >
                ：{applicantBasicInfo.mailAddress1}
              </Typography>
              <Typography
                variant="caption"
                component="p"
                sx={{ fontSize: "0.5rem", fontWeight: "bold" }}
              >
                ：{applicantBasicInfo.mailAddress2}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid xs={2}>
          <Box>
            {/* <StyledSpeedDial
              ariaLabel="SpeedDial menu"
              icon={<SpeedDialIcon />}
              direction="left"
              onClose={handleCloseSpeedDial}
              onOpen={handleOpenSpeedDial}
              open={openSpeedDial}
            >
              {actions.map((action) => (
                <SpeedDialAction
                  key={action.id}
                  icon={action.icon}
                  onClick={() => handleOpenModal(action.id)}
                  tooltipTitle={action.name}
                />
              ))}
            </StyledSpeedDial> */}
            {actions.map((action) => {
              return (
                <>
                  <Tooltip title={action.name}>
                    <IconButton
                      key={action.id}
                      onClick={() => handleOpenModal(action.id)}
                      sx={{
                        width: "24px",
                        height: "24px",
                        marginLeft: "0.5rem",
                        padding: "5px",
                        background: "#fff",
                        boxShadow:
                          "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
                      }}
                    >
                      {action.icon}
                    </IconButton>
                  </Tooltip>
                  <Modal
                    key={action.id}
                    open={activeModal === action.id}
                    onClose={handleCloseModal}
                    aria-labelledby={`${action.id}-modal-title`}
                    aria-describedby={`${action.id}-modal-description`}
                  >
                    {action.modal}
                  </Modal>
                </>
              );
            })}
          </Box>
        </Grid>
        <Grid xs={12}>
          <TableContainer>
            <StyledSideToSideTable
              sx={{ minWidth: 250, mt: "1rem" }}
              aria-label="simple table"
            >
              <StyledSideToSideTableRow>
                <StyledSideToSideTableHead
                  align="left"
                  sx={{ width: "15%", color: "#4B576B" }}
                >
                  応募者コード
                </StyledSideToSideTableHead>
                <StyledSideToSideTableCell sx={{ width: "20%" }}>
                  {applicantBasicInfo.applicantId}
                </StyledSideToSideTableCell>
                <StyledSideToSideTableHead
                  align="left"
                  sx={{ width: "15%", color: "#4B576B" }}
                >
                  生年月日
                </StyledSideToSideTableHead>
                <StyledSideToSideTableCell sx={{ width: "17%" }}>
                  {applicantBasicInfo.birthday}&nbsp;&#40;
                  {calcAge(applicantBasicInfo.birthday)}歳&#41;
                </StyledSideToSideTableCell>
                <StyledSideToSideTableHead
                  align="left"
                  sx={{ width: "18%", color: "#4B576B" }}
                >
                  担当者
                </StyledSideToSideTableHead>
                <StyledSideToSideTableCell sx={{ width: "15%" }}>
                  {applicantBasicInfo.manager}
                </StyledSideToSideTableCell>
              </StyledSideToSideTableRow>
              <StyledSideToSideTableRow>
                <StyledSideToSideTableHead
                  align="left"
                  sx={{ width: "15%", color: "#4B576B" }}
                >
                  スタッフコード
                </StyledSideToSideTableHead>
                <StyledSideToSideTableCell sx={{ width: "20%" }}>
                  <Link to="https://www.neo-career.co.jp/" target="_blank">
                    {applicantBasicInfo.caerusId}
                  </Link>
                </StyledSideToSideTableCell>
                <StyledSideToSideTableHead
                  align="left"
                  sx={{ width: "15%", color: "#4B576B" }}
                >
                  性別
                </StyledSideToSideTableHead>
                <StyledSideToSideTableCell sx={{ width: "17%" }}>
                  {applicantBasicInfo.gender}
                </StyledSideToSideTableCell>
                <StyledSideToSideTableHead
                  align="left"
                  sx={{ width: "18%", color: "#4B576B" }}
                >
                  応募支店（応募時）
                </StyledSideToSideTableHead>
                <StyledSideToSideTableCell sx={{ width: "15%" }}>
                  {applicantBasicInfo.branch1}
                </StyledSideToSideTableCell>
              </StyledSideToSideTableRow>
              <StyledSideToSideTableRow>
                <StyledSideToSideTableHead
                  align="left"
                  sx={{ width: "15%", color: "#4B576B" }}
                >
                  応募媒体
                </StyledSideToSideTableHead>
                <StyledSideToSideTableCell sx={{ width: "20%" }}>
                  {applicantBasicInfo.appliMedia}
                </StyledSideToSideTableCell>
                <StyledSideToSideTableHead
                  align="left"
                  sx={{ width: "15%", color: "#4B576B" }}
                >
                  住所(都道府県)
                </StyledSideToSideTableHead>
                <StyledSideToSideTableCell sx={{ width: "17%" }}>
                  {applicantBasicInfo.addressPref}
                </StyledSideToSideTableCell>
                <StyledSideToSideTableHead
                  align="left"
                  sx={{ width: "18%", color: "#4B576B" }}
                >
                  応募支店（担当）
                </StyledSideToSideTableHead>
                <StyledSideToSideTableCell sx={{ width: "15%" }}>
                  {applicantBasicInfo.branch2}
                </StyledSideToSideTableCell>
              </StyledSideToSideTableRow>
              <StyledSideToSideTableRow>
                <StyledSideToSideTableHead
                  align="left"
                  sx={{ width: "15%", color: "#4B576B" }}
                >
                  現在の職業
                </StyledSideToSideTableHead>
                <StyledSideToSideTableCell sx={{ width: "20%" }}>
                  {applicantBasicInfo.currentOccupation}
                </StyledSideToSideTableCell>
                <StyledSideToSideTableHead
                  align="left"
                  sx={{ width: "15%", color: "#4B576B" }}
                >
                  住所(市区町村)
                </StyledSideToSideTableHead>
                <StyledSideToSideTableCell sx={{ width: "17%" }}>
                  {applicantBasicInfo.addressCity}
                </StyledSideToSideTableCell>
                <StyledSideToSideTableHead
                  align="left"
                  sx={{ width: "18%", color: "#4B576B" }}
                >
                  登録支店
                </StyledSideToSideTableHead>
                <StyledSideToSideTableCell sx={{ width: "15%" }}>
                  {applicantBasicInfo.branch3}
                </StyledSideToSideTableCell>
              </StyledSideToSideTableRow>
            </StyledSideToSideTable>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid container>
        {/* {progressStatus.map((progressStatu, i) => {
          return (
            <Grid xs={4} sx={{ p: 1 }}>
              <Alert severity={progressStatu.severity}>
                <AlertTitle
                  sx={{
                    fontSize: "0.5rem",
                    fontWeight: progressStatu.fontWeightTitle,
                  }}
                >
                  {progressStatu.title}
                </AlertTitle>
                <Typography
                  sx={{
                    fontSize: "0.5rem",
                    fontWeight: progressStatu.fontWeightTypo,
                  }}
                >
                  {progressStatu.typography}
                </Typography>
              </Alert>
            </Grid>
          );
        })} */}
        <Grid item xs={7} sm={7} md={7} lg={7}>
          <Grid container>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Alert severity="error" sx={{ margin: "1px", height: "100%" }}>
                <AlertTitle
                  align="left"
                  sx={{
                    fontSize: "0.5rem",
                    fontWeight: "bold",
                  }}
                >
                  【ステータス】
                </AlertTitle>
                <Typography
                  sx={{
                    fontSize: "0.5rem",
                    fontWeight: "bold",
                  }}
                >
                  未対応
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.5rem",
                    fontWeight: "regular",
                  }}
                >
                  強制クローズまで&nbsp;&nbsp;
                  <Typography
                    component="span"
                    sx={{ fontWeight: "bold", fontSize: "0.8rem" }}
                  >
                    3
                  </Typography>
                  日
                </Typography>
              </Alert>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6}>
              <Alert severity="warning" sx={{ margin: "1px", height: "100%" }}>
                <AlertTitle
                  align="left"
                  sx={{
                    fontSize: "0.5rem",
                    fontWeight: "bold",
                  }}
                >
                  【本人確認書類】
                </AlertTitle>
                <Typography
                  sx={{
                    fontSize: "0.5rem",
                    fontWeight: "bold",
                  }}
                >
                  未対応
                </Typography>
              </Alert>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5}>
          <Alert severity="success" sx={{ margin: "1px", height: "100%" }}>
            <AlertTitle
              align="left"
              sx={{
                fontSize: "0.5rem",
                fontWeight: "bold",
              }}
            >
              【ネット予約】
            </AlertTitle>
            <Typography
              align="left"
              sx={{
                fontSize: "0.5rem",
                fontWeight: "bold",
              }}
            >
              7/28（金）10:00 新宿支店 田中 Web
            </Typography>
            <Typography align="left" sx={{ fontSize: "0.5rem" }}>
              (代理予約：― 7/19 17:35)
            </Typography>
          </Alert>
        </Grid>
      </Grid>
    </StyledMuiPaper>
  );
}

// const styles = {
//   statusArea: {
//     fontSize: "0.6rem",
//     marginTop: "0.5rem",
//     padding: "1rem 0",
//   },
//   statusMain: {
//     display: "flex",
//     alignItems: "center",
//     marginLeft: "1rem",
//   },
//   flex: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//   },

//   nameArea: {
//     width: "",
//     textAlign: "center",
//     marginLeft: "0.5rem",
//     marginRight: "0.5rem",
//   },
//   iconArea: {
//     textAlign: "left",
//     display: "flex",
//   },
//   birthdayArea: {
//     textAlign: "right",
//   },
//   contentBasicInformation: {
//     fontSize: "0.625rem",
//   },

//   basicInfoName: {
//     display: "flex",
//     justifyContent: "",
//     alignItems: "center",
//     marginLeft: "1rem",
//     padding: "8px 1rem",
//     cursor: "pointer",
//     borderRadius: "10px",
//   },
//   statusTable: {
//     width: "33%",
//     display: "flex",
//     justifyContent: "space-between",
//     marginTop: "2.5rem",
//   },
//   statusThead: {},
//   statusTdata: {
//     width: "50%",
//     marginRight: "1.5rem",
//     fontWeight: "bold",
//     borderBottom: "1px solid rgba(224, 224, 224, 1)",
//   },
//   modal: {
//     position: "fixed",
//     top: "50%",
//     left: "50%",
//     transform: "translate(-50%, -50%)",
//     width: "75%",
//     backgroundColor: "#FFF",
//     borderRadius: "0.25rem",
//     padding: "15px 20px",
//   },
// };

// function ActionIconButton(props) {
//   const { name, id, icon } = props;
//   const [activeModal, setActiveModal] = useState(null);
//   const handleOpenModal = (modalId) => {
//     setActiveModal(modalId);
//   };
//   const handleCloseModal = () => {
//     setActiveModal(null);
//   };
//   return (
//     <>
//       <Tooltip title={action.name}>
//         <IconButton
//           key={action.id}
//           onClick={() => handleOpenModal(action.id)}
//           sx={{
//             width: "24px",
//             height: "24px",
//             marginLeft: "0.5rem",
//             padding: "5px",
//             background: "#fff",
//             boxShadow:
//               "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
//           }}
//         >
//           {action.icon}
//         </IconButton>
//       </Tooltip>
//       <Modal
//         key={action.id}
//         open={activeModal === action.id}
//         onClose={handleCloseModal}
//         aria-labelledby={`${action.id}-modal-title`}
//         aria-describedby={`${action.id}-modal-description`}
//       >
//         {action.modal}
//       </Modal>
//     </>
//   );
// }
