/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

import { styled } from '@mui/material';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

//---------------------電話番号にハイフンを自動補完する関数---------------------
const formatPhoneNumber = (value) => {
  // 日本の国コード
  const region = 'JP';

  const util = PhoneNumberUtil.getInstance();

  try {
    // 番号と地域を設定
    const number = util.parseAndKeepRawInput(value, region);

    // 電話番号の有効性チェック
    if (!util.isValidNumberForRegion(number, region)) {
      return value;
    }

    // ハイフン付きの形式で返却
    return util.format(number, PhoneNumberFormat.NATIONAL);
  } catch (e) {
    return value;
  }
};
//---------------------電話番号にハイフンを自動補完する関数---------------------



//==========================関数コンポーネント==========================
function TextFieldInputPhoneNumber({ textSize, width, id, label, size, disabled }) {

  //------------コンポーネントのスタイル------------
  const styles = {
    textarea:{
      width: '90%',

      '& .MuiInputBase-input':{
        fontSize: textSize,
      },
      '& .MuiInputBase-root':{
        // padding: '3px 0.5rem',
      },
      '& .MuiInputLabel-root':{
        fontSize: textSize,
      }
    },
    inputItemForm:{
      display:'flex',
      alignItems: 'center',
    },
    paddingLeft5:{
      paddingLeft: '5px',
    }
  }
  //------------コンポーネントのスタイル------------

  const [phoneNumber, setPhoneNumber] = useState('');
  const handleChange = (event) => {
    const formattedNumber = formatPhoneNumber(event.target.value);
    setPhoneNumber(formattedNumber || event.target.value);
  };
  useEffect(() => {
    setPhoneNumber(formatPhoneNumber(phoneNumber));
  }, [phoneNumber]);

  return (
    <Grid item xs={width} sm={width} md={width} lg={width} xl={width}>
      <TextField
        id={id}
        label={label}
        size={size}
        value={phoneNumber}
        onChange={handleChange}
        css={styles.textarea}
        disabled={disabled}
      />
    </Grid>
  );
}
export default TextFieldInputPhoneNumber;