/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Const from '../../../libs/Const';

const styles = {
  selectBox:{
    width: '90%',
    textAlign: 'left',
    '& .MuiInputBase-input':{
      fontSize: '0.5rem',
      color: '#0047A4',
    },
    '& .MuiInputBase-root':{
      // padding: '3px 0.5rem',
      fontSize: '0.5rem',
    },
    '& .MuiFormLabel-root':{
      fontSize: '0.5rem',
    },
    '& .MuiInputLabel-root':{
      fontSize: '0.5rem',
    },
    '& .MuiSelect-root':{
      fontSize: '0.5rem'
    }
  },
  inputItemForm:{
    display:'flex',
    alignItems: 'center',
  },
  paddingLeft5:{
    paddingLeft: '5px',
  },
  textSize:{
    fontSize: '0.5rem'
  }
}

export default function SelectedPrefecture({ defaultValue }) {
  const [prefecture, setPrefecture] = useState(defaultValue || '');
  const handleChangePrefecture = (event) => {
    setPrefecture(event.target.value);
  };
  
  return (
    <FormControl sx={{ mr: 2, minWidth: 120 }} size="small">
      <InputLabel id="prefecture" sx={{ fontSize: '0.5rem' }}>都道府県</InputLabel>
      <Select
        labelId="prefecture"
        id="prefecture"
        value={prefecture}
        label="都道府県"
        css={styles.selectBox}
        sx={{ fontSize: '0.5rem' }}
        onChange={handleChangePrefecture}
      >
        {Const.PREF_OPTIONS.map((option) => {
            return (
              <MenuItem value={option} sx={{ fontSize: '0.5rem' }}>{option}</MenuItem>
            )})
        }
      </Select>
    </FormControl>
  )
}
