/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Button, Paper, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import StyledMuiPaper from '../../parts/surface/StyledMuiPaper';
import StyledMuiBtnBlue from '../../parts/button/StyledMuiBtnBlue';
import Const from '../../../libs/Const';




const columns = [
  { id: 'no', label: 'No.', minWidth: 10 },
  {
    id: 'applicantsBranch',
    label: '応募支店（応募時）',
    minWidth: 100,
    align: 'left',
  },
  { id: 'name', label: '応募者名', minWidth: 100 },
  {
    id: 'applicantsDate',
    label: '応募日時',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'applicantsMedia',
    label: '応募媒体',
    minWidth: 170,
    align: 'left',
    
  },
  {
    id: 'fankunOptions',
    label: 'ふぁん君',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'caerusOptions',
    label: 'CAERUS',
    minWidth: 50,
    align: 'left',
  },
  {
    id: 'path',
    label: '',
    minWidth: 20,
    align: 'center',
  },
];

function randomSelectBranch() {
  const randomBranchIndex = Math.floor(Math.random() * Const.BRANCH_OPTIONS.length);
  const applicantsBranch = Const.BRANCH_OPTIONS[randomBranchIndex];
  return applicantsBranch;
}

function generateName() {
  const randomFirstIndex = Math.floor(Math.random() * Const.FIRST_NAME_LIST.length);
  const randomLastIndex = Math.floor(Math.random() * Const.LAST_NAME_LIST.length);
  const applicantNameKanji = Const.LAST_NAME_LIST[randomLastIndex]['sei_kanji'] + ' ' + Const.FIRST_NAME_LIST[randomFirstIndex]['mei_kanji'];
  const applicantNameKana = Const.LAST_NAME_LIST[randomLastIndex]['sei_kana'] + ' ' + Const.FIRST_NAME_LIST[randomFirstIndex]['mei_kana'];
  const applicantName = { kana: applicantNameKana, kanji: applicantNameKanji };
  return applicantName;
}

function randomSelectMedia() {
  const randomMediaIndex = Math.floor(Math.random() * Const.APPLI_MEDIA_OPTIONS.length);
  const media = Const.APPLI_MEDIA_OPTIONS[randomMediaIndex];
  return media;
}

function getRandomDateInRange(startYear, endYear) {
  const startDate = new Date(startYear, 0, 1); // yyyy年1月1日
  const endDate = new Date(endYear + 1, 0, 1); // (endYear+1)年1月1日
  const randomTimestamp = startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime());
  const randomDate = new Date(randomTimestamp);
  const year = randomDate.getFullYear();
  const month = (randomDate.getMonth() + 1).toString().padStart(2, '0');
  const day = randomDate.getDate().toString().padStart(2, '0');
  return `${year}年${month}月${day}日`;
}

function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function createData(no, applicantsBranch, nameKana, nameKanji, applicantsDate, applicantsMedia, fankunOptions, caerusOptions, path) {
  return { no, applicantsBranch, nameKana, nameKanji, applicantsDate, applicantsMedia, fankunOptions, caerusOptions, path };
}

const rows = [];
for (let i = 1; i <= 50; i++) {
  const no = i;
  const applicantsBranch = randomSelectBranch();
  const name = generateName();
  const nameKana = name.kana
  const nameKanji = name.kanji;
  const applicantsDate = getRandomDateInRange(2020, 2022);
  const applicantsMedia = randomSelectMedia();
  const fankunOptions = getRandomInt(1, 5);
  const caerusOptions = getRandomInt(0, 5);
  const path = no;

  rows.push(createData(no, applicantsBranch, nameKana, nameKanji, applicantsDate, applicantsMedia, fankunOptions, caerusOptions, path));
}


const styles = {
  pagenation:{
    "& .MuiTablePagination-selectLabel":{
      fontSize: '0.5rem'
    },
    "& .MuiTablePagination-displayedRows":{
      fontSize: '0.5rem'
    },
    "& .MuiTablePagination-actions":{
      fontSize: '0.5rem'
    },
    fontSize: '0.5rem',
    "& .MuiTablePagination-toolbar":{
      minHeight: '40px'
    },
  },
  link:{
    fontSize: '0.5rem',
    color: '#FFFFFF',
    textDecoration: 'none',
    width: '100%',
  },
  headerFontStyles: {
    fontSize: '0.5rem',
    fontWeight: 'bold',
    color: '#0047A4',
  },
  cellFontStyles: {
    fontSize: '0.5rem',
    fontWeight: 'regular',
    color: '#0047A4',
  }
}


export default function DetermineApplicantsList() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <StyledMuiPaper sx={{ width: '100%', overflow: 'hidden', py: 1, mb:3, textAlign: 'left', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      <TableContainer>
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead>
            {/* <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{
                    minWidth: column.minWidth,
                    fontSize: '0.5rem',
                    fontWeight: 'bold',
                    color: '#0047A4',
                    
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow> */}
            <TableRow>
              <TableCell css={styles.headerFontStyles} sx={{ minWidth: columns[0].minWidth }}>{columns[0].label}</TableCell>
              <TableCell css={styles.headerFontStyles} sx={{ minWidth: columns[1].minWidth }}>{columns[1].label}</TableCell>
              <TableCell css={styles.headerFontStyles} sx={{ minWidth: columns[2].minWidth }}>{columns[2].label}</TableCell>
              <TableCell css={styles.headerFontStyles} sx={{ minWidth: columns[3].minWidth }}>{columns[3].label}</TableCell>
              <TableCell css={styles.headerFontStyles} sx={{ minWidth: columns[4].minWidth }}>{columns[4].label}</TableCell>
              <TableCell css={styles.headerFontStyles} sx={{ minWidth: columns[5].minWidth }}>
              <Typography component="p" css={styles.headerFontStyles} sx={{ textAlign: 'center' }}>候補件数</Typography>
                <Typography component="div" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography component="p" css={styles.headerFontStyles}>{columns[5].label}</Typography>
                  <Typography component="p" css={styles.headerFontStyles}>{columns[6].label}</Typography>
                </Typography>
              </TableCell>
              <TableCell sx={{ minWidth: columns[6].minWidth }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, k) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    <TableCell key={k} align={row.align} css={styles.cellFontStyles}>{row.no}</TableCell>
                    <TableCell key={k} align={row.align} css={styles.cellFontStyles}>{row.applicantsBranch}</TableCell>
                    <TableCell key={k} align={row.align} css={styles.cellFontStyles}>{row.nameKanji}&nbsp;&#040;{row.nameKana}&#041;</TableCell>
                    <TableCell key={k} align={row.align} css={styles.cellFontStyles}>{row.applicantsDate}</TableCell>
                    <TableCell key={k} align={row.align} css={styles.cellFontStyles}>{row.applicantsMedia}</TableCell>
                    <TableCell key={k} align={row.align} css={styles.cellFontStyles}>
                      <Typography component="div" sx={{ display: 'flex', justifyContent: 'space-between' }} >
                        <Typography component="p" css={styles.cellFontStyles} sx={{ display: 'inline-block', width: '40px', pl:1 }}>{row.fankunOptions}件</Typography>
                        <Typography component="p" css={styles.cellFontStyles} sx={{ display: 'inline-block', width: '40px', pl:1 }}>{row.caerusOptions}件</Typography>
                      </Typography>
                    </TableCell>
                    <TableCell key={k} align={row.align} css={styles.cellFontStyles}>
                      <Link to={`/determination_detail/` + row.path} css={styles.link}><StyledMuiBtnBlue variant="contained">詳細</StyledMuiBtnBlue></Link>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        css={styles.pagenation}
      />
    </StyledMuiPaper>
  )
}





