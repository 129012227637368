import React from "react";
import { Box, Grid } from "@mui/material";
import PhoneApplicantsList from "../feature/PhoneApplicantsList/PhoneApplicantsList";
import StyledMuiAppBar from "../parts/AppBar/StyledMuiAppBar";

const rows = [
  {
    cooperationDate: "2023/04/01 12:34",
    branch: "OS池袋",
    applicantMedia: "バイトルドットコム",
    phoneNumber: "090-1234-5678",
    path: "/oubo_regist",
  },
  {
    cooperationDate: "2023/05/20 12:34",
    branch: "OS池袋",
    applicantMedia: "ウェブアグレ",
    phoneNumber: "",
    path: "/oubo_regist",
  },
  {
    cooperationDate: "2023/05/17 12:34",
    branch: "OS池袋",
    applicantMedia: "Airワーク採用管理",
    phoneNumber: "090-1234-5678",
    path: "/oubo_regist",
  },
  {
    cooperationDate: "2023/05/12 12:34",
    branch: "",
    applicantMedia: "マイナビバイト",
    phoneNumber: "",
    path: "/oubo_regist",
  },
  {
    cooperationDate: "2023/05/05 12:34",
    branch: "OS池袋",
    applicantMedia: "アルバイトEX",
    phoneNumber: "090-1234-5678",
    path: "/oubo_regist",
  },
  {
    cooperationDate: "2023/04/29 12:34",
    branch: "OS池袋",
    applicantMedia: "はたらこねっと",
    phoneNumber: "",
    path: "/oubo_regist",
  },
  {
    cooperationDate: "2023/04/23 12:34",
    branch: "OS池袋",
    applicantMedia: "バイトルドットコム",
    phoneNumber: "090-1234-5678",
    path: "/oubo_regist",
  },
  {
    cooperationDate: "2023/04/09 12:34",
    branch: "OS池袋",
    applicantMedia: "アスコム",
    phoneNumber: "090-1234-5678",
    path: "/oubo_regist",
  },
];

export default function PhoneApplicantsListPage() {
  return (
    <>
      <StyledMuiAppBar headerTitle="電話番号のみ応募一覧" />
      <Box sx={{ width: "100%", mt: 7, mb: 3 }}>
        <Grid container spacing={2} rowSpacing={2}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <PhoneApplicantsList applicants={rows} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
