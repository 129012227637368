export default class Const {
  static PREF_OPTIONS = [
    "北海道",
    "青森県",
    "岩手県",
    "宮城県",
    "秋田県",
    "山形県",
    "福島県",
    "茨城県",
    "栃木県",
    "群馬県",
    "埼玉県",
    "千葉県",
    "東京都",
    "神奈川県",
    "新潟県",
    "富山県",
    "石川県",
    "福井県",
    "山梨県",
    "長野県",
    "岐阜県",
    "静岡県",
    "愛知県",
    "三重県",
    "滋賀県",
    "京都府",
    "大阪府",
    "兵庫県",
    "奈良県",
    "和歌山県",
    "鳥取県",
    "島根県",
    "岡山県",
    "広島県",
    "山口県",
    "徳島県",
    "香川県",
    "愛媛県",
    "高知県",
    "福岡県",
    "佐賀県",
    "長崎県",
    "熊本県",
    "大分県",
    "宮崎県",
    "鹿児島県",
    "沖縄県",
  ];
  static APPLI_MEDIA_OPTIONS = [
    "ウェブアグレ",
    "Airワーク採用管理",
    "Xeee(アルバイトNEXT)",
    "マイナビバイト",
    "アルバイトEX",
    "しゅふJOB",
    "おうぼ受ける君",
    "はたらこねっと",
    "バイトルドットコム",
    "シフトワークス",
    "アイデム",
    "アスコム",
    "ワークゲート",
    "エン転職",
    "マッハバイト",
    "indeed",
  ];
  static OFFICE_OPTIONS = [
    "OS北海道",
    "OS東北",
    "OS関東",
    "OS北陸",
    "OS東海",
    "OS近畿",
    "OS四国",
    "OS中国",
    "OS九州",
  ];
  static BRANCH_OPTIONS = [
    "新宿南口旧総合ビルディング中央支店",
    "ケアリート",
    "受注センター",
    "VLA",
    "VL",
    "OS京都",
    "OS梅田",
    "BS新宿第一",
    "BS新宿第二",
    "BS秋葉原",
    "BS池袋",
    "BS渋谷",
    "BS横浜",
    "きゃりふらわー東京",
    "ネオコン新宿",
    "特別法人営業",
    "BPO",
    "TECH梅田",
    "特法新宿",
    "BS立川",
    "BSシニア",
    "仙台事務センター",
    "東日本事務センター",
    "西日本事務センター",
    "エージェント",
    "盛岡",
    "メディカル大阪",
    "仙台",
    "TC（ダミー）",
    "盛岡事務センター",
    "新宿事務センター",
    "OS名古屋",
    "BS名古屋",
    "OS製造滋賀",
    "大阪事務センター",
    "LV札幌大通",
    "山形",
    "青森",
    "OS仙台",
    "札幌",
    "高崎",
    "水戸",
    "千葉",
    "市原",
    "川越",
    "OS町田",
    "OS横浜",
    "OS川崎",
    "OS藤沢",
    "EP渋谷",
    "OS広島",
    "OS岡山",
    "大宮",
    "宇都宮",
    "EP横浜",
    "OS札幌",
    "VA札幌",
    "OS大宮",
    "OS熊本",
    "OS鹿児島",
    "HRG",
    "きゃり札幌",
    "CA",
    "BS大宮",
    "越谷",
    "錦糸町",
    "池袋",
    "秋葉原",
    "立川",
    "甲府",
    "町田",
    "横浜",
    "藤沢",
    "沼津",
    "静岡",
    "浜松",
    "新潟",
    "金沢",
    "名古屋",
    "岡崎",
    "岐阜",
    "滋賀",
    "京都",
    "伏見",
    "三重",
    "高槻",
    "西宮",
    "高松",
    "松山",
    "北九州",
    "福岡",
    "長崎",
    "熊本",
    "鹿児島",
    "Work to札幌",
    "OS神戸",
    "Work to盛岡",
    "厚木",
    "OS池袋",
    "VA池袋",
    "堺",
    "長野",
    "松本",
    "OS千葉",
    "OS柏",
    "OS錦糸町",
    "OS秋葉原",
    "OS天神",
    "OS博多",
    "富山",
    "BS博多",
    "OS小倉",
    "大阪",
    "天王寺",
    "神戸",
    "岡山",
    "SOS札幌",
    "OS天王寺",
    "OS梅田第二",
    "八王子",
    "新宿",
    "新宿第二",
    "久留米",
    "郡山",
    "OS創世",
    "BS1G",
    "BS2G",
    "OSなんば",
    "OS新宿",
    "SP新宿",
    "SP渋谷",
    "OS立川",
    "OS渋谷",
    "OSシニア",
    "SOS新宿",
    "OS公共事業",
    "スタッフ人事",
    "柏",
    "川崎",
    "奈良",
    "姫路",
    "広島",
    "OS沖縄",
    "OS沖縄第二",
  ];
  static FIRST_NAME_LIST = [
    { mei_kana: "ヒロト", mei_kanji: "大翔", gender: "男性" },
    { mei_kana: "タクミ", mei_kanji: "拓海", gender: "男性" },
    { mei_kana: "ヒナ", mei_kanji: "陽菜", gender: "女性" },
    { mei_kana: "ミウ", mei_kanji: "美羽", gender: "女性" },
    { mei_kana: "ユウト", mei_kanji: "優斗", gender: "男性" },
    { mei_kana: "ユウマ", mei_kanji: "悠真", gender: "男性" },
    { mei_kana: "ココア", mei_kanji: "心愛", gender: "女性" },
    { mei_kana: "ユナ", mei_kanji: "結菜", gender: "女性" },
    { mei_kana: "レン", mei_kanji: "蓮", gender: "男性" },
    { mei_kana: "カイト", mei_kanji: "海斗", gender: "男性" },
    { mei_kana: "リン", mei_kanji: "凛", gender: "男性" },
    { mei_kana: "ハヤテ", mei_kanji: "颯太", gender: "男性" },
    { mei_kana: "シオリ", mei_kanji: "詩織", gender: "女性" },
    { mei_kana: "カズハ", mei_kanji: "一葉", gender: "女性" },
    { mei_kana: "タイチ", mei_kanji: "太一", gender: "男性" },
    { mei_kana: "マオ", mei_kanji: "真央", gender: "女性" },
    { mei_kana: "ケイタ", mei_kanji: "慶太", gender: "男性" },
    { mei_kana: "ヒトミ", mei_kanji: "瞳", gender: "女性" },
    { mei_kana: "ユウ", mei_kanji: "悠", gender: "男性" },
    { mei_kana: "ケンタ", mei_kanji: "健太", gender: "男性" },
  ];
  static LAST_NAME_LIST = [
    { sei_kana: "サトウ", sei_kanji: "佐藤" },
    { sei_kana: "スズキ", sei_kanji: "鈴木" },
    { sei_kana: "タカハシ", sei_kanji: "高橋" },
    { sei_kana: "タナカ", sei_kanji: "田中" },
    { sei_kana: "ワタナベ", sei_kanji: "渡辺" },
    { sei_kana: "イトウ", sei_kanji: "伊藤" },
    { sei_kana: "ヤマモト", sei_kanji: "山本" },
    { sei_kana: "ナカムラ", sei_kanji: "中村" },
    { sei_kana: "コバヤシ", sei_kanji: "小林" },
    { sei_kana: "ヨシダ", sei_kanji: "吉田" },
    { sei_kana: "ヤマダ", sei_kanji: "山田" },
    { sei_kana: "ササキ", sei_kanji: "佐々木" },
    { sei_kana: "ヤマグチ", sei_kanji: "山口" },
    { sei_kana: "マツモト", sei_kanji: "松本" },
    { sei_kana: "イノウエ", sei_kanji: "井上" },
    { sei_kana: "キムラ", sei_kanji: "木村" },
    { sei_kana: "ハヤシ", sei_kanji: "林" },
    { sei_kana: "シミズ", sei_kanji: "清水" },
    { sei_kana: "ヤマザキ", sei_kanji: "山崎" },
  ];

  //新規・既存
  static NEW_OR_EXISTING = ["新規", "既存", "反社/連絡禁止"];
  //進捗・ステータス
  static STATUS_LIST = [
    "未対応",
    "呼び込み中",
    "仮登録",
    "登録完了",
    "その他",
    "登録辞退",
    "対象外",
  ];

  //応募経路
  static APPLICANT_ROUTE = [
    "WEB媒体（TEL応募）",
    "紙媒体（TW本誌）",
    "紙媒体（地場媒体）",
    "友達紹介",
    "別事業部からの連携",
    "外部登録会",
    "不明",
    "その他",
    "WEB媒体（WEB応募）",
  ];

  //応募登録時の応募媒体
  static APPLICANT_MEDIA_BY_REGIST = [
    "その他おうぼうける君",
    "フロムエーナビ",
    "はたらいく",
    "とらばーゆ",
    "タウンワーク",
    "モバ得",
    "WEB得",
    "TW枠得",
    "バイトル",
    "製造札幌　はたらこねっと",
    "はたらこねっと",
    "バイトルPRO",
    "enその他",
    "enバイト",
    "enウィメンズワーク",
    "en派遣",
    "製造札幌　マイナビバイト",
    "マイナビバイト",
    "マッハバイト",
    "アルバイトEX",
    "シフトワークス",
    "アルバイトネクスト",
    "アイデム",
    "製造札幌　アスコム",
    "アスコム",
    "しゅふJOB",
    "ワークゲート",
    "製造札幌　その他おうぼうける君",
    "製造札幌　フロムエーナビ",
    "製造札幌　はたらいく",
    "製造札幌　とらばーゆ",
    "製造札幌　タウンワーク",
    "製造札幌　モバ得",
    "製造札幌　WEB得",
    "製造札幌　TW枠得",
    "北日本　その他おうぼうける君",
    "北日本　フロムエーナビ",
    "北日本　はたらいく",
    "北日本　とらばーゆ",
    "北日本　タウンワーク",
    "北日本　モバ得",
    "北日本　WEB得",
    "北日本　TW枠得",
    "首都圏　その他おうぼうける君",
    "首都圏　フロムエーナビ",
    "首都圏　はたらいく",
    "首都圏　とらばーゆ",
    "首都圏　タウンワーク",
    "首都圏　モバ得",
    "首都圏　WEB得",
    "首都圏　TW枠得",
    "関西　その他おうぼうける君",
    "関西　フロムエーナビ",
    "関西　はたらいく",
    "関西　とらばーゆ",
    "関西　タウンワーク",
    "関西　モバ得",
    "関西　WEB得",
    "関西　TW枠得",
    "広域　その他おうぼうける君",
    "広域　フロムエーナビ",
    "広域　はたらいく",
    "広域　とらばーゆ",
    "広域　タウンワーク",
    "広域　モバ得",
    "広域　WEB得",
    "広域　TW枠得",
    "製造札幌　ジョブオプlite",
    "北日本　ジョブオプlite",
    "首都圏　ジョブオプlite",
    "関西　ジョブオプlite",
    "広域　ジョブオプlite",
    "アグレ",
    "EP　その他おうぼうける君",
    "EP　フロムエーナビ",
    "EP　はたらいく",
    "EP　とらばーゆ",
    "EP　タウンワーク",
    "EP　モバ得",
    "EP　WEB得",
    "EP　TW枠得",
    "EP　ジョブオプlite",
    "EP　バイトル",
    "EP　enその他",
    "EP　enバイト",
    "EP　enウィメンズワーク",
    "EP　en派遣",
    "EP　マイナビバイト",
    "BS　タウンワーク",
    "フロムエーナビ　BS　タウンワーク",
    "はたらいく　BS　タウンワーク",
    "とらばーゆ　BS　タウンワーク",
    "タウンワーク　BS　タウンワーク",
    "モバ得　BS　タウンワーク",
    "WEB得　BS　タウンワーク",
    "TW枠得　BS　タウンワーク",
    "BS　ジョブオプlite",
    "indeed(Q-mate)",
  ];

  //=============================== NCエントリー情報関係 ===============================

  //希望勤務時間
  static DESIRED_WORK_HOURS_NC = ["早番", "日勤", "遅番", "夜勤"];
  //希望条件（通勤時間）
  static COMMUTING_METHOD = ["自宅から", "最寄りから"];

  //希望施設形態
  static DESIRED_FACILITY_TYPE = [
    "特養",
    "老健",
    "有料",
    "サ高住",
    "GH",
    "デイ",
    "ショート",
    "養護",
    "病院",
    "診療",
    "小多機",
    "デイケア",
    "障がい",
    "軽費",
    "地域包括",
    "訪問介護",
    "訪問看護",
    "ケア",
    "訪入浴",
    "その他",
  ];

  //希望通勤手段
  static PREFERRED_COMMUTING_METHOD = ["車", "バイク・原付", "徒歩"];

  //三大介助の抵抗有無
  static RESISTANCE_TO_THREE_MAJOR_TYPES_OF_ASSISTANCE = [
    "あり",
    "なし",
    "わからない",
  ];

  //レクリエーションの対応可否
  static RECREATION_SUPPORT_AVAILABLE_OR_NOT = ["可", "不可", "わからない"];

  //調理補助の対応可否
  static COOKING_ASSISTANCE_AVAILABLE_OR_NOT = [
    "不可",
    "湯銭レベルであれば可能",
    "メニューが決まっており、材料もそろっている状態での調理であれば対応可能",
    "献立作りから対応可能",
    "わからない",
  ];

  //送迎業務の対応可否
  static AVAILABILITY_OF_TRANSPORTATION_SERVICES = [
    "不可",
    "軽自動車であれば対応可能",
    "普通車であれば対応可能（②も対応可能）",
    "送迎で使用することが多いバンタイプのお車も対応可能（②～③も対応可能）",
    "わからない",
  ];

  //希望条件の優先順位
  static PREFERRED_ORDER_OF_PREFERENCES = [
    "時間給・給与",
    "勤務時間・時間帯",
    "休日",
    "通勤距離・駅近",
    "就業日数",
    "職場環境",
    "その他",
  ];

  //車両任意保険加入状況
  static VOLUNTARY_VEHICLE_INSURANCE = ["加入済", "未加入"];

  //=============================== OSエントリー情報関係 ===============================

  //希望の雇用形態
  static DESIRED_EMPLOYMENT_TYPE = [
    "派遣",
    "直接雇用(正社員)",
    "直接雇用(契約社員)",
  ];

  //希望勤務期間
  static DESIRED_WORK_HOURS = [
    "短期(1か月以上～3か月未満)",
    "中期(3か月以上～6か月未満)",
    "長期(6か月以上)",
    "紹介予定派遣",
    "該当なし",
  ];

  //【希望職種】事務職
  static DESIRED_JOB_TYPE_CLERICAL_WORK = [
    "一般事務",
    "営業事務",
    "貿易・国際事務",
    "特許事務",
    "英文事務",
    "通訳・翻訳",
    "金融事務（銀行・証券）",
    "金融事務（生保・損保）",
    "経理事務・財経・会計",
    "総務・人事",
    "広報・宣伝",
    "秘書",
    "受付",
    "データ入力",
    "企画・マーケティング",
    "事務的軽作業（封入など）",
    "労務（保険手続き・給与計算）",
    "旅行事務",
    "医療事務",
    "学校事務",
  ];

  //希望職種】オペレーター職
  static DESIRED_JOB_TYPE_OPERATOR_POSITION = [
    "テレマーケティング（受信）",
    "テレマーケティング（発信）",
    "テレマーケティング（受発信）",
    "テレマーケティング（SV）",
    "テレマーケティング（保険）",
    "テレマーケティング（証券）",
    "カスタマーサポート（受電1次・受電2次）",
    "カスタマーサポート（メール対応）",
    "ヘルプデスク",
  ];

  //【希望職種】販売・接客・営業職・その他
  static DESIRED_JOB_TYPE_SALES_CUSTOMER_SERVICE_STAFF = [
    "販売（アパレル・コスメ）",
    "携帯販売（docomo）",
    "携帯販売（au）",
    "携帯販売（softbonk）",
    "携帯販売（その他キャリア）",
    "販売（家電・PCなど）",
    "販売（食品）",
    "接客（ショールーム・カウンター）",
    "販売・接客（その他）",
    "フード・飲食（接客）",
    "営業（企画営業）",
    "営業（ルート営業）",
    "製造・物流作業",
    "軽作業",
    "CADオペレーター",
    "CAD設計",
    "システムエンジニア",
    "プログラマー",
    "テスト・評価",
    "キッティング",
    "Webデザイナー",
    "Webディレクター",
    "Web制作・編集",
    "編集・制作・校正",
    "DTPオペレーター",
  ];

  //希望勤務可能日
  static DAY_OF_WEEK = ["月", "火", "水", "木", "金", "土", "日", "祝"];

  //希望勤務日数／週
  static DESIRED_NUMBER_OF_WORKING_DAYS = [
    "週1日",
    "週2日",
    "週3日",
    "週4日",
    "週5日",
  ];

  //希望勤務形態
  static DESIRED_WORK_TYPE = [
    "フルタイム(時間固定・平日)",
    "フルタイム(変則固定・平日)",
    "シフト制(土日含む)",
    "シフト制(時間固定)",
    "夜勤シフト",
    "該当なし",
  ];

  //残業可否
  static CAN_OVERTIME_WORK = ["可", "不可", "場合により可"];

  //夜勤可否
  static IS_IT_POSSIBLE = ["可", "不可"];

  //希望条件(時給)
  static HOURLY_WAGE_RANGE = [
    "800円～",
    "900円～",
    "1,000円～",
    "1,100円～",
    "1,200円～",
    "1,300円～",
    "1,400円～",
    "1,500円～",
    "1,600円～",
  ];

  //OAスキル5段階
  static OA_SKILL_LEVEL = [
    "実務応用操作/取得",
    "実務基本操作/受験のみ",
    "プライベート利用中/勉強中",
    "知識レベル",
    "該当なし",
  ];

  //就業経験
  static WORK_HISTORY = ["就業経験なし"];

  //一般事務
  static GENERAL_OFFICE_WORK = [
    "データ入力",
    "資料作成",
    "議事録作成",
    "部内アシスタント",
  ];

  //営業事務
  static BUSINESS_OFFICE_WORK = [
    "受注発注業務",
    "海外受発注",
    "見積作成",
    "契約書作成",
    "請求書作成",
    "納期管理",
    "営業アシスタント",
    "クレーム対応",
  ];

  //経理事務
  static ACCOUNTING_OFFICE_WORK = [
    "伝票起票",
    "伝票仕訳",
    "売掛金管理",
    "買掛金管理",
    "会計ソフト入力",
    "月次決算(補助含む)",
    "年次決算(補助含む)",
    "英文経理",
  ];

  //総務事務
  static CLERICAL_OFFICE_WORK = [
    "経費精算",
    "旅費計算",
    "小口現金管理",
    "備品管理・発注",
    "郵便物仕分",
  ];

  //人事事務
  static HR_OFFICE_WORK = [
    "給与計算",
    "給与計算・名分",
    "社会保険事務",
    "年末調整",
    "採用",
    "説明会対応",
    "面接",
    "合否連絡",
    "教育・研修",
    "社宅管理",
  ];

  //秘書
  static SECRETARY_OFFICE_WORK = [
    "役員付秘書(日本人)",
    "役員付秘書(外国人)",
    "グループ付秘書",
    "スケジュール管理",
    "慶弔対応",
    "出張手配",
  ];

  //受付
  static RECEPTION_OFFICE_WORK = ["総合受付", "ショールーム受付", "その他受付"];

  //貿易事務
  static TRADING_OFFICE_WORK = [
    "輸出業務",
    "輸入業務",
    "三国間貿易",
    "通関業務（乙仲）",
    "インボイス作成",
    "パッキングリスト",
    "B/L作成",
    "AWB作成",
    "A/N作成",
    "S/I作成",
    "L/C関係",
    "英語（メール・電話）",
  ];

  //英文事務
  static GENERAL_WORK_IN_ENGLISH = ["英文資料作成", "翻訳", "同時翻訳"];

  //電話業務
  static TELEPHONING_OFFICE_WORK = [
    "テレマーケティング",
    "テレフォンオペレーター",
    "テレフォンアポインター",
    "カスタマーサポート",
    "ユーザーサポート",
    "債権回収",
    "クレーム対応",
  ];

  //コールセンター
  static CALL_CENTER_WORK = ["受信", "発信", "対法人", "対個人"];

  //デザイン・WEB全般
  static WEB_DESIGN_WORK = [
    "サイト更新・修正",
    "サイトページの作成(HTML)",
    "バナー制作",
    "効果測定・分析(クリック率等)",
    "入稿・出稿",
    "メルマガ作成・配信",
    "進行管理",
    "マーケティング",
    "ランディングページ制作",
    "プレスリリース作成",
    "SNSの運用",
    "企画・立案",
    "その他",
  ];

  //保有資格
  static QUALIFICATIONS = [
    "普通自動車第一種運転免許",
    "宅地建物取引士",
    "社会保険労務士",
    "行政書士",
    "税理士",
    "弁護士",
    "中小企業診断士",
    "経理士",
    "宅地建物調査士",
    "不動産鑑定士",
    "ファイナンシャルプランナー",
    "基本情報技術者",
    "応用情報技術者",
    "ITパスポート",
    "商業登記代理業務取扱主任者",
    "簿記検定",
    "保育士資格",
    "看護師資格",
    "教員免許（小学校、中学校、高校など）",
    "キャリアコンサルタント",
    "介護福祉士",
    "精神保健福祉士",
    "社会福祉主事任用資格",
  ];

  //PC入力スキル
  static PC_SKILL = [
    "ブラインドタッチ入力",
    "手を見ながら入力",
    "キーボードの配置がわかり、時々手を見ながら入力	",
    "入力不可（PC経験なし）",
  ];

  //英語スキル
  static ENGLISH_SKILL = [
    "文章読み書き可能",
    "日常レベルの会話可能",
    "ビジネス上での英語の読み書き・会話が可能",
    "該当なし",
  ];

  //使用したOAソフト
  static OA_SOFTWARE = [
    "Word",
    "Excel",
    "Outlook",
    "Access",
    "PowerPoint",
    "illustrator",
    "Photoshop",
    "Dreamweaver",
    "その他",
    "使用なし",
  ];
  //雇用形態
  static WORK_TYPE = [];
}
