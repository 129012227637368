/** @jsxImportSource @emotion/react */
import { Typography, Box } from "@mui/material";

import StyledMuiTableCell from "../../../parts/table/StyledMuiTable/StyledMuiTableCell";
import StyledMuiTableHead from "../../../parts/table/StyledMuiTable/StyledMuiTableHead";
import StyledMuiTableRow from "../../../parts/table/StyledMuiTable/StyledMuiTableRow";
import PopoverEditHistory from "./PopoverEditHistory";

export default function PanelNc1({ item }) {
  function getMatchStatus(arr1, arr2) {
    return arr1.map((item) => ({
      label: item,
      isMatch: arr2.includes(item),
    }));
  }

  return (
    <>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[0].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              {getMatchStatus(["未", "済"], item[0].tdata).map((match, i) => {
                return (
                  <>
                    <Typography
                      component="span"
                      sx={{
                        fontSize: "0.5rem",
                        fontWeight: match.isMatch ? "bold" : "",
                        color: !match.isMatch ? "#CCC" : "",
                        pr: "5px",
                        display: "inline-block",
                      }}
                    >
                      {match.label}
                    </Typography>
                    {getMatchStatus(["未", "済"], item[0].tdata).length - 1 !==
                    i ? (
                      <Typography
                        component="span"
                        sx={{
                          fontSize: "0.5rem",
                          color: "#CCC",
                          display: "inline-block",
                          pr: "5px",
                        }}
                      >
                        &frasl;
                      </Typography>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </Box>
            {item[0].history ? (
              <PopoverEditHistory data={item[0]} />
            ) : (
              <Box sx={{ width: "20px" }} />
            )}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[1].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "0.5rem", fontWeight: "bold" }}>
              {item[1].tdata}
            </Typography>
            {item[1].history ? (
              <PopoverEditHistory data={item[1]} />
            ) : (
              <Typography />
            )}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[2].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "0.5rem", fontWeight: "bold" }}>
              {item[2].tdata}
            </Typography>
            {item[2].history ? (
              <PopoverEditHistory data={item[2]} />
            ) : (
              <Typography />
            )}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[3].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "0.5rem", fontWeight: "bold" }}>
              {item[3].tdata}
            </Typography>
            {item[3].history ? (
              <PopoverEditHistory data={item[3]} />
            ) : (
              <Typography />
            )}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[4].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "0.5rem", fontWeight: "bold" }}>
              {item[4].tdata}
            </Typography>
            {item[4].history ? (
              <PopoverEditHistory data={item[4]} />
            ) : (
              <Typography />
            )}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[5].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "0.5rem", fontWeight: "bold" }}>
              {item[5].tdata}
            </Typography>
            {item[5].history ? (
              <PopoverEditHistory data={item[5]} />
            ) : (
              <Typography />
            )}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[6].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "0.5rem", fontWeight: "bold" }}>
              {item[6].tdata}
            </Typography>
            {item[6].history ? (
              <PopoverEditHistory data={item[6]} />
            ) : (
              <Typography />
            )}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[7].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "0.5rem", fontWeight: "bold" }}>
              {item[7].tdata}
            </Typography>
            {item[7].history ? (
              <PopoverEditHistory data={item[7]} />
            ) : (
              <Typography />
            )}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[8].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "0.5rem", fontWeight: "bold" }}>
              {item[8].tdata}
            </Typography>
            {item[8].history ? (
              <PopoverEditHistory data={item[8]} />
            ) : (
              <Typography />
            )}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[9].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "0.5rem", fontWeight: "bold" }}>
              {item[9].tdata}
            </Typography>
            {item[9].history ? (
              <PopoverEditHistory data={item[9]} />
            ) : (
              <Typography />
            )}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[10].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "0.5rem", fontWeight: "bold" }}>
              {item[10].tdata}
            </Typography>
            {item[10].history ? (
              <PopoverEditHistory data={item[10]} />
            ) : (
              <Typography />
            )}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[11].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "0.5rem", fontWeight: "bold" }}>
              {item[11].tdata}
            </Typography>
            {item[11].history ? (
              <PopoverEditHistory data={item[11]} />
            ) : (
              <Typography />
            )}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[12].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "0.5rem", fontWeight: "bold" }}>
              {item[12].tdata}
            </Typography>
            {item[12].history ? (
              <PopoverEditHistory data={item[12]} />
            ) : (
              <Typography />
            )}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[13].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "0.5rem", fontWeight: "bold" }}>
              {item[13].tdata}
            </Typography>
            {item[13].history ? (
              <PopoverEditHistory data={item[13]} />
            ) : (
              <Typography />
            )}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[14].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "0.5rem", fontWeight: "bold" }}>
              {item[14].tdata}
            </Typography>
            {item[14].history ? (
              <PopoverEditHistory data={item[14]} />
            ) : (
              <Typography />
            )}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[15].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "0.5rem", fontWeight: "bold" }}>
              {item[15].tdata}
            </Typography>
            {item[15].history ? (
              <PopoverEditHistory data={item[15]} />
            ) : (
              <Typography />
            )}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
    </>
  );
}
