/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import {
  Box,
  Typography,
  Table,
  Paper,
  TableContainer,
  Button,
  Modal,
  TextField,
  IconButton,
  ToggleButtonGroup,
  ToggleButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";

import CancelIcon from "@mui/icons-material/Cancel";
import StyledMuiTableCell from "../../../parts/table/StyledMuiTable/StyledMuiTableCell";
import StyledMuiTableHead from "../../../parts/table/StyledMuiTable/StyledMuiTableHead";
import StyledMuiTableRow from "../../../parts/table/StyledMuiTable/StyledMuiTableRow";
import RadioButtonsProgresses from "../../../parts/radio/RadioButtonsProgresses";
import RadioButtonsIdentificationDoc from "../../../parts/radio/RadioButtonsIdentificationDoc";
import CheckboxRegistContanct from "../../../parts/checkbox/CheckboxRegistContanct";
import StyledReactDatePickerDate from "../../../parts/Datepicker/StyledReactDatePickerDate";
import MuiRadioButton from "../../../parts/radio/MuiRadioButton";
import ModalSendRequestEdit from "./ModalSendRequestEdit";
import Const from "../../../../libs/Const";

export default function ModalRegistContact({ editData, onClose, editFlg }) {
  const [formData, setFormData] = useState({
    action: editData ? editData.action : null,
    branch: editData ? editData.branch : null,
    status: editData ? editData.status : null,
    identification: editData ? editData.identification : null,
    web_entry: editData ? editData.web_entry : null,
    web_reserve: editData ? editData.web_reserve : null,
    send_mail: editData ? editData.send_mail : null,
    job_suggestion: editData ? editData.job_suggestion : null,
    comment: editData ? editData.comment : null,
    // 必要に応じて他のフィールドも追加してください
  });
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const [formats, setFormats] = useState([
    editData ? editData.web_entry : null,
    editData ? editData.web_reserve : null,
    editData ? editData.send_mail : null,
  ]);

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
  };

  const [activeModal, setActiveModal] = useState(null);
  const handleOpenModal = (modalId) => {
    setActiveModal(modalId);
  };
  const handleCloseModal = () => {
    setActiveModal(null);
  };

  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate("/send_mail", {
      state: {
        seiKanji: "応募",
        meiKanji: "花子",
        mailAddress: "oubo@ilovex.co.jp",
        fromRegistContact: true,
      },
    });
  };

  return (
    <Box css={styles.modal}>
      <Typography
        id="modal-title"
        variant="h6"
        component="h2"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography component="p" css={styles.title}>
          対応履歴入力
        </Typography>
        <IconButton
          aria-label="close-modal-view-more-contact"
          onClick={handleClose}
        >
          <CancelIcon sx={{ color: "#777" }} />
        </IconButton>
      </Typography>
      <Typography id="modal-description" sx={{ mt: 2 }}>
        <Box sx={{ width: "100%" }}>
          <TableContainer component={Paper} sx={{ my: 1, height: "75vh" }}>
            <Table aria-label="simple table">
              <StyledMuiTableRow sx={{ display: "flex", alignItems: "center" }}>
                <StyledMuiTableHead
                  css={styles.contactTableHeader}
                  align="left"
                >
                  ■対応日
                </StyledMuiTableHead>
                <StyledMuiTableCell sx={{ width: "75%" }}>
                  <StyledReactDatePickerDate fieldSize="100px" />
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow sx={{ display: "flex", alignItems: "center" }}>
                <StyledMuiTableHead
                  css={styles.contactTableHeader}
                  align="left"
                >
                  ■応募支店(担当)
                </StyledMuiTableHead>
                <StyledMuiTableCell sx={{ width: "75%" }}>
                  <FormControl sx={{ minWidth: 120 }} size="small">
                    <Select
                      labelId="branch"
                      id="branch"
                      value={formData.branch}
                      css={styles.selectBox}
                      sx={{ fontSize: "0.5rem" }}
                      displayEmpty
                      onChange={handleInputChange}
                      renderValue={(selected) => {
                        if (!selected || selected.length === 0) {
                          return (
                            <em style={{ fontSize: "0.5rem" }}>
                              ----------------
                            </em>
                          );
                        }
                        return selected;
                      }}
                    >
                      <MenuItem value="" sx={{ fontSize: "0.5rem" }}>
                        <em style={{ fontSize: "0.5rem" }}>----------------</em>
                      </MenuItem>
                      {Const.BRANCH_OPTIONS.map((option) => {
                        return (
                          <MenuItem value={option} sx={{ fontSize: "0.5rem" }}>
                            {option}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow sx={{ display: "flex", alignItems: "center" }}>
                <StyledMuiTableHead
                  css={styles.contactTableHeader}
                  align="left"
                >
                  ■対応区分
                </StyledMuiTableHead>
                <StyledMuiTableCell sx={{ width: "75%" }}>
                  <FormControl sx={{ fontSize: "0.5rem" }}>
                    <RadioGroup
                      row
                      aria-labelledby="radio-buttons-group-actions"
                      name="actions"
                      value={formData.action}
                      onChange={handleInputChange}
                    >
                      <FormControlLabel
                        value="コール(未接触)"
                        control={<Radio size="small" css={styles.inputRadio} />}
                        label="コール(未接触)"
                        css={styles.radioLabel}
                      />
                      <FormControlLabel
                        value="コール(接触)"
                        control={<Radio size="small" css={styles.inputRadio} />}
                        label="コール(接触)"
                        css={styles.radioLabel}
                      />
                      <FormControlLabel
                        value="入電"
                        control={<Radio size="small" css={styles.inputRadio} />}
                        label="入電"
                        css={styles.radioLabel}
                      />
                      <FormControlLabel
                        value="メール送信"
                        control={<Radio size="small" css={styles.inputRadio} />}
                        label="メール送信"
                        css={styles.radioLabel}
                      />
                      <FormControlLabel
                        value="メール受信"
                        control={<Radio size="small" css={styles.inputRadio} />}
                        label="メール受信"
                        css={styles.radioLabel}
                      />
                      <FormControlLabel
                        value="その他"
                        control={<Radio size="small" css={styles.inputRadio} />}
                        label="その他"
                        css={styles.radioLabel}
                      />
                    </RadioGroup>
                  </FormControl>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow sx={{ display: "flex", alignItems: "center" }}>
                <StyledMuiTableHead
                  css={styles.contactTableHeader}
                  align="left"
                >
                  ■ステータス
                </StyledMuiTableHead>
                <StyledMuiTableCell sx={{ width: "75%" }}>
                  <RadioButtonsProgresses defaultValue={formData.status} editFlg={editFlg} />
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow sx={{ display: "flex", alignItems: "center" }}>
                <StyledMuiTableHead
                  css={styles.contactTableHeader}
                  align="left"
                >
                  ■本人確認書類
                </StyledMuiTableHead>
                <StyledMuiTableCell sx={{ width: "75%" }}>
                  <FormControl sx={{ fontSize: "0.5rem" }}>
                    <RadioGroup
                      row
                      aria-labelledby="radio-buttons-group-identification-doc"
                      name="identification"
                      value={formData.identification}
                      onChange={handleInputChange}
                    >
                      <FormControlLabel
                        value="未対応(依頼前)"
                        control={<Radio size="small" css={styles.inputRadio} />}
                        label="未対応(依頼前)"
                        css={styles.radioLabel}
                      />
                      <FormControlLabel
                        value="提示依頼済み"
                        control={<Radio size="small" css={styles.inputRadio} />}
                        label="提示依頼済み"
                        css={styles.radioLabel}
                      />
                      <FormControlLabel
                        value="提示・確認済み"
                        control={<Radio size="small" css={styles.inputRadio} />}
                        label="提示・確認済み"
                        css={styles.radioLabel}
                      />
                    </RadioGroup>
                  </FormControl>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow sx={{ display: "flex", alignItems: "center" }}>
                <StyledMuiTableHead
                  css={styles.contactTableHeader}
                  align="left"
                >
                  ■Webエントリー
                </StyledMuiTableHead>
                <StyledMuiTableCell sx={{ width: "75%" }}>
                  <ToggleButtonGroup
                    size="small"
                    // value={formData.web_entry}
                    // onChange={handleInputChange}
                    value={formats}
                    onChange={handleFormat}
                    aria-label="text formatting"
                    css={styles.toggleBtn}
                  >
                    <ToggleButton
                      value="登録用"
                      sx={{ fontSize: "0.5rem", mr: "1rem" }}
                      onClick={() => handleOpenModal("registration")}
                    >
                      登録用
                    </ToggleButton>
                    <Modal
                      key="registration"
                      open={activeModal === "registration"}
                      onClose={handleCloseModal}
                      aria-labelledby="send-request-edit-modal-title"
                      aria-describedby="send-request-edit-modal-description"
                    >
                      <ModalSendRequestEdit onClose={handleCloseModal} />
                    </Modal>
                    <ToggleButton
                      value="開始用"
                      sx={{ fontSize: "0.5rem", mr: "1rem" }}
                      onClick={() => handleOpenModal("start")}
                    >
                      開始用
                    </ToggleButton>
                    <Modal
                      key="start"
                      open={activeModal === "start"}
                      onClose={handleCloseModal}
                      aria-labelledby="send-request-edit-modal-title"
                      aria-describedby="send-request-edit-modal-description"
                    >
                      <ModalSendRequestEdit onClose={handleCloseModal} />
                    </Modal>
                    <ToggleButton value="修正用" sx={{ fontSize: "0.5rem" }}>
                      修正用
                    </ToggleButton>
                  </ToggleButtonGroup>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow sx={{ display: "flex", alignItems: "center" }}>
                <StyledMuiTableHead
                  css={styles.contactTableHeader}
                  align="left"
                >
                  ■ネット予約
                </StyledMuiTableHead>
                <StyledMuiTableCell sx={{ width: "75%" }}>
                  <ToggleButtonGroup
                    size="small"
                    // value={formData.web_entry}
                    // onChange={handleInputChange}
                    value={formats}
                    onChange={handleFormat}
                    aria-label="ネット予約"
                    css={styles.toggleBtn}
                  >
                    <ToggleButton
                      value="案内メール送信"
                      sx={{ fontSize: "0.5rem", marginRight: "1rem" }}
                      onClick={() => handleOpenModal("send-request-edit")}
                    >
                      案内メール送信
                    </ToggleButton>
                    <Modal
                      key="send-request-edit"
                      open={activeModal === "send-request-edit"}
                      onClose={handleCloseModal}
                      aria-labelledby="send-request-edit-modal-title"
                      aria-describedby="send-request-edit-modal-description"
                    >
                      <ModalSendRequestEdit onClose={handleCloseModal} />
                    </Modal>
                    <ToggleButton
                      value="代理予約"
                      sx={{ fontSize: "0.5rem", marginRight: "1rem" }}
                      onClick={() => handleOpenModal("substitute-entry")}
                    >
                      代理予約
                    </ToggleButton>
                    <Modal
                      key="substitute-entry"
                      open={activeModal === "substitute-entry"}
                      onClose={handleCloseModal}
                      aria-labelledby="send-request-edit-modal-title"
                      aria-describedby="send-request-edit-modal-description"
                    >
                      <ModalSendRequestEdit onClose={handleCloseModal} />
                    </Modal>
                    <ToggleButton
                      component={Link}
                      to="https://xd.adobe.com/view/c813086e-2dcd-46ac-b652-56d46fb9a7e3-9d40/screen/dae92e5b-4dfc-4c80-b168-ceff4d324222"
                      target="_blank"
                      value="変更・キャンセル"
                      sx={{ fontSize: "0.5rem" }}
                    >
                      変更・キャンセル
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <Typography component="span" sx={{ fontSize: "0.5rem", marginLeft: "1rem" }}>※7/28（金）10:00 新宿支店 田中 Web</Typography>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow sx={{ display: "flex", alignItems: "center" }}>
                <StyledMuiTableHead
                  css={styles.contactTableHeader}
                  align="left"
                >
                  ■汎用メール送信
                </StyledMuiTableHead>
                <StyledMuiTableCell sx={{ width: "75%" }}>
                  <ToggleButtonGroup
                    size="small"
                    // value={formData.web_entry}
                    // onChange={handleInputChange}
                    value={formats}
                    onChange={handleFormat}
                    aria-label="send mail"
                    css={styles.toggleBtn}
                  >
                    <ToggleButton
                      value="汎用メール送信"
                      onClick={handleButtonClick}
                      sx={{
                        fontSize: "0.5rem",
                        marginRight: "1rem",
                        padding: "5px 1rem",
                      }}
                    >
                      汎用メール送信
                    </ToggleButton>
                  </ToggleButtonGroup>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow sx={{ display: "flex", alignItems: "center" }}>
                <StyledMuiTableHead
                  css={styles.contactTableHeader}
                  align="left"
                >
                  ■案件提示
                </StyledMuiTableHead>
                <StyledMuiTableCell sx={{ width: "75%" }}>
                  <CheckboxRegistContanct items={["提示NG"]} />
                  <FormControl sx={{ fontSize: "0.5rem" }}>
                    <RadioGroup
                      row
                      aria-labelledby="radio-buttons-group-job_suggestion"
                      name="job_suggestion"
                      value={formData.job_suggestion}
                      onChange={handleInputChange}
                    >
                      <FormControlLabel
                        value="職種NG"
                        control={<Radio size="small" css={styles.inputRadio} />}
                        label="職種NG"
                        css={styles.radioLabel}
                      />
                      <FormControlLabel
                        value="シフト条件NG"
                        control={<Radio size="small" css={styles.inputRadio} />}
                        label="シフト条件NG"
                        css={styles.radioLabel}
                      />
                      <FormControlLabel
                        value="時給NG"
                        control={<Radio size="small" css={styles.inputRadio} />}
                        label="時給NG"
                        css={styles.radioLabel}
                      />
                      <FormControlLabel
                        value="エリア(場所)NG"
                        control={<Radio size="small" css={styles.inputRadio} />}
                        label="エリア(場所)NG"
                        css={styles.radioLabel}
                      />
                      <FormControlLabel
                        value="交通費NG"
                        control={<Radio size="small" css={styles.inputRadio} />}
                        label="交通費NG"
                        css={styles.radioLabel}
                      />
                      <FormControlLabel
                        value="その他"
                        control={<Radio size="small" css={styles.inputRadio} />}
                        label="その他"
                        css={styles.radioLabel}
                      />
                    </RadioGroup>
                  </FormControl>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow
                sx={{ display: "flex", alignItems: "center", mb: 1 }}
              >
                <StyledMuiTableHead
                  css={styles.contactTableHeader}
                  align="left"
                >
                  ■対応内容
                </StyledMuiTableHead>
                <StyledMuiTableCell sx={{ width: "75%" }}>
                  <TextField
                    aria-label="minimum height"
                    multiline={true}
                    minRows={5}
                    sx={{ mt: 1, width: "80%" }}
                    css={styles.textarea}
                    value={formData.comment}
                    onChange={handleInputChange}
                    placeholder="コメントを追加してください。"
                  />
                </StyledMuiTableCell>
              </StyledMuiTableRow>
            </Table>
          </TableContainer>
        </Box>
      </Typography>
      <Box css={styles.BtnBlockCenter}>
        <Button variant="contained" color="inherit" css={styles.btnSizeSmall}>
          閉じる
        </Button>
        <Button variant="contained" css={styles.btnSizeSmall}>
          登録する
        </Button>
      </Box>
      {editFlg ? (
        <Button
          size="small"
          color="error"
          variant="contained"
          css={styles.btnSizeSmall}
          sx={{ position: "absolute", bottom: "15px", right: "8px" }}
        >
          履歴を削除する
        </Button>
      ) : (
        <></>
      )}
    </Box>
  );
}

const styles = {
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    backgroundColor: "#FFF",
    borderRadius: "0.25rem",
    padding: "15px 20px",
    fontSize: "0.625rem",
  },
  title: {
    display: "inline-block",
    padding: "0 1rem",
    fontSize: "1rem",
    color: "#0047A4",
    backgroundColor: "#c6e0f7",
    height: "1.5rem",
  },
  BtnBlockCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  btnSizeSmall: {
    transform: "scale(0.75)",
    transformOrigin: "center bottom",
  },
  textarea: {
    "& .MuiInputBase-input": {
      fontSize: "0.5rem",
    },
    "& .MuiInputBase-root": {
      padding: "3px 0.5rem",
    },
  },
  contactTableHeader: {
    width: "25%",
    color: "#4B576B",
  },
  toggleBtn: {
    "& .MuiToggleButtonGroup-grouped.Mui-selected+.MuiToggleButtonGroup-grouped.Mui-selected":
      {
        borderLeft: "initial",
        marginLeft: "initial",
      },
    "& .MuiToggleButtonGroup-grouped:not(:first-of-type)": {
      borderLeft: "initial",
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
      border: "1px solid #CCC",
      padding: "5px 1rem",
    },
    "& .MuiToggleButtonGroup-grouped:not(:last-of-type)": {
      borderLeft: "initial",
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
      border: "1px solid #CCC",
      padding: "5px 1rem",
    },
    "& .MuiToggleButton-root.Mui-selected": {
      backgroundColor: "#3DD37C",
      border: "1px solid #3DD37C",
      color: "#FFFFFF",
    },
  },
  inputRadio: {
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
    },
  },
  radioLabel: {
    "& .MuiFormControlLabel-label": {
      fontSize: "0.5rem",
    },
  },
  selectBox: {
    width: "100%",
    textAlign: "left",
    "& .MuiInputBase-input": {
      fontSize: "0.5rem",
      color: "#0047A4",
    },
    "& .MuiInputBase-root": {
      // padding: '3px 0.5rem',
      fontSize: "0.5rem",
    },
    "& .MuiFormLabel-root": {
      fontSize: "0.5rem",
    },
    "& .MuiInputLabel-root": {
      fontSize: "0.5rem",
    },
    "& .MuiSelect-root": {
      fontSize: "0.5rem",
    },
  },
  inputItemForm: {
    display: "flex",
    alignItems: "center",
  },
};
