/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  Stack,
  Button,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import DangerousIcon from "@mui/icons-material/Dangerous";
import DoneIcon from "@mui/icons-material/Done";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";

function ModalDeleteApplicants({ onClose, seiKanji, meiKanji }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const [displayFirst, setDisplayFirst] = useState("block");
  const [displaySecond, setDisplaySecond] = useState("none");
  const [displayThird, setDisplayThird] = useState("none");
  const handleClickNextConfirmBtn = () => {
    setDisplayFirst("none");
    setDisplaySecond("block");
  };
  const handleClickNextCompleteBtn = () => {
    setDisplaySecond("none");
    setDisplayThird("block");
  };

  return (
    <Box css={styles.modal}>
      <Box sx={{ display: displayFirst }}>
        <IconButton
          aria-label="close-modal-view-more-contact"
          onClick={handleClose}
          sx={{ position: "absolute", top: "0", right: "0" }}
        >
          <CancelIcon sx={{ color: "#777" }} />
        </IconButton>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Stack direction="column">
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <WarningIcon
                color="warning"
                sx={{ width: "5rem", height: "5rem" }}
              />
            </Box>
            <Typography component="p" sx={{ fontSize: "0.5rem" }}>
              {seiKanji}&emsp;{meiKanji}&nbsp;
              <Typography
                component="span"
                sx={{ fontSize: "0.5rem", color: "#4B576B" }}
              >
                様の削除処理に進みます。よろしいですか？
              </Typography>
            </Typography>
          </Stack>
        </Box>
        <Box css={styles.BtnBlockCenter}>
          <Button
            variant="contained"
            color="inherit"
            css={styles.btnSizeSmall}
            onClick={handleClose}
            sx={{ color: "#4B576B" }}
          >
            いいえ
          </Button>
          <Button
            variant="contained"
            color="error"
            css={styles.btnSizeSmall}
            onClick={handleClickNextConfirmBtn}
          >
            はい
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: displaySecond }}>
        <IconButton
          aria-label="close-modal-view-more-contact"
          onClick={handleClose}
          sx={{ position: "absolute", top: "0", right: "0" }}
        >
          <CancelIcon sx={{ color: "#777" }} />
        </IconButton>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Stack direction="column">
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <DangerousIcon
                color="error"
                sx={{ width: "5rem", height: "5rem" }}
              />
            </Box>
            <Typography component="p" sx={{ fontSize: "0.5rem" }}>
              {seiKanji}&emsp;{meiKanji}&nbsp;
              <Typography
                component="span"
                sx={{ fontSize: "0.5rem", color: "#4B576B" }}
              >
                様の履歴データも含めて完全に削除されます。よろしいですか？
              </Typography>
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleCheckboxChange}
                  inputProps={{ "aria-label": "Checkbox" }}
                  css={styles.inputCheckBox}
                />
              }
              label="はい、完全に削除します。"
              css={styles.checkBoxLabel}
            />
          </Stack>
        </Box>
        <Box css={styles.BtnBlockCenter}>
          <Button
            variant="contained"
            color="inherit"
            css={styles.btnSizeSmall}
            onClick={handleClose}
            sx={{ color: "#4B576B" }}
          >
            閉じる
          </Button>
          <Button
            variant="contained"
            color="error"
            css={styles.btnSizeSmall}
            disabled={!checked}
            onClick={handleClickNextCompleteBtn}
          >
            完全に削除する
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: displayThird }}>
        <IconButton
          aria-label="close-modal-view-more-contact"
          onClick={handleClose}
          sx={{ position: "absolute", top: "0", right: "0" }}
        >
          <CancelIcon sx={{ color: "#777" }} />
        </IconButton>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Stack direction="column">
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <DoneIcon
                color="success"
                sx={{ width: "5rem", height: "5rem" }}
              />
            </Box>
            <Typography component="p" sx={{ fontSize: "0.5rem" }}>
              {seiKanji}&emsp;{meiKanji}&nbsp;
              <Typography
                component="span"
                sx={{ fontSize: "0.5rem", color: "#4B576B" }}
              >
                様を削除しました。
              </Typography>
            </Typography>
          </Stack>
        </Box>
        <Box css={styles.BtnBlockCenter}>
          <Button
            variant="contained"
            color="inherit"
            css={styles.btnSizeSmall}
            onClick={handleClose}
            sx={{ color: "#4B576B" }}
          >
            閉じる
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

const styles = {
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    backgroundColor: "#FFF",
    borderRadius: "0.25rem",
    padding: "15px 20px",
    fontSize: "0.625rem",
  },
  BtnBlockCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  btnSizeSmall: {
    transform: "scale(0.75)",
    transformOrigin: "center bottom",
  },
  inputCheckBox: {
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
    },
  },
  checkBoxLabel: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& .MuiFormControlLabel-label": {
      fontSize: "0.5rem",
      color: "#4B576B",
    },
  },
};

export default ModalDeleteApplicants;
