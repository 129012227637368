/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import {
  Grid,
  Typography,
  Divider,
  TextField,
  FormControl,
  Box,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import StyledMuiPaper from "../../parts/surface/StyledMuiPaper";

import MuiSelectBox from "../../parts/select/MuiSelectBox";
import MuiCheckbox from "../../parts/checkbox/MuiCheckbox";
import MuiTextField from "../../parts/textField/MuiTextField";
import Const from "../../../libs/Const";

const styles = {
  middleWidth: {
    width: "50%",
    "@media (max-width:1200px)": {
      width: "90%",
      padding: "2rem",
    },
  },
  formBox: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  formTitle: {
    display: "inline-block",
    fontSize: "0.625rem",
    fontWeight: "bold",
    width: "10rem",
  },
  selectBoxForm: {
    minWidth: "100px",
    display: "inline-block",
  },
  selectBox: {
    width: "100%",
    fontSize: "0.5rem",
    "& .MuiInputBase-input": {
      fontSize: "0.5rem",
      color: "#0047A4",
    },
    "& .MuiInputBase-root": {
      // padding: '3px 0.5rem',
      fontSize: "0.5rem",
    },
    "& .MuiFormLabel-root": {
      fontSize: "0.5rem",
    },
    "& .MuiInputLabel-root": {
      fontSize: "0.5rem",
    },
    "& .MuiSelect-root": {
      fontSize: "0.5rem",
    },
  },
  fieldStyle: {
    mt: 1,
    "& .MuiInputBase-input": {
      fontSize: "0.5rem",
      color: "#0047A4",
    },
    "& .MuiInputLabel-root": {
      fontSize: "0.5rem",
    },
  },
  btnSize: {
    transform: "scale(0.75)",
    transformOrigin: "center center",
  },
};

export default function BranchMasterSettingForm() {
  const [division, setDivision] = useState("NC");
  const handleChange = (e) => {
    setDivision(e.target.value);
    console.log(division);
  };

  return (
    <StyledMuiPaper
      css={styles.middleWidth}
      sx={{
        overflow: "hidden",
        py: 5,
        mb: 3,
        textAlign: "left",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid
        container
        spacing={2}
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
        sx={{ px: 5 }}
      >
        <Grid item xs={12} css={styles.formBox}>
          <Typography component="p" variant="p" css={styles.formTitle}>
            事業部
          </Typography>
          <FormControl size="small" css={styles.selectBoxForm}>
            <Select
              id="division"
              value={division}
              onChange={handleChange}
              css={styles.selectBox}
            >
              <MenuItem value="NC" sx={{ fontSize: "0.5rem" }}>
                NC
              </MenuItem>
              <MenuItem value="OS" sx={{ fontSize: "0.5rem" }}>
                OS
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} css={styles.formBox}>
          <Typography component="p" variant="p" css={styles.formTitle}>
            支店
          </Typography>
          <MuiSelectBox
            items={Const.BRANCH_OPTIONS}
            id="branch"
            labelId="branch"
            css={styles.selectBox}
            width="200px"
          />
        </Grid>
        <Box sx={{ mt: 3, width: "100%", borderBottom: "1px solid #d9d9d9" }} />
        <Grid item xs={12} css={styles.formBox}>
          <Typography component="p" variant="p" css={styles.formTitle}>
            自動クローズ
          </Typography>
        </Grid>
        <Grid item xs={12} css={styles.formBox}>
          <Typography
            component="p"
            variant="p"
            css={styles.formTitle}
            sx={{ pl: 2 }}
          >
            有効
          </Typography>
          <MuiCheckbox items={["有効"]} />
        </Grid>
        <Grid item xs={12} css={styles.formBox}>
          <Typography
            component="p"
            variant="p"
            css={styles.formTitle}
            sx={{ pl: 2 }}
          >
            コール数
          </Typography>
          <TextField
            size="small"
            name="howmanyCalling"
            css={styles.fieldStyle}
            sx={{ width: "10%" }}
          />
        </Grid>
        <Grid item xs={12} css={styles.formBox}>
          <Typography
            component="p"
            variant="p"
            css={styles.formTitle}
            sx={{ pl: 2 }}
          >
            日数
          </Typography>
          <TextField
            size="small"
            name="howmanyDate"
            css={styles.fieldStyle}
            sx={{ width: "10%" }}
          />
        </Grid>
        <Box sx={{ mt: 3, width: "100%", borderBottom: "1px solid #d9d9d9" }} />
        <Grid item xs={12} css={styles.formBox}>
          <Typography component="p" variant="p" css={styles.formTitle}>
            メールアドレス
          </Typography>
        </Grid>
        <Grid item xs={12} css={styles.formBox}>
          <Typography
            component="p"
            variant="p"
            css={styles.formTitle}
            sx={{ pl: 2 }}
          >
            支店
          </Typography>
          <TextField
            size="small"
            name="branchMailAddress"
            css={styles.fieldStyle}
            sx={{ width: "50%" }}
          />
        </Grid>
        <Grid item xs={12} css={styles.formBox}>
          <Typography
            component="p"
            variant="p"
            css={styles.formTitle}
            sx={{ pl: 2 }}
          >
            {division === "NC" ? "HR" : "誘致共有"}
          </Typography>
          <TextField
            size="small"
            name="hr"
            css={styles.fieldStyle}
            sx={{ width: "50%" }}
          />
        </Grid>
        <Grid item xs={12} css={styles.formBox}>
          <Typography
            component="p"
            variant="p"
            css={styles.formTitle}
            sx={{ pl: 2 }}
          >
            予備
          </Typography>
          <TextField
            size="small"
            name="mailAddressSpare"
            css={styles.fieldStyle}
            sx={{ width: "50%" }}
          />
        </Grid>
        <Box sx={{ mt: 3, width: "100%", borderBottom: "1px solid #d9d9d9" }} />
        <Grid item xs={12} css={styles.formBox}>
          <Typography component="p" variant="p" css={styles.formTitle}>
            アクティブ判定
          </Typography>
        </Grid>
        <Grid item xs={12} css={styles.formBox}>
          <Typography
            component="p"
            variant="p"
            css={styles.formTitle}
            sx={{ pl: 2 }}
          >
            希望勤務日数
          </Typography>
          <MuiSelectBox
            items={Const.DESIRED_NUMBER_OF_WORKING_DAYS}
            id="branch"
            labelId="branch"
            css={styles.selectBox}
            width="30%"
          />
          <Typography variant="span" sx={{ fontSize: "0.5rem", pl: 1 }}>
            以上
          </Typography>
        </Grid>
        <Grid item xs={12} css={styles.formBox}>
          <Typography
            component="p"
            variant="p"
            css={styles.formTitle}
            sx={{ pl: 2 }}
          >
            希望勤務時間
          </Typography>
          {division === "NC" ? (
            <Typography variant="span" sx={{ fontSize: "0.5rem", pr: 1 }}>
              希望勤務時間の中から
            </Typography>
          ) : (
            <></>
          )}
          <TextField
            size="small"
            name="howmanyCalling"
            css={styles.fieldStyle}
            sx={{ width: "10%" }}
          />
          {division === "NC" ? (
            <Typography variant="span" sx={{ fontSize: "0.5rem", pl: 1 }}>
              つ以上選択
            </Typography>
          ) : (
            <Typography variant="span" sx={{ fontSize: "0.5rem", pl: 1 }}>
              時間以上
            </Typography>
          )}
        </Grid>
        {division === "NC" ? (
          <Grid item xs={12} css={styles.formBox}>
            <Typography
              component="p"
              variant="p"
              css={styles.formTitle}
              sx={{ pl: 2 }}
            >
              三大介助の抵抗有無
            </Typography>
            <MuiCheckbox
              items={["三大介助の抵抗有無をアクティブの判定条件に含める"]}
            />
          </Grid>
        ) : (
          <></>
        )}
        {division === "OS" ? (
          <>
            <Box
              sx={{ mt: 3, width: "100%", borderBottom: "1px solid #d9d9d9" }}
            />
            <Grid item xs={12} css={styles.formBox}>
              <Typography component="p" variant="p" css={styles.formTitle}>
                登録会予約案内メール記載内容
              </Typography>
            </Grid>
            <Grid item xs={12} css={styles.formBox}>
              <Typography
                component="p"
                variant="p"
                css={styles.formTitle}
                sx={{ pl: 2 }}
              >
                登録会予約センター名
              </Typography>
              <TextField
                size="small"
                name="howmanyDate"
                css={styles.fieldStyle}
                sx={{ width: "50%" }}
              />
            </Grid>
            <Grid item xs={12} css={styles.formBox}>
              <Typography
                component="p"
                variant="p"
                css={styles.formTitle}
                sx={{ pl: 2 }}
              >
                登録会予約用TEL
              </Typography>
              <TextField
                size="small"
                name="howmanyCalling"
                css={styles.fieldStyle}
                sx={{ width: "50%" }}
              />
            </Grid>
            <Grid item xs={12} css={styles.formBox}>
              <Typography
                component="p"
                variant="p"
                css={styles.formTitle}
                sx={{ pl: 2 }}
              >
                電話予約の受付時間
              </Typography>
              <TextField
                size="small"
                name="howmanyDate"
                css={styles.fieldStyle}
                sx={{ width: "50%" }}
              />
            </Grid>
            <Grid item xs={12} css={styles.formBox}>
              <Typography
                component="p"
                variant="p"
                css={styles.formTitle}
                sx={{ pl: 2 }}
              >
                登録会予約用署名
              </Typography>
              <TextField
                size="small"
                name="howmanyDate"
                multiline={true}
                minRows="6"
                css={styles.fieldStyle}
                sx={{ width: "65%", padding: "initial" }}
              />
            </Grid>
          </>
        ) : (
          <></>
        )}
        <Box sx={{ mt: 3, width: "100%", borderBottom: "1px solid #d9d9d9" }} />
        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <Button variant="contained" css={styles.btnSize}>
            更新
          </Button>
        </Grid>
      </Grid>
    </StyledMuiPaper>
  );
}
