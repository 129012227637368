/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import StyledMuiBtnBlue from "../../../parts/button/StyledMuiBtnBlue";
import { Button } from "@mui/material";

const styles = {
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    backgroundColor: "#FFF",
    borderRadius: "0.25rem",
    padding: "15px 20px",
    fontSize: "0.625rem",
  },
  flexSaC: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  flexCC: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  btnSizeSmall: {
    transform: "scale(0.75)",
    transformOrigin: "center bottom",
  },
};

export default function ModalSendMailConfirm({ onClose, count }) {
  return (
    <Box css={styles.modal}>
      <Alert severity="warning">
        {count}件のメールを送信します。よろしいですか？
      </Alert>
      <Box css={styles.flexSaC} sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="inherit"
          css={styles.btnSizeSmall}
          onClick={onClose}
          sx={{ display: "inline-block", color: "#555" }}
        >
          戻る
        </Button>
        <Button
          variant="contained"
          css={styles.btnSizeSmall}
          color="primary"
          sx={{ display: "inline-block" }}
        >
          送信する
        </Button>
      </Box>
    </Box>
  );
}
