/** @jsxImportSource @emotion/react */
import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

function LoginPage() {
  return (
    <Box css={styles.loginFormBox}>
      <Paper css={styles.loginFormPaper}>
        <Box sx={{ textAlign: "center" }}>
          <Typography component="h1" css={styles.titleTypo}>
            ふぁん君
          </Typography>
          <Typography
            component="p"
            sx={{ color: "#0047A4", fontSize: "0.7rem" }}
          >
            応募者管理システム
          </Typography>
        </Box>
        <Box css={styles.TextFieldBlock}>
          <TextField
            id={"userId"}
            type="text"
            label="ユーザID"
            size="small"
            margin="normal"
            sx={{ width: "60%" }}
          />
        </Box>
        <Box css={styles.TextFieldBlock}>
          <TextField
            id={"password"}
            type="password"
            label="パスワード"
            size="small"
            margin="normal"
            sx={{ width: "60%" }}
          />
        </Box>
        <Box css={styles.TextFieldBlock}>
          <Link to="https://www.neo-career.co.jp/">
            パスワードを忘れた方はこちら
          </Link>
        </Box>
        <Box css={styles.TextFieldBlock} sx={{ marginTop: "32px" }}>
          <Button type="submit" variant="contained" color="primary">
            <Link to="/top" css={styles.buttonLink}>
              ログイン
            </Link>
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

const styles = {
  titleTypo: {
    fontSize: "1.4rem",
    fontWeight: "regular",
    color: "#0047A4",
    marginBottom: "4px",
  },
  loginFormBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "4rem",
  },
  loginFormPaper: {
    width: "40vw",
    minWidth: "350px",
    height: "40vh",
    minHeight: "400px",
    padding: "1rem 2rem",
  },
  TextFieldBlock: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "1rem",
    fontSize: "0.7rem",
  },
  buttonLink: {
    color: "#FFFFFF",
    textDecoration: "none",
  },
};

export default LoginPage;
