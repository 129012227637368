/** @jsxImportSource @emotion/react */
import React, { useState } from "react";

import {
  Button, Tab, Typography, Box, Table, TableContainer, TableCell, TableRow, TableBody, TableHead, Paper, Popover,
  } from '@mui/material';

import HistoryIcon from '@mui/icons-material/History';
import StyledMuiTableCell from '../../../parts/table/StyledMuiTable/StyledMuiTableCell';
import StyledMuiTableHead from '../../../parts/table/StyledMuiTable/StyledMuiTableHead';
import StyledMuiTableRow from '../../../parts/table/StyledMuiTable/StyledMuiTableRow';
import PopoverEditHistory from "./PopoverEditHistory";
import Const from "../../../../libs/Const";

export default function PanelOs4({item}) {

  function getMatchStatus(arr1, arr2) {
    return arr1.map(item => ({
      label: item,
      isMatch: arr2.includes(item)
    }));
  }

  function getLastNonString(array) {
    if (Array.isArray(array) && array.length > 0) {
      const lastElement = array[array.length - 1];
      return Const.CALL_CENTER_WORK.some(item => item === lastElement) ? null : lastElement;
    }
    return null;
  }

  return(
    <>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[0].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.WORK_HISTORY, item[0].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.WORK_HISTORY, item[0].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[0].history ? <PopoverEditHistory data={item[0]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[1].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.GENERAL_OFFICE_WORK, item[1].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.GENERAL_OFFICE_WORK, item[1].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[1].history ? <PopoverEditHistory data={item[1]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[2].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.BUSINESS_OFFICE_WORK, item[2].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.BUSINESS_OFFICE_WORK, item[2].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[2].history ? <PopoverEditHistory data={item[2]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[3].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.ACCOUNTING_OFFICE_WORK, item[3].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.ACCOUNTING_OFFICE_WORK, item[3].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[3].history ? <PopoverEditHistory data={item[3]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[4].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.CLERICAL_OFFICE_WORK, item[4].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.CLERICAL_OFFICE_WORK, item[4].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[4].history ? <PopoverEditHistory data={item[4]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[5].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.HR_OFFICE_WORK, item[5].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.HR_OFFICE_WORK, item[5].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[5].history ? <PopoverEditHistory data={item[5]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[6].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.SECRETARY_OFFICE_WORK, item[6].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.SECRETARY_OFFICE_WORK, item[6].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[6].history ? <PopoverEditHistory data={item[6]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[7].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.RECEPTION_OFFICE_WORK, item[7].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.RECEPTION_OFFICE_WORK, item[7].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[7].history ? <PopoverEditHistory data={item[7]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[8].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.TRADING_OFFICE_WORK, item[8].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.TRADING_OFFICE_WORK, item[8].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[8].history ? <PopoverEditHistory data={item[8]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[9].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.GENERAL_WORK_IN_ENGLISH, item[9].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.GENERAL_WORK_IN_ENGLISH, item[9].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[9].history ? <PopoverEditHistory data={item[9]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[10].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.TELEPHONING_OFFICE_WORK, item[10].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.TELEPHONING_OFFICE_WORK, item[10].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[10].history ? <PopoverEditHistory data={item[10]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left" sx={{ width: '37%' }}>{item[11].thead}</StyledMuiTableHead> 
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[11].tdata}</Typography>
            {item[11].history ? <PopoverEditHistory data={item[11]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left" sx={{ width: '37%', pl:6 }}>{item[12].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.CALL_CENTER_WORK, item[12].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: 1,
                    display: 'inline-block'
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.CALL_CENTER_WORK, item[12].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            {getLastNonString(item[12].tdata) !== null 
              ? <Typography component="span" sx={{ fontSize: '0.5rem', pl:3, color: '#4B576B' }}>
                  経験年数：&nbsp;
                  <Typography component="span" sx={{ fontSize: '0.5rem', color: '#0047A4', fontWeight: 'bold' }}>{getLastNonString(item[12].tdata)}</Typography>
                  &nbsp;年
                </Typography>
              : <></>}
            </Box>
            {item[12].history ? <PopoverEditHistory data={item[12]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left" sx={{ width: '37%', pl:6 }}>{item[13].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.CALL_CENTER_WORK, item[13].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: 1,
                    display: 'inline-block'
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.CALL_CENTER_WORK, item[13].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            {getLastNonString(item[13].tdata) !== null 
              ? <Typography component="span" sx={{ fontSize: '0.5rem', pl:3, color: '#4B576B' }}>
                  経験年数：&nbsp;
                  <Typography component="span" sx={{ fontSize: '0.5rem', color: '#0047A4', fontWeight: 'bold' }}>{getLastNonString(item[13].tdata)}</Typography>
                  &nbsp;年
                </Typography>
              : <></>}
            </Box>
            {item[13].history ? <PopoverEditHistory data={item[13]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left" sx={{ width: '37%', pl:6 }}>{item[14].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.CALL_CENTER_WORK, item[14].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: 1,
                    display: 'inline-block'
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.CALL_CENTER_WORK, item[14].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            {getLastNonString(item[14].tdata) !== null 
              ? <Typography component="span" sx={{ fontSize: '0.5rem', pl:3, color: '#4B576B' }}>
                  経験年数：&nbsp;
                  <Typography component="span" sx={{ fontSize: '0.5rem', color: '#0047A4', fontWeight: 'bold' }}>{getLastNonString(item[14].tdata)}</Typography>
                  &nbsp;年
                </Typography>
              : <></>}
            </Box>
            {item[14].history ? <PopoverEditHistory data={item[14]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left" sx={{ width: '37%', pl:6 }}>{item[15].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.CALL_CENTER_WORK, item[15].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: 1,
                    display: 'inline-block'
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.CALL_CENTER_WORK, item[15].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            {getLastNonString(item[15].tdata) !== null 
              ? <Typography component="span" sx={{ fontSize: '0.5rem', pl:3, color: '#4B576B' }}>
                  経験年数：&nbsp;
                  <Typography component="span" sx={{ fontSize: '0.5rem', color: '#0047A4', fontWeight: 'bold' }}>{getLastNonString(item[15].tdata)}</Typography>
                  &nbsp;年
                </Typography>
              : <></>}
            </Box>
            {item[15].history ? <PopoverEditHistory data={item[15]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left" sx={{ width: '37%', pl:6 }}>{item[16].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.CALL_CENTER_WORK, item[16].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: 1,
                    display: 'inline-block'
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.CALL_CENTER_WORK, item[16].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            {getLastNonString(item[16].tdata) !== null 
              ? <Typography component="span" sx={{ fontSize: '0.5rem', pl:3, color: '#4B576B' }}>
                  経験年数：&nbsp;
                  <Typography component="span" sx={{ fontSize: '0.5rem', color: '#0047A4', fontWeight: 'bold' }}>{getLastNonString(item[16].tdata)}</Typography>
                  &nbsp;年
                </Typography>
              : <></>}
            </Box>
            {item[16].history ? <PopoverEditHistory data={item[16]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left" sx={{ width: '37%', pl:6 }}>{item[17].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.CALL_CENTER_WORK, item[17].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: 1,
                    display: 'inline-block'
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.CALL_CENTER_WORK, item[17].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            {getLastNonString(item[17].tdata) !== null 
              ? <Typography component="span" sx={{ fontSize: '0.5rem', pl:3, color: '#4B576B' }}>
                  経験年数：&nbsp;
                  <Typography component="span" sx={{ fontSize: '0.5rem', color: '#0047A4', fontWeight: 'bold' }}>{getLastNonString(item[17].tdata)}</Typography>
                  &nbsp;年
                </Typography>
              : <></>}
            </Box>
            {item[17].history ? <PopoverEditHistory data={item[17]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left" sx={{ width: '37%', pl:6 }}>{item[18].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.CALL_CENTER_WORK, item[18].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: 1,
                    display: 'inline-block'
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.CALL_CENTER_WORK, item[18].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            {getLastNonString(item[18].tdata) !== null 
              ? <Typography component="span" sx={{ fontSize: '0.5rem', pl:3, color: '#4B576B' }}>
                  経験年数：&nbsp;
                  <Typography component="span" sx={{ fontSize: '0.5rem', color: '#0047A4', fontWeight: 'bold' }}>{getLastNonString(item[18].tdata)}</Typography>
                  &nbsp;年
                </Typography>
              : <></>}
            </Box>
            {item[18].history ? <PopoverEditHistory data={item[18]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left" sx={{ width: '37%', pl:6 }}>{item[19].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.CALL_CENTER_WORK, item[19].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: 1,
                    display: 'inline-block'
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.CALL_CENTER_WORK, item[19].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            {getLastNonString(item[19].tdata) !== null 
              ? <Typography component="span" sx={{ fontSize: '0.5rem', pl:3, color: '#4B576B' }}>
                  経験年数：&nbsp;
                  <Typography component="span" sx={{ fontSize: '0.5rem', color: '#0047A4', fontWeight: 'bold' }}>{getLastNonString(item[19].tdata)}</Typography>
                  &nbsp;年
                </Typography>
              : <></>}
            </Box>
            {item[19].history ? <PopoverEditHistory data={item[19]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left" sx={{ width: '37%', pl:6 }}>{item[20].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.CALL_CENTER_WORK, item[20].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: 1,
                    display: 'inline-block'
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.CALL_CENTER_WORK, item[20].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            {getLastNonString(item[20].tdata) !== null 
              ? <Typography component="span" sx={{ fontSize: '0.5rem', pl:3, color: '#4B576B' }}>
                  経験年数：&nbsp;
                  <Typography component="span" sx={{ fontSize: '0.5rem', color: '#0047A4', fontWeight: 'bold' }}>{getLastNonString(item[20].tdata)}</Typography>
                  &nbsp;年
                </Typography>
              : <></>}
            </Box>
            {item[20].history ? <PopoverEditHistory data={item[20]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left" sx={{ width: '37%', pl:6 }}>{item[21].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.CALL_CENTER_WORK, item[21].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: 1,
                    display: 'inline-block'
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.CALL_CENTER_WORK, item[21].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            {getLastNonString(item[21].tdata) !== null 
              ? <Typography component="span" sx={{ fontSize: '0.5rem', pl:3, color: '#4B576B' }}>
                  経験年数：&nbsp;
                  <Typography component="span" sx={{ fontSize: '0.5rem', color: '#0047A4', fontWeight: 'bold' }}>{getLastNonString(item[21].tdata)}</Typography>
                  &nbsp;年
                </Typography>
              : <></>}
            </Box>
            {item[21].history ? <PopoverEditHistory data={item[21]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[22].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.WEB_DESIGN_WORK, item[22].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.WEB_DESIGN_WORK, item[22].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[22].history ? <PopoverEditHistory data={item[22]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
    </>
  )
}

