
function DummyEntryDataOs6(){

  const tableDataListsOS6 = [
    {
      id: 'os-6-1',
      thead: '【職務経歴1】',
      tdata: [
        
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-2',
      thead: '企業名',
      tdata: [
        'ABC株式会社'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-3',
      thead: '所属部署名',
      tdata: [
        'マーケティング部'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-4',
      thead: '入社日',
      tdata: [
        ' 2017/04/01'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-5',
      thead: '退職日',
      tdata: [
        '2022/03/31'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-6',
      thead: '雇用形態',
      tdata: [
        '正社員'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-7',
      thead: '職務内容',
      tdata: [
        'マーケティング戦略の立案と実行、広告・プロモーション企画の企画・実行、マーケットリサーチの実施、競合他社分析、プロモーションイベントの企画・実行など'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-8',
      thead: '職種カテゴリー',
      tdata: [
        '情報通信'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-9',
      thead: '職種',
      tdata: [
        'IT・サービス業'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-10',
      thead: '使用したOAソフト',
      tdata: [
        'Word',
        'Excel',
        'Outlook',
        'PowerPoint',
        'illustrator',
        'Photoshop',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-11',
      thead: '給与',
      tdata: [
        
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-12',
      thead: '退職理由',
      tdata: [
        
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-13',
      thead: '【職務経歴2】',
      tdata: [
        
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-14',
      thead: '企業名',
      tdata: [
        'XYZ株式会社'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-15',
      thead: '所属部署名',
      tdata: [
        '技術開発部'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-16',
      thead: '入社日',
      tdata: [
        '2015/10/01'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-17',
      thead: '退職日',
      tdata: [
        '2023/09/30'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-18',
      thead: '雇用形態',
      tdata: [
        '契約社員'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-19',
      thead: '職務内容',
      tdata: [
        '新製品の設計・開発、既存製品の改良・改善、生産ラインの立ち上げ・改善、品質管理業務、開発プロセスの改善など'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-20',
      thead: '職種カテゴリー',
      tdata: [
        '製造'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-21',
      thead: '職種',
      tdata: [
        '製造業'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-22',
      thead: '使用したOAソフト',
      tdata: [
        'Word',
        'Excel',
        'Outlook',
        'PowerPoint',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-23',
      thead: '給与',
      tdata: [
        
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-24',
      thead: '退職理由',
      tdata: [
        
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-25',
      thead: '【最終学歴】',
      tdata: [
        
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-26',
      thead: '学校名',
      tdata: [
        '東京大学'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-27',
      thead: '最終学歴区分',
      tdata: [
        '大学'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-28',
      thead: '専攻学部/学科',
      tdata: [
        '経済学部'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-29',
      thead: '卒業年月',
      tdata: [
        '2020/03'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-6-30',
      thead: '卒業区分',
      tdata: [
        '卒業'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
  ];
  return tableDataListsOS6;

}

export default DummyEntryDataOs6;