/** @jsxImportSource @emotion/react */
import React from "react";
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const styles = {
  inputRadio:{
    '& .MuiSvgIcon-root':{
      fontSize: '1rem',
    },
  },
  radioLabel:{
    '& .MuiFormControlLabel-label':{
      fontSize: '0.5rem',
    },
  },
}


export default function MuiRadioButton(props) {
  const { ariaLabel, name, formLabels, defaultValue, disabled, ...other } = props;

  return (
    <FormControl sx={{ fontSize: '0.5rem' }}>
      <RadioGroup
        row
        aria-labelledby={ariaLabel}
        name={name}
        defaultValue={defaultValue}
      >
        {formLabels.map((radio, i) => {
          return (
            <FormControlLabel value={radio.value} control={<Radio size="small" css={styles.inputRadio} disabled={disabled} />} label={radio.label} css={styles.radioLabel}/>
          )
        })}
      </RadioGroup>
    </FormControl>
  );
}
