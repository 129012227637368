function PersonCircleMan() {
  return (
    <svg
      version="1.1"
      id="person_circle_man"
      x="0px"
      y="0px"
      viewBox="0 0 48 48"
      style={{ enableBackground: "new 0 0 48 48" }}
      width="36"
      height="36"
    >
      <style
        type="text/css"
        dangerouslySetInnerHTML={{
          __html:
            "\n    .person_circle_man0{fill:#548AD1;stroke:#548AD1;stroke-miterlimit:10;} .person_circle_man1{fill:#548AD1;}\n  ",
        }}
      />
      <path
        className="person_circle_man0"
        d="M24,24c-2.2,0-4-0.7-5.4-2.1c-1.4-1.4-2.1-3.2-2.1-5.4s0.7-4,2.1-5.4C20,9.7,21.8,9,24,9s4,0.7,5.4,2.1
      c1.4,1.4,2.1,3.2,2.1,5.4s-0.7,4-2.1,5.4C28,23.2,26.2,24,24,24z M8,40v-4.7C8,34,8.3,32.9,8.9,32c0.6-0.9,1.4-1.6,2.4-2
      c2.2-1,4.4-1.8,6.4-2.2S21.9,27,24,27s4.1,0.3,6.1,0.8s4.2,1.3,6.4,2.2c1,0.5,1.9,1.1,2.5,2c0.6,0.9,1,2,1,3.2V40H8z"
      />
      <path
        className="person_circle_man1"
        d="M24,3c11.6,0,21,9.4,21,21s-9.4,21-21,21S3,35.6,3,24S12.4,3,24,3 M24,0C10.7,0,0,10.7,0,24
        c0,13.3,10.7,24,24,24c13.3,0,24-10.7,24-24C48,10.7,37.3,0,24,0L24,0z"
      />
    </svg>
  );
}

export default PersonCircleMan;
