/** @jsxImportSource @emotion/react */
import React from "react";
import StyledMuiAppBar from "../parts/AppBar/StyledMuiAppBar";
import ApplicantsBasicInfo from "../feature/ApplicantsDetail/ApplicantsBasicInfo";
import ApplicantsEntryInfo from "../feature/ApplicantsDetail/ApplicantsEntryInfo";
import ApplicantsContactHistory from "../feature/ApplicantsDetail/ApplicantsContactHistory";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

const isAntisocial = false;

export default function ApplicantsDetailPage() {
  return (
    <>
      <StyledMuiAppBar headerTitle="応募者管理" />
      <Box sx={{ width: "100%", mt: 6, mb: 3, flexGrow: 1 }}>
        <Grid container flexDirection="row" wrap="wrap" sx={{ p: 1 }}>
          <Grid item xs={12} md={12} lg={6} xl={6} css={styles.gridStyleLeft}>
            <Grid container flexDirection="column" wrap="wrap">
              <Grid item css={styles.gridStyleLeftTop}>
                <ApplicantsBasicInfo />
              </Grid>
              <Grid item css={styles.gridStyleLeftBottom}>
                <ApplicantsContactHistory />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} lg={6} xl={6} css={styles.gridStyleRight}>
            {isAntisocial ? (
              <Box sx={{ width: "100%", height: "100%", background: "red" }} />
            ) : (
              <ApplicantsEntryInfo />
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

const styles = {
  gridStyleLeft: {
    height: "90vh",
    "@media screen and (max-width: 1200px)": {
      height: "initial",
    },
  },
  gridStyleLeftTop: {
    height: "45vh",
    paddingBottom: "8px",
    "@media screen and (max-width: 1200px)": {
      height: "60vh",
    },
  },
  gridStyleLeftBottom: {
    height: "45vh",
    paddingTop: "8px",
    "@media screen and (max-width: 1200px)": {
      height: "60vh",
    },
  },
  gridStyleRight: {
    height: "90vh",
    paddingTop: "0px",
    paddingLeft: "16px",
    "@media screen and (max-width: 1200px)": {
      paddingLeft: "0px",
      paddingTop: "16px",
    },
  },
};
