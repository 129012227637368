/** @jsxImportSource @emotion/react */
import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import StyledMuiPaper from "../../parts/surface/StyledMuiPaper";
import CheckBoxApplicantSearch from "../../parts/checkbox/CheckBoxApplicantSearch";
import SelectedDepartment from "../../parts/select/SelectedDepartment";
import SelectedOffice from "../../parts/select/SelectedOffice";
import SelectedBranch from "../../parts/select/SelectedBranch";
import TextFiledApplicantSearch from "../../parts/textField/TextFiledApplicantSearch";
import StyledReactDatePickerDate from "../../parts/Datepicker/StyledReactDatePickerDate";
import SelectedPrefectureAndCity from "../../parts/select/SelectedPrefectureAndCity";
import SelectedAppliMedia from "../../parts/select/SelectedAppliMedia";
import StyledMuiBtnBlue from "../../parts/button/StyledMuiBtnBlue";

import Const from "../../../libs/Const";

const label = { inputProps: { "aria-label": "mitaiou" } };

export default function ApplicantsSearch() {
  const [branch1, setBranch1] = React.useState("");
  const handleChangeBranch1 = (event) => {
    setBranch1(event.target.value);
  };
  return (
    <>
      <StyledMuiPaper
        sx={{ width: "100%", overflow: "hidden", py: 5, textAlign: "left" }}
      >
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          sx={{ px: 5 }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box css={styles.titleAndForm}>
              <Typography component="div" css={styles.titleStyles}>
                新規/既存
              </Typography>
              <CheckBoxApplicantSearch
                items={Const.NEW_OR_EXISTING}
                disabled={null}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box css={styles.titleAndForm}>
              <Typography component="div" css={styles.titleStyles}>
                ステータス
              </Typography>
              <CheckBoxApplicantSearch
                items={Const.STATUS_LIST}
                disabled={null}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box css={styles.titleAndForm}>
              <Typography component="div" css={styles.titleStyles}>
                応募支店（担当）
              </Typography>
              <SelectedDepartment />
              <SelectedOffice />
              <SelectedBranch />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box css={styles.titleAndForm}>
              <Typography component="div" css={styles.titleStyles}>
                登録支店
              </Typography>
              <SelectedDepartment />
              <SelectedOffice />
              <SelectedBranch />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box css={styles.titleAndForm}>
              <Typography component="div" css={styles.titleStyles}>
                名前（カナ可）
              </Typography>
              <TextFiledApplicantSearch
                id="applicant_name"
                label="名前"
                fieldSize={200}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box css={styles.titleAndForm}>
              <Typography component="div" css={styles.titleStyles}>
                住所
              </Typography>
              <SelectedPrefectureAndCity />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box css={styles.titleAndForm}>
              <Typography component="div" css={styles.titleStyles}>
                応募者コード
              </Typography>
              <TextFiledApplicantSearch
                id="appli_id"
                label="応募者コード"
                fieldSize={200}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box css={styles.titleAndForm}>
              <Typography component="div" css={styles.titleStyles}>
                CAERUS
              </Typography>
              <TextFiledApplicantSearch
                id="caerus_code"
                label="CAERUSスタッフコード"
                fieldSize={200}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box css={styles.titleAndForm}>
              <Typography component="div" css={styles.titleStyles}>
                年齢
              </Typography>
              <TextFiledApplicantSearch
                id="age_from"
                label="From"
                fieldSize={60}
              />
              <span>&nbsp;～&nbsp;</span>
              <TextFiledApplicantSearch id="age_to" label="To" fieldSize={60} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box css={styles.titleAndForm}>
              <Typography component="div" css={styles.titleStyles}>
                電話番号
              </Typography>
              <TextFiledApplicantSearch
                id="tel"
                label="電話番号"
                fieldSize={200}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box css={styles.titleAndForm}>
              <Typography component="div" css={styles.titleStyles}>
                メールアドレス
              </Typography>
              <TextFiledApplicantSearch
                id="mail_address"
                label="メールアドレス"
                fieldSize={200}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box css={styles.titleAndForm}>
              <Typography component="div" css={styles.titleStyles}>
                応募日
              </Typography>
              <StyledReactDatePickerDate fieldSize={90} />
              <span>&nbsp;～&nbsp;</span>
              <StyledReactDatePickerDate fieldSize={90} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box css={styles.titleAndForm}>
              <Typography component="div" css={styles.titleStyles}>
                応募媒体
              </Typography>
              <SelectedAppliMedia fieldSize={200} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box css={styles.titleAndForm}>
              <Typography component="div" css={styles.titleStyles}>
                求人タイトル
              </Typography>
              <TextFiledApplicantSearch
                id="job_title"
                label="求人タイトル"
                fieldSize={200}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box css={styles.titleAndForm}>
              <Typography component="div" css={styles.titleStyles}>
                本人確認書類
              </Typography>
              <CheckBoxApplicantSearch
                items={["未対応（依頼前）", "提示依頼済み", "提示・確認済み"]}
                disabled={null}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box css={styles.titleAndForm}>
              <Typography component="div" css={styles.titleStyles}>
                在留資格
              </Typography>
              <CheckBoxApplicantSearch items={["未確認"]} disabled={null} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box css={styles.titleAndForm}>
              <Typography component="div" css={styles.titleStyles}>
                ネット予約
              </Typography>
              <StyledReactDatePickerDate fieldSize={90} />
              <span>&nbsp;～&nbsp;</span>
              <StyledReactDatePickerDate fieldSize={90} />
              <Typography component="span" sx={{ pl: 2 }}>
                <CheckBoxApplicantSearch
                  items={["来場", "Web", "電話"]}
                  disabled={null}
                  sx={{ padding: "20rem" }}
                />
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box css={styles.titleAndForm}>
              <Typography component="div" css={styles.titleStyles}>
                接触状況
              </Typography>
              <CheckBoxApplicantSearch
                items={["未接触者のみ"]}
                disabled={null}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box css={styles.titleAndForm}>
              <Typography component="div" css={styles.titleStyles}>
                Webエントリー
              </Typography>
              <CheckBoxApplicantSearch
                items={["入力済み", "未入力"]}
                disabled={null}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid
              container
              spacing={2}
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
              sx={{ px: 5 }}
            >
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Box css={styles.downloadBtn}>
                  {/* <StyledMuiBtnBlue variant="contained" color="primary" size="small" sx={{ width: '70%', ml: 2 }}>応募者データCSV</StyledMuiBtnBlue>
                  <StyledMuiBtnBlue variant="contained" color="primary" size="small" sx={{ width: '70%', ml: 2 }}>応募者対応履歴CSV</StyledMuiBtnBlue> */}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Box css={styles.searchBtnBox}>
                  <Button
                    variant="outlined"
                    color="success"
                    size="large"
                    css={styles.searchBtn}
                    sx={{ width: "50%" }}
                  >
                    検索
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Box css={styles.clearBtnBox}>
                  <StyledMuiBtnBlue
                    variant="outlined"
                    color="error"
                    size="small"
                    css={styles.clearBtn}
                    sx={{ width: "70%", ml: 2 }}
                  >
                    検索条件をクリア
                  </StyledMuiBtnBlue>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </StyledMuiPaper>
    </>
  );
}

const styles = {
  titleAndForm: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  titleStyles: {
    fontSize: "0.5rem",
    fontWeight: "bold",
    width: "100px",
  },
  searchItem: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "40px",
    marginBottom: "10px",
  },
  downloadBtn: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
    height: "40px",
    marginBottom: "10px",
  },

  searchBtnBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "40px",
    marginBottom: "10px",
  },
  searchBtn: {
    transform: "scale(0.75)",
    transformOrigin: "center center",
    color: "#46D295",
    width: "120px",
    border: "1px solid #46D295",
    "&:hover": {
      color: "#FFFFFF",
      background: "#46D295",
      border: "1px solid #46D295",
    },
  },
  clearBtnBox: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "100%",
    height: "40px",
    marginBottom: "10px",
  },
  clearBtn: {
    transform: "scale(0.75)",
    transformOrigin: "center center",
    color: "#ccc",
    width: "160px",
    border: "1px solid #ccc",
    "&:hover": {
      color: "#FFFFFF",
      background: "#ccc",
      border: "1px solid #ccc",
    },
  },
  searchItemHeader: {
    width: "120px",
    marginRight: "30px",
    textAlign: "left",
  },
  searchBoxFlex: {
    display: "flex",
    // justifyContent: 'flex-start'
  },
};
