/** @jsxImportSource @emotion/react */
import React from "react";
import StyledMuiAppBar from "../parts/AppBar/StyledMuiAppBar";
import ApplicantsInfomation from "../feature/DetermineApplicants/ApplicantsInfomation";
import ApplicantsOptions20230824Alt from "../feature/DetermineApplicants/ApplicantsOptions20230824Alt";
import ApplicantsCaerusOptionsAlt from "../feature/DetermineApplicants/ApplicantsCaerusOptionsAlt";
import { Grid, Box, Paper, Button } from "@mui/material";

function createApplicantData(
  applicant_date,
  media,
  sei_kana,
  mei_kana,
  sei_kanji,
  mei_kanji,
  birthday,
  prefecture,
  city,
  address,
  building,
  phone_number_1,
  phone_number_2,
  mailaddress_1,
  mailaddress_2,
  status,
  identify
) {
  return {
    applicant_date,
    media,
    sei_kana,
    mei_kana,
    sei_kanji,
    mei_kanji,
    birthday,
    prefecture,
    city,
    address,
    building,
    phone_number_1,
    phone_number_2,
    mailaddress_1,
    mailaddress_2,
    status,
    identify,
  };
}

const applicantDatas = [
  createApplicantData(
    "2023/01/15 12:01",
    "おうぼ受ける君",
    "オウボ",
    "ハナコ",
    "応募",
    "花子",
    "2000/01/01",
    "東京都",
    "中央区日本橋",
    "人形町3-3-13",
    "人形町フォレストビル2F",
    "03-3333-3333",
    "090-3333-3333",
    "oubo@ilovex.com",
    "wagahai_ha_neko_de_aru@docomo.ne.jp",
    1
  ),
];

function createApplicantOptionData(
  applicant_date,
  media,
  sei_kana,
  mei_kana,
  sei_kanji,
  mei_kanji,
  birthday,
  prefecture,
  city,
  address,
  building,
  phone_number_1,
  phone_number_2,
  mailaddress_1,
  mailaddress_2,
  status,
  identify
) {
  return {
    applicant_date,
    media,
    sei_kana,
    mei_kana,
    sei_kanji,
    mei_kanji,
    birthday,
    prefecture,
    city,
    address,
    building,
    phone_number_1,
    phone_number_2,
    mailaddress_1,
    mailaddress_2,
    status,
    identify,
  };
}

// const caerusDatas = [
//   createCaerusData('2023/01/15 12:01','バイトルドットコム', 'オウボ', 'ハナコ', '応募', '花子', '2000/01/01', '東京都', '中央区日本橋', '人形町3-3-13', '人形町フォレストビル2F', '03-3333-3333', '090-3333-3333', 'oubo@ilovex.com', 'wagahai_ha_neko_de_aru@docomo.ne.jp'),
// ];

const caerusDatas = [
  createCaerusData(
    "仮登録",
    "",
    "",
    "2665226551",
    "オウボ",
    "ハナコ",
    "応募",
    "花子",
    "2000/01/01",
    "東京都",
    "中央区日本橋",
    "人形町3-3-13",
    "人形町フォレストビル2F",
    "03-3333-3333",
    "090-3333-3333",
    "oubo@ilovex.com",
    "wagahai_ha_neko_de_aru@docomo.ne.jp",
    1
  ),
  createCaerusData(
    "完了",
    "仮登録",
    "",
    "6842395678",
    "アカノ",
    "タニン",
    "赤野",
    "他人",
    "2000/01/01",
    "東京都",
    "新宿区",
    "西新宿1-22-2",
    "新宿サンエービル2階",
    "03-9999-8888",
    "090-3333-3333",
    "tanin@google.com",
    "dummy@ezweb.ne.jp",
    2
  ),
  createCaerusData(
    "",
    "仮登録",
    "反社",
    "4151034432",
    "オウボ",
    "ハナコ",
    "応募",
    "花子",
    "2000/01/01",
    "大阪府",
    "大阪市此花区",
    "桜島2丁目1-33",
    "",
    "0570-200-606",
    "090-5678-4321",
    "hanako@yahoo.com",
    "hanako-oubo@ezweb.ne.jp",
    null
  ),
  createCaerusData(
    "",
    "完了",
    "連禁",
    "5826654651",
    "オウボ",
    "ハナコ",
    "尾宇募",
    "華子",
    "2000/01/01",
    "福岡県",
    "福岡市博多区",
    "博多駅中央街1-1",
    "",
    "092-431-0202",
    "080-4321-9876",
    "tanin@google.com",
    "dummy@ezweb.ne.jp",
    null
  ),
];

function createCaerusData(
  os,
  nc,
  prohibition,
  caerus_code,
  sei_kana,
  mei_kana,
  sei_kanji,
  mei_kanji,
  birthday,
  prefecture,
  city,
  address,
  building,
  phone_number_1,
  phone_number_2,
  mailaddress_1,
  mailaddress_2,
  identify
) {
  return {
    os,
    nc,
    prohibition,
    caerus_code,
    sei_kana,
    mei_kana,
    sei_kanji,
    mei_kanji,
    birthday,
    prefecture,
    city,
    address,
    building,
    phone_number_1,
    phone_number_2,
    mailaddress_1,
    mailaddress_2,
    identify,
  };
}

const applicantOptionDatas = [
  [
    createApplicantOptionData(
      "2022/12/28 14:58",
      "マイナビバイト",
      "オウボ",
      "ハナコ",
      "応募",
      "花子",
      "2000/01/01",
      "東京都",
      "中央区日本橋",
      "人形町3-3-13",
      "人形町フォレストビル2F",
      "03-3333-3333",
      "090-3333-3333",
      "oubo@ilovex.com",
      "wagahai_ha_neko_de_aru@docomo.ne.jp",
      "呼び込み中",
      1
    ),
    // createApplicantOptionData('2022/12/28 14:57','バイトルドットコム', 'オウボ', 'ハナコ', '応募', '花子', '2000/01/01', '東京都', '中央区日本橋', '人形町3-3-13', '人形町フォレストビル2F', '03-3333-3333', '090-3333-3333', 'oubo@ilovex.com', 'wagahai_ha_neko_de_aru@docomo.ne.jp', '呼び込み中', 1),
    // createApplicantOptionData('2022/12/28 14:56','アルバイトEX', 'オウボ', 'ハナコ', '応募', '花子', '2000/01/01', '東京都', '中央区日本橋', '人形町3-3-13', '人形町フォレストビル2F', '03-3333-3333', '090-3333-3333', 'oubo@ilovex.com', 'wagahai_ha_neko_de_aru@docomo.ne.jp', '呼び込み中', 1),
  ],
  [
    createApplicantOptionData(
      "2022/07/01 08:41",
      "おうぼ受ける君",
      "キュウセイ",
      "ハナコ",
      "旧姓",
      "花子",
      "2000/01/01",
      "新潟県",
      "新潟市中央区",
      "上大川前通3-6-8",
      "",
      "025-1111-2222",
      "090-3333-3333",
      "fake@ilovex.com",
      "test@docomo.ne.jp",
      "未対応",
      null
    ),
    createApplicantOptionData(
      "2022/12/28 14:57",
      "バイトルドットコム",
      "キュウセイ",
      "ハナコ",
      "旧姓",
      "花子",
      "2000/01/01",
      "新潟県",
      "新潟市中央区",
      "上大川前通3-6-8",
      "",
      "025-1111-2222",
      "090-3333-3333",
      "fake@ilovex.com",
      "test@docomo.ne.jp",
      "未対応",
      null
    ),
    createApplicantOptionData(
      "2022/12/28 14:56",
      "アルバイトEX",
      "オウボ",
      "ハナコ",
      "応募",
      "花子",
      "2000/01/01",
      "新潟県",
      "新潟市中央区",
      "上大川前通3-6-8",
      "",
      "025-1111-2222",
      "090-3333-3333",
      "fake@ilovex.com",
      "test@docomo.ne.jp",
      "未対応",
      null
    ),
    createApplicantOptionData(
      "2022/10/14 17:23",
      "Xeee(アルバイトNEXT)",
      "キュウセイ",
      "ハナコ",
      "旧姓",
      "花子",
      "2000/01/01",
      "新潟県",
      "新潟市中央区",
      "上大川前通3-6-8",
      "",
      "025-1111-2222",
      "090-3333-3333",
      "fake@ilovex.com",
      "test@docomo.ne.jp",
      "未対応",
      null
    ),
    createApplicantOptionData(
      "2022/10/14 16:09",
      "indeed",
      "キュウセイ",
      "ハナコ",
      "旧姓",
      "花子",
      "2000/01/01",
      "新潟県",
      "新潟市中央区",
      "上大川前通3-6-8",
      "",
      "025-1111-2222",
      "090-3333-3333",
      "fake@ilovex.com",
      "test@docomo.ne.jp",
      "未対応",
      null
    ),



  ],
  [
    createApplicantOptionData(
      "2022/09/12 19:03",
      "バイトルドットコム",
      "ヤマダ",
      "タロウ",
      "山田",
      "太郎",
      "1999/12/31",
      "東京都",
      "港区",
      "芝公園４丁目２−８",
      "",
      "03-5432-1987",
      "090-3333-3333",
      "faketest@ilovex.com",
      "testtest@docomo.ne.jp",
      "未対応",
      null
    ),
  ],
  [
    createApplicantOptionData(
      "2021/10/16 23:09",
      "エン転職",
      "アカノ",
      "タニン",
      "赤野",
      "他人",
      "2000/01/01",
      "東京都",
      "新宿区",
      "西新宿1-22-2",
      "新宿サンエービル2階",
      "03-9999-8888",
      "080-4321-9876",
      "tanin@google.com",
      "wagahai_ha_neko_de_aru@docomo.ne.jp",
      "登録完了",
      2
    ),
  ],
];

function DetermineApplicantsDetailPage() {
  return (
    <>
      <StyledMuiAppBar headerTitle="新・既 未判定応募詳細" />
      <Box sx={{ width: "100%", mt: 7, mb: 3 }}>
        <Grid container spacing={1} rowSpacing={1} sx={{ px: 2 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ApplicantsInfomation
              applicantDatas={applicantDatas}
              applicantOptionDatas={applicantOptionDatas}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ApplicantsOptions20230824Alt
              applicantDatas={applicantDatas}
              applicantOptionDatas={applicantOptionDatas}
              caerusDatas={caerusDatas}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ApplicantsCaerusOptionsAlt
              applicantDatas={applicantDatas}
              caerusDatas={caerusDatas}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mb: 2 }}>
            <Paper sx={{ display: "flex", justifyContent: "center", p: 2 }}>
              <Button variant="contained" color="primary" css={styles.backBtn}>
                戻る
              </Button>
              <Button
                variant="contained"
                color="primary"
                css={styles.registBtn}
              >
                登録
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

const styles = {
  registBtn: {
    width: "200px",
    transform: "scale(0.75)",
    transformOrigin: "center center",
  },
  backBtn: {
    width: "200px",
    transform: "scale(0.75)",
    transformOrigin: "center center",
    background: "#AAA",
    "&:hover": {
      background: "#999",
    },
  },
};

export default DetermineApplicantsDetailPage;
