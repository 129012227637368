/** @jsxImportSource @emotion/react */
import React from "react";
import { styled, useTheme, createTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";

import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import ListAltIcon from "@mui/icons-material/ListAlt";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import FlakyIcon from "@mui/icons-material/Flaky";

import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import BarChartIcon from "@mui/icons-material/BarChart";
import SettingsIcon from "@mui/icons-material/Settings";
import CircleIcon from "@mui/icons-material/Circle";

import ThemeProvider from "@mui/material/styles/ThemeProvider";

import StyledMuiToolbar from "../toolbar/StyledMuiToolbar";

import logoImg from "../../../assets/images/logo-test.png";

const drawerWidth = 240;

const appBarCustomTheme = createTheme({
  palette: {
    secondary: {
      main: "#c6e0f7",
      contrastText: "#0047a4",
      width: "100vw",
    },
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
  minHeight: "40px !important",
}));

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: "1.5rem",
  display: "flex",
  alignItems: "center",
}));

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
  "& .MuiListItemText-root": {
    fontSize: "0.5rem",
  },
  "& .MuiTypography-root": {
    fontSize: "0.5rem",
  },
}));

const StyledAccordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  backgroundColor: "initial",
  color: "#FFFFFF",
  width: "100%",
}));

const StyledAccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={
      <ExpandMoreIcon
        sx={{
          fontSize: "0.9rem",
          transform: "rotate(-90deg)",
          color: "#FFFFFF",
        }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginTop: "0px",
    marginBottom: "0px",
  },
  "& .MuiAccordionSummary-root": {},
  margin: "initial",
  minHeight: "40px",
  width: "100%",
  padding: "0px 0px 0px 0px",
}));

const StyledAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: "0px 0px 0px 1rem",
}));

function StyledMuiAppBar(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <ThemeProvider theme={appBarCustomTheme}>
        <AppBar position="fixed" color={"secondary"} open={open} elevation={0}>
          <StyledMuiToolbar
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Box>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{ mr: 2, ...(open && { display: "none" }) }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Box sx={{ height: "36px", mr: 1 }}>
              <Link to="/top" css={styles.link}>
                <img src={logoImg} alt="ふぁん君ロゴ" css={styles.logoImage} />
              </Link>
            </Box>
          </StyledMuiToolbar>
        </AppBar>
        <Typography
          variant="h6"
          component="div"
          sx={{
            position: "fixed",
            zIndex: "1101",
            top: "0.5%",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          {props.headerTitle}
        </Typography>
      </ThemeProvider>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            background: "#78BAF3",
            border: "none",
            color: "#FFFFFF",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider sx={{ background: "#DDD" }} />
        <List>
          <ListItem disablePadding>
            <Link to="/oubo_list" css={styles.link}>
              <ListItemButton sx={{ minHeight: "36px" }}>
                <StyledListItemIcon>
                  <ListAltIcon css={styles.itemIconSvg} />
                </StyledListItemIcon>
                <StyledListItemText primary="応募者一覧" />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disablePadding>
            <Link to="/phone_applicants_list" css={styles.link}>
              <ListItemButton sx={{ minHeight: "36px" }}>
                <StyledListItemIcon>
                  <ContactPhoneIcon css={styles.itemIconSvg} />
                </StyledListItemIcon>
                <StyledListItemText primary="電話番号のみ応募一覧" />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disablePadding>
            <Link to="/oubo_regist" css={styles.link}>
              <ListItemButton sx={{ minHeight: "36px" }}>
                <StyledListItemIcon>
                  <PersonAddIcon css={styles.itemIconSvg} />
                </StyledListItemIcon>
                <StyledListItemText primary="応募者登録" />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disablePadding>
            <Link to="/determination_list" css={styles.link}>
              <ListItemButton sx={{ minHeight: "36px" }}>
                <StyledListItemIcon>
                  <FlakyIcon css={styles.itemIconSvg} />
                </StyledListItemIcon>
                <StyledListItemText primary="新・既 未判定応募者一覧" />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disablePadding>
            <Link to="/send_mail" css={styles.link}>
              <ListItemButton sx={{ minHeight: "36px" }}>
                <StyledListItemIcon>
                  <ForwardToInboxIcon css={styles.itemIconSvg} />
                </StyledListItemIcon>
                <StyledListItemText primary="汎用メール送信" />
              </ListItemButton>
            </Link>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton sx={{ minHeight: "36px" }}>
              <StyledListItemIcon>
                <MeetingRoomIcon css={styles.itemIconSvg} />
              </StyledListItemIcon>
              <StyledListItemText primary="登録会予約枠設定" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <StyledAccordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <ListItemButton sx={{ width: "100%", padding: "0px 16px" }}>
                <StyledAccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <StyledListItemIcon>
                    <BarChartIcon css={styles.itemIconSvg} />
                  </StyledListItemIcon>
                  <StyledListItemText primary="集計機能" />
                </StyledAccordionSummary>
              </ListItemButton>
              <StyledAccordionDetails>
                <ListItem disablePadding>
                  <ListItemButton>
                    <StyledListItemIcon>
                      <CircleIcon css={styles.itemIconSvgSub} />
                    </StyledListItemIcon>
                    <StyledListItemText primary="集計A：媒体別応募数集計" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <StyledListItemIcon>
                      <CircleIcon css={styles.itemIconSvgSub} />
                    </StyledListItemIcon>
                    <StyledListItemText primary="集計B：メール送信件数履歴" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <StyledListItemIcon>
                      <CircleIcon css={styles.itemIconSvgSub} />
                    </StyledListItemIcon>
                    <StyledListItemText primary="集計C：コール件数別応募数集計" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <StyledListItemIcon>
                      <CircleIcon css={styles.itemIconSvgSub} />
                    </StyledListItemIcon>
                    <StyledListItemText primary="集計D：年代別応募数・登録数集計" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <StyledListItemIcon>
                      <CircleIcon css={styles.itemIconSvgSub} />
                    </StyledListItemIcon>
                    <StyledListItemText primary="集計E：応募データCSV出力機能" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <StyledListItemIcon>
                      <CircleIcon css={styles.itemIconSvgSub} />
                    </StyledListItemIcon>
                    <StyledListItemText primary="集計F：各種進捗別集計" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <StyledListItemIcon>
                      <CircleIcon css={styles.itemIconSvgSub} />
                    </StyledListItemIcon>
                    <StyledListItemText primary="集計G：支店別登録進捗集計" />
                  </ListItemButton>
                </ListItem>
              </StyledAccordionDetails>
            </StyledAccordion>
          </ListItem>
          <ListItem disablePadding>
            <StyledAccordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <ListItemButton sx={{ width: "100%", padding: "0px 16px" }}>
                <StyledAccordionSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <StyledListItemIcon>
                    <SettingsIcon css={styles.itemIconSvg} />
                  </StyledListItemIcon>
                  <StyledListItemText primary="マスタメンテナンス" />
                </StyledAccordionSummary>
              </ListItemButton>
              <StyledAccordionDetails>
                <ListItem disablePadding>
                  <ListItemButton>
                    <StyledListItemIcon>
                      <CircleIcon css={styles.itemIconSvgSub} />
                    </StyledListItemIcon>
                    <StyledListItemText primary="登録会会場一覧" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton>
                    <StyledListItemIcon>
                      <CircleIcon css={styles.itemIconSvgSub} />
                    </StyledListItemIcon>
                    <StyledListItemText primary="登録会会場予約可能条件一覧" />
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <Link to="/mail_template_list" css={styles.link}>
                    <ListItemButton>
                      <StyledListItemIcon>
                        <CircleIcon css={styles.itemIconSvgSub} />
                      </StyledListItemIcon>
                      <StyledListItemText primary="汎用メール テンプレート一覧" />
                    </ListItemButton>
                  </Link>
                </ListItem>
                <ListItem disablePadding>
                  <Link to="/branch_master_settings" css={styles.link}>
                    <ListItemButton>
                      <StyledListItemIcon>
                        <CircleIcon css={styles.itemIconSvgSub} />
                      </StyledListItemIcon>
                      <StyledListItemText primary="支店独自項目マスタメンテ" />
                    </ListItemButton>
                  </Link>
                </ListItem>
              </StyledAccordionDetails>
            </StyledAccordion>
          </ListItem>
        </List>
        <Divider sx={{ background: "#DDD" }} />
      </Drawer>
      {/* <Main open={open}>
        <DrawerHeader />
      </Main> */}
    </Box>
  );
}

const styles = {
  itemIconSvg: {
    "& .MuiSvgIcon-root": {
      width: "1rem",
      height: "1rem",
    },
    width: "1rem",
    height: "1rem",
    color: "#FFFFFF",
  },
  itemIconSvgSub: {
    width: "0.6rem",
    height: "0.6rem",
    color: "#FFFFFF",
  },
  link: {
    fontSize: "0.5rem",
    color: "#FFFFFF",
    textDecoration: "none",
    width: "100%",
  },
  logoImage: {
    height: "36px",
  },
};

export default StyledMuiAppBar;
