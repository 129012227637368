import React from "react";
import StyledMuiAppBar from "../parts/AppBar/StyledMuiAppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ApplicantsRegistForm from "../feature/ApplicantsRegist/ApplicantsRegistForm";



function ApplicantsRegistPage() {
  return (
    <>
      <StyledMuiAppBar headerTitle='応募者登録'/>
      <Box sx={{ width: '100%', mt: 7, mb: 3 }}>
        <Grid container spacing={2} rowSpacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <ApplicantsRegistForm />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default ApplicantsRegistPage;