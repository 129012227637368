/** @jsxImportSource @emotion/react */

import {
  Box,
  Typography,
  Paper,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Grid,
  Alert,
  AlertTitle,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
// import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
// import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
// import EmailIcon from "@mui/icons-material/Email";
import SendIcon from "@mui/icons-material/Send";
import ReplyIcon from "@mui/icons-material/Reply";
// import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import ReceivedMailIcon from "../../../parts/icons/ReceivedMail";
import SendMailIcon from "../../../parts/icons/SendMail";

function getLastPartOfUrl(url) {
  const urlObj = new URL(url);
  const parts = urlObj.pathname.split("/");
  return parts[parts.length - 1];
}

const currentPageUrl = window.location.href;
const currentPageDivision = getLastPartOfUrl(currentPageUrl);

// 最後の2文字を取得する
const lastTwoChars = currentPageDivision.substring(
  currentPageDivision.length - 2
);

let division = "";
if (lastTwoChars === "nc") {
  division = "NC";
} else {
  division = "OS";
}

const contacts = [
  {
    action: "メール送信",
    correspondence_date: "2023/07/31",
    branch: "OS新宿",
    status: "登録完了",
    identification: "提示・確認済み",
    web_entry: "開始用",
    web_reserve: "案内メール送信",
    send_mail: "送信あり",
    job_suggestion: "時給NG",
    comment:
      "ここに対応内容が入ります。ここに対応内容が入ります。ここに対応内容が入ります。",
    created_by: "山田　太郎",
    created_at: "2023/08/01 10:00",
  },
  {
    action: "入電",
    correspondence_date: "2023/07/28",
    branch: "OS新宿",
    status: "仮登録",
    identification: "提示依頼済み",
    web_entry: "登録用",
    web_reserve: "案内メール送信",
    send_mail: "",
    job_suggestion: "",
    comment: "ここに対応内容が入ります。",
    created_by: "山田　太郎",
    created_at: "2023/07/28 18:21",
  },
  {
    action: "コール(未接触)",
    correspondence_date: "2023/07/28",
    branch: "OS新宿",
    status: "仮登録",
    identification: "提示依頼済み",
    web_entry: "登録用",
    web_reserve: "案内メール送信",
    send_mail: "",
    job_suggestion: "",
    comment: "ここに対応内容が入ります。",
    created_by: "山田　太郎",
    created_at: "2023/07/28 18:21",
  },
  {
    action: "コール(接触)",
    correspondence_date: "2023/07/28",
    branch: "OS新宿",
    status: "仮登録",
    identification: "提示依頼済み",
    web_entry: "登録用",
    web_reserve: "案内メール送信",
    send_mail: "",
    job_suggestion: "",
    comment: "ここに対応内容が入ります。",
    created_by: "山田　太郎",
    created_at: "2023/07/28 18:21",
  },
  {
    action: "コール(未接触)",
    correspondence_date: "2023/07/28",
    branch: "OS新宿",
    status: "呼び込み中",
    identification: "提示依頼済み",
    web_entry: "登録用",
    web_reserve: "案内メール送信",
    send_mail: "",
    job_suggestion: "",
    comment: "ここに対応内容が入ります。",
    created_by: "山田　太郎",
    created_at: "2023/07/28 18:21",
  },
  {
    action: "コール(未接触)",
    correspondence_date: "2023/07/28",
    branch: "OS新宿",
    status: "呼び込み中",
    identification: "提示依頼済み",
    web_entry: "登録用",
    web_reserve: "案内メール送信",
    send_mail: "",
    job_suggestion: "",
    comment: "ここに対応内容が入ります。",
    created_by: "山田　太郎",
    created_at: "2023/07/28 18:21",
  },
  {
    action: "入電",
    correspondence_date: "2023/07/28",
    branch: "OS新宿",
    status: "辞退（連絡あり）",
    identification: "提示依頼済み",
    web_entry: "登録用",
    web_reserve: "案内メール送信",
    send_mail: "",
    job_suggestion: "",
    comment: "ここに対応内容が入ります。",
    created_by: "山田　太郎",
    created_at: "2023/07/28 18:21",
  },
  {
    action: "メール受信",
    correspondence_date: "2023/04/03",
    branch: "OS新宿",
    status: "辞退（連絡あり）",
    identification: "提示依頼済み",
    web_entry: "登録用",
    web_reserve: "案内メール送信",
    send_mail: "",
    job_suggestion: "",
    comment: "ここに対応内容が入ります。",
    created_by: "山田　太郎",
    created_at: "2023/07/28 18:21",
  },
  {
    action: "コール(未接触)",
    correspondence_date: "2023/04/01",
    branch: "OS新宿",
    status: "未対応",
    identification: "未対応(依頼前)",
    web_entry: "登録用",
    web_reserve: "案内メール送信",
    send_mail: "汎用メール送信",
    job_suggestion: "",
    comment: "ここに対応内容が入ります。",
    created_by: "山田　太郎",
    created_at: "2023/07/28 18:21",
  },
  {
    action: "その他",
    correspondence_date: "",
    branch: "",
    status: "",
    identification: "",
    web_entry: "",
    web_reserve: "",
    send_mail: "",
    job_suggestion: "",
    comment: "",
    created_by: "",
    created_at: "",
  },
];

export default function ModalViewMoreContact({ onClose }) {
  function switchStepIcon(action) {
    switch (action) {
      case "コール(未接触)":
        return <PhoneDisabledIcon sx={{ width: "1rem", height: "1rem" }} />;
      case "コール(接触)":
        return <PhoneEnabledIcon sx={{ width: "1rem", height: "1rem" }} />;
      case "入電":
        return <PhoneCallbackIcon sx={{ width: "1rem", height: "1rem" }} />;
      case "メール送信":
        return <SendIcon sx={{ width: "1rem", height: "1rem" }} />;
      case "メール受信":
        return <ReplyIcon sx={{ width: "1rem", height: "1rem" }} />;
      default:
        return <ErrorOutlineIcon sx={{ width: "1rem", height: "1rem" }} />;
    }
  }

  return (
    <Box css={styles.modal}>
      <Typography
        id="modal-title"
        variant="h6"
        component="h2"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <p className="contentBlockTitle">対応履歴一覧</p>
        <IconButton
          aria-label="close-modal-view-more-contact"
          onClick={onClose}
        >
          <CancelIcon sx={{ color: "#777" }} />
        </IconButton>
      </Typography>
      <Stepper
        orientation="vertical"
        sx={{
          height: "88vh",
          overflowY: "scroll",
          "& .MuiStepConnector-line": { minHeight: "initial", height: "12px" },
        }}
      >
        {contacts.map((data, index) => {
          return (
            <Step active={true} css={styles.stepIconLabel} key={index}>
              <StepLabel
                icon={switchStepIcon(data.action)}
                css={styles.stepLabelText}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                  }}
                >
                  <Typography
                    components="span"
                    sx={{ fontSize: "0.7rem", color: "#333" }}
                  >
                    {data.action}
                  </Typography>
                  <Typography
                    components="span"
                    sx={{ display: "flex", justifyContent: "flex-end", pr: 3 }}
                  >
                    <Typography
                      component="span"
                      align="right"
                      sx={{ fontSize: "0.5rem", color: "#aaa", pr: 2 }}
                    >
                      登録者：{data.created_by}
                    </Typography>
                    <Typography
                      component="span"
                      align="right"
                      sx={{ fontSize: "0.5rem", color: "#aaa" }}
                    >
                      登録日：{data.created_at}
                    </Typography>
                  </Typography>
                </Box>
              </StepLabel>
              <StepContent>
                <Paper
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    padding: "1px",
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={4}
                      sx={{ padding: "0 4px" }}
                    >
                      <Grid container>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                          <Box css={styles.gridBox}>
                            <Typography css={styles.theadBox}>
                              対応日
                            </Typography>
                            <Typography css={styles.tdataBox}>
                              {data.correspondence_date}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                          <Box css={styles.gridBox}>
                            <Typography css={styles.theadBox}>
                              応募支店&#40;担当&#41;
                            </Typography>
                            <Typography css={styles.tdataBox}>
                              {data.branch}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4}>
                          <Box css={styles.gridBox}>
                            <Typography css={styles.theadBox}>
                              本人確認書類
                            </Typography>
                            <Typography css={styles.tdataBox}>
                              {data.identification}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <StatusBox status={data.status} />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={3}
                      sx={{ padding: "0 4px" }}
                    >
                      {division === "NC" ? (
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box css={styles.gridBox}>
                              <Typography css={styles.theadBox}>
                                Webエントリー
                              </Typography>
                              <Typography css={styles.tdataBox}>
                                {data.web_entry}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Box css={styles.gridBox}>
                              <Typography css={styles.theadBox}>
                                汎用メール送信
                              </Typography>
                              <Typography css={styles.tdataBox}>
                                {data.send_mail}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container>
                          <Grid item xs={6} sm={6} md={6} lg={6}>
                            <Box css={styles.gridBox}>
                              <Typography css={styles.theadBox}>
                                Webエントリー
                              </Typography>
                              <Typography css={styles.tdataBox}>
                                {data.web_entry}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6}>
                            <Box css={styles.gridBox}>
                              <Typography css={styles.theadBox}>
                                ネット予約
                              </Typography>
                              <Typography css={styles.tdataBox}>
                                {data.web_reserve}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6}>
                            <Box css={styles.gridBox}>
                              <Typography css={styles.theadBox}>
                                汎用メール送信
                              </Typography>
                              <Typography css={styles.tdataBox}>
                                {data.send_mail}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={6} sm={6} md={6} lg={6}>
                            <Box css={styles.gridBox}>
                              <Typography css={styles.theadBox}>
                                案件提示
                              </Typography>
                              <Typography css={styles.tdataBox}>
                                {data.job_suggestion}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={5}
                      sx={{ padding: "0 4px" }}
                    >
                      <Box
                        sx={{
                          margin: "1px",
                          padding: "4px",
                          border: "1px solid #eee",
                          minHeight: "82px",
                          borderRadius: "4px",
                        }}
                      >
                        <Typography css={styles.theadBox}>対応内容</Typography>
                        <Typography css={styles.tdataBox}>
                          {data.comment}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
}

const styles = {
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    backgroundColor: "#FFF",
    borderRadius: "0.25rem",
    padding: "15px 20px",
    fontSize: "0.625rem",
  },
  tableHeader: {
    fontSize: "0.5rem",
    padding: "3px",
    color: "#4B576B",
    textAlign: "left",
    fontWeight: "bold",
  },
  titleAndEditBtn: {
    display: "flex",
    justifyContent: "space-between",
  },
  tdata: {
    fontSize: "0.5rem",
    padding: "10px 2px",
    color: "#0047A4",
    lineHeight: "1.2",
  },
  moreHistoryBtnBlock: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  moreHistoryBtn: {
    transform: "scale(0.75)",
    transformOrigin: "center bottom",
  },
  stepLabelText: {
    paddingBottom: "4px",
    "& .MuiStepLabel-label.Mui-active": {
      color: "#333",
      fontSize: "0.8rem",
      fontWeight: "600",
    },
  },
  stepIconLabel: {
    "& .MuiSvgIcon-root.Mui-active": {
      color: "#0047A4",
    },
    "& .MuiStepIcon-text": {
      display: "none",
    },
  },
  listItemStyle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "baseline",
    background: "#F2F8FA",
    margin: "2px",
    width: "initial",
  },
  listItemLeft: {
    width: "20%",
    fontWeight: "bold",
  },
  listItemRight: {
    width: "80%",
    color: "#0047A4",
  },
  gridBox: {
    margin: "1px",
    padding: "4px",
    border: "1px solid #eee",
    height: "auto",
    minHeight: "40px",
    borderRadius: "4px",
  },
  theadBox: {
    fontSize: "0.5rem",
    color: "#777",
    width: "96px",
  },
  tdataBox: {
    fontSize: "0.5rem",
    color: "#0047A4",
    fontWeight: "bold",
  },
};

function StatusBox({ status }) {
  function switchStatus(status) {
    switch (status) {
      case "未対応":
        return "error";
      case "呼び込み中":
        return "warning";
      case "仮登録":
        return "info";
      case "登録完了":
        return "success";
      default:
        return "grayout";
    }
  }

  const statusColor = switchStatus(status);

  return statusColor !== "grayout" ? (
    <Alert
      severity={switchStatus(status)}
      sx={{
        margin: "1px",
        padding: "2px 16px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <AlertTitle
        align="left"
        sx={{
          display: "flex",
          marginBottom: "initial",
          marginTop: "initial",
        }}
      >
        <Typography sx={{ fontSize: "0.5rem" }}>【ステータス】</Typography>
        <Typography sx={{ fontSize: "0.5rem", pl: 2, fontWeight: "bold" }}>
          {status}
        </Typography>
      </AlertTitle>
    </Alert>
  ) : (
    <Alert
      icon={<RemoveCircleOutlineIcon />}
      sx={{
        margin: "1px",
        padding: "2px 16px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        background: "#d9d9d9",
        "& .MuiSvgIcon-root": {
          fontSize: "inherit",
          color: "#555",
        },
      }}
    >
      <AlertTitle
        align="left"
        sx={{
          display: "flex",
          marginBottom: "initial",
          marginTop: "initial",
        }}
      >
        <Typography sx={{ fontSize: "0.5rem", color: "#555" }}>
          【ステータス】
        </Typography>
        <Typography
          sx={{ fontSize: "0.5rem", color: "#555", pl: 2, fontWeight: "bold" }}
        >
          {status}
        </Typography>
      </AlertTitle>
    </Alert>
  );
}

// function BoxListItemRight({ selectList, selected }) {
//   function getMatchStatus(selectList, selected) {
//     return selectList.map((item) => ({
//       label: item,
//       isMatch: selected.includes(item),
//     }));
//   }
//   return (
//     <>
//       {getMatchStatus(selectList, selected).map((match, i) => {
//         return (
//           <>
//             <Typography
//               component="span"
//               sx={{
//                 fontSize: "0.5rem",
//                 fontWeight: match.isMatch ? "bold" : "",
//                 color: !match.isMatch ? "#CCC" : "",
//                 pr: "5px",
//                 display: "inline-block",
//               }}
//             >
//               {match.label}
//             </Typography>
//             {getMatchStatus(selectList, selected).length - 1 !== i ? (
//               <Typography
//                 component="span"
//                 sx={{
//                   fontSize: "0.5rem",
//                   color: "#CCC",
//                   display: "inline-block",
//                   pr: "5px",
//                 }}
//               >
//                 &frasl;
//               </Typography>
//             ) : (
//               <></>
//             )}
//           </>
//         );
//       })}
//     </>
//   );
// }
