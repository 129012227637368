import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const StyledMuiTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    fontSize: '0.5rem',
    color: '#0047A4',
    padding: '0.5rem',
    background: '#FFF',
    width: '30vw',
  },
}));

export default StyledMuiTextField;