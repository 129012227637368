/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

import {
  Grid,
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  OutlinedInput,
  Button,
} from "@mui/material";

import StyledMuiPaper from "../../parts/surface/StyledMuiPaper";
import MuiRadioButton from "../../parts/radio/MuiRadioButton";
import MuiTextField from "../../parts/textField/MuiTextField";
import MuiTextFieldInsertTags from "../../parts/textField/MuiTextFieldInsertTags";
import MuiSelectBox from "../../parts/select/MuiSelectBox";
import StyledReactDatePicker from "../../parts/Datepicker/StyledReactDatePicker";
import StyledMuiBtnBlue from "../../parts/button/StyledMuiBtnBlue";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const styles = {
  middleWidth: {
    width: "50%",
    "@media (max-width:1200px)": {
      width: "90%",
      padding: "2rem",
    },
  },
  btnSizeSmall: {
    transform: "scale(0.75)",
    transformOrigin: "center bottom",
  },
  title: {
    fontSize: "0.8rem",
    fontWeight: "bold",
  },
  flexFsE: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "end",
  },
  flexSbC: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexCC: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  selectBox: {
    fontSize: "0.5rem",
    "& .MuiInputBase-input": {
      fontSize: "0.5rem",
      color: "#0047A4",
    },
    "& .MuiInputBase-root": {
      padding: "3px 0.5rem",
      fontSize: "0.5rem",
    },
    "& .MuiFormLabel-root": {
      fontSize: "0.5rem",
    },
    "& .MuiInputLabel-root": {
      fontSize: "0.5rem",
    },
    "& .MuiSelect-root": {
      fontSize: "0.5rem",
    },
  },
};

const arrayOfficeAndMailaddresses = [
  {
    label: "OS北海道",
    mailaddress: "sample-from-hokkaido@test.com",
  },
  {
    label: "OS東北",
    mailaddress: "sample-from-tohoku@test.com",
  },
  {
    label: "OS関東",
    mailaddress: "sample-from-kanto@test.com",
  },
  {
    label: "OS北陸",
    mailaddress: "sample-from-hokuriku@test.com",
  },
  {
    label: "OS東海",
    mailaddress: "sample-from-tokai@test.com",
  },
  {
    label: "OS近畿",
    mailaddress: "sample-from-kinki@test.com",
  },
  {
    label: "OS四国",
    mailaddress: "sample-from-shikoku@test.com",
  },
  {
    label: "OS中国",
    mailaddress: "sample-from-chugoku@test.com",
  },
  {
    label: "OS九州",
    mailaddress: "sample-from-kyushu@test.com",
  },
];

const arrayBranchAndMailaddresses = [
  { label: "OS京都", mailaddress: "sample-from-branch@test.com" },
  { label: "OS梅田", mailaddress: "sample-from-branch@test.com" },
  { label: "OS名古屋", mailaddress: "sample-from-branch@test.com" },
  { label: "OS製造滋賀", mailaddress: "sample-from-branch@test.com" },
  { label: "OS仙台", mailaddress: "sample-from-branch@test.com" },
  { label: "OS町田", mailaddress: "sample-from-branch@test.com" },
  { label: "OS横浜", mailaddress: "sample-from-branch@test.com" },
  { label: "OS川崎", mailaddress: "sample-from-branch@test.com" },
  { label: "OS藤沢", mailaddress: "sample-from-branch@test.com" },
  { label: "OS広島", mailaddress: "sample-from-branch@test.com" },
  { label: "OS岡山", mailaddress: "sample-from-branch@test.com" },
  { label: "OS札幌", mailaddress: "sample-from-branch@test.com" },
  { label: "OS大宮", mailaddress: "sample-from-branch@test.com" },
  { label: "OS熊本", mailaddress: "sample-from-branch@test.com" },
  { label: "OS鹿児島", mailaddress: "sample-from-branch@test.com" },
  { label: "OS神戸", mailaddress: "sample-from-branch@test.com" },
  { label: "OS池袋", mailaddress: "sample-from-branch@test.com" },
  { label: "OS千葉", mailaddress: "sample-from-branch@test.com" },
  { label: "OS柏", mailaddress: "sample-from-branch@test.com" },
  { label: "OS錦糸町", mailaddress: "sample-from-branch@test.com" },
  { label: "OS秋葉原", mailaddress: "sample-from-branch@test.com" },
  { label: "OS天神", mailaddress: "sample-from-branch@test.com" },
  { label: "OS博多", mailaddress: "sample-from-branch@test.com" },
  { label: "OS小倉", mailaddress: "sample-from-branch@test.com" },
  { label: "OS天王寺", mailaddress: "sample-from-branch@test.com" },
  { label: "OS梅田第二", mailaddress: "sample-from-branch@test.com" },
  { label: "OS創世", mailaddress: "sample-from-branch@test.com" },
  { label: "OSなんば", mailaddress: "sample-from-branch@test.com" },
  { label: "OS新宿", mailaddress: "sample-from-branch@test.com" },
  { label: "OS立川", mailaddress: "sample-from-branch@test.com" },
  { label: "OS渋谷", mailaddress: "sample-from-branch@test.com" },
  { label: "OSシニア", mailaddress: "sample-from-branch@test.com" },
  { label: "OS公共事業", mailaddress: "sample-from-branch@test.com" },
  { label: "OS沖縄", mailaddress: "sample-from-branch@test.com" },
  { label: "OS沖縄第二", mailaddress: "sample-from-branch@test.com" },
];

export default function CreateNewMail({ datas, tagValue }) {
  const location = useLocation();
  const applicantsData = location?.state;

  const [selectedOption, setSelectedOption] = useState("");

  const handleChangeOption = (e) => {
    setSelectedOption(e.target.value);
  };

  const [selectedOffice, setSelectedOffice] = useState(""); // この変数はオフィスの名前を保持します
  const [selectedMailaddress, setSelectedMailaddress] = useState(""); // この変数はメールアドレスを保持します

  const handleChangeOffice = (e) => {
    const selected = e.target.value;
    setSelectedOffice(selected); // オフィスの名前をセット

    // selectedOptionに基づいて対応する配列を探索
    let targetArray = [];
    if (selectedOption === "HR・エリア") {
      targetArray = arrayOfficeAndMailaddresses;
    } else if (selectedOption === "支店") {
      targetArray = arrayBranchAndMailaddresses;
    }

    const correspondingMail = targetArray.find(
      (item) => item.label === selected
    )?.mailaddress;
    setSelectedMailaddress(correspondingMail || "");
  };

  console.log("送信元：" + selectedOption);
  console.log("エリア：" + selectedOffice);

  if (applicantsData?.fromRegistContact) {
    return (
      <StyledMuiPaper
        css={styles.middleWidth}
        sx={{
          overflow: "hidden",
          p: 5,
          mb: 3,
          textAlign: "left",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box>
              <Typography components="h2" css={styles.title} sx={{ pb: 1 }}>
                事業部
              </Typography>
              <MuiSelectBox
                items={[
                  "全社  ( oubo_all@neo-career.co.jp )",
                  "HR・エリア  ( kaigo_information@neo-career.co.jp )",
                  "支店  ( kaigo-omiya@neo-career.co.jp )",
                ]}
                id="replyTo"
                labelId="replyToLabel"
                width="100%"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box>
              <MuiRadioButton
                ariaLabel="transmissionTiming"
                name="transmission"
                formLabels={[
                  { value: "ImmediateTransmission", label: "即時送信" },
                  { value: "reservedTransmission", label: "予約送信" },
                ]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box>
                <StyledReactDatePicker />
              </Box>
              <Typography
                components="p"
                sx={{ fontSize: "0.5rem", display: "inline-block", pl: 1 }}
              >
                に送信する
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
            <Box>
              <Typography components="h2" css={styles.title} sx={{ pb: 1 }}>
                宛先
              </Typography>
              <Typography components="p" sx={{ fontSize: "0.5rem" }}>
                {applicantsData.seiKanji}&emsp;{applicantsData.meiKanji}
                &emsp;&#40;&nbsp;{applicantsData.mailAddress}&nbsp;&#41;
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
            <Box>
              <Typography components="h2" css={styles.title} sx={{ pb: 1 }}>
                返信先メールアドレス
              </Typography>
              <MuiSelectBox
                items={[
                  "全社  ( oubo_all@neo-career.co.jp )",
                  "HR・エリア  ( kaigo_information@neo-career.co.jp )",
                  "支店  ( kaigo-omiya@neo-career.co.jp )",
                ]}
                id="replyTo"
                labelId="replyToLabel"
                width="70%"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box>
              <Box css={styles.flexSbC}>
                <Typography
                  components="h2"
                  css={styles.title}
                  sx={{ display: "inline-block" }}
                >
                  件名
                </Typography>
                <Link to="/mail_template_search" css={styles.link}>
                  <StyledMuiBtnBlue variant="contained">
                    テンプレート選択
                  </StyledMuiBtnBlue>
                </Link>
              </Box>
              <MuiTextField
                ariaLabel="mail-title"
                multiline={false}
                minRows={1}
                placeholder="件名を入力してください。"
                defaultValue={datas?.title}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box>
              <Typography components="h2" css={styles.title} sx={{ pb: 1 }}>
                本文
              </Typography>
              <MuiTextFieldInsertTags
                ariaLabel="mail-content"
                multiline={true}
                minRows={15}
                placeholder="本文を入力してください。"
                defaultValue={datas?.content}
                tagValue={tagValue}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ textAlign: "center" }}
          >
            <Box css={styles.flexCC}>
              <StyledMuiBtnBlue
                variant="contained"
                sx={{ display: "inline-block" }}
              >
                送信する
              </StyledMuiBtnBlue>
            </Box>
          </Grid>
        </Grid>
      </StyledMuiPaper>
    );
  } else {
    return (
      <StyledMuiPaper
        css={styles.middleWidth}
        sx={{
          overflow: "hidden",
          p: 5,
          mb: 3,
          textAlign: "left",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box>
              <Typography components="h2" css={styles.title} sx={{ pb: 1 }}>
                事業部
              </Typography>
              <MuiSelectBox
                items={["NC", "OS"]}
                id="replyTo"
                labelId="replyToLabel"
                width="25%"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box>
              <MuiRadioButton
                ariaLabel="transmissionTiming"
                name="transmission"
                formLabels={[
                  { value: "ImmediateTransmission", label: "即時送信" },
                  { value: "reservedTransmission", label: "予約送信" },
                ]}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box>
                <StyledReactDatePicker />
              </Box>
              <Typography
                components="p"
                sx={{ fontSize: "0.5rem", display: "inline-block", pl: 1 }}
              >
                に送信する
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box>
              <Typography components="h2" css={styles.title} sx={{ pb: 1 }}>
                返信先メールアドレス
              </Typography>
              <Box css={styles.flexFsE}>
                <Box>
                  <Box>
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ minWidth: "60px" }}
                    >
                      <Select
                        displayEmpty
                        value={selectedOption}
                        onChange={handleChangeOption}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                          if (!selected || selected.length === 0) {
                            return (
                              <em css={styles.textSize}>選択してください</em>
                            );
                          }
                          return selected;
                        }}
                        MenuProps={MenuProps}
                        inputProps={{ "aria-label": "Without label" }}
                        css={styles.selectBox}
                      >
                        <MenuItem value="" sx={{ fontSize: "0.5rem" }}>
                          <em css={styles.textSize}>選択してください</em>
                        </MenuItem>
                        {["全社", "HR・エリア", "支店"].map((item) => (
                          <MenuItem
                            key={item}
                            value={item}
                            sx={{ fontSize: "0.5rem" }}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <FormControl
                      fullWidth
                      size="small"
                      sx={{ minWidth: "60px" }}
                    >
                      <Select
                        displayEmpty
                        value={selectedOffice}
                        onChange={handleChangeOffice}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                          if (!selected || selected.length === 0) {
                            return (
                              <em css={styles.textSize}>選択してください</em>
                            );
                          }
                          return selected;
                        }}
                        MenuProps={MenuProps}
                        inputProps={{ "aria-label": "Without label" }}
                        css={styles.selectBox}
                        disabled={
                          selectedOption.length === 0 ||
                          selectedOption === "全社"
                            ? true
                            : false
                        }
                      >
                        <MenuItem value="" sx={{ fontSize: "0.5rem" }}>
                          <em css={styles.textSize}>選択してください</em>
                        </MenuItem>
                        {(() => {
                          // selectedOptionの値に基づいて、適切な配列を選択
                          let targetArray = [];
                          if (selectedOption === "HR・エリア") {
                            targetArray = arrayOfficeAndMailaddresses;
                          } else if (selectedOption === "支店") {
                            targetArray = arrayBranchAndMailaddresses;
                          }

                          return targetArray.map((item, i) => (
                            <MenuItem
                              key={i}
                              value={item.label}
                              sx={{ fontSize: "0.5rem" }}
                            >
                              {item.label}
                            </MenuItem>
                          ));
                        })()}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <Box sx={{ ml: 2 }}>
                  <Typography sx={{ fontSize: "0.5rem" }}>
                    {selectedOption === "全社"
                      ? "sample-from-all@test.com"
                      : selectedMailaddress}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={8} sm={8} md={4} lg={4} xl={4}>
            <Box></Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mt: 2 }}>
            <Box>
              <Box css={styles.flexSbC}>
                <Typography
                  components="h2"
                  css={styles.title}
                  sx={{ display: "inline-block" }}
                >
                  件名
                </Typography>
                <Link to="/mail_template_search" css={styles.link}>
                  <StyledMuiBtnBlue variant="contained">
                    テンプレート選択
                  </StyledMuiBtnBlue>
                </Link>
              </Box>
              <MuiTextField
                ariaLabel="mail-title"
                multiline={false}
                minRows={1}
                placeholder="件名を入力してください。"
                defaultValue={datas?.title}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Box>
              <Typography components="h2" css={styles.title} sx={{ pb: 1 }}>
                本文
              </Typography>
              <MuiTextFieldInsertTags
                ariaLabel="mail-content"
                multiline={true}
                minRows={15}
                placeholder="本文を入力してください。"
                defaultValue={datas?.content}
                tagValue={tagValue}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ textAlign: "center" }}
          >
            <Typography component="p" sx={{ fontSize: "0.5rem", pb: 1 }}>
              以下のボタンから応募者一覧画面に遷移して送信対象者を指定してください。
            </Typography>
            <Box css={styles.flexCC}>
              <Button
                component={Link}
                to={`/oubo_list${"/send_mail"}`}
                variant="contained"
                sx={{ display: "inline-block" }}
                css={styles.btnSizeSmall}
              >
                宛先設定
              </Button>
            </Box>
          </Grid>
        </Grid>
      </StyledMuiPaper>
    );
  }
}
