/** @jsxImportSource @emotion/react */
import FormControlLabel from '@mui/material/FormControlLabel';
import { Checkbox, Box } from '@mui/material';


export default function CheckboxRegistContanct({ items }) {
  return (
    <Box>
      {items.map((item) => {
        return <FormControlLabel control={<Checkbox css={styles.inputCheckbox} />} label={item} css={styles.formLabel}/>
      })}
    </Box>
  )
}

const styles = {
  inputCheckbox:{
    '& .MuiSvgIcon-root':{
      fontSize: '1rem',
    },
  },
  formLabel:{
    '& .MuiFormControlLabel-label':{
      fontSize: '0.5rem',
    },
  },
}

