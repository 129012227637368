/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import Alert from "@mui/material/Alert";

import StyledMuiTabs from "../../../parts/tab/StyledMuiTabs";
import ModalSendRequestEdit from "./ModalSendRequestEdit";

//import TabPanelBasicInfo from "../../ApplicantsDetail/WebEntry/TabPanelBasicInfo";
import TabPanelNc1 from "../../ApplicantsDetail/WebEntry/TabPanelNc1";
import TabPanelNc2 from "../../ApplicantsDetail/WebEntry/TabPanelNc2";
import TabPanelOs1 from "../../ApplicantsDetail/WebEntry/TabPanelOs1";
import TabPanelOs2 from "../../ApplicantsDetail/WebEntry/TabPanelOs2";

import WarningIconRed from "../../../parts/icons/WarningIconRed";
import WarningIconYellow from "../../../parts/icons/WarningIconYellow";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function allyProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

//======================================関数コンポーネント======================================
export default function ModalEditEntryInfo({ onClose, data, division }) {
  const tabHeaders =
    division === "NC"
      ? [
          { tabTitle: "基本情報", progress: "確認依頼前" },
          { tabTitle: "希望条件", progress: "済" },
          { tabTitle: "経験職種", progress: "確認依頼前" },
          { tabTitle: "資格", progress: null },
          { tabTitle: "社内用", progress: null },
          { tabTitle: "緊急連絡先", progress: "済" },
          { tabTitle: "給与口座", progress: "済" },
          { tabTitle: "社保・雇保", progress: "CAERUS未連携" },
          { tabTitle: "在留カード", progress: "CAERUS未連携" },
        ]
      : [
          { tabTitle: "基本情報", progress: "確認依頼前" },
          { tabTitle: "希望条件", progress: "済" },
          { tabTitle: "OAスキル", progress: null },
          { tabTitle: "経験職種", progress: "確認依頼前" },
          { tabTitle: "資格", progress: null },

          { tabTitle: "職歴/学歴", progress: null },
          { tabTitle: "社内用", progress: null },
          { tabTitle: "緊急連絡先", progress: "済" },
          { tabTitle: "給与口座", progress: "済" },
          { tabTitle: "社保・雇保", progress: "CAERUS未連携" },
          { tabTitle: "在留カード", progress: "CAERUS未連携" },
        ];

  const tableAndDatas =
    division === "NC"
      ? [
          {
            data: data[0].entryDatas,
            table: (
              <TabPanelNc1
                data={data[0].entryDatas}
                conflict={data[0].conflict}
              />
            ),
          },
          {
            data: data[1].entryDatas,
            table: (
              <TabPanelNc2
                data={data[1].entryDatas}
                conflict={data[1].conflict}
              />
            ),
          },
        ]
      : [
          {
            data: data[0].entryDatas,
            table: (
              <TabPanelOs1
                data={data[0].entryDatas}
                conflict={data[0].conflict}
              />
            ),
          },
          {
            data: data[1].entryDatas,
            table: (
              <TabPanelOs2
                data={data[1].entryDatas}
                conflict={data[1].conflict}
              />
            ),
          },
        ];

  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [activeModal, setActiveModal] = useState(null);
  const handleOpenModal = (modalId) => {
    setActiveModal(modalId);
  };
  const handleCloseModal = () => {
    setActiveModal(null);
  };

  const [conflict, setConflict] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const [openNextWindow, setOpenNextWindow] = useState(false);
  const [currentModal, setCurrentModal] = useState(null);

  const handleOpenNextWindow = (modalName) => {
    setCurrentModal(modalName);
    setOpenNextWindow(true);
  };

  const body =
    currentModal === "代理入力" || currentModal === "転記入力" ? (
      <>
        <Box sx={{ borderBottom: 1, borderColor: "divider", flexWrap: "wrap" }}>
          <StyledMuiTabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{ style: { display: "none" } }}
          >
            {tabHeaders.map((head, i) => {
              return (
                <Tab
                  label={head.tabTitle}
                  css={styles.MuiTab}
                  iconPosition="end"
                  icon={
                    head.progress === "CAERUS未連携" ? (
                      <WarningIconRed />
                    ) : head.progress === "確認依頼前" ? (
                      <WarningIconYellow />
                    ) : (
                      ""
                    )
                  }
                />
              );
            })}
          </StyledMuiTabs>
        </Box>

        {tableAndDatas.map((panel, j) => {
          return (
            <TabPanel value={value} index={j}>
              <div css={styles.titleAndEditBtn}>
                <p className="contentBlockTitle">{currentModal}</p>
                {data[j].conflict ? (
                  <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                    <Alert
                      variant="filled"
                      severity="error"
                      size="small"
                      icon={false}
                      sx={{
                        fontSize: "0.5rem",
                        alignItems: "center",
                        height: "2rem",
                        ml: 2,
                      }}
                    >
                      CAERUS編集中
                    </Alert>
                    <Typography sx={{ fontSize: "0.5rem", marginLeft: "0.5rem", color: "#555" }}>（2023/04/04 10:00&emsp;営業 太郎）</Typography>
                  </Box>
                ) : null}
              </div>
              <TableContainer component={Paper} sx={{ my: 3, height: "55vh" }}>
                <Table sx={{ minWidth: 250 }} aria-label="simple table">
                  {panel.table}
                </Table>
              </TableContainer>
              <div css={styles.BtnBlockCenter}>
                <Button
                  variant="contained"
                  color="inherit"
                  css={styles.btnSizeSmall}
                  onClick={handleClose}
                >
                  閉じる
                </Button>
                <Button
                  variant="contained"
                  css={styles.btnSizeSmall}
                  // disabled={panel.conflict ? 'disabled' : null}
                  onClick={() => handleOpenModal("send-request-edit")}
                >
                  一時保存
                </Button>
                <Modal
                  key="send-request-edit"
                  open={activeModal === "send-request-edit"}
                  onClose={handleCloseModal}
                  aria-labelledby="send-request-edit-modal-title"
                  aria-describedby="send-request-edit-modal-description"
                >
                  <ModalSendRequestEdit onClose={handleCloseModal} />
                </Modal>
              </div>
            </TabPanel>
          );
        })}
      </>
    ) : null;

  if (!openNextWindow) {
    return (
      <Box css={styles.modal}>
        <Box css={styles.btnArea}>
          <Button
            variant="contained"
            onClick={() => handleOpenNextWindow("転記入力")}
            css={styles.largeBtnStyle}
          >
            紙のエントリーシートを
            <br />
            転記入力する
          </Button>
          <Button
            variant="contained"
            onClick={() => handleOpenNextWindow("代理入力")}
            css={styles.largeBtnStyle}
          >
            応募者の代わりに
            <br />
            代理入力をする
          </Button>
        </Box>
      </Box>
    );
  } else {
    return <Box css={styles.modal}>{body}</Box>;
  }
}

const styles = {
  titleAndEditBtn: {
    display: "flex",
    justifyContent: "flex-start",
  },
  btnArea: {
    height: "40vh",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },

  MuiTab: {
    maxWidth: "initial",
    minWidth: "initial",
    width: "16.66%",
    minHeight: "1vh",
    fontSize: "0.625rem",
    padding: "0.625rem 0",
  },
  entryAreaBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  modal: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    "@media (max-width:1200px)": {
      width: "90%",
    },
    backgroundColor: "#FFF",
    borderRadius: "0.25rem",
    padding: "15px 20px",
  },
  BtnBlockCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  btnSizeSmall: {
    transform: "scale(0.75)",
    transformOrigin: "center bottom",
  },
  largeBtnStyle: {
    width: "30%",
    padding: "1rem 2rem",
  },
};
