import React, { useState, useEffect } from "react";
import StyledMuiAppBar from "../parts/AppBar/StyledMuiAppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import RegistMailTemplate from "../feature/GenericMail/RegistMailTemplate";
import InsertionTagBlock from"../feature/GenericMail/InsertionTagBlock";
import { useLocation } from 'react-router-dom';


export default function SendGenericMailPage() {
  const location = useLocation();
  const datas = location.state ? location.state.datas : {};
  const [tagValue, setTagValue] = useState('');


  return (
    <>
      <StyledMuiAppBar headerTitle='汎用メールテンプレート登録'/>
      <Box sx={{ width: '100%', mt: 7, mb: 3 }}>
        <Grid container spacing={2} rowSpacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <RegistMailTemplate datas={datas} tagValue={tagValue}/>
          </Grid>
        </Grid>
      </Box>
      <InsertionTagBlock onTagValueChange={setTagValue}/>
    </>
  )
}

