/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { styled } from '@mui/material';
import TextField from "@mui/material/TextField";
import Grid from '@mui/material/Grid';


function TextFieldInputBirthday (props) {
  const { onBirthdayChange = () => {}, textSize = 14, birthday = "2000-01-01", disabled = false } = props;

  //birthdayをyyyyとMMとddの3つの値に分割する
  const defaultBirthdayYear = birthday.slice(0, 4);
  const defaultBirthdayMonth = birthday.slice(5, 7);
  const defaultBirthdayDay = birthday.slice(8, 10);

  const [year, setYear] = useState(defaultBirthdayYear);
  const [month, setMonth] = useState(defaultBirthdayMonth);
  const [day, setDay] = useState(defaultBirthdayDay);

  

  const handleYearChange = (event) => {
    setYear(event.target.value);
    onBirthdayChange(event.target.value, month, day);
  };

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
    onBirthdayChange(year, event.target.value, day);
  };

  const handleDayChange = (event) => {
    setDay(event.target.value);
    onBirthdayChange(year, month, event.target.value);
  };




  const styles = {
    smallTextField: {
      width: '100%',
      mr: 5,
      '& .MuiInputBase-root':{
        fontSize: textSize,
      },
      '& .MuiOutlinedInput-root':{
        fontSize: textSize,
      },
    },
    inputItemForm:{
      display:'flex',
      alignItems: 'center',
    },
    paddingLeft5:{
      paddingLeft: '5px',
    }
  }

  return (
    <>
      <Grid item xs={4} sm={4} md={4} lg={4} xl={4} css={styles.inputItemForm}>
        <TextField
          type="text"
          size="small"
          value={year}
          onChange={handleYearChange}
          css={styles.smallTextField}
          disabled={disabled}
        />
        <span css={styles.paddingLeft5}>年</span>
      </Grid>
      <Grid item xs={3} sm={3} md={3} lg={3} xl={3} css={styles.inputItemForm}>
        <TextField
          type="text"
          size="small"
          value={month}
          onChange={handleMonthChange}
          css={styles.smallTextField}
          disabled={disabled}
        />
        <span css={styles.paddingLeft5}>月</span>
      </Grid>
      <Grid item xs={3} sm={3} md={3} lg={3} xl={3} css={styles.inputItemForm}>
        <TextField
          type="text"
          size="small"
          value={day}
          onChange={handleDayChange}
          css={styles.smallTextField}
          disabled={disabled}
        />
        <span css={styles.paddingLeft5}>日</span>
      </Grid>
    </>
  );
};

export default TextFieldInputBirthday;
