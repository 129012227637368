/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@mui/material";
import StyledMuiTableCell from "../../../parts/table/StyledMuiTable/StyledMuiTableCell";
import StyledMuiTableHead from "../../../parts/table/StyledMuiTable/StyledMuiTableHead";
import StyledMuiTableRow from "../../../parts/table/StyledMuiTable/StyledMuiTableRow";
import TextFieldNameKanji from "../../forms/TextFieldNameKanji";
import axios from "axios";
import { PhoneNumberUtil, PhoneNumberFormat } from "google-libphonenumber";

import Const from "../../../../libs/Const";

const styles = {
  requiredIcon: {
    fontSize: "0.5rem",
    color: "#FFF",
    background: "#FF008C",
    padding: "1px 5px",
    borderRadius: "5px",
    marginRight: "5px",
  },
  byApplicantIcon: {
    fontSize: "0.5rem",
    color: "#FFF",
    background: "#868CB7",
    padding: "1px 5px",
    borderRadius: "5px",
    marginRight: "5px",
  },
  selectBox: {
    fontSize: "0.5rem",
    "& .MuiInputBase-input": {
      fontSize: "0.5rem",
      color: "#0047A4",
    },
    "& .MuiInputBase-root": {
      padding: "3px 0.5rem",
      fontSize: "0.5rem",
    },
    "& .MuiFormLabel-root": {
      fontSize: "0.5rem",
    },
    "& .MuiInputLabel-root": {
      fontSize: "0.5rem",
    },
    "& .MuiSelect-root": {
      fontSize: "0.5rem",
    },
  },
  textField: {
    mt: 1,
    "& .MuiInputBase-input": {
      fontSize: "0.5rem",
      padding: "0.5rem",
      color: "#0047A4",
    },
  },
  radioButton: {
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
    },
  },
  radioButtonLabel: {
    "& .MuiFormControlLabel-label": {
      fontSize: "0.5rem",
    },
  },
  smallTextSize: {
    fontSize: "0.5rem",
    color: "0047A4",
  },
};

export default function TabPanelOs1({ data, conflict }) {
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");

  const yearOptions = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= currentYear - 100; i--) {
    yearOptions.push(i);
  }

  const monthOptions = [];
  for (let i = 1; i <= 12; i++) {
    monthOptions.push(i);
  }

  const dayOptions = [];
  for (let i = 1; i <= 31; i++) {
    dayOptions.push(i);
  }

  // Calculate age
  let age = "";
  if (year && month && day) {
    const today = new Date();
    const birthDate = new Date(year, month - 1, day);
    age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
  }

  const [zipcode, setZipcodeMain] = useState({
    main: data[7].tdata[0].split("-")[0],
    sub: data[7].tdata[0].split("-")[1],
  });

  console.log(zipcode);

  const updateZipcodeMain = (e) => {
    setZipcodeMain({ ...zipcode, main: e.target.value });
  };
  const updateZipcodeSub = async (e) => {
    setZipcodeMain({ ...zipcode, sub: e.target.value });
    if (e.target.value.length === 4 && zipcode.main.length === 3) {
      try {
        const res = await axios.get(
          "https://zipcloud.ibsnet.co.jp/api/search",
          {
            params: {
              zipcode: zipcode.main + e.target.value,
            },
          }
        );
        if (res.data.results) {
          const result = res.data.results[0];
          setAddress({
            address1: result["address1"],
            address2: result["address2"],
            address3: result["address3"],
          });
        }
      } catch {
        alert("住所の取得に失敗しました。");
      }
    }
  };

  const [prefecture, setPrefecture] = React.useState("");
  const handleChangePrefecture = (event) => {
    setPrefecture(event.target.value);
  };

  const [address, setAddress] = useState({
    address1: "",
    address2: "",
    address3: "",
  });

  //---------------------電話番号にハイフンを自動補完する関数---------------------
  const formatPhoneNumber = (value) => {
    // 日本の国コード
    const region = "JP";

    const util = PhoneNumberUtil.getInstance();

    try {
      // 番号と地域を設定
      const number = util.parseAndKeepRawInput(value, region);

      // 電話番号の有効性チェック
      if (!util.isValidNumberForRegion(number, region)) {
        return value;
      }

      // ハイフン付きの形式で返却
      return util.format(number, PhoneNumberFormat.NATIONAL);
    } catch (e) {
      return value;
    }
  };
  const [phoneNumber1, setPhoneNumber1] = useState("");
  const handleChange1 = (event) => {
    const formattedNumber1 = formatPhoneNumber(event.target.value);
    setPhoneNumber1(formattedNumber1 || event.target.value);
  };
  useEffect(() => {
    setPhoneNumber1(formatPhoneNumber(phoneNumber1));
  }, [phoneNumber1]);

  const [phoneNumber2, setPhoneNumber2] = useState("");
  const handleChange2 = (event) => {
    const formattedNumber2 = formatPhoneNumber(event.target.value);
    setPhoneNumber2(formattedNumber2 || event.target.value);
  };
  useEffect(() => {
    setPhoneNumber2(formatPhoneNumber(phoneNumber2));
  }, [phoneNumber2]);
  //---------------------電話番号にハイフンを自動補完する関数---------------------

  console.log(data);
  return (
    <>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            各種同意
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="select-gender-radio-buttons-group-label"
                  defaultValue={data[0].tdata}
                  name="radio-buttons-group"
                  sx={{ flexDirection: "row" }}
                >
                  <FormControlLabel
                    value="未"
                    css={styles.radioButtonLabel}
                    control={
                      <Radio
                        size="small"
                        css={styles.radioButton}
                        disabled={conflict ? "disabled" : null}
                      />
                    }
                    label="未"
                  />
                  <FormControlLabel
                    value="済"
                    css={styles.radioButtonLabel}
                    control={
                      <Radio
                        size="small"
                        css={styles.radioButton}
                        disabled={conflict ? "disabled" : null}
                      />
                    }
                    label="済"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            名前（漢字）
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <TextField
                size="small"
                defaultValue={data[1].tdata}
                placeholder="応募"
                disabled={conflict ? "disabled" : null}
                css={styles.textField}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <TextField
                size="small"
                defaultValue={data[2].tdata}
                placeholder="花子"
                disabled={conflict ? "disabled" : null}
                css={styles.textField}
              />
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            名前（カナ）
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <TextField
                size="small"
                defaultValue={data[3].tdata}
                placeholder="オウボ"
                disabled={conflict ? "disabled" : null}
                css={styles.textField}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <TextField
                size="small"
                defaultValue={data[4].tdata}
                placeholder="ハナコ"
                disabled={conflict ? "disabled" : null}
                css={styles.textField}
              />
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            性別
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="select-gender-radio-buttons-group-label"
                  defaultValue={data[5].tdata}
                  name="radio-buttons-group"
                  sx={{ flexDirection: "row" }}
                >
                  <FormControlLabel
                    value="男性"
                    css={styles.radioButtonLabel}
                    control={
                      <Radio
                        size="small"
                        css={styles.radioButton}
                        disabled={conflict ? "disabled" : null}
                      />
                    }
                    label="男性"
                  />
                  <FormControlLabel
                    value="女性"
                    css={styles.radioButtonLabel}
                    control={
                      <Radio
                        size="small"
                        css={styles.radioButton}
                        disabled={conflict ? "disabled" : null}
                      />
                    }
                    label="女性"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            生年月日
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={8}
              sm={8}
              md={8}
              lg={8}
              xl={8}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FormControl fullWidth size="small" sx={{ minWidth: "70px" }}>
                  <Select
                    labelId="year-select-label"
                    value={year}
                    css={styles.selectBox}
                    onChange={(e) => setYear(e.target.value)}
                    disabled={conflict ? "disabled" : null}
                  >
                    {yearOptions.map((year) => (
                      <MenuItem
                        key={year}
                        value={year}
                        css={styles.smallTextSize}
                      >
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography
                  component="div"
                  css={styles.smallTextSize}
                  sx={{ ml: "3px" }}
                >
                  年
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
                <FormControl fullWidth size="small" sx={{ minWidth: "60px" }}>
                  <Select
                    labelId="month-select-label"
                    value={month}
                    css={styles.selectBox}
                    onChange={(e) => setMonth(e.target.value)}
                    disabled={conflict ? "disabled" : null}
                  >
                    {monthOptions.map((month) => (
                      <MenuItem
                        key={month}
                        value={month}
                        css={styles.smallTextSize}
                      >
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography
                  component="div"
                  css={styles.smallTextSize}
                  sx={{ ml: "3px" }}
                >
                  月
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
                <FormControl fullWidth size="small" sx={{ minWidth: "60px" }}>
                  <Select
                    labelId="day-select-label"
                    value={day}
                    css={styles.selectBox}
                    onChange={(e) => setDay(e.target.value)}
                    disabled={conflict ? "disabled" : null}
                  >
                    {dayOptions.map((day) => (
                      <MenuItem
                        key={day}
                        value={day}
                        css={styles.smallTextSize}
                      >
                        {day}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography
                  component="div"
                  css={styles.smallTextSize}
                  sx={{ ml: "3px" }}
                >
                  日
                </Typography>
              </Box>
              {age && (
                <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
                  <Typography component="p" css={styles.smallTextSize}>
                    &emsp;年齢: {age}&nbsp;歳
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            住所（郵便番号）
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small" sx={{ minWidth: "60px" }}>
                <TextField
                  id="zipcode_main"
                  size="small"
                  disabled={conflict ? "disabled" : null}
                  value={zipcode.main}
                  onChange={updateZipcodeMain}
                  css={styles.textField}
                />
              </FormControl>
              <Typography component="span" css={styles.smallTextSize}>
                &nbsp;&mdash;&nbsp;
              </Typography>
              <FormControl fullWidth size="small" sx={{ minWidth: "60px" }}>
                <TextField
                  id="zipcode_sub"
                  size="small"
                  disabled={conflict ? "disabled" : null}
                  value={zipcode.sub}
                  onChange={updateZipcodeSub}
                  css={styles.textField}
                />
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            住所（都道府県）
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={3}
              sm={3}
              md={3}
              lg={3}
              xl={3}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small">
                <Select
                  id="prefecture"
                  value={address.address1}
                  size="small"
                  onChange={handleChangePrefecture}
                  disabled={conflict ? "disabled" : null}
                  css={styles.selectBox}
                >
                  {/* Populate with actual list of prefectures */}
                  {Const.PREF_OPTIONS.map((prefecture) => (
                    <MenuItem
                      key={prefecture}
                      value={prefecture}
                      css={styles.smallTextSize}
                    >
                      {prefecture}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>

      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            住所（市区町村）
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small">
                <TextField
                  id="city"
                  size="small"
                  value={address.address2}
                  disabled={conflict ? "disabled" : null}
                  css={styles.textField}
                />
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            住所（番地）
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  disabled={conflict ? "disabled" : null}
                  css={styles.textField}
                />
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            住所（建物名）
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  disabled={conflict ? "disabled" : null}
                  css={styles.textField}
                />
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            最寄り駅①（沿線）
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  disabled={conflict ? "disabled" : null}
                  css={styles.textField}
                />
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            最寄り駅①（駅名）
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  disabled={conflict ? "disabled" : null}
                  css={styles.textField}
                />
              </FormControl>
              <Typography
                component="div"
                css={styles.smallTextSize}
                sx={{ ml: "3px" }}
              >
                駅
              </Typography>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            最寄り駅①からの交通手段
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small" sx={{ minWidth: "60px" }}>
                <Select labelId="transport-select-label" css={styles.selectBox}>
                  {["徒歩", "バス"].map((transport) => (
                    <MenuItem
                      key={transport}
                      value={transport}
                      css={styles.smallTextSize}
                    >
                      {transport}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            最寄り駅①からの所要時間
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              lg={2}
              xl={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  disabled={conflict ? "disabled" : null}
                  css={styles.textField}
                />
              </FormControl>
              <Typography
                component="div"
                css={styles.smallTextSize}
                sx={{ ml: "3px" }}
              >
                分
              </Typography>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            最寄り駅②（沿線）
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  disabled={conflict ? "disabled" : null}
                  css={styles.textField}
                />
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            最寄り駅②（駅名）
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  disabled={conflict ? "disabled" : null}
                  css={styles.textField}
                />
              </FormControl>
              <Typography
                component="div"
                css={styles.smallTextSize}
                sx={{ ml: "3px" }}
              >
                駅
              </Typography>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            最寄り駅②からの交通手段
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small" sx={{ minWidth: "60px" }}>
                <Select labelId="transport-select-label" css={styles.selectBox}>
                  {["徒歩", "バス"].map((transport) => (
                    <MenuItem
                      key={transport}
                      value={transport}
                      css={styles.smallTextSize}
                    >
                      {transport}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            最寄り駅②からの所要時間
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              lg={2}
              xl={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  disabled={conflict ? "disabled" : null}
                  css={styles.textField}
                />
              </FormControl>
              <Typography
                component="div"
                css={styles.smallTextSize}
                sx={{ ml: "3px" }}
              >
                分
              </Typography>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            電話番号①
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  value={phoneNumber1}
                  onChange={handleChange1}
                  disabled={conflict ? "disabled" : null}
                  css={styles.textField}
                />
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            電話番号②
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  value={phoneNumber2}
                  onChange={handleChange2}
                  disabled={conflict ? "disabled" : null}
                  css={styles.textField}
                />
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            メールアドレス①
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={8}
              sm={8}
              md={8}
              lg={8}
              xl={8}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  disabled={conflict ? "disabled" : null}
                  css={styles.textField}
                />
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            メールアドレス②
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={8}
              sm={8}
              md={8}
              lg={8}
              xl={8}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  disabled={conflict ? "disabled" : null}
                  css={styles.textField}
                />
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            国籍
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  disabled={conflict ? "disabled" : null}
                  css={styles.textField}
                />
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
    </>
  );
}
