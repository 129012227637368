export default function WarningIcon() {
  return (
    <svg id="warning-icon-yellow" viewBox="0 0 30 30" width="20" height="20">
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n .warning-icon-yellow-1{fill:none;stroke:#333;stroke-linecap:square;stroke-miterlimit:10;stroke-width:3px;}.warning-icon-yellow-2{fill:#F2F55F;}\n ",
          }}
        />
      </defs>
      <circle className="warning-icon-yellow-2" cx={15} cy={15} r={12} />
      <line className="warning-icon-yellow-1" x1="15.1" y1="8.6" x2="15.1" y2="15.6" />
      <line
        className="warning-icon-yellow-1"
        x1="15.1"
        y1="20.9"
        x2="15.1"
        y2="20.9"
      />
    </svg>
  );
}
