/** @jsxImportSource @emotion/react */
import React, { useState } from "react";

import {
  Button, Tab, Typography, Box, Table, TableContainer, TableCell, TableRow, TableBody, TableHead, Paper, Popover,
  } from '@mui/material';

import HistoryIcon from '@mui/icons-material/History';
import StyledMuiTableCell from '../../../parts/table/StyledMuiTable/StyledMuiTableCell';
import StyledMuiTableHead from '../../../parts/table/StyledMuiTable/StyledMuiTableHead';
import StyledMuiTableRow from '../../../parts/table/StyledMuiTable/StyledMuiTableRow';
import PopoverEditHistory from "./PopoverEditHistory";
import Const from "../../../../libs/Const";

export default function PanelOs2({item}) {

  function getMatchStatus(arr1, arr2) {
    return arr1.map(item => ({
      label: item,
      isMatch: arr2.includes(item)
    }));
  }

  return(
    <>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[0].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.DESIRED_EMPLOYMENT_TYPE, item[0].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.DESIRED_EMPLOYMENT_TYPE, item[0].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[0].history ? <PopoverEditHistory data={item[0]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[1].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.DESIRED_WORK_HOURS, item[1].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.DESIRED_WORK_HOURS, item[1].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[1].history ? <PopoverEditHistory data={item[1]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[2].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[2].tdata}</Typography>
            {item[2].history ? <PopoverEditHistory data={item[2]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[3].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus([ '該当なし' ], item[3].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus([ '該当なし' ], item[3].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[3].history ? <PopoverEditHistory data={item[3]}/> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[4].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.DESIRED_JOB_TYPE_CLERICAL_WORK, item[4].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.DESIRED_JOB_TYPE_CLERICAL_WORK, item[4].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[4].history ? <PopoverEditHistory data={item[4]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[5].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.DESIRED_JOB_TYPE_OPERATOR_POSITION, item[5].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.DESIRED_JOB_TYPE_OPERATOR_POSITION, item[5].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[5].history ? <PopoverEditHistory data={item[5]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[6].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.DESIRED_JOB_TYPE_SALES_CUSTOMER_SERVICE_STAFF, item[6].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.DESIRED_JOB_TYPE_SALES_CUSTOMER_SERVICE_STAFF, item[6].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[6].history ? <PopoverEditHistory data={item[6]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[7].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[7].tdata}</Typography>
            {item[7].history ? <PopoverEditHistory data={item[7]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[8].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.DAY_OF_WEEK, item[8].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.DAY_OF_WEEK, item[8].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[8].history ? <PopoverEditHistory data={item[8]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[9].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[9].tdata}日／週</Typography>
            {item[9].history ? <PopoverEditHistory data={item[9]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[10].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.DESIRED_WORK_TYPE, item[10].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.DESIRED_WORK_TYPE, item[10].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[10].history ? <PopoverEditHistory data={item[10]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[11].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[11].tdata}</Typography>
            {item[11].history ? <PopoverEditHistory data={item[11]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[12].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[12].tdata}</Typography>
            {item[12].history ? <PopoverEditHistory data={item[12]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[13].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[13].tdata}</Typography>
            {item[13].history ? <PopoverEditHistory data={item[13]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[14].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[14].tdata}</Typography>
            {item[14].history ? <PopoverEditHistory data={item[14]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[15].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.CAN_OVERTIME_WORK, item[15].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.CAN_OVERTIME_WORK, item[15].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[15].history ? <PopoverEditHistory data={item[15]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[16].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.IS_IT_POSSIBLE, item[16].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.IS_IT_POSSIBLE, item[16].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[16].history ? <PopoverEditHistory data={item[16]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[17].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.HOURLY_WAGE_RANGE, item[17].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.HOURLY_WAGE_RANGE, item[17].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[17].history ? <PopoverEditHistory data={item[17]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[18].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[18].tdata}</Typography>
            {item[18].history ? <PopoverEditHistory data={item[18]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[19].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[19].tdata}</Typography>
            {item[19].history ? <PopoverEditHistory data={item[19]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
    </>
  )
}

