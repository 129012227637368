function DummyEntryDataNc2() {
  const tableDataListsNc2 = [
    {
      id: "nc-2-1",
      thead: "希望の雇用形態",
      tdata: ["派遣"],
      history: true,
      historyData: [
        {
          editDate: "2022/12/15 10:30",
          editor: "応募　花子(応募者)",
          mode: "応募者保存",
          changes: "派遣",
        },
        {
          editDate: "2022/12/15 10:30",
          editor: "媒体データ取込",
          mode: "",
          changes: "直接雇用(正社員)",
        },
      ],
    },
    {
      id: "nc-2-2",
      thead: "案内を控えてほしい施設・病院",
      tdata: ["応募病院"],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-3",
      thead: "就業開始可能日",
      tdata: ["2023/04/01"],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-4",
      thead: "希望勤務可能日",
      tdata: ["月", "火", "水", "木", "金"],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-5",
      thead: "希望勤務時間",
      tdata: ["早番", "日勤", "遅番"],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-6",
      thead: "希望勤務日数／週",
      tdata: ["週5日"],
      history: false,
      historyData: [],
    },
    {
      id: "nc-2-7",
      thead: "具体的希望時間",
      tdata: ["特になし"],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-8",
      thead: "残業可否",
      tdata: ["可"],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-9",
      thead: "希望条件",
      tdata: [],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-10",
      thead: "時給",
      tdata: ["1,500円～1,600円"],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-11",
      thead: "通勤時間(手段)",
      tdata: ["自宅から"],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-12",
      thead: "通勤時間",
      tdata: ["30分"],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-13",
      thead: "希望施設形態",
      tdata: [
        "特養",
        "老健",
        "有料",
        "デイ",
        "ショート",
        "デイケア",
        "軽費",
        "訪問介護",
        "訪問看護",
        "訪入浴",
      ],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-14",
      thead: "介護職を選ばれた理由",
      tdata: [
        "・自らの力で支えられる幸せを感じる\n・毎日実感できる役割の拡大\n・介護を通して役立った人々に喜びを与えられる",
      ],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-15",
      thead: "仕事探しをしている理由",
      tdata: [
        "・家族に何かを貢献する理由\n・自身のスキルを生かすため\n・将来性のある職業を求める",
      ],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-16",
      thead: "希望交通手段",
      tdata: ["公共交通機関", "徒歩"],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-17",
      thead: "その他希望事項",
      tdata: [],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-18",
      thead: "三大介助の抵抗有無",
      tdata: ["わからない"],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-19",
      thead: "レクリエーションの対応可否",
      tdata: ["可"],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-20",
      thead: "調理補助の対応可否",
      tdata: [
        "メニューが決まっており、材料もそろっている状態での調理であれば対応可能",
      ],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-21",
      thead: "送迎業務の対応可否",
      tdata: ["軽自動車であれば対応可能"],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-22",
      thead: "希望条件の優先順位（1位）",
      tdata: ["時間給・給与"],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-23",
      thead: "希望条件の優先順位（2位）",
      tdata: ["勤務時間・時間帯"],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-24",
      thead: "希望条件の優先順位（3位）",
      tdata: ["職場環境"],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-25",
      thead: "電話の繋がりやすい時間帯",
      tdata: ["昼頃、または18時以降"],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-26",
      thead: "車両任意保険加入状況",
      tdata: ["加入済"],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-27",
      thead: "自宅からの最寄り駅①(沿線)",
      tdata: ["東京メトロ日比谷線"],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-28",
      thead: "自宅からの最寄り駅①(駅名)",
      tdata: ["人形町駅"],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-29",
      thead: "最寄り駅からの交通手段①",
      tdata: ["徒歩"],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-30",
      thead: "最寄り駅からの所要時間①",
      tdata: ["5分"],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-31",
      thead: "自宅からの最寄り駅②(沿線)",
      tdata: ["東京メトロ半蔵門線"],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-32",
      thead: "自宅からの最寄り駅②(駅名)",
      tdata: ["水天宮前駅"],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-33",
      thead: "最寄り駅からの交通手段②",
      tdata: ["バス"],
      history: false,
      historyData: [{}],
    },
    {
      id: "nc-2-34",
      thead: "最寄り駅からの所要時間②",
      tdata: ["20分"],
      history: false,
      historyData: [{}],
    },
  ];
  return tableDataListsNc2;
}

export default DummyEntryDataNc2;
