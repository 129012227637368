/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';


import {
  Grid, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,  Button
} from '@mui/material';

import StyledMuiPaper from '../../parts/surface/StyledMuiPaper';
import MuiRadioButton from'../../parts/radio/MuiRadioButton';
import StyledReactDatePicker from '../../parts/Datepicker/StyledReactDatePicker';
import MuiTextField from '../../parts/textField/MuiTextField';
import StyledMuiBtnBlue from '../../parts/button/StyledMuiBtnBlue';
import SendGenericMailPage from '../../pages/SendGenericMailPage';


const styles = {
  btnSizeSmall:{
    transform: 'scale(0.75)',
    transformOrigin: 'center center',
  },
  title: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
  },
  flexSbC: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexCC: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  customTableCell: {
    padding: '5px',
  },
  globalFontStyle: {
    color: '#0047A4',
    fontSize: '0.5rem',
  }
}


const rows = [
  { 
    action: 'send_mail',
    title: '年末年始のご案内',
    content: '拝啓、いつも格別のご愛顧を賜り、厚くお礼申し上げます。\n\nさて、年末年始の営業日・休業日について、以下の通りご案内申し上げます。\n\n【年末年始の営業日・休業日】\n\n12月29日（木）〜1月4日（水）まで 休業\n1月5日（木）から 通常営業\nなお、上記期間中のお問い合わせにつきましては、緊急を要するものを除き、1月5日以降に順次ご対応させていただきます。\n\nお客様には大変ご迷惑をおかけいたしますが、何卒ご了承くださいますようお願い申し上げます。\n\n引き続き、弊社をご愛顧賜りますようお願い申し上げます。\n敬具',
    edit: 'send_mail',
  },
  { 
    actionLink: 'send_mail',
    title: '【重要】年末調整に関するお知らせ',
    content: 'いつもお世話になっております。株式会社ネオキャリアでございます。\n\nこの度、年末調整のお知らせを申し上げます。年末調整は、源泉徴収された給与所得税や社会保険料などを、年間を通じて申告する制度です。また、年末調整がされていないと、年末に確定申告を行うことになり、手続きが複雑となる場合がございます。\n\n調整に必要な書類は、ご提出いただいた給与明細や源泉徴収票などがございます。ご不明点がございましたら、担当者までお問い合わせください。\n\n年末調整は、12月末日までに行う必要がございます。期日を過ぎますと、調整ができなくなりますので、お早めに手続きをお済ませください。\n\n今後とも、株式会社ネオキャリアをよろしくお願い申し上げます。',
    editLink: 'edit',
  },

];




export default function MailTemplateSearch() {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const navigate = useNavigate();

  const handleSelectTemplate = (row) => {
    setSelectedTemplate(row);
    navigate('/send_mail', { state: { datas: row } });
  };

  return (
    <>
      <StyledMuiPaper
        sx={{
          width: "70%",
          overflow: "hidden",
          p: 5,
          mb: 3,
          textAlign: "left",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell css={[styles.globalFontStyle, styles.customTableCell ]} sx={{ fontWeight: 'bold', width: '5%' }} align="left">No</TableCell>
                    <TableCell css={[styles.globalFontStyle, styles.customTableCell ]} sx={{ fontWeight: 'bold', width: '85%' }} align="left">件名</TableCell>
                    <TableCell css={[styles.globalFontStyle, styles.customTableCell ]} sx={{ fontWeight: 'bold', width: '10%' }}>&nbsp;</TableCell>
                    
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, i) => (
                    <TableRow
                      key={row.title}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell css={[styles.globalFontStyle, styles.customTableCell ]} align="left">{ i + 1 }</TableCell>
                      <TableCell css={[styles.globalFontStyle, styles.customTableCell ]} align="left">{row.title}</TableCell>
                      <TableCell
                        css={[styles.globalFontStyle, styles.customTableCell]}
                        scope="row"
                      >
                        <Button variant="contained" css={styles.btnSizeSmall} onClick={() => handleSelectTemplate(row)}>
                          選択
                        </Button>
                      </TableCell>
                      {/* <TableCell css={[styles.globalFontStyle, styles.customTableCell ]}>
                        <Link to={row.editLink}><Button variant="contained" css={styles.btnSizeSmall}>編集</Button></Link>
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </StyledMuiPaper>
    </>
  )
}