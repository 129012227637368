/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import StyledMuiPaper from "../../parts/surface/StyledMuiPaper";
import SelectedDepartment from "../../parts/select/SelectedDepartment";
import SelectedOffice from "../../parts/select/SelectedOffice";
import SelectedBranch from "../../parts/select/SelectedBranch";
import StyledMuiBtnBlue from "../../parts/button/StyledMuiBtnBlue";

const styles = {
  customTableHeader: {
    fontSize: "0.5rem",
    fontWeight: "bold",
    color: "#0047A4",
    padding: "4px 10px",
    textAlign: "center",
  },
  customTableRow: {
    fontSize: "0.5rem",
    color: "#0047A4",
  },
  ruby: {
    fontSize: "0.5rem",
  },
  searchBtn: {
    transform: "scale(0.75)",
    transformOrigin: "center center",
    color: "#46D295",
    width: "120px",
    border: "1px solid #46D295",
    "&:hover": {
      color: "#FFFFFF",
      background: "#46D295",
      border: "1px solid #46D295",
    },
  },
};

export default function SeacrhFormsThree() {
  return (
    <StyledMuiPaper sx={{ width: "100%", overflow: "hidden", mb: 1 }}>
      <Grid container sx={{ mt: 1 }}>
        <Grid item xs={3} sm={3} md={3} lg={3}>
          <Typography component="span" sx={{ fontSize: "0.5rem", pr: 2 }}>
            事業部
          </Typography>
          <SelectedDepartment />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3}>
          <Typography component="span" sx={{ fontSize: "0.5rem", pr: 2 }}>
            支社
          </Typography>
          <SelectedOffice />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3}>
          <Typography component="span" sx={{ fontSize: "0.5rem", pr: 2 }}>
            支店
          </Typography>
          <SelectedBranch />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3}>
          <Button variant="outlined" css={styles.searchBtn}>
            検索
          </Button>
        </Grid>
      </Grid>
    </StyledMuiPaper>
  );
}
