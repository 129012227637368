/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';

import { Autocomplete, Box, Typography, Table, Paper, TableContainer, Button, Modal, TextField, FormControl, Select, MenuItem, } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';


import StyledMuiTableCell from '../../../parts/table/StyledMuiTable/StyledMuiTableCell';
import StyledMuiTableHead from '../../../parts/table/StyledMuiTable/StyledMuiTableHead';
import StyledMuiTableRow from '../../../parts/table/StyledMuiTable/StyledMuiTableRow';

import MuiAutocomplete from '../../../parts/Autocomplete/MuiAutocomplete';
import MuiTextField from '../../../parts/textField/MuiTextField';
import MuiRadioButton from '../../../parts/radio/MuiRadioButton';
import SelectedPrefecture from '../../../parts/select/SelectedPrefecture';
import MuiSelectBox from '../../../parts/select/MuiSelectBox';

import SelectedAppliMedia from '../../../parts/select/SelectedAppliMedia';
import SelectedBranch from '../../../parts/select/SelectedBranch';



const tableDataListsBasicInfo = [
  { 'thead': '姓(漢字)', 'tdata': '応募' },
  { 'thead': '名(漢字)', 'tdata': '花子' },
  { 'thead': 'セイ(カナ)', 'tdata': 'オウボ' },
  { 'thead': 'メイ(カナ)', 'tdata': 'ハナコ' },
  { 'thead': '性別','tdata': '女性' },
  { 'thead': '現在の職業','tdata': '会社員' },
  { 'thead': '生年月日', 'tdata': '2000年01月01日' },
  { 'thead': '住所(都道府県)','tdata': '東京都' },
  { 'thead': '住所(市区町村)','tdata': '中央区日本橋人形町' },
  { 'thead': '電話番号(携帯)', 'tdata': '090-3333-3333' },
  { 'thead': '電話番号(その他連絡先)', 'tdata': '03-3333-3333' },
  { 'thead': 'メールアドレス1', 'tdata': 'oubo@ilovex.co.jp' },
  { 'thead': 'メールアドレス2', 'tdata': 'dammy-test-fake@docomo.co.jp' },
];

function ModalEditBasicInfo(props) {
  const { onClose, applicantBasicInfo, ...other } = props;
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  const birthday = applicantBasicInfo.birthday;
  console.log(applicantBasicInfo);
  function splitBirthday(birthday) {
    const dateParts = birthday.split("/");
    const dayOfBirth = parseInt(dateParts[2], 10);
    const monthOfBirth = parseInt(dateParts[1], 10);
    const yearOfBirth = parseInt(dateParts[0], 10);
    
    return {dayOfBirth, monthOfBirth, yearOfBirth};
  }
  const {dayOfBirth, monthOfBirth, yearOfBirth} = splitBirthday(birthday);
  
  const [year, setYear] = useState(yearOfBirth);
  const [month, setMonth] = useState(monthOfBirth);
  const [day, setDay] = useState(dayOfBirth);

  const yearOptions = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= (currentYear - 100); i--) {
    yearOptions.push(i);
  }

  const monthOptions = [];
  for (let i = 1; i <= 12; i++) {
    monthOptions.push(i);
  }

  const dayOptions = [];
  for (let i = 1; i <= 31; i++) {
    dayOptions.push(i);
  }

  // Calculate age
  let age = "";
  if (year && month && day) {
    const today = new Date();
    const birthDate = new Date(year, month - 1, day);
    age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
  }

  //郵便番号を2つのテキストボックスに格納する
  let threeDigitNumber = '';
  let fourDigitNumber = '';
  if (applicantBasicInfo.zipcode){
    const zipcodeArray = applicantBasicInfo.zipcode.split('-');
    threeDigitNumber = zipcodeArray[0];
    fourDigitNumber = zipcodeArray[1];
  }

  const items = [
    { label: '千代田区' },
    { label: '中央区' },
    { label: '港区' },
    { label: '新宿区' },
    { label: '文京区' },
    { label: '台東区' },
    { label: '墨田区' },
    { label: '江東区' },
    { label: '品川区' },
    { label: '目黒区' },
    { label: '大田区' },
    { label: '世田谷区' },
    { label: '渋谷区' },
    { label: '中野区' },
    { label: '杉並区' },
    { label: '豊島区' },
    { label: '北区' },
    { label: '荒川区' },
    { label: '板橋区' },
    { label: '練馬区' },
    { label: '足立区' },
    { label: '葛飾区' },
    { label: '江戸川区' },
    { label: '八王子市' },
    { label: '立川市' },
    { label: '武蔵野市' },
    { label: '三鷹市' },
    { label: '青梅市' },
    { label: '府中市' },
    { label: '昭島市' },
    { label: '調布市' },
    { label: '町田市' },
    { label: '小金井市' },
    { label: '小平市' },
    { label: '日野市' },
    { label: '東村山市' },
    { label: '国分寺市' },
    { label: '国立市' },
    { label: '福生市' },
    { label: '狛江市' },
    { label: '東大和市' },
    { label: '清瀬市' },
    { label: '東久留米市' },
    { label: '武蔵村山市' },
    { label: '多摩市' },
    { label: '稲城市' },
    { label: '羽村市' },
    { label: 'あきる野市' },
    { label: '西東京市' },
    { label: '西多摩郡' },
    { label: '大島町' },
    { label: '新島村' },
    { label: '三宅島' },
    { label: '八丈島' },
    { label: '小笠原村 '}
  ];





  return (
    <Box css={styles.modal}>
      <Typography id="modal-title" variant="h6" component="h2" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p className="contentBlockTitle">応募者情報編集</p>
        <IconButton aria-label="close-modal-view-more-contact" onClick={handleClose}>
          <CancelIcon sx={{ color: '#777' }}/>
        </IconButton>
      </Typography>
      <Typography id="modal-description" sx={{ mt: 2 }}>
        <Box sx={{ width: '100%' }}>
          <TableContainer component={Paper} sx={{ my:3, height: '70vh', pt:1 }}>
            <Table sx={{ minWidth: 250 }} aria-label="simple table">
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">名前（漢字）</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ mr:1 }}>
                    <MuiTextField label="姓" defaultValue={applicantBasicInfo.seiKanji}/>
                  </Box>
                  <Box sx={{ ml:1 }}>
                    <MuiTextField label="名" defaultValue={applicantBasicInfo.meiKanji}/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">名前（カナ）</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ mr:1 }}>
                    <MuiTextField label="セイ" defaultValue={applicantBasicInfo.seiKana}/>
                  </Box>
                  <Box sx={{ ml:1 }}>
                    <MuiTextField label="メイ" defaultValue={applicantBasicInfo.meiKana}/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">性別</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box>
                    <MuiRadioButton
                      ariaLabel="selectGender"
                      name="gender"
                      formLabels={[
                        { value: "男性", label: "男性" },
                        { value: "女性", label: "女性" }
                      ]}
                      defaultValue={applicantBasicInfo.gender}
                    />
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">生年月日</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControl fullWidth size="small" sx={{ minWidth: '70px' }}>
                      <Select
                        labelId="year-select-label"
                        value={year}
                        css={styles.selectBox}
                        onChange={(e) => setYear(e.target.value)}
                      >
                        {yearOptions.map((year) => (
                          <MenuItem key={year} value={year} css={styles.smallTextSize}>{year}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Typography component="div" css={styles.smallTextSize} sx={{ ml: '3px' }}>年</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                    <FormControl fullWidth size="small" sx={{ minWidth: '60px' }}>
                      <Select
                        labelId="month-select-label"
                        value={month}
                        css={styles.selectBox}
                        onChange={(e) => setMonth(e.target.value)}
                      >
                        {monthOptions.map((month) => (
                          <MenuItem key={month} value={month} css={styles.smallTextSize}>{month}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Typography component="div" css={styles.smallTextSize} sx={{ ml: '3px' }}>月</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                    <FormControl fullWidth size="small" sx={{ minWidth: '60px' }}>
                      <Select
                        labelId="day-select-label"
                        value={day}
                        css={styles.selectBox}
                        onChange={(e) => setDay(e.target.value)}
                      >
                        {dayOptions.map((day) => (
                          <MenuItem key={day} value={day} css={styles.smallTextSize}>{day}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Typography component="div" css={styles.smallTextSize} sx={{ ml: '3px' }}>日</Typography>
                  </Box>
                  {age && (
                  <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                    <Typography component="p" css={styles.smallTextSize}>
                      &emsp;年齢: {age}&nbsp;歳
                    </Typography>
                  </Box>
                  )}
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              {/* <StyledMuiTableRow>
                <StyledMuiTableHead align="left">応募者コード</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '50%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <MuiTextField placeholder="" defaultValue={applicantBasicInfo.applicantId}/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">スタッフコード</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '50%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <MuiTextField placeholder="" defaultValue={applicantBasicInfo.caerusId}/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">応募媒体</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '50%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <SelectedAppliMedia fieldSize={120} defaultValue={applicantBasicInfo.appliMedia}/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">現在の職業</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '50%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <MuiSelectBox
                      items={['正社員', '派遣', 'バイト', '学生', 'なし']}
                      id="transportaion2"
                      labelId="transportaion2Label"
                      defaultValue={applicantBasicInfo.currentOccupation}
                    />
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">年齢</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '30%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <MuiTextField placeholder="20"/><Typography component="span" sx={{ fontSize: '0.5rem', pl: 1 }}>歳</Typography>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow> */}
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">郵便番号</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '20%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <MuiTextField placeholder="160" defaultValue={threeDigitNumber}/><Typography component="span" sx={{ fontSize: '0.5rem', pl: 1 }}>―</Typography>
                  </Box>
                  <Box sx={{ width: '20%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <MuiTextField placeholder="0023" defaultValue={fourDigitNumber}/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">住所(都道府県)</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '30%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <SelectedPrefecture defaultValue={applicantBasicInfo.addressPref}/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">住所(市区町村)</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '50%' }}>
                    <MuiAutocomplete
                      options={items}
                      label="市区町村"
                      defaultValue={applicantBasicInfo.addressCity}
                    />
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">住所(町域・番地)</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '100%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <MuiTextField placeholder="西新宿1-22-2" defaultValue={applicantBasicInfo.addressHouse}/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">住所(建物名など)</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '100%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <MuiTextField placeholder="新宿サンエービル 2階" defaultValue={applicantBasicInfo.addressBuilding}/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
            {/* 
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">最寄り駅①(沿線)</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '100%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <MuiTextField placeholder="沿線"/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">最寄り駅①(駅名)</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '100%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <MuiTextField placeholder="駅名"/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">最寄り駅①からの交通手段</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '100%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <MuiSelectBox items={['徒歩', 'バス']} id="transportaion1" labelId="transportaion1Label"/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">最寄り駅①からの所要時間</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '20%', mr:1, display: 'flex', alignItems: 'center' }}>
                  <MuiTextField placeholder="10"/><Typography component="span" sx={{ fontSize: '0.5rem', pl: 1 }}>分</Typography>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">最寄り駅②(沿線)</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '100%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <MuiTextField placeholder="沿線"/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">最寄り駅②(駅名)</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '100%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <MuiTextField placeholder="駅名"/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">最寄り駅②からの交通手段</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '100%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <MuiSelectBox items={['徒歩', 'バス']} id="transportaion2" labelId="transportaion2Label"/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">最寄り駅②からの所要時間</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '20%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <MuiTextField placeholder="10"/><Typography component="span" sx={{ fontSize: '0.5rem', pl: 1 }}>分</Typography>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow> */}
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">電話番号①(携帯)</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '50%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <MuiTextField placeholder="090-0000-0000" defaultValue={applicantBasicInfo.tel1}/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">電話番号②(その他連絡先)</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '50%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <MuiTextField placeholder="03-0000-0000" defaultValue={applicantBasicInfo.tel2}/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">電話番号③(その他連絡先)</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '50%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <MuiTextField placeholder="03-0000-0000" defaultValue={applicantBasicInfo.tel2}/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">メールアドレス①</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '100%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <MuiTextField placeholder="" defaultValue={applicantBasicInfo.mailAddress1}/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">メールアドレス②</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '100%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <MuiTextField placeholder="" defaultValue={applicantBasicInfo.mailAddress2}/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              {/* <StyledMuiTableRow>
                <StyledMuiTableHead align="left">担当者</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '50%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <MuiTextField placeholder="" defaultValue={applicantBasicInfo.manager}/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">担当支店(応募時)</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '100%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <SelectedBranch defaultValue={applicantBasicInfo.branch1}/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">担当支店(担当)</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '100%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <SelectedBranch defaultValue={applicantBasicInfo.branch2}/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">登録支店</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '100%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <SelectedBranch defaultValue={applicantBasicInfo.branch3}/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">質問</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '100%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <MuiTextField placeholder="" defaultValue={applicantBasicInfo.question}/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">希望連絡時間</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '100%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <MuiTextField placeholder="" defaultValue={applicantBasicInfo.contactTime}/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">希望連絡方法</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '100%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <MuiSelectBox
                      items={['電話(携帯)', '電話(その他)', 'メール①', 'メール②']}
                      id="contactMethod"
                      labelId="contactMethod"
                      defaultValue={applicantBasicInfo.contactMethod}
                    />
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow>
              <StyledMuiTableRow>
                <StyledMuiTableHead align="left">在留カード情報</StyledMuiTableHead>
                <StyledMuiTableCell sx={{ display: 'flex' }}>
                  <Box sx={{ width: '100%', mr:1, display: 'flex', alignItems: 'center' }}>
                    <MuiSelectBox items={['あり', 'なし']} id="residentCard" labelId="residentCard"/>
                  </Box>
                </StyledMuiTableCell>
              </StyledMuiTableRow> */}
              
            </Table>
          </TableContainer>
        </Box>
      </Typography>
      <div css={styles.BtnBlockCenter}>
        <Button variant="contained" color='inherit' css={styles.btnSizeSmall} onClick={handleClose}>閉じる</Button>
        <Button variant="contained" css={styles.btnSizeSmall}>確定する</Button>
      </div>
    </Box>
  )
}

const styles = {
  modal:{
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    backgroundColor: '#FFF',
    borderRadius: '0.25rem',
    padding: '15px 20px',
    fontSize: '0.625rem',
  },
  BtnBlockCenter:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnSizeSmall:{
    transform: 'scale(0.75)',
    transformOrigin: 'center bottom',
  },
  selectBox:{
    fontSize: '0.5rem',
    '& .MuiInputBase-input':{
      fontSize: '0.5rem',
      color: '#0047A4',
    },
    '& .MuiInputBase-root':{
      padding: '3px 0.5rem',
      fontSize: '0.5rem',
    },
    '& .MuiFormLabel-root':{
      fontSize: '0.5rem',
    },
    '& .MuiInputLabel-root':{
      fontSize: '0.5rem',
    },
    '& .MuiSelect-root':{
      fontSize: '0.5rem'
    }
  },
  smallTextSize:{
    fontSize: '0.5rem',
    color: '0047A4',
  },
}

export default ModalEditBasicInfo;
