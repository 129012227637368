
function DummyEntryDataOs5(){

  const tableDataListsOS5 = [
    {
      id: 'os-5-1',
      thead: '保有資格1',
      tdata: [
        '普通自動車第一種運転免許',
        '2018/10',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-5-2',
      thead: '保有資格2',
      tdata: [
        '宅地建物取引士',
        '2019/04',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-5-3',
      thead: '保有資格3',
      tdata: [
        '行政書士',
        '2020/04',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-5-4',
      thead: '保有資格4',
      tdata: [
        'ファイナンシャルプランナー',
        '2021/07',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-5-5',
      thead: 'PC入力スキル',
      tdata: [
        'ブラインドタッチ入力'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-5-6',
      thead: '英語スキル',
      tdata: [
        '文章読み書き可能'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
  ];
  return tableDataListsOS5;

}

export default DummyEntryDataOs5;