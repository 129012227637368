import React from 'react';
import StyledMuiAppBar from "../parts/AppBar/StyledMuiAppBar";
import BranchMasterSetting from "../feature/BranchMasterSetting/BranchMasterSettingForm";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

export default function BranchMasterSettingPage() {
  return (
    <>
      <StyledMuiAppBar headerTitle='支店独自項目マスタメンテ'/>
      <Box sx={{ width: '100%', mt: 7, mb: 3 }}>
        <Grid container spacing={2} rowSpacing={2} sx={{px:10}}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <BranchMasterSetting/>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
