/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { styled } from '@mui/material';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

function TextFieldInputAddress({ address, textSize, disabled }) {
  //------------コンポーネントのスタイル------------
  const styles = {
    textarea:{
      width: '90%',

      '& .MuiInputBase-input':{
        fontSize: textSize,
      },
      '& .MuiInputBase-root':{
        // padding: '3px 0.5rem',
      },
      '& .MuiInputLabel-root':{
        fontSize: textSize,
      }
    },
    inputItemForm:{
      display:'flex',
      alignItems: 'center',
    },
    paddingLeft5:{
      paddingLeft: '5px',
    }
  }
  //------------コンポーネントのスタイル------------

  return (
    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>

      <TextField
        id="address"
        label="町域・番地"
        size="small"
        value={address.address3}
        css={styles.textarea}
        disabled={disabled}
      />
    </Grid>
  );
}
export default TextFieldInputAddress;