import React from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { styled } from '@mui/material';

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

const scrollToBottom = () => {
  window.scrollTo({
    top: document.documentElement.scrollHeight,
    behavior: 'smooth',
  });
};
const ScrollButtonsContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: '20px',
  right: '7px',
  display: 'flex',
  flexDirection: 'column',
}));

export default function StyledAnkerLink() {
  return (
    <ScrollButtonsContainer>
      <IconButton onClick={scrollToTop} color="primary" aria-label="scroll to top">
        <ArrowUpwardIcon />
      </IconButton>
      <IconButton onClick={scrollToBottom} color="primary" aria-label="scroll to bottom">
        <ArrowDownwardIcon />
      </IconButton>
    </ScrollButtonsContainer>
  );
};
