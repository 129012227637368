export default function WarningIconCircleRed() {
  return (
    <svg id="warning-icon-red" viewBox="0 0 30 30" width="20" height="20">
      <defs>
        <style
          dangerouslySetInnerHTML={{
            __html:
              "\n .warning-icon-red-1{fill:none;stroke:#fff;stroke-linecap:square;stroke-miterlimit:10;stroke-width:3px;}.warning-icon-red-2{fill:#f4605e;}\n ",
          }}
        />
      </defs>
      <circle className="warning-icon-red-2" cx={15} cy={15} r={12} />
    </svg>
  );
}
