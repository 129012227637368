
function DummyEntryDataOs4(){

  const tableDataListsOS4 = [
    {
      id: 'os-4-1',
      thead: '就業経験',
      tdata: [''],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-4-2',
      thead: '一般事務',
      tdata: [
        '資料作成',
        '議事録作成',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-4-3',
      thead: '営業事務',
      tdata: [
        '受注発注業務',
        '海外受発注',
        '見積作成',
        '契約書作成',
        '請求書作成',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-4-4',
      thead: '経理事務',
      tdata: [
        '伝票起票',
        '伝票仕訳',
        '売掛金管理',
        '買掛金管理',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-4-5',
      thead: '総務事務',
      tdata: [
        '経費精算',
        '旅費計算',
        '小口現金管理',
        '備品管理・発注',
        '郵便物仕分',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-4-6',
      thead: '人事事務',
      tdata: [
        '採用',
        '説明会対応',
        '面接',
        '合否連絡',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-4-7',
      thead: '秘書',
      tdata: [

      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-4-8',
      thead: '受付',
      tdata: [
        '総合受付',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-4-9',
      thead: '貿易事務',
      tdata: [
        ,
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-4-10',
      thead: '英文事務',
      tdata: [
        '翻訳',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-4-11',
      thead: '電話業務',
      tdata: [
        'テレマーケティング',
        'テレフォンオペレーター',
        'テレフォンアポインター',
        'カスタマーサポート',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-4-12',
      thead: 'コールセンター',
      tdata: [

      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-4-13',
      thead: '金融',
      tdata: [
        '受信',
        '発信',
        '対法人',
        '対個人',
        '3',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-4-14',
      thead: '情報通信',
      tdata: [
        '受信',
        '発信',
        '2'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-4-15',
      thead: '製造',
      tdata: [
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-4-16',
      thead: 'サービス',
      tdata: [
        '受信',
        '対個人',
        '1'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-4-17',
      thead: '官公庁/自治体',
      tdata: [
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-4-18',
      thead: 'ユーザサポート・ヘルプデスク',
      tdata: [
        '受信',
        '対個人',
        '0.5'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-4-19',
      thead: '運用保守・管理',
      tdata: [
        '受信',
        '対法人',
        '対個人',
        '0.3'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-4-20',
      thead: 'SV経験',
      tdata: [
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-4-21',
      thead: 'その他①',
      tdata: [
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-4-22',
      thead: 'その他②',
      tdata: [
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-4-23',
      thead: 'デザイン・WEB全般',
      tdata: [
        'マーケティング',
        'ランディングページ制作',
        'プレスリリース作成',
        'SNSの運用',
        '企画・立案',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
  ];
  return tableDataListsOS4;

}

export default DummyEntryDataOs4;