/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { styled } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from "@mui/material/Grid";

export default function CheckBoxWebEntry({ textSize, buttonSize, width, items, disabled }) {
  //------------コンポーネントのスタイル------------
  const styles = {
    inputCheckBox:{
      '& .MuiSvgIcon-root':{
        fontSize: buttonSize,
      },
    },
    checkBoxLabel:{
      '& .MuiFormControlLabel-label':{
        fontSize: textSize,
      },
    },
  }
  //------------コンポーネントのスタイル------------

  const checkboxItems = items;

  return (
    <Grid item xs={width} sm={width} md={width} lg={width} xl={width}>
      {checkboxItems.map((item) => {
        return <FormControlLabel control={<Checkbox css={styles.inputCheckBox} disabled={disabled} />} label={item} css={styles.checkBoxLabel} sx={{ mr:2 }}/>
      })}
    </Grid>
  );
}
