/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { styled } from '@mui/material';
import { TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

export default function MuiSelectBox (props) {
  const { items, id, labelId, defaultValue, width, disabled, ...other } = props;

  //------------コンポーネントのスタイル------------
  const styles = {
    selectBox:{
      '& .MuiInputBase-input':{
        fontSize: '0.5rem',
        color: '#0047A4',
      },
      '& .MuiInputBase-root':{
        // padding: '3px 0.5rem',
        fontSize: '0.5rem',
      },
      '& .MuiFormLabel-root':{
        fontSize: '0.5rem',
      },
      '& .MuiInputLabel-root':{
        fontSize: '0.5rem',
      },
      '& .MuiSelect-root':{
        fontSize: '0.5rem'
      }
    },
    inputItemForm:{
      display:'flex',
      alignItems: 'center',
    },
    paddingLeft5:{
      paddingLeft: '5px',
    },
    textSize:{
      fontSize: '0.5rem',
      color: '0047A4',
    }
  }
  //------------コンポーネントのスタイル------------
  const [value, setValue] = useState(defaultValue || '');
  const handleChangeValue = (event) => {
    setValue(event.target.value);
  };
  
  return (
    <FormControl sx={{ mr: 2, width: width }} size="small">
      <Select
        labelId={labelId}
        id={id}
        value={value}
        css={styles.selectBox}
        sx={{ fontSize: '0.5rem' }}
        displayEmpty
        onChange={handleChangeValue}
        disabled={disabled}
        renderValue={(selected) => {
          if (!selected || selected.length === 0) {
            return <em css={styles.textSize}>選択してください</em>;
          }
          return selected;
        }}
      >
        <MenuItem value="" sx={{ fontSize: '0.5rem' }}><em css={styles.textSize}>選択してください</em></MenuItem>
        {items.map((item) => (
          <MenuItem value={item} css={styles.textSize}>{item}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
