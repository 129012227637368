/** @jsxImportSource @emotion/react */
import { useState } from "react";
import StyledMuiPaper from "../../parts/surface/StyledMuiPaper";
import {
  Modal,
  Paper,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import ModalRegistContact from "./Modals/ModalRegistContact";
import ModalViewMoreContact from "./Modals/ModalViewMoreContact";

const contacts = [
  {
    action: "メール送信",
    correspondence_date: "2023/07/31",
    branch: "OS新宿",
    status: "登録完了",
    identification: "提示・確認済み",
    web_entry: "開始用",
    web_reserve: "案内メール送信",
    send_mail: "送信あり",
    job_suggestion: "時給NG",
    comment:
      "ここに対応内容が入ります。ここに対応内容が入ります。ここに対応内容が入ります。",
    created_by: "山田　太郎",
    created_at: "2023/08/01 10:00",
  },
  {
    action: "入電",
    correspondence_date: "2023/07/28",
    branch: "OS新宿",
    status: "仮登録",
    identification: "提示依頼済み",
    web_entry: "登録用",
    web_reserve: "案内メール送信",
    send_mail: "",
    job_suggestion: "",
    comment: "ここに対応内容が入ります。",
    created_by: "山田　太郎",
    created_at: "2023/07/28 18:21",
  },
  {
    action: "コール(未接触)",
    correspondence_date: "2023/07/28",
    branch: "OS新宿",
    status: "仮登録",
    identification: "提示依頼済み",
    web_entry: "登録用",
    web_reserve: "案内メール送信",
    send_mail: "",
    job_suggestion: "",
    comment: "ここに対応内容が入ります。",
    created_by: "山田　太郎",
    created_at: "2023/07/28 18:21",
  },
  {
    action: "コール(接触)",
    correspondence_date: "2023/07/28",
    branch: "OS新宿",
    status: "仮登録",
    identification: "提示依頼済み",
    web_entry: "登録用",
    web_reserve: "案内メール送信",
    send_mail: "",
    job_suggestion: "",
    comment: "ここに対応内容が入ります。",
    created_by: "山田　太郎",
    created_at: "2023/07/28 18:21",
  },
  {
    action: "コール(未接触)",
    correspondence_date: "2023/07/28",
    branch: "OS新宿",
    status: "呼び込み中",
    identification: "提示依頼済み",
    web_entry: "登録用",
    web_reserve: "案内メール送信",
    send_mail: "",
    job_suggestion: "",
    comment: "ここに対応内容が入ります。",
    created_by: "山田　太郎",
    created_at: "2023/07/28 18:21",
  },
  {
    action: "コール(未接触)",
    correspondence_date: "2023/07/28",
    branch: "OS新宿",
    status: "呼び込み中",
    identification: "提示依頼済み",
    web_entry: "登録用",
    web_reserve: "案内メール送信",
    send_mail: "",
    job_suggestion: "",
    comment: "ここに対応内容が入ります。",
    created_by: "山田　太郎",
    created_at: "2023/07/28 18:21",
  },
  {
    action: "入電",
    correspondence_date: "2023/07/28",
    branch: "OS新宿",
    status: "辞退（連絡あり）",
    identification: "提示依頼済み",
    web_entry: "登録用",
    web_reserve: "案内メール送信",
    send_mail: "",
    job_suggestion: "",
    comment: "ここに対応内容が入ります。",
    created_by: "山田　太郎",
    created_at: "2023/07/28 18:21",
  },
  {
    action: "コール(未接触)",
    correspondence_date: "2023/07/28",
    branch: "OS新宿",
    status: "未対応",
    identification: "提示依頼済み",
    web_entry: "登録用",
    web_reserve: "案内メール送信",
    send_mail: "",
    job_suggestion: "",
    comment: "ここに対応内容が入ります。",
    created_by: "山田　太郎",
    created_at: "2023/07/28 18:21",
  },
  {
    action: "",
    correspondence_date: "",
    branch: "",
    status: "",
    identification: "",
    web_entry: "",
    web_reserve: "",
    send_mail: "",
    job_suggestion: "",
    comment: "",
    created_by: "",
    created_at: "",
  },
];

export default function ApplicantsContactHistory() {
  const [activeModal, setActiveModal] = useState(null);

  const handleOpenModal = (modalId) => {
    setActiveModal(modalId);
  };
  const handleCloseModal = () => {
    setActiveModal(null);
  };

  const actions = [
    {
      icon: <AddToPhotosIcon />,
      name: "対応履歴を新規登録する",
      id: "regist-contact",
      modal: <ModalRegistContact onClose={handleCloseModal} editFlg={false} />,
    },
    {
      icon: <HistoryIcon />,
      name: "対応履歴をもっと見る",
      id: "more-history",
      modal: <ModalViewMoreContact onClose={handleCloseModal} />,
    },
  ];

  return (
    <StyledMuiPaper sx={{ width: "100%", height: "100%" }}>
      <Box css={styles.titleAndEditBtn}>
        <Typography component="p" css={styles.title}>
          対応履歴
        </Typography>
        <Box sx={{ paddingRight: "6px" }}>
          {actions.map((action) => {
            return (
              <>
                <Tooltip title={action.name}>
                  <IconButton
                    key={action.id}
                    onClick={() => handleOpenModal(action.id)}
                    sx={{
                      width: "24px",
                      height: "24px",
                      marginLeft: "0.5rem",
                      padding: "5px",
                      background: "#fff",
                      boxShadow:
                        "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
                    }}
                  >
                    {action.icon}
                  </IconButton>
                </Tooltip>
                <Modal
                  key={action.id}
                  open={activeModal === action.id}
                  onClose={handleCloseModal}
                  aria-labelledby={`${action.id}-modal-title`}
                  aria-describedby={`${action.id}-modal-description`}
                >
                  {action.modal}
                </Modal>
              </>
            );
          })}
        </Box>
      </Box>
      <TableContainer
        component={Paper}
        sx={{
          width: "initial",
          height: "35vh",
          px: 1,
          py: 1,
          mt: 2,
          fontSize: "0.5rem",
        }}
      >
        <Table>
          <TableHead>
            <TableCell css={styles.tableHeader} sx={{ width: "15%" }}>
              対応日
            </TableCell>
            <TableCell css={styles.tableHeader} sx={{ width: "15%" }}>
              対応区分
            </TableCell>
            <TableCell css={styles.tableHeader}>対応内容</TableCell>
            <TableCell css={styles.tableHeader} sx={{ width: "15%" }}>
              ステータス
            </TableCell>
            <TableCell
              css={styles.tableHeader}
              sx={{ width: "5%" }}
            ></TableCell>
          </TableHead>
          {contacts.slice(0, 5).map((contactData, index) => (
            <TableRow key={index}>
              <TableCell css={styles.tdata}>
                {contactData.correspondence_date}
              </TableCell>
              <TableCell css={styles.tdata}>{contactData.action}</TableCell>
              <TableCell css={styles.tdata}>{contactData.comment}</TableCell>
              <TableCell css={styles.tdata}>{contactData.status}</TableCell>
              <TableCell css={styles.tdata}>
                {index === 0 ? (
                  <>
                    <Tooltip title="対応履歴を編集する">
                      <IconButton
                        sx={{
                          width: "24px",
                          height: "24px",
                          padding: "5px",
                        }}
                      >
                        <EditIcon
                          sx={{ color: "initial" }}
                          onClick={() => handleOpenModal("edit-contact")}
                        />
                      </IconButton>
                    </Tooltip>
                    <Modal
                      key="edit-contact"
                      open={activeModal === "edit-contact"}
                      onClose={handleCloseModal}
                      aria-labelledby="edit-contact-modal-title"
                      aria-describedby="edit-contact-modal-description"
                    >
                      <ModalRegistContact
                        editData={contactData}
                        onClose={handleCloseModal}
                        editFlg={true}
                      />
                    </Modal>
                  </>
                ) : (
                  <></>
                )}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>
    </StyledMuiPaper>
  );
}

const styles = {
  title: {
    display: "inline-block",
    padding: "0 1rem",
    fontSize: "1rem",
    color: "#0047A4",
    backgroundColor: "#c6e0f7",
    height: "1.5rem",
  },
  tableHeader: {
    fontSize: "0.5rem",
    padding: "3px",
    color: "#4B576B",
    textAlign: "center",
  },
  titleAndEditBtn: {
    display: "flex",
    justifyContent: "space-between",
  },
  tdata: {
    fontSize: "0.5rem",
    padding: "10px 8px",
    color: "#0047A4",
    lineHeight: "1.2",
    fontWeight: "bold",
    verticalAlign: "baseline",
  },
  moreHistoryBtnBlock: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  moreHistoryBtn: {
    transform: "scale(0.75)",
    transformOrigin: "center bottom",
  },
};
