/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";

import StyledMuiPaper from "../../parts/surface/StyledMuiPaper";
import MuiSelectBox from "../../parts/select/MuiSelectBox";

const styles = {
  middleWidth: {
    width: "70%",
    "@media (max-width:1200px)": {
      width: "90%",
      padding: "2rem",
    },
  },
  middleTableWidth: {
    width: "80%",
    "@media (max-width:1200px)": {
      width: "100%",
      padding: "2rem",
    },
  },
  btnSizeSmall: {
    transform: "scale(0.75)",
    transformOrigin: "center bottom",
  },
  selectBox: {
    fontSize: "0.5rem",
    "& .MuiInputBase-input": {
      fontSize: "0.5rem",
      color: "#0047A4",
    },
    "& .MuiInputBase-root": {
      padding: "3px 0.5rem",
      fontSize: "0.5rem",
    },
    "& .MuiFormLabel-root": {
      fontSize: "0.5rem",
    },
    "& .MuiInputLabel-root": {
      fontSize: "0.5rem",
    },
    "& .MuiSelect-root": {
      fontSize: "0.5rem",
    },
  },
  inputCheckBox: {
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
    },
  },
  smallTextSize: {
    fontSize: "0.5rem",
    color: "0047A4",
  },

  title: {
    fontSize: "0.8rem",
    fontWeight: "bold",
  },
  flexSbC: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexCC: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  customTableCell: {
    padding: "5px",
  },
  globalFontStyle: {
    color: "#0047A4",
    fontSize: "0.5rem",
  },
};
export default function PhoneApplicantsList({ applicants }) {
  return (
    <StyledMuiPaper
      css={styles.middleWidth}
      sx={{
        overflow: "hidden",
        pt: 2,
        pb: 5,
        mb: 3,
        textAlign: "left",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid
        container
        spacing={2}
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
      >
        <DepartmentSelector />
        <ApplicantTable applicants={applicants} />
      </Grid>
    </StyledMuiPaper>
  );
}

function DepartmentSelector() {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Typography
        component="div"
        css={styles.smallTextSize}
        sx={{ mr: 1, pl: 3 }}
      >
        事業部
      </Typography>
      <MuiSelectBox
        items={["OS", "NC"]}
        id="department"
        labelId="department"
        css={styles.selectBox}
        width="130px"
      />
    </Grid>
  );
}

function ApplicantTable({ applicants }) {
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <ApplicantTableHeader />
          </TableHead>
          <TableBody>
            {applicants.map((row, i) => (
              <ApplicantRow key={i} applicant={row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

function ApplicantTableHeader() {
  return (
    <TableRow>
      <HeaderCell width="15%">連携日時</HeaderCell>
      <HeaderCell width="20%">支店</HeaderCell>
      <HeaderCell width="20%">応募媒体</HeaderCell>
      <HeaderCell width="15%">電話番号</HeaderCell>
      <HeaderCell width="20%">&nbsp;</HeaderCell>
      <HeaderCell width="10%">&nbsp;</HeaderCell>
    </TableRow>
  );
}

function HeaderCell({ width, children }) {
  return (
    <TableCell
      css={[styles.globalFontStyle, styles.customTableCell]}
      sx={{ fontWeight: "bold", width }}
      align="left"
    >
      {children}
    </TableCell>
  );
}

function ApplicantRow({ applicant }) {
  const { cooperationDate, branch, applicantMedia, phoneNumber, path } =
    applicant;
  const buttonType =
    !branch && !phoneNumber
      ? "delete"
      : branch && !phoneNumber
      ? "exclude"
      : "";

  return (
    <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
      <TableCellContent>{cooperationDate}</TableCellContent>
      <TableCellContent>{branch}</TableCellContent>
      <TableCellContent>{applicantMedia}</TableCellContent>
      <TableCellContent>{phoneNumber}</TableCellContent>
      <TableCellContent align="right">
        <Link to={path} target="_blank">
          <Button variant="contained" css={styles.btnSizeSmall}>
            新規登録画面へ
          </Button>
        </Link>
      </TableCellContent>
      <TableCellContent align="right">
        {(branch && !phoneNumber) || (!branch && !phoneNumber) ? (
          <DeleteOrExcludeButton buttonType={buttonType} />
        ) : (
          <></>
        )}
      </TableCellContent>
    </TableRow>
  );
}

function TableCellContent({ align = "left", children }) {
  return (
    <TableCell
      css={[styles.globalFontStyle, styles.customTableCell]}
      align={align}
    >
      {children}
    </TableCell>
  );
}

function DeleteOrExcludeButton({ buttonType }) {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        variant="contained"
        color={buttonType === "exclude" ? "warning" : "error"}
        css={styles.btnSizeSmall}
        onClick={handleClickOpen}
        sx={{
          width: "100%",
          display:
            buttonType === "exclude" || buttonType === "delete"
              ? "block"
              : "none",
        }}
      >
        {buttonType === "exclude"
          ? "対象外"
          : buttonType === "delete"
          ? "削除"
          : ""}
      </Button>
      <ConfirmationDialog
        open={open}
        handleClose={handleClose}
        buttonType={buttonType}
      />
    </>
  );
}

function ConfirmationDialog({ open, handleClose, buttonType }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle css={styles.title}>
        {buttonType === "exclude" ? "対象外の確認" : "削除の確認"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText css={styles.smallTextSize}>
          {buttonType === "exclude"
            ? "対象外とします。宜しいですか？電話・メアド不備として登録します。"
            : "選択したアイテムを削除します。よろしいですか？"}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          onClick={handleClose}
          css={styles.btnSizeSmall}
          sx={{ color: "#777" }}
        >
          いいえ
        </Button>
        <Button
          onClick={handleClose}
          color={buttonType === "exclude" ? "warning" : "error"}
          variant="contained"
          autoFocus
          css={styles.btnSizeSmall}
        >
          はい
        </Button>
      </DialogActions>
    </Dialog>
  );
}
