/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { styled } from '@mui/material';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


function SelectBoxWebEntry ({ textSize, width, items, id, labelId, disabled }) {

  //------------コンポーネントのスタイル------------
  const styles = {
    selectBox:{
      width: '90%',

      '& .MuiInputBase-input':{
        fontSize: textSize,
      },
      '& .MuiInputBase-root':{
        // padding: '3px 0.5rem',
        fontSize: textSize,
      },
      '& .MuiFormLabel-root':{
        fontSize: textSize,
      },
      '& .MuiInputLabel-root':{
        fontSize: textSize,
      },
      '& .MuiSelect-root':{
        fontSize: textSize
      }
    },
    inputItemForm:{
      display:'flex',
      alignItems: 'center',
    },
    paddingLeft5:{
      paddingLeft: '5px',
    },
    textStyle:{
      fontSize: textSize,
      color: '#0047A4',
    }
  }
  //------------コンポーネントのスタイル------------
  const [value, setValue] = useState('');
  const handleChangeValue = (event) => {
    setValue(event.target.value);
  };
  
  return (
    <Grid item xs={width} sm={width} md={width} lg={width} xl={width}>
      <FormControl sx={{ mr: 2, minWidth: '100%' }} size="small">
        <Select
          labelId={labelId}
          id={id}
          value={value}
          css={styles.selectBox}
          sx={{ fontSize: textSize }}
          disabled={disabled}
          displayEmpty
          onChange={handleChangeValue}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em css={styles.textStyle}>選択してください</em>;
            }
            return selected;
          }}
        >
          <MenuItem value="" css={styles.textStyle}><em css={styles.textStyle}>選択してください</em></MenuItem>
          {items.map((item) => (
            <MenuItem value={item} css={styles.textStyle}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  )
}

export default SelectBoxWebEntry;