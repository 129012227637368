/** @jsxImportSource @emotion/react */
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import StyledMuiTextField from '../../../parts/textField/StyledMuiTextField';
import StyledMuiTableCell from '../../../parts/table/StyledMuiTable/StyledMuiTableCell';
import StyledMuiTableHead from '../../../parts/table/StyledMuiTable/StyledMuiTableHead';
import StyledMuiTableRow from '../../../parts/table/StyledMuiTable/StyledMuiTableRow';


const checkboxListsOs = [
  '基本情報',
  '希望条件',
  'OAスキル',
  '資格',
  '経験職種',
  '職歴/学歴',
  '緊急連絡先',
  '給与口座',
  '社保・雇用',
  '在留カード',
];

const requestItemListsOs = [
  { label: '基本情報', conflict: true, description: '編集中（2023/04/04 10:00 営業 太郎）' },
  { label: '希望条件', conflict: false, description: '編集中（2023/04/04 10:00 営業 太郎）' },
  { label: 'OAスキル', conflict: false, description: '編集中（2023/04/04 10:00 営業 太郎）' },
  { label: '資格', conflict: false, description: '編集中（2023/04/04 10:00 営業 太郎）' },
  { label: '経験職種', conflict: true, description: '編集中（2023/04/04 10:00 営業 太郎）' },
  { label: '職歴/学歴', conflict: false, description: '編集中（2023/04/04 10:00 営業 太郎）' },
  { label: '緊急連絡先', conflict: false, description: '編集中（2023/04/04 10:00 営業 太郎）' },
  { label: '給与口座', conflict: true, description: '編集中（2023/04/04 10:00 営業 太郎）' },
  { label: '社保・雇用', conflict: false, description: '編集中（2023/04/04 10:00 営業 太郎）' },
  { label: '在留カード', conflict: true, description: '編集中（2023/04/04 10:00 営業 太郎）' },
]

function ModalSendRequestEdit({onClose}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Box css={styles.modal}>
      <Typography id="modal-title" variant="h6" component="h2" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p className="contentBlockTitle">修正依頼の送信</p>
        <IconButton aria-label="close-modal-view-more-contact" onClick={handleClose}>
          <CancelIcon sx={{ color: '#777' }}/>
        </IconButton>
      </Typography>
      <Grid container sx={{ width: '100%', mt: 2 }}>
        {requestItemListsOs.map((item, i) => {
          return(
            <Grid item xs={6} sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', p: 1 }}>
              <Typography sx={{ fontSize: '0.5rem', fontWeight: item.conflict ? 'regular' : 'bold', color: item.conflict ? '#777' : '#0047A4', width: "3.5rem" }}>{item.label}</Typography>
              <Typography color="error" sx={{ fontSize: '0.5rem', pl: 2 }}>{item.conflict ? item.description : ''}</Typography>
            </Grid>
          )
        })}
        <Grid item xs={12} sx={{ my: 1, width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <TextField
            aria-label="minimum height"
            multiline={true}
            minRows={5}
            sx={{ mt:1, width: '100%' }}
            placeholder="コメントを追加してください。"
            css={styles.textArea}
          />
        </Grid>
        <Grid item xs={12} sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box css={styles.BtnBlockCenter}>
            <Button variant="contained" color='inherit' css={styles.btnSizeSmall} onClick={handleClose}>閉じる</Button>
            <Button variant="contained" css={styles.btnSizeSmall}>送信する</Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

const styles = {
  modal:{
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    
    backgroundColor: '#FFF',
    borderRadius: '0.25rem',
    padding: '15px 20px',
    fontSize: '0.5rem',
  },
  inputCheckbox:{
    '& .MuiSvgIcon-root':{
      fontSize: '1rem',
    },
  },
  formLabel:{
    '& .MuiFormControlLabel-label':{
      fontSize: '0.5rem',
    },
  },
  textArea:{
    "& .MuiInputBase-root":{
      padding: '6px 8px',
    },
    "& .MuiInputBase-input":{
      fontSize: '0.5rem'
    },
    "& .MuiInputBase-input::placeholder":{
      fontSize: '0.5rem'
    }
  },
  BtnBlockCenter:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  btnSizeSmall:{
    transform: 'scale(0.75)',
    transformOrigin: 'center bottom',
  },


  
}

export default ModalSendRequestEdit;
