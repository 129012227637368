/** @jsxImportSource @emotion/react */
import React from "react";

import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";

import StyledMuiPaper from "../../parts/surface/StyledMuiPaper";

const styles = {
  customTableHeader: {
    fontSize: "0.5rem",
    fontWeight: "bold",
    color: "#4B576B",
    padding: "4px 10px",
  },
  customTableHeaderAlignC: {
    fontSize: "0.5rem",
    fontWeight: "bold",
    color: "#0047A4",
    padding: "4px 10px",
  },
  customTableRow: {
    fontSize: "0.5rem",
    color: "#0047A4",
    padding: "2px 5px",
  },
  customTableData: {
    fontSize: "0.5rem",
    padding: "2px 8px",
  },
  ruby: {
    fontSize: "0.5rem",
    transform: "scale(0.75)",
    transformOrigin: "center left",
  },
};

export default function ApplicantsInformation({ applicantDatas }) {

  return (
    <StyledMuiPaper sx={{ width: "100%", overflow: "hidden", mb: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          component="p"
          sx={{
            display: "inline-block",
            padding: "0 1rem",
            fontSize: "1rem",
            color: "#0047A4",
            backgroundColor: "#c6e0f7",
            height: "1.5rem",
          }}
        >
          応募者情報
        </Typography>
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell css={styles.customTableHeader} sx={{ width: "4%" }}>&emsp;</TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "8%" }}>応募日時</TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "11%" }}>応募媒体</TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "10%" }}>氏名(カナ)</TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "7%" }}>生年月日</TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "13%" }}>住所</TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "8%" }}>電話番号</TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "18%" }}>
                メールアドレス
              </TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "8%" }}>&emsp;</TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "8%" }}>&emsp;</TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "5%" }}>&emsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {applicantDatas.map((row, i) => (
              <TableRow
                key={i}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  width: "100%",
                }}
              >
                <TableCell css={styles.customTableRow}>&emsp;</TableCell>
                <TableCell css={styles.customTableRow}>
                  <Typography component="p" css={styles.customTableData}>
                    {row.applicant_date}
                  </Typography>
                </TableCell>
                <TableCell css={styles.customTableRow}>
                  <Typography component="p" css={styles.customTableData}>
                    {row.media}
                  </Typography>
                </TableCell>
                <TableCell css={styles.customTableRow}>
                  <Typography
                    component="p"
                    css={[styles.ruby, styles.customTableRow]}
                  >
                    {row.sei_kana}&emsp;{row.mei_kana}
                  </Typography>
                  <Typography component="p" css={styles.customTableData}>
                    {row.sei_kanji}&emsp;{row.mei_kanji}
                  </Typography>
                </TableCell>
                <TableCell css={styles.customTableRow}>
                  <Typography component="p" css={styles.customTableData}>
                    {row.birthday}
                  </Typography>
                </TableCell>
                <TableCell css={styles.customTableRow}>
                  <Typography component="p" css={styles.customTableData}>
                    {row.prefecture}
                    {row.city}
                  </Typography>
                  <Typography component="p" css={styles.customTableData}>
                    {row.address}
                  </Typography>
                  <Typography component="p" css={styles.customTableData}>
                    {row.building}
                  </Typography>
                </TableCell>
                <TableCell css={styles.customTableRow}>
                  <Typography component="p" css={styles.customTableData}>
                    {row.phone_number_1}
                  </Typography>
                  <Typography component="p" css={styles.customTableData}>
                    {row.phone_number_2}
                  </Typography>
                </TableCell>
                <TableCell css={styles.customTableRow}>
                  <Typography component="p" css={styles.customTableData}>
                    {row.mailaddress_1}
                  </Typography>
                  <Typography component="p" css={styles.customTableData}>
                    {row.mailaddress_2}
                  </Typography>
                </TableCell>
                <TableCell css={styles.customTableRow} align="center">
                  &emsp;
                </TableCell>
                <TableCell css={styles.customTableRow} align="center">
                  &emsp;
                </TableCell>
                <TableCell css={styles.customTableRow} align="center">
                  &emsp;
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledMuiPaper>
  );
}
