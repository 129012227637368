import React from "react";
import StyledMuiAppBar from "../parts/AppBar/StyledMuiAppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import ApplicantsRegistForm from "../feature/ApplicantsRegist/ApplicantsRegistForm";
import MailTemplateSearch from "../feature/GenericMail/MailTemplateSearch";
import SearchFormsKeyWord from "../feature/SearchForms/SearchFormsKeyWord";



export default function MailTemplateListPage() {
  return (
    <>
      <StyledMuiAppBar headerTitle='汎用メールテンプレート検索'/>
      <Box sx={{ width: '100%', mt: 7, mb: 3 }}>
        <Grid container spacing={2} rowSpacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <SearchFormsKeyWord />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <MailTemplateSearch />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}
