/** @jsxImportSource @emotion/react */
import React, { useState } from "react";

import {
  Button, Tab, Typography, Box, Table, TableContainer, TableCell, TableRow, TableBody, TableHead, Paper, Popover,
  } from '@mui/material';

import HistoryIcon from '@mui/icons-material/History';
import StyledMuiTableCell from '../../../parts/table/StyledMuiTable/StyledMuiTableCell';
import StyledMuiTableHead from '../../../parts/table/StyledMuiTable/StyledMuiTableHead';
import StyledMuiTableRow from '../../../parts/table/StyledMuiTable/StyledMuiTableRow';
import PopoverEditHistory from "./PopoverEditHistory";
import Const from "../../../../libs/Const";

export default function PanelOs3({item}) {

  function getMatchStatus(arr1, arr2) {
    return arr1.map(item => ({
      label: item,
      isMatch: arr2.includes(item)
    }));
  }

  return(
    <>
    {item.map((data) => {
      return (
        data.thead !== '使用したOAソフト'
        ?
        (<StyledMuiTableRow>
          <StyledMuiTableHead align="left" sx={{ pl: data.thead.charAt(0) === '【' ? '5px' : 6 }}>{data.thead}</StyledMuiTableHead> 
          <StyledMuiTableCell>
            <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
              <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{data.tdata}</Typography>
              {data.history ? <PopoverEditHistory data={data} /> : <Box sx={{ width: '20px' }}/>}
            </Box>
          </StyledMuiTableCell>
        </StyledMuiTableRow>)
        :
        (
        <StyledMuiTableRow>
          <StyledMuiTableHead align="left">{data.thead}</StyledMuiTableHead>
          <StyledMuiTableCell>
            <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
              <Box>
              {getMatchStatus(Const.OA_SOFTWARE, data.tdata).map((match, i) => {
                return(
                  <>
                    <Typography component="span" sx={{
                      fontSize: '0.5rem',
                      fontWeight: match.isMatch ? 'bold' : '',
                      color: !match.isMatch ? '#CCC': '',
                      pr: '5px',
                      display: 'inline-block',
                      }}
                    >
                      {match.label}
                    </Typography>
                    {
                    getMatchStatus(Const.OA_SOFTWARE, data.tdata).length - 1 !== i 
                    ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                    : <></>
                    }
                  </>
                )
              })}
              </Box>
              {data.history ? <PopoverEditHistory data={data} /> : <Box sx={{ width: '20px' }}/>}
            </Box>
          </StyledMuiTableCell>
        </StyledMuiTableRow>
        )
      )
    })}

    </>
  )
}

