/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { styled } from '@mui/material';
import { FormControl, TextField } from "@mui/material";

function TextFiledApplicantSearch({ id, label,value, disabled, fieldSize }) {
  //------------コンポーネントのスタイル------------
  const styles = {
    textarea:{
      width: '100%',

      '& .MuiInputBase-input':{
        fontSize: '0.5rem',
      },
      '& .MuiInputBase-root':{
        // padding: '3px 0.5rem',
      },
      '& .MuiInputLabel-root':{
        fontSize: '0.5rem',
      }
    },
    inputItemForm:{
      display:'flex',
      alignItems: 'center',
    },
    paddingLeft5:{
      paddingLeft: '5px',
    }
  }
  //------------コンポーネントのスタイル------------
  return (
    <FormControl sx={{ width: fieldSize }} size="small">
      <TextField
        id={id}
        label={label}
        size="small"
        disabled={disabled}
        defaultValue={value}
        css={styles.textarea}
      />
    </FormControl>
  );
}
export default TextFiledApplicantSearch;