import React from "react";
import { Chip } from "@mui/material";

export default function MuiChipNew() {
  return (
    <Chip
      label="新規"
      size="small"
      sx={{
        fontSize: "0.3rem",
        background: "#7EABEE",
        color: "#FFF",
      }}
    />
  );
}
