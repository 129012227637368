/** @jsxImportSource @emotion/react */
import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

//------------コンポーネントのスタイル------------
const styles = {
  autocomplete:{
    fontSize: '0.5rem',
    padding: '0px',
    '& .MuiOutlinedInput-root':{
      padding: '0px 8px',
    },
  },
  textField:{
    textAlign: 'left',
    '& .MuiInputBase-input':{
      fontSize: '0.5rem',
      color: '#0047A4',
    },
    '& .MuiInputLabel-root':{
      fontSize: '0.5rem',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 8px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
}
//------------コンポーネントのスタイル------------

export default function MuiAutocomplete({ options, label, defaultValue }) {
  return (
    <Autocomplete
      disablePortal
      id="combo-box"
      options={options}
      css={styles.autocomplete}
      value={defaultValue}
      renderOption={(props, option) => (
        <li {...props} style={{ fontSize: '0.5rem' }}>
          {option.label}
        </li>
      )}
      renderInput={(params) => <TextField {...params} label={label} css={styles.textField} />}
    />
  );
};

