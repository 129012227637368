/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ToggleButton,
  Collapse,
  IconButton,
  Paper,
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import StyledMuiPaper from "../../parts/surface/StyledMuiPaper";

const styles = {
  customTableHeader: {
    fontSize: "0.5rem",
    fontWeight: "bold",
    color: "#4B576B",
    padding: "4px 10px",
  },
  customTableRow: {
    fontSize: "0.5rem",
    color: "#0047A4",
    padding: "2px 5px",
  },
  customTableData: {
    fontSize: "0.5rem",
    padding: "2px 8px",
  },
  ruby: {
    fontSize: "0.5rem",
    transform: "scale(0.75)",
    transformOrigin: "center left",
  },
  highlights: {
    background: "",
  },
};
function ApplicantRow(props) {
  const { row, applicantDatas, caerusDatas, index } = props;
  const [open, setOpen] = useState(true);

  const isDuplicate = (key, row) => {
    return applicantDatas.some((data) => data[key] === row[0][key]);
  };
  const isDuplicateChildren = (key, row) => {
    return applicantDatas.some((data) => data[key] === row[key]);
  };

  function CustomTypography({ row, field }) {
    let background = "";
    let content = "";

    if (row[0]) {
      background = isDuplicate(field, row) ? "rgb(255,255,125,.8)" : "";
      content = row[0][field];
    } else {
      background = isDuplicateChildren(field, row) ? "rgb(255,255,125,.8)" : "";
      content = row[field];
    }

    if (!content) {
      content = <br />;
    }

    return (
      <Typography
        component="span"
        sx={{
          fontSize: "0.5rem",
          background: background,
        }}
      >
        {content}
      </Typography>
    );
  }

  const isSameHuman = (row) => {
    const data = applicantDatas[0];
    const nameSeiKanaMatch = data.sei_kana === row.sei_kana;
    const nameMeiKanaMatch = data.mei_kana === row.mei_kana;
    const nameSeiKanjiMatch = data.sei_kanji === row.sei_kanji;
    const nameMeiKanjiMatch = data.mei_kanji === row.mei_kanji;
    const phoneNumber1Match = data.phone_number_1 === row.phone_number_1;
    const phoneNumber2Match = data.phone_number_2 === row.phone_number_2;
    const mailAddress1Match = data.mailaddress_1 === row.mailaddress_1;
    const mailAddress2Match = data.mailaddress_2 === row.mailaddress_2;
    const birthdayMatch = data.birthday === row.birthday;

    if (
      ((nameSeiKanaMatch && nameMeiKanaMatch) ||
        (nameSeiKanjiMatch && nameMeiKanjiMatch)) &&
      (phoneNumber1Match ||
        phoneNumber2Match ||
        mailAddress1Match ||
        mailAddress2Match)
    ) {
      return 1; //確定
    }
    if (
      ((nameSeiKanaMatch && nameMeiKanaMatch) ||
        (nameSeiKanjiMatch && nameMeiKanjiMatch)) &&
      birthdayMatch
    ) {
      return 2; //候補
    }
    if (
      phoneNumber1Match ||
      phoneNumber2Match ||
      mailAddress1Match ||
      mailAddress2Match
    ) {
      return 2; //候補
    }

    return 3;
  };

  function colorKeying(row) {
    for (let i = 0; i < caerusDatas.length; i++) {
      if (
        caerusDatas[i].identify !== null &&
        row[0].identify !== null &&
        caerusDatas[i].identify === row[0].identify
      ) {
        switch (caerusDatas[i].identify && row[0].identify) {
          case 1:
            return "rgb(152,251,152,.4)";

          case 2:
            return "rgb(250,250,152,.4)";

          case 3:
            return "rgb(201,152,250,.4)";

          case 4:
            return "rgb(250,152,152,.4)";

          case 5:
            return "rgb(152,201,250,.4)";

          default:
            return null;
        }
      }
    }
    return null;
  }

  const [selected, setSelected] = useState(
    Array(caerusDatas.length).fill(false)
  );

  const handleToggle = (index) => {
    setSelected((prevState) => {
      const newState = [...prevState];
      newState[index] = !newState[index];
      return newState;
    });
  };

  // 単一のレコードの場合は通常のTableRow
  if (row.length === 1) {
    return (
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          width: "100%",
          background: colorKeying(row),
        }}
      >
        <TableCell css={styles.customTableRow}>
          <Typography component="span" css={styles.customTableData}>
            &nbsp;
          </Typography>
        </TableCell>
        <TableCell css={styles.customTableRow}>
          <Typography component="p" css={styles.customTableData}>
            <CustomTypography row={row} field="applicant_date" />
          </Typography>
        </TableCell>
        <TableCell css={styles.customTableRow}>
          <Typography component="p" css={styles.customTableData}>
            <CustomTypography row={row} field="media" />
          </Typography>
        </TableCell>
        <TableCell css={styles.customTableRow}>
          <Typography component="p" css={[styles.ruby, styles.customTableData]}>
            <CustomTypography row={row} field="sei_kana" />
            &emsp;
            <CustomTypography row={row} field="mei_kana" />
          </Typography>
          <Link to="/oubo_detail" target="_blank">
            <Typography component="p" css={styles.customTableData}>
              <CustomTypography row={row} field="sei_kanji" />
              &emsp;
              <CustomTypography row={row} field="mei_kanji" />
            </Typography>
          </Link>
        </TableCell>
        <TableCell css={styles.customTableRow}>
          <Typography component="p" css={styles.customTableData}>
            <CustomTypography row={row} field="birthday" />
          </Typography>
        </TableCell>
        <TableCell css={styles.customTableRow}>
          <Typography component="p" css={styles.customTableData}>
            <CustomTypography row={row} field="prefecture" />
            <CustomTypography row={row} field="city" />
          </Typography>
          <Typography component="p" css={styles.customTableData}>
            <CustomTypography row={row} field="address" />
          </Typography>
          <Typography component="p" css={styles.customTableData}>
            <CustomTypography row={row} field="building" />
          </Typography>
        </TableCell>
        <TableCell css={styles.customTableRow}>
          <Typography component="p" css={styles.customTableData}>
            <CustomTypography row={row} field="phone_number_1" />
          </Typography>
          <Typography component="p" css={styles.customTableData}>
            <CustomTypography row={row} field="phone_number_2" />
          </Typography>
        </TableCell>
        <TableCell css={styles.customTableRow}>
          <Typography component="p" css={styles.customTableData}>
            <CustomTypography row={row} field="mailaddress_1" />
          </Typography>
          <Typography component="p" css={styles.customTableData}>
            <CustomTypography row={row} field="mailaddress_2" />
          </Typography>
        </TableCell>
        <TableCell css={styles.customTableRow}>
          <Typography component="p" css={styles.customTableData}>
            <CustomTypography row={row} field="status" />
          </Typography>
        </TableCell>
        <TableCell css={styles.customTableRow} align="center">
          {isSameHuman(row[0]) === 1 ? (
            <Typography
              component="p"
              sx={{ fontSize: "0.5rem", fontWeight: "bold", color: "#E4007F" }}
            >
              確定
            </Typography>
          ) : isSameHuman(row[0]) === 2 ? (
            <Typography
              component="p"
              sx={{ fontSize: "0.5rem", color: "#aaa" }}
            >
              候補
            </Typography>
          ) : (
            <Typography
              component="p"
              sx={{ fontSize: "0.5rem", color: "#777" }}
            >
              ×
            </Typography>
          )}
        </TableCell>
        <TableCell css={styles.customTableRow} align="center">
          <ToggleButton
            key={index}
            value="check"
            selected={selected[index]}
            onChange={() => handleToggle(index)}
            sx={{
              fontSize: "0.5rem",
              width: "50px",
              height: "30px",
              padding: "5px",
              "& .MuiToggleButton-root .Mui-selected": {
                backgroundColor: "#2e7d32 !important",
              },
            }}
          >
            紐付
          </ToggleButton>
        </TableCell>
      </TableRow>
    );
  }

  // 複数レコードの場合は折りたたみ可能なTableRow
  return (
    <>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          width: "100%",
          background: colorKeying(row),
        }}
      >
        <TableCell css={styles.customTableRow} sx={{ borderBottom: "initial" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell css={styles.customTableRow} sx={{ borderBottom: "initial" }}>
          <Typography component="p" css={styles.customTableData}>
            <CustomTypography row={row} field="applicant_date" />
          </Typography>
        </TableCell>
        <TableCell css={styles.customTableRow} sx={{ borderBottom: "initial" }}>
          <Typography component="p" css={styles.customTableData}>
            <CustomTypography row={row} field="media" />
          </Typography>
        </TableCell>
        <TableCell css={styles.customTableRow} sx={{ borderBottom: "initial" }}>
          <Typography component="p" css={[styles.ruby, styles.customTableData]}>
            <CustomTypography row={row} field="sei_kana" />
            &emsp;
            <CustomTypography row={row} field="mei_kana" />
          </Typography>
          <Link to="/oubo_detail" target="_blank">
            <Typography component="p" css={styles.customTableData}>
              <CustomTypography row={row} field="sei_kanji" />
              &emsp;
              <CustomTypography row={row} field="mei_kanji" />
            </Typography>
          </Link>
        </TableCell>
        <TableCell css={styles.customTableRow} sx={{ borderBottom: "initial" }}>
          <Typography component="p" css={styles.customTableData}>
            <CustomTypography row={row} field="birthday" />
          </Typography>
        </TableCell>
        <TableCell css={styles.customTableRow} sx={{ borderBottom: "initial" }}>
          <Typography component="p" css={styles.customTableData}>
            <CustomTypography row={row} field="prefecture" />
            <CustomTypography row={row} field="city" />
          </Typography>
          <Typography component="p" css={styles.customTableData}>
            <CustomTypography row={row} field="address" />
          </Typography>
          <Typography component="p" css={styles.customTableData}>
            <CustomTypography row={row} field="building" />
          </Typography>
        </TableCell>
        <TableCell css={styles.customTableRow} sx={{ borderBottom: "initial" }}>
          <Typography component="p" css={styles.customTableData}>
            <CustomTypography row={row} field="phone_number_1" />
          </Typography>
          <Typography component="p" css={styles.customTableData}>
            <CustomTypography row={row} field="phone_number_2" />
          </Typography>
        </TableCell>
        <TableCell css={styles.customTableRow} sx={{ borderBottom: "initial" }}>
          <Typography component="p" css={styles.customTableData}>
            <CustomTypography row={row} field="mailaddress_1" />
          </Typography>
          <Typography component="p" css={styles.customTableData}>
            <CustomTypography row={row} field="mailaddress_2" />
          </Typography>
        </TableCell>
        <TableCell css={styles.customTableRow} sx={{ borderBottom: "initial" }}>
          <Typography component="p" css={styles.customTableData}>
            <CustomTypography row={row} field="status" />
          </Typography>
        </TableCell>
        <TableCell
          css={styles.customTableRow}
          sx={{ borderBottom: "initial" }}
          align="center"
        >
          <Typography
            component="p"
            css={styles.customTableData}
            sx={{ position: "relative", color: "#aaa" }}
          >
            候補
            {/* <Typography
              component="span"
              sx={{
                position: "absolute",
                top: "2px",
                right: "22px",
                fontSize: "0.5rem",
              }}
            >
              ※
            </Typography> */}
            <AnnouncementIcon
              sx={{ marginLeft: "2px", fontSize: "0.8rem", color: "#E4007F" }}
            />
          </Typography>
        </TableCell>
        <TableCell
          css={styles.customTableRow}
          sx={{ borderBottom: "initial" }}
          align="center"
        >
          <ToggleButton
            key={index}
            value="check"
            selected={selected[index]}
            onChange={() => handleToggle(index)}
            sx={{
              fontSize: "0.5rem",
              width: "50px",
              height: "30px",
              padding: "5px",
              "& .MuiToggleButton-root .Mui-selected": {
                backgroundColor: "#2e7d32 !important",
              },
            }}
          >
            紐付
          </ToggleButton>
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
          width: "100%",
          background: colorKeying(row),
        }}
      >
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, marginLeft: "30px", marginRight: "46px" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  sx={{
                    fontSize: "0.5rem",
                    color: "#4B576B",
                    padding: "2px 5px",
                  }}
                >
                  この候補者の他の媒体情報
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.5rem",
                    color: "#4B576B",
                    padding: "2px 5px",
                  }}
                >
                  判定結果が"候補"かつ、グループ内に"確定"がある場合
                  <AnnouncementIcon
                    sx={{
                      marginLeft: "2px",
                      fontSize: "0.8rem",
                      color: "#E4007F",
                    }}
                  />
                  を表示
                </Typography>
              </Box>
              <Table sx={{ border: "1px solid #EEE" }} component={Paper}>
                <TableHead>
                  <TableRow>
                    <TableCell
                      css={styles.customTableHeader}
                      sx={{ width: "9%" }}
                    >
                      応募日時
                    </TableCell>
                    <TableCell
                      css={styles.customTableHeader}
                      sx={{ width: "12%" }}
                    >
                      応募媒体
                    </TableCell>
                    <TableCell
                      css={styles.customTableHeader}
                      sx={{ width: "11%" }}
                    >
                      氏名(カナ)
                    </TableCell>
                    <TableCell
                      css={styles.customTableHeader}
                      sx={{ width: "8%" }}
                    >
                      生年月日
                    </TableCell>
                    <TableCell
                      css={styles.customTableHeader}
                      sx={{ width: "14%" }}
                    >
                      住所
                    </TableCell>
                    <TableCell
                      css={styles.customTableHeader}
                      sx={{ width: "9%" }}
                    >
                      電話番号
                    </TableCell>
                    <TableCell
                      css={styles.customTableHeader}
                      sx={{ width: "19.5%" }}
                    >
                      メールアドレス
                    </TableCell>
                    <TableCell
                      css={styles.customTableHeader}
                      sx={{ width: "9%" }}
                    >
                      ステータス
                    </TableCell>
                    <TableCell
                      css={styles.customTableHeader}
                      sx={{ width: "8.5%" }}
                      align="center"
                    >
                      判定
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.map((childrenRow, index) => {
                    if (index === 0) {
                      return <></>;
                    }
                    return (
                      <TableRow key={index}>
                        <TableCell css={styles.customTableRow}>
                          <Typography
                            component="p"
                            css={styles.customTableData}
                          >
                            <CustomTypography
                              row={childrenRow}
                              field="applicant_date"
                            />
                          </Typography>
                        </TableCell>
                        <TableCell css={styles.customTableRow}>
                          <Typography
                            component="p"
                            css={styles.customTableData}
                          >
                            <CustomTypography row={childrenRow} field="media" />
                          </Typography>
                        </TableCell>
                        <TableCell css={styles.customTableRow}>
                          <Typography
                            component="p"
                            css={[styles.ruby, styles.customTableData]}
                          >
                            <CustomTypography
                              row={childrenRow}
                              field="sei_kana"
                            />
                            &emsp;
                            <CustomTypography
                              row={childrenRow}
                              field="mei_kana"
                            />
                          </Typography>
                          <Typography
                            component="p"
                            css={styles.customTableData}
                          >
                            <CustomTypography
                              row={childrenRow}
                              field="sei_kanji"
                            />
                            &emsp;
                            <CustomTypography
                              row={childrenRow}
                              field="mei_kanji"
                            />
                          </Typography>
                        </TableCell>
                        <TableCell css={styles.customTableRow}>
                          <Typography
                            component="p"
                            css={styles.customTableData}
                          >
                            <CustomTypography
                              row={childrenRow}
                              field="birthday"
                            />
                          </Typography>
                        </TableCell>
                        <TableCell css={styles.customTableRow}>
                          <Typography
                            component="p"
                            css={styles.customTableData}
                          >
                            <CustomTypography
                              row={childrenRow}
                              field="prefecture"
                            />
                            <CustomTypography row={childrenRow} field="city" />
                          </Typography>
                          <Typography
                            component="p"
                            css={styles.customTableData}
                          >
                            <CustomTypography
                              row={childrenRow}
                              field="address"
                            />
                          </Typography>
                          <Typography
                            component="p"
                            css={styles.customTableData}
                          >
                            <CustomTypography
                              row={childrenRow}
                              field="building"
                            />
                          </Typography>
                        </TableCell>
                        <TableCell css={styles.customTableRow}>
                          <Typography
                            component="p"
                            css={styles.customTableData}
                          >
                            <CustomTypography
                              row={childrenRow}
                              field="phone_number_1"
                            />
                          </Typography>
                          <Typography
                            component="p"
                            css={styles.customTableData}
                          >
                            <CustomTypography
                              row={childrenRow}
                              field="phone_number_2"
                            />
                          </Typography>
                        </TableCell>
                        <TableCell css={styles.customTableRow}>
                          <Typography
                            component="p"
                            css={styles.customTableData}
                          >
                            <CustomTypography
                              row={childrenRow}
                              field="mailaddress_1"
                            />
                          </Typography>
                          <Typography
                            component="p"
                            css={styles.customTableData}
                          >
                            <CustomTypography
                              row={childrenRow}
                              field="mailaddress_2"
                            />
                          </Typography>
                        </TableCell>
                        <TableCell css={styles.customTableRow}>
                          <Typography
                            component="p"
                            css={styles.customTableData}
                          >
                            <CustomTypography row={row} field="status" />
                          </Typography>
                        </TableCell>
                        <TableCell css={styles.customTableRow} align="center">
                          {isSameHuman(childrenRow) === 1 ? (
                            <Typography
                              component="p"
                              sx={{
                                fontSize: "0.5rem",
                                fontWeight: "bold",
                                color: "#E4007F",
                              }}
                            >
                              確定
                            </Typography>
                          ) : isSameHuman(childrenRow) === 2 ? (
                            <Typography
                              component="p"
                              sx={{ fontSize: "0.5rem", color: "#aaa" }}
                            >
                              候補
                            </Typography>
                          ) : (
                            <Typography
                              component="p"
                              sx={{ fontSize: "0.5rem", color: "#777" }}
                            >
                              ×
                            </Typography>
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

ApplicantRow.propTypes = {
  row: PropTypes.arrayOf(
    PropTypes.shape({
      applicant_date: PropTypes.string.isRequired,
      media: PropTypes.string.isRequired,
      sei_kanji: PropTypes.string.isRequired,
      mei_kanji: PropTypes.string.isRequired,
      mei_kana: PropTypes.string.isRequired,
      birthday: PropTypes.string.isRequired,
      prefecture: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      building: PropTypes.string,
      phone_number_1: PropTypes.string.isRequired,
      phone_number_2: PropTypes.string.isRequired,
      mailaddress_1: PropTypes.string.isRequired,
      mailaddress_2: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      identify: PropTypes.number,
    }).isRequired
  ).isRequired,
};

export default function ApplicantOptionTable({
  applicantDatas,
  applicantOptionDatas,
  caerusDatas,
}) {
  const [selectedNoMatch, setSelectedNoMatch] = useState(false);

  return (
    <StyledMuiPaper sx={{ width: "100%", overflow: "hidden", mb: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          component="p"
          sx={{
            display: "inline-block",
            padding: "0 1rem",
            fontSize: "1rem",
            color: "#0047A4",
            backgroundColor: "#c6e0f7",
            height: "1.5rem",
          }}
        >
          過去応募&emsp;同一人物判定・候補者一覧
        </Typography>
        <ToggleButton
          value="check"
          selected={selectedNoMatch}
          onChange={() => {
            setSelectedNoMatch(!selectedNoMatch);
          }}
          sx={{
            fontSize: "0.5rem",
            width: "100px",
            height: "24px",
            padding: "5px",
            "& .MuiToggleButton-root .Mui-selected": {
              backgroundColor: "#2e7d32 !important",
            },
            marginBottom: "6px",
          }}
        >
          該当なし
        </ToggleButton>
      </Box>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell css={styles.customTableHeader} sx={{ width: "4%" }}>
                &emsp;
              </TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "8%" }}>
                応募日時
              </TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "11%" }}>
                応募媒体
              </TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "10%" }}>
                氏名(カナ)
              </TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "7%" }}>
                生年月日
              </TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "13%" }}>
                住所
              </TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "8%" }}>
                電話番号
              </TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "18%" }}>
                メールアドレス
              </TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "8%" }}>
                ステータス
              </TableCell>
              <TableCell
                css={styles.customTableHeader}
                sx={{ width: "8%" }}
                align="center"
              >
                判定
              </TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "5%" }}>
                &emsp;
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {applicantOptionDatas.map((row, index) => (
              <ApplicantRow
                key={index}
                row={row}
                applicantDatas={applicantDatas}
                caerusDatas={caerusDatas}
                index={index}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledMuiPaper>
  );
}
