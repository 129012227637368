import React, { useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import ja from "date-fns/locale/ja";
import { FormControl, TextField, IconButton, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import "react-datepicker/dist/react-datepicker.css";
import GlobalStyles from "../../../GlobalStyles";

registerLocale("ja", ja);

const CustomTextField = styled(TextField)({
  fontSize: "0.5rem",
  color: "#0047A4",
  "& .MuiInputBase-root": {
    fontSize: "0.5rem",
  },
  "& .MuiFormControlLabel-label": {
    fontSize: "0.5rem",
  },
});

export default function StyledReactDatePickerDate({ fieldSize }) {
  const [startDate, setStartDate] = useState(new Date());

  const renderPreviousMonthButton = (onClick) => {
    return (
      <Button
        onClick={onClick}
        startIcon={<ChevronLeft />}
        color="primary"
        sx={{ fontSize: "0.5rem" }}
      >
        前月
      </Button>
    );
  };

  const renderNextMonthButton = (onClick) => {
    return (
      <Button
        onClick={onClick}
        endIcon={<ChevronRight />}
        color="primary"
        sx={{ fontSize: "0.5rem" }}
      >
        次月
      </Button>
    );
  };

  return (
    <FormControl sx={{ width: fieldSize }} size="small">
      {GlobalStyles}
      <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        maxDate={new Date()}
        dateFormat="yyyy/MM/dd"
        locale="ja"
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {renderPreviousMonthButton(() => {
              if (!prevMonthButtonDisabled) {
                decreaseMonth();
              }
            })}
            <span
              style={{ fontWeight: "bold", fontSize: "1rem", color: "#0047A4" }}
            >
              {date.toLocaleString("ja", { year: "numeric", month: "long" })}
            </span>
            {renderNextMonthButton(() => {
              if (!nextMonthButtonDisabled) {
                increaseMonth();
              }
            })}
          </div>
        )}
        customInput={
          <CustomTextField
            size="small"
            variant="outlined"
            css={styles.calendar}
          />
        }
      />
    </FormControl>
  );
}

const styles = {
  calendar: {
    color: "#0047A4",
    background: "#FFFFFF",
    borderColor: "#FFFFFF",
  },
};
