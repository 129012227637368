import React from "react";
import StyledMuiAppBar from "../parts/AppBar/StyledMuiAppBar";
import ApplicantsInfomation from "../feature/DetermineApplicants/ApplicantsInfomation";
import ApplicantsOptions from "../feature/DetermineApplicants/ApplicantsOptionsTest";
import DetermineApplicantsList from "../feature/DetermineApplicants/DetermineApplicantsList";
import SearchFormsThree from "../feature/SearchForms/SeacrhFormsThree";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

function DetermineApplicantsListPage() {
  return (
    <>
      <StyledMuiAppBar headerTitle='新・既 未判定応募一覧'/>
      <Box sx={{ width: '100%', mt: 7, mb: 3 }}>
        <Grid container spacing={2} rowSpacing={2} sx={{px:10}}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchFormsThree />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <DetermineApplicantsList />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default DetermineApplicantsListPage;