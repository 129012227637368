/** @jsxImportSource @emotion/react */
import React, { useState } from "react";import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

function RadioSelectGender(props) {
  const [value, setValue] = useState('');
  const handleChange = (event) => {
    setValue(event.target.value);
  }
  const styles = {
    inputRadio:{
      '& .MuiSvgIcon-root':{
        fontSize: props.buttonSize,
      },
    },
    radioLabel:{
      '& .MuiFormControlLabel-label':{
        fontSize: props.textSize,
      },
    },
  }
  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          defaultValue={props.gender}
          onChange={handleChange}
        >
          <FormControlLabel value="男性" control={<Radio css={styles.inputRadio} disabled={props.disabled}/>} css={styles.radioLabel} label="男性" />
          <FormControlLabel value="女性" control={<Radio css={styles.inputRadio} disabled={props.disabled}/>} css={styles.radioLabel} label="女性" />
        </RadioGroup>
      </FormControl>
    </Grid>
  );
}

export default RadioSelectGender;