/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  Paper,
  Stack,
  Typography,
  Drawer,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const styles = {
  btnSizeSmall:{
    transform: 'scale(0.75)',
    transformOrigin: 'center center',
  }
}

const insertTags = [
  { id: 'name-tag', label: '氏名', value: '<!-- 氏名 -->' },
  { id: 'branch-tag', label: '応募支店(担当)の支店名', value: '<!-- 応募支店(担当)の支店名 -->' },
  { id: 'zipcode-tag', label: '応募支店(担当)の郵便番号', value: '<!-- 応募支店(担当)の郵便番号 -->' },
  { id: 'address-tag', label: '応募支店(担当)の住所', value: '<!-- 応募支店(担当)の住所 -->' },
  { id: 'phone-number-tag', label: '応募支店(担当)の電話番号', value: '<!-- 応募支店(担当)の電話番号 -->' },
  { id: 'mail-address-tag', label: '応募支店(担当)のﾒｰﾙｱﾄﾞﾚｽ', value: '<!-- 応募支店(担当)のメールアドレス -->' },

]

export default function InsertionTagBlock({ onTagValueChange }) {
  const [isDrawer, setIsDrawer] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [tagValue, setTagValue] = useState('');

  useEffect(() => {
    const handleResize = () => {
      setIsDrawer(window.innerWidth < 1200);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const handleClickBtn = (tagValue) => {
    setTagValue(tagValue);
    onTagValueChange(tagValue);
  };
  

  return (
    <>
      {isDrawer ? (
        <>
          <Button
          variant="contained"
            sx={{
              position: "fixed",
              bottom: "10px",
              right: "10px",
              zIndex: 1,
              fontSize: '0.5rem'
            }}
            onClick={handleDrawerOpen}
          >
            差し込みタグ
          </Button>
          <Drawer
            anchor="right"
            open={openDrawer}
            onClose={handleDrawerClose}
            sx={{
              width: "20vw",
              "& .MuiDrawer-paper": {
                width: "40vw",
              },
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", px: 2, pt: 2 }}>
              <Typography sx={{ fontSize: '0.8rem', textAlign: 'center' }}>差し込みタグ</Typography>
              <IconButton edge="end" color="inherit" onClick={handleDrawerClose} aria-label="close">
                <CloseIcon />
              </IconButton>
            </Box>
            <Stack spacing={1} sx={{ mt: 3 }}>
              {insertTags.map((tag, i) => {
                return (
                  <Button variant="outlined" css={styles.btnSizeSmall} onClick={() => handleClickBtn(tag.value)}>{tag.label}</Button>
                )
              })}
            </Stack>
          </Drawer>
        </>
      ) : (
        <Paper
          sx={{
            width: "20vw",
            height: "80vh",
            top: "10vh",
            margin: 3,
            position: "fixed",
            bottom: "0",
            right: "0",
            
            px: 1,
            py: 1,
          }}
        >
          <Typography sx={{ fontSize: '0.8rem', textAlign: 'center', mt:2 }}>差し込みタグ</Typography>
          <Stack spacing={1} sx={{ mt: 3 }}>
            {insertTags.map((tag, i) => {
              return (
                <Button variant="outlined" css={styles.btnSizeSmall} onClick={() => handleClickBtn(tag.value)}>{tag.label}</Button>
              )
            })}
          </Stack>
        </Paper>
      )}
    </>
  );
}

InsertionTagBlock.propTypes = {
  onTagValueChange: PropTypes.func.isRequired,
};