/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';

import { Grid, Box, Typography } from '@mui/material';

import StyledMuiPaper from '../../parts/surface/StyledMuiPaper';
import MuiRadioButton from'../../parts/radio/MuiRadioButton';
import StyledReactDatePicker from '../../parts/Datepicker/StyledReactDatePicker';
import MuiTextField from '../../parts/textField/MuiTextField';
import MuiTextFieldInsertTags from '../../parts/textField/MuiTextFieldInsertTags';
import StyledMuiBtnBlue from '../../parts/button/StyledMuiBtnBlue';


const styles = {
  middleWidth: {
    width: '50%',
    "@media (max-width:1200px)":{
      width: '90%',
      padding: '2rem',
    },
  },
  btnSizeSmall:{
    transform: 'scale(0.75)',
    transformOrigin: 'center bottom',
  },
  title: {
    fontSize: '0.8rem',
    fontWeight: 'bold',
  },
  flexSbC: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexCC: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

export default function RegistMailTemplate({ datas, tagValue }) {

  return (
    <StyledMuiPaper css={styles.middleWidth} sx={{ overflow: 'hidden', p: 5, mb:3, textAlign: 'left', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      <Grid container spacing={2} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }} >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box>
            <Box css={styles.flexSbC}>
              <Typography components="h2" css={styles.title} sx={{ display: 'inline-block', pb: 1 }}>対象事業部</Typography>
            </Box>
            <MuiRadioButton
              ariaLabel="department"
              name="department"
              formLabels={[
                { value: "OS", label: "OS" },
                { value: "NC", label: "NC" }
              ]}
              defaultValue={datas.dept}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box>
            <Box css={styles.flexSbC}>
              <Typography components="h2" css={styles.title} sx={{ display: 'inline-block', pb: 1 }}>テンプレート件名</Typography>
            </Box>
            <MuiTextField
              ariaLabel="mail-title"
              multiline={false}
              minRows={1}
              placeholder="件名を入力してください。"
              defaultValue={datas.title}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box>
            <Typography components="h2" css={styles.title} sx={{ pb: 1 }}>テンプレート本文</Typography>
            <MuiTextFieldInsertTags
              ariaLabel="mail-content"
              multiline={true}
              minRows={15}
              placeholder="本文を入力してください。"
              defaultValue={datas.content}
              tagValue={tagValue}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Box css={styles.flexCC}>
            <Link to="/mail_template_list" css={styles.link}>
              <StyledMuiBtnBlue variant="contained" sx={{ display: 'inline-block' }}>登録</StyledMuiBtnBlue>
            </Link>
          </Box>
        </Grid>
      </Grid>
    </StyledMuiPaper>
  )
}