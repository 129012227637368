/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { styled } from '@mui/material';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

export default function TextFieldWebEntry(props) {
  const {textSize, width, id, label, size, value, multiline, lines, disabled, placeholder, unit, ...other} = props;
  //------------コンポーネントのスタイル------------
  const styles = {
    textarea:{
      width: '90%',

      '& .MuiInputBase-input':{
        fontSize: textSize,
      },
      '& .MuiInputBase-root':{
        // padding: '3px 0.5rem',
      },
      '& .MuiInputLabel-root':{
        fontSize: textSize,
      }
    },
    inputItemForm:{
      display:'flex',
      alignItems: 'center',
    },
    paddingLeft5:{
      paddingLeft: '5px',
    }
  }
  //------------コンポーネントのスタイル------------
  return (
    <Grid item xs={width} sm={width} md={width} lg={width} xl={width} css={styles.inputItemForm}>
      <TextField
        id={id}
        multiline={multiline}
        minRows={lines}
        label={label}
        size={size}
        disabled={disabled}
        defaultValue={value}
        css={styles.textarea}
        placeholder={placeholder}
        
      />
      <span css={styles.paddingLeft5}>{unit}</span>
    </Grid>
  );
}
