/** @jsxImportSource @emotion/react */
import { useState, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  Box,
  Modal,
  TextField,
  Button,
} from "@mui/material";
import ModalSendMailConfirm from "./Modals/ModalSendMailConfirm";
import StyledMuiPaper from "../../parts/surface/StyledMuiPaper";
import { Link } from "react-router-dom";

const styles = {
  inputCheckbox: {
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
    },
  },
  formLabel: {
    "& .MuiFormControlLabel-label": {
      fontSize: "0.5rem",
    },
  },
  customTableHead: {
    fontSize: "0.5rem",
    fontWeight: "bold",
    color: "#0047A4",
    "& .MuiTableCell-root": {
      padding: "0px",
    },
  },
  customTableCell: {
    fontSize: "0.5rem",
    color: "#0047A4",
    "& .MuiTableCell-root": {
      padding: "0px",
    },
  },
  flexSbC: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexCC: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  fieldStyle: {
    mt: 1,
    "& .MuiInputBase-input": {
      fontSize: "0.5rem",
      padding: "0.5rem",
      color: "#0047A4",
    },
    "& .MuiInputBase-root": {
      padding: "3px 0.5rem",
    },
  },
  btnSize: {
    transform: "scale(0.75)",
    transformOrigin: "center center",
  },
  pagenation: {
    "& .MuiTablePagination-selectLabel": {
      fontSize: "0.5rem",
    },
    "& .MuiTablePagination-displayedRows": {
      fontSize: "0.5rem",
    },
    "& .MuiTablePagination-actions": {
      fontSize: "0.5rem",
    },
    fontSize: "0.5rem",
    "& .MuiTablePagination-toolbar": {
      minHeight: "40px",
    },
  },
};

export default function GenericMailTargetSelection({ datas }) {
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [open, setOpen] = useState(false);
  const [nameSearchQuery, setNameSearchQuery] = useState("");
  const [emailSearchQuery, setEmailSearchQuery] = useState("");



  const handleSelectRow = (event, row) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(selectedRows.filter((r) => r.id !== row.id));
      setSelectAll(false);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const clearSearch = () => {
    setNameSearchQuery("");
    setEmailSearchQuery("");
  };

  const filteredDatas = datas.filter(
    (item) =>
      (item.seiKanji.includes(nameSearchQuery) ||
        item.meiKanji.includes(nameSearchQuery) ||
        item.seiKana.includes(nameSearchQuery) ||
        item.meiKana.includes(nameSearchQuery)) &&
      item.email.includes(emailSearchQuery)
  );

  //ページネーション
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //表示件数切替
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedRows(filteredDatas);
      setSelectAll(true);
    } else {
      // 絞り込んだレコードだけのチェックを外す
      const newSelectedRows = selectedRows.filter(
        (row) => !filteredDatas.some((filteredRow) => filteredRow.id === row.id)
      );
      setSelectedRows(newSelectedRows);
      setSelectAll(false);
    }
  };

  const element = useRef(null);

  return (
    <StyledMuiPaper
      sx={{
        width: "70%",
        overflow: "hidden",
        p: 5,
        mb: 3,
        textAlign: "left",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid
        container
        spacing={2}
        rowSpacing={2}
        columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography
            component="p"
            sx={{ fontSize: "0.5rem", pb: 1, textAlign: "center" }}
          >
            以下の応募者からメールを送信する対象者にチェックをつけてください。
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <TextField
              placeholder="氏名で検索"
              value={nameSearchQuery}
              onChange={(e) => setNameSearchQuery(e.target.value)}
              css={styles.fieldStyle}
              sx={{ display: "inline-block", marginRight: "10px" }}
            />
            <TextField
              placeholder="メールアドレスで検索"
              value={emailSearchQuery}
              onChange={(e) => setEmailSearchQuery(e.target.value)}
              css={styles.fieldStyle}
              sx={{ display: "inline-block", marginLeft: "10px" }}
            />
            <Button
              variant="outlined"
              css={styles.btnSize}
              onClick={clearSearch}
            >
              クリア
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TableContainer>
            <Table ref={element} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell
                    css={styles.customTableHead}
                    sx={{ width: "10%", padding: "0 0 0 1rem" }}
                  >
                    <FormControlLabel
                      css={styles.formLabel}
                      control={
                        <Checkbox
                          css={styles.inputCheckbox}
                          checked={selectAll}
                          onChange={handleSelectAll}
                          indeterminate={
                            selectedRows.length > 0 &&
                            selectedRows.length < datas.length
                          }
                        />
                      }
                    />
                  </TableCell>
                  <TableCell
                    css={styles.customTableHead}
                    sx={{ width: "15%", padding: "0px" }}
                  >
                    応募者コード
                  </TableCell>
                  <TableCell
                    css={styles.customTableHead}
                    sx={{ width: "30%", padding: "0px" }}
                  >
                    応募者名
                  </TableCell>
                  <TableCell
                    css={styles.customTableHead}
                    sx={{ width: "45%", padding: "0px" }}
                  >
                    メールアドレス
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredDatas
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, k) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={k}>
                      <TableCell sx={{ padding: "0 0 0 1rem" }}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              css={styles.inputCheckbox}
                              checked={selectedRows.some(
                                (r) => r.id === row.id
                              )}
                              onChange={(event) => handleSelectRow(event, row)}
                            />
                          }
                          label=""
                          css={styles.formLabel}
                        />
                      </TableCell>
                      <TableCell
                        css={styles.customTableCell}
                        sx={{ padding: "0px" }}
                      >
                        {row.applicantsId}
                      </TableCell>
                      <TableCell
                        css={styles.customTableCell}
                        sx={{ padding: "0px" }}
                      >
                        {row.seiKanji}&nbsp;{row.meiKanji}&emsp;&#40;
                        {row.seiKana}
                        &nbsp;{row.meiKana}&#41;
                      </TableCell>
                      <TableCell
                        css={styles.customTableCell}
                        sx={{ padding: "0px" }}
                      >
                        {row.email}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[50, 100]}
            component="div"
            count={filteredDatas.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            css={styles.pagenation}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          sx={{ textAlign: "center" }}
        >
          <Box css={styles.flexSbC}>
            <Button
              component={Link}
              to={`/oubo_list${"/send_mail"}`}
              variant="contained"
              color="inherit"
              css={styles.btnSize}
              sx={{ display: "inline-block" }}
            >
              戻る
            </Button>
            <Button
              variant="contained"
              color="primary"
              css={styles.btnSize}
              sx={{ display: "inline-block" }}
              onClick={handleOpen}
            >
              送信する
            </Button>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="send-confirm-modal-title"
              aria-describedby="send-confirm-modal-description"
            >
              <ModalSendMailConfirm
                onClose={handleClose}
                count={selectedRows.length}
              />
            </Modal>
          </Box>
        </Grid>
      </Grid>
    </StyledMuiPaper>
  );
}
