/** @jsxImportSource @emotion/react */
import React, { useState } from "react";

import {
  Button, Tab, Typography, Box, Table, TableContainer, TableCell, TableRow, TableBody, TableHead, Paper, Popover,
  } from '@mui/material';

import HistoryIcon from '@mui/icons-material/History';
import StyledMuiTableCell from '../../../parts/table/StyledMuiTable/StyledMuiTableCell';
import StyledMuiTableHead from '../../../parts/table/StyledMuiTable/StyledMuiTableHead';
import StyledMuiTableRow from '../../../parts/table/StyledMuiTable/StyledMuiTableRow';
import PopoverEditHistory from "./PopoverEditHistory";
import Const from "../../../../libs/Const";

export default function PanelNc2({item}) {

  function getMatchStatus(arr1, arr2) {
    return arr1.map(item => ({
      label: item,
      isMatch: arr2.includes(item)
    }));
  }

  return(
    <>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[0].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.DESIRED_EMPLOYMENT_TYPE, item[0].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.DESIRED_EMPLOYMENT_TYPE, item[0].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[0].history ? <PopoverEditHistory data={item[0]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[1].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[1].tdata}</Typography>
            {item[1].history ? <PopoverEditHistory data={item[1]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[2].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[2].tdata}</Typography>
            {item[2].history ? <PopoverEditHistory data={item[2]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[3].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.DAY_OF_WEEK, item[3].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.DAY_OF_WEEK, item[3].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[3].history ? <PopoverEditHistory data={item[3]}/> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[4].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.DESIRED_WORK_HOURS_NC, item[4].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.DESIRED_WORK_HOURS_NC, item[4].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[4].history ? <PopoverEditHistory data={item[4]} /> :  <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[5].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.DESIRED_NUMBER_OF_WORKING_DAYS, item[5].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.DESIRED_NUMBER_OF_WORKING_DAYS, item[5].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[5].history ? <PopoverEditHistory data={item[5]} /> :  <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[6].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[6].tdata}</Typography>
            {item[6].history ? <PopoverEditHistory data={item[6]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[7].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.IS_IT_POSSIBLE, item[7].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.IS_IT_POSSIBLE, item[7].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[7].history ? <PopoverEditHistory data={item[7]} /> :  <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[8].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[8].tdata}</Typography>
            {item[8].history ? <PopoverEditHistory data={item[8]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left" sx={{ pl:6 }}>{item[9].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.HOURLY_WAGE_RANGE, item[9].tdata).map((match, i) => {
              return(
                <Box sx={{ display: 'flex', position: 'relative' }}>
                  <Typography
                    component="span"
                    sx={{
                      position: 'absolute',
                      top: '6px',
                      display: 'inline-block',
                      width: '4px',
                      height: '4px',
                      backgroundColor: match.isMatch ? '#0047A4': '#CCC',
                      mr:'5px',
                      borderRadius: '2px',
                    }}
                  ></Typography>
                  <Typography component="span" sx={{
                    ml: 1,
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'block',
                    }}
                  >

                    {match.label}
                  </Typography>
                </Box>
              )
            })}
            </Box>
            {item[9].history ? <PopoverEditHistory data={item[9]} /> :  <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left" sx={{ pl:6 }}>{item[10].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.COMMUTING_METHOD, item[10].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.COMMUTING_METHOD, item[10].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[10].history ? <PopoverEditHistory data={item[10]} /> :  <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left" sx={{ pl:6 }}>{item[11].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[11].tdata}
              <Typography component="span" sx={{ fontSize: '0.5rem', color: '#4B576B' }}>&nbsp;以内</Typography>
            </Typography>
            {item[11].history ? <PopoverEditHistory data={item[11]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[12].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.DESIRED_FACILITY_TYPE, item[12].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.DESIRED_FACILITY_TYPE, item[12].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[12].history ? <PopoverEditHistory data={item[12]} /> :  <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[13].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[13].tdata}</Typography>
            {item[13].history ? <PopoverEditHistory data={item[13]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[14].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[14].tdata}</Typography>
            {item[14].history ? <PopoverEditHistory data={item[14]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[15].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.PREFERRED_COMMUTING_METHOD, item[15].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.PREFERRED_COMMUTING_METHOD, item[15].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[15].history ? <PopoverEditHistory data={item[15]} /> :  <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[16].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[16].tdata}</Typography>
            {item[16].history ? <PopoverEditHistory data={item[16]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[17].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.RESISTANCE_TO_THREE_MAJOR_TYPES_OF_ASSISTANCE, item[17].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.RESISTANCE_TO_THREE_MAJOR_TYPES_OF_ASSISTANCE, item[17].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[17].history ? <PopoverEditHistory data={item[17]} /> :  <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[18].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.RECREATION_SUPPORT_AVAILABLE_OR_NOT, item[18].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.RECREATION_SUPPORT_AVAILABLE_OR_NOT, item[18].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[18].history ? <PopoverEditHistory data={item[18]} /> :  <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[19].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.COOKING_ASSISTANCE_AVAILABLE_OR_NOT, item[19].tdata).map((match, i) => {
              return(
                <Box sx={{ display: 'flex', position: 'relative' }}>
                  <Typography
                    component="span"
                    sx={{
                      position: 'absolute',
                      top: '6px',
                      display: 'inline-block',
                      width: '4px',
                      height: '4px',
                      backgroundColor: match.isMatch ? '#0047A4': '#CCC',
                      mr:'5px',
                      borderRadius: '2px',
                    }}
                  ></Typography>
                  <Typography component="span" sx={{
                    ml: 1,
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'block',
                    }}
                  >

                    {match.label}
                  </Typography>
                </Box>
              )
            })}
            </Box>
            {item[19].history ? <PopoverEditHistory data={item[19]} /> :  <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[20].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.AVAILABILITY_OF_TRANSPORTATION_SERVICES, item[20].tdata).map((match, i) => {
              return(
                <Box sx={{ display: 'flex', position: 'relative' }}>
                  <Typography
                    component="span"
                    sx={{
                      position: 'absolute',
                      top: '6px',
                      display: 'inline-block',
                      width: '4px',
                      height: '4px',
                      backgroundColor: match.isMatch ? '#0047A4': '#CCC',
                      mr:'5px',
                      borderRadius: '2px',
                    }}
                  ></Typography>
                  <Typography component="span" sx={{
                    ml: 1,
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'block',
                    }}
                  >

                    {match.label}
                  </Typography>
                </Box>
              )
            })}
            </Box>
            {item[20].history ? <PopoverEditHistory data={item[20]} /> :  <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[21].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.PREFERRED_ORDER_OF_PREFERENCES, item[21].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.PREFERRED_ORDER_OF_PREFERENCES, item[21].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[21].history ? <PopoverEditHistory data={item[21]} /> :  <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[22].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.PREFERRED_ORDER_OF_PREFERENCES, item[22].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.PREFERRED_ORDER_OF_PREFERENCES, item[22].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[22].history ? <PopoverEditHistory data={item[22]} /> :  <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[23].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Box>
            {getMatchStatus(Const.PREFERRED_ORDER_OF_PREFERENCES, item[23].tdata).map((match, i) => {
              return(
                <>
                  <Typography component="span" sx={{
                    fontSize: '0.5rem',
                    fontWeight: match.isMatch ? 'bold' : '',
                    color: !match.isMatch ? '#CCC': '',
                    pr: '5px',
                    display: 'inline-block',
                    }}
                  >
                    {match.label}
                  </Typography>
                  {
                  getMatchStatus(Const.PREFERRED_ORDER_OF_PREFERENCES, item[23].tdata).length - 1 !== i 
                  ? <Typography component="span" sx={{ fontSize: '0.5rem', color: '#CCC', display: 'inline-block', pr: '5px', }}>&frasl;</Typography>
                  : <></>
                  }
                </>
              )
            })}
            </Box>
            {item[23].history ? <PopoverEditHistory data={item[23]} /> :  <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[24].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[24].tdata}</Typography>
            {item[24].history ? <PopoverEditHistory data={item[24]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[25].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[25].tdata}</Typography>
            {item[25].history ? <PopoverEditHistory data={item[25]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[26].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[26].tdata}</Typography>
            {item[26].history ? <PopoverEditHistory data={item[26]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[27].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[27].tdata}</Typography>
            {item[27].history ? <PopoverEditHistory data={item[27]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[28].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[28].tdata}</Typography>
            {item[28].history ? <PopoverEditHistory data={item[28]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[29].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[29].tdata}</Typography>
            {item[29].history ? <PopoverEditHistory data={item[29]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[30].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[30].tdata}</Typography>
            {item[30].history ? <PopoverEditHistory data={item[30]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[31].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[31].tdata}</Typography>
            {item[31].history ? <PopoverEditHistory data={item[31]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[32].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[32].tdata}</Typography>
            {item[32].history ? <PopoverEditHistory data={item[32]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead align="left">{item[33].thead}</StyledMuiTableHead>
        <StyledMuiTableCell>
          <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
            <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{item[33].tdata}</Typography>
            {item[33].history ? <PopoverEditHistory data={item[33]} /> : <Box sx={{ width: '20px' }}/>}
          </Box>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
    </>
  )
}

