/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';

import { Box, Typography, Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';


export default function ModalInputByRentPc({onClose}) {
  const [open, setOpen] = useState(false);
  const [seconds, setSeconds] = useState(180);
  const [hashNumber, setHashNumber] = useState(() => generateHashNumber());

  function generateHashNumber() {
    const hashNumber = Math.floor(1000 + Math.random() * 9000) + '-' + Math.floor(1000 + Math.random() * 9000);
    return hashNumber;
  }

  useEffect(() => {
    let interval;
    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds - 1);
      }, 1000);
    } else {
      handleClose();
    }

    // cleanup function
    return () => clearInterval(interval);
  }, [seconds]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <Box css={styles.modal}>
      <Box id="modal-title" variant="h6" component="h2" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography component="p" css={styles.modalTitleStyle}>貸与PCで入力</Typography>
        <IconButton aria-label="close-modal-view-more-contact" onClick={handleClose}>
          <CancelIcon sx={{ color: '#777' }}/>
        </IconButton>
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Typography component="p" css={styles.modalTextAreaStyle}>貸与PCでHashログイン画面を開き、Hash IDを入力してください。</Typography>
        <Typography component="p" css={styles.modalTextAreaStyle}>Hashログイン画面URL：https://xxxxxxxx.co.jp/hashlogin/</Typography>
        <Box css={styles.loginHashArea}>
          <Typography component="p" sx={{ fontWeight: 'regular', fontSize: '0.75rem' }}>ログインHash ID</Typography>
          <Typography component="p" sx={{ fontWeight: 'bold', fontSize: '1.4rem', m: 1 }}>{hashNumber}</Typography>
          <Typography component="p" sx={{ fontSize: '0.5rem', m: 1 }}>※有効時間：{seconds}秒</Typography>
        </Box>
        <Button variant="contained" css={styles.btnSizeSmall} onClick={handleClose}>閉じる</Button>
      </Box>
    </Box>
  )
}

const styles = {
  modal:{
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    
    backgroundColor: '#FFF',
    borderRadius: '0.25rem',
    padding: '15px 20px',
    fontSize: '0.5rem',
  },
  modalTitleStyle:{
    display: 'inline-block',
    padding: '0 1rem',
    fontSize: '1rem',
    color: '#0047A4',
    backgroundColor: '#c6e0f7',
    height: '1.5rem',
  },
  modalTextAreaStyle:{
    fontSize: '0.5rem',
    lineHeight: '1.5rem',
  },
  loginHashArea:{
    margin: '1rem'
  },
  btnSizeSmall:{
    transform: 'scale(0.75)',
    transformOrigin: 'center bottom',
  },
}
