/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { styled } from '@mui/material/styles';

import FormControl from "@mui/material/FormControl";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Grid from "@mui/material/Grid";

const priorityMenuItems = [
  '時間給・給与',
  '勤務時間・時間帯',
  '休日',
  '通勤距離・駅近',
  '就業日数',
  '職場環境',
  'その他',
]

function SelectPreferredPriority({id, labelId, width, textSize, disabled}) {

  //------------コンポーネントのスタイル------------
  const styles = {
    selectBox:{
      width: '90%',

      '& .MuiInputBase-input':{
        fontSize: textSize,
      },
      '& .MuiInputBase-root':{
        // padding: '3px 0.5rem',
        fontSize: textSize,
      },
      '& .MuiFormLabel-root':{
        fontSize: textSize,
      },
      '& .MuiInputLabel-root':{
        fontSize: textSize,
      },
      '& .MuiSelect-root':{
        fontSize: textSize
      }
    },
    inputItemForm:{
      display:'flex',
      alignItems: 'center',
    },
    paddingLeft5:{
      paddingLeft: '5px',
    },
    textSize:{
      fontSize: textSize
    },
    smallTextField: {
      width: '100%',
      mr: 5,
      '& .MuiInputBase-root':{
        fontSize: textSize,
      },
      '& .MuiOutlinedInput-root':{
        fontSize: textSize,
      },
    },
    inputItemForm:{
      display:'flex',
      alignItems: 'center',
    },
  }
  //------------コンポーネントのスタイル------------

  const [ priority, setPriority ] = useState('');
  const handleChangePriority = (event) => {
    
    setPriority(event.target.value);

  };
  return (
    <Grid item xs={width} sm={width} md={width} lg={width} xl={width} css={styles.inputItemForm}>
      <FormControl sx={{ mr: 2, minWidth: '100%' }} size="small">
        <Select 
          labelId={labelId}
          id={id}
          value={priority}
          displayEmpty
          css={styles.selectBox}
          sx={{ fontSize: textSize }}
          disabled={disabled}
          onChange={handleChangePriority}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return <em>選択してください</em>;
            }
            return selected;
          }}
        >
          <MenuItem value="" sx={{ fontSize: textSize }}><em>選択してください</em></MenuItem>
          {priorityMenuItems.map((item) => (
            <MenuItem value={item} sx={{ fontSize: textSize }}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {priority === 'その他' ? <TextField label="その他理由" id="other" size="small" sx={{ width: '90%', mr: 5, mt:3 }} css={styles.smallTextField} /> : ''}
    </Grid>
  )
}

export default SelectPreferredPriority;