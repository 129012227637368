/** @jsxImportSource @emotion/react */
import React from "react";
import { styled } from '@mui/material';
import TextField from "@mui/material/TextField";
import Grid from '@mui/material/Grid';


function TextFieldInputAge  ({ age, textSize, disabled }) {
  const styles = {
    smallTextField: {
      width: '100%',
      mr: 5,
      '& .MuiInputBase-root':{
        fontSize: textSize,
      },
      '& .MuiOutlinedInput-root':{
        fontSize: textSize,
      },
    },
    inputItemForm:{
      display:'flex',
      alignItems: 'center',
    },
    paddingLeft5:{
      paddingLeft: '5px',
    }
  }

  return (
    <Grid item xs={3} sm={3} md={2} lg={2} xl={2} css={styles.inputItemForm}>

      <TextField
        type="text"
        size="small"
        value={age}
        css={styles.smallTextField}
        disabled={disabled}
      />
      <span css={styles.paddingLeft5}>歳</span>
    </Grid>
  );
};

export default TextFieldInputAge;