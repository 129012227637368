import React from "react";
import StyledMuiAppBar from "../parts/AppBar/StyledMuiAppBar";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import GenericMailTargetSelection from "../feature/GenericMail/GenericMailTargetSelection";



// カナ文字をローマ字に変換する関数
function kanaToRomaji(kana) {
  const kanaMap = {
    "ア": "a", "イ": "i", "ウ": "u", "エ": "e", "オ": "o",
    "カ": "ka", "キ": "ki", "ク": "ku", "ケ": "ke", "コ": "ko",
    "サ": "sa", "シ": "shi", "ス": "su", "セ": "se", "ソ": "so",
    "タ": "ta", "チ": "chi", "ツ": "tsu", "テ": "te", "ト": "to",
    "ナ": "na", "ニ": "ni", "ヌ": "nu", "ネ": "ne", "ノ": "no",
    "ハ": "ha", "ヒ": "hi", "フ": "fu", "ヘ": "he", "ホ": "ho",
    "マ": "ma", "ミ": "mi", "ム": "mu", "メ": "me", "モ": "mo",
    "ヤ": "ya", "ユ": "yu", "ヨ": "yo",
    "ラ": "ra", "リ": "ri", "ル": "ru", "レ": "re", "ロ": "ro",
    "ワ": "wa", "ヲ": "wo", "ン": "n",
    "ガ": "ga", "ギ": "gi", "グ": "gu", "ゲ": "ge", "ゴ": "go",
    "ザ": "za", "ジ": "ji", "ズ": "zu", "ゼ": "ze", "ゾ": "zo",
    "ダ": "da", "ヂ": "ji", "ヅ": "zu", "デ": "de", "ド": "do",
    "バ": "ba", "ビ": "bi", "ブ": "bu", "ベ": "be", "ボ": "bo",
    "パ": "pa", "ピ": "pi", "プ": "pu", "ペ": "pe", "ポ": "po"
  };

  let romaji = "";
  for (let i = 0; i < kana.length; i++) {
    romaji += kanaMap[kana[i]] || "";
  }
  return romaji;
}

// 50件のデータを自動生成する関数
function generateData() {
  const datas = [];

  for (let i = 1; i <= 2000; i++) {
    const randomFirstIndex = Math.floor(Math.random() * firstNameList.length);
    const randomLastIndex = Math.floor(Math.random() * lastNameList.length);

    const seiKana = lastNameList[randomLastIndex].sei_kana;
    const meiKana = firstNameList[randomFirstIndex].mei_kana;

    const seiKanji = lastNameList[randomLastIndex].sei_kanji;
    const meiKanji = firstNameList[randomFirstIndex].mei_kanji;

    const email = `${kanaToRomaji(seiKana)}@example.com`;

    // 10桁のランダムな整数を生成
    const applicantsId = Math.floor(Math.random() * (9999999999 - 1000000000 + 1)) + 1000000000;

    datas.push({
      id: i,
      applicantsId,
      seiKanji,
      meiKanji,
      seiKana,
      meiKana,
      email,
    });
  }
  return datas;
}

const firstNameList = [
  { mei_kana: "ヒロト", mei_kanji: "大翔", },
  { mei_kana: "タクミ", mei_kanji: "拓海", },
  { mei_kana: "ヒナ", mei_kanji: "陽菜", },
  { mei_kana: "ミウ", mei_kanji: "美羽", },
  { mei_kana: "ユウト", mei_kanji: "優斗", },
  { mei_kana: "ユウマ", mei_kanji: "悠真", },
  { mei_kana: "ココア", mei_kanji: "心愛", },
  { mei_kana: "ユナ", mei_kanji: "結菜", },
  { mei_kana: "レン", mei_kanji: "蓮", },
  { mei_kana: "カイト", mei_kanji: "海斗", },
  { mei_kana: "リン", mei_kanji: "凛", },
  { mei_kana: "ハヤテ", mei_kanji: "颯太", },
  { mei_kana: "シオリ", mei_kanji: "詩織", },
  { mei_kana: "カズハ", mei_kanji: "一葉", },
  { mei_kana: "タイチ", mei_kanji: "太一", },
  { mei_kana: "マオ", mei_kanji: "真央", },
  { mei_kana: "ケイタ", mei_kanji: "慶太", },
  { mei_kana: "ヒトミ", mei_kanji: "瞳", },
  { mei_kana: "ユウ", mei_kanji: "悠", },
  { mei_kana: "ケンタ", mei_kanji: "健太", },
  { mei_kana: "ジュンペイ", mei_kanji: "淳平", },
];

const lastNameList = [
  { sei_kana: "サトウ", sei_kanji: "佐藤" },
  { sei_kana: "スズキ", sei_kanji: "鈴木" },
  { sei_kana: "タカハシ", sei_kanji: "高橋" },
  { sei_kana: "タナカ", sei_kanji: "田中" },
  { sei_kana: "ワタナベ", sei_kanji: "渡辺" },
  { sei_kana: "イトウ", sei_kanji: "伊藤" },
  { sei_kana: "ヤマモト", sei_kanji: "山本" },
  { sei_kana: "ナカムラ", sei_kanji: "中村" },
  { sei_kana: "コバヤシ", sei_kanji: "小林" },
  { sei_kana: "ヨシダ", sei_kanji: "吉田" },
  { sei_kana: "ヤマダ", sei_kanji: "山田" },
  { sei_kana: "ササキ", sei_kanji: "佐々木" },
  { sei_kana: "ヤマグチ", sei_kanji: "山口" },
  { sei_kana: "マツモト", sei_kanji: "松本" },
  { sei_kana: "イノウエ", sei_kanji: "井上" },
  { sei_kana: "キムラ", sei_kanji: "木村" },
  { sei_kana: "ハヤシ", sei_kanji: "林" },
  { sei_kana: "シミズ", sei_kanji: "清水" },
  { sei_kana: "ヤマザキ", sei_kanji: "山崎" },
];

const datas = generateData();
console.log(datas);



export default function GenericMailTargetSelectionPage() {
  return (
    <>
      <StyledMuiAppBar headerTitle='汎用メール対象者選択'/>
      <Box sx={{ width: '100%', mt: 7, mb: 3 }}>
        <Grid container spacing={2} rowSpacing={2} sx={{px:20}}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <GenericMailTargetSelection datas={datas}/>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

