/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


//------------コンポーネントのスタイル------------
const styles = {
  selectBox:{
    width: '90%',
    textAlign: 'left',
    '& .MuiInputBase-input':{
      fontSize: '0.5rem',
    },
    '& .MuiInputBase-root':{
      // padding: '3px 0.5rem',
      fontSize: '0.5rem',
    },
    '& .MuiFormLabel-root':{
      fontSize: '0.5rem',
    },
    '& .MuiInputLabel-root':{
      fontSize: '0.5rem',
    },
    '& .MuiSelect-root':{
      fontSize: '0.5rem'
    }
  },
  inputItemForm:{
    display:'flex',
    alignItems: 'center',
  },
  paddingLeft5:{
    paddingLeft: '5px',
  },
  textSize:{
    fontSize: '0.5rem',
    
  }
}
//------------コンポーネントのスタイル------------



export default function SelectedDepartment() {
  const [department, setDepartment] = useState('');
  const handleChangeDepartment = (event) => {
    setDepartment(event.target.value);
  };
  
  return (
    <FormControl sx={{ minWidth: 120 }} size="small">
      <Select
        labelId="department"
        id="department"
        value={department}
        css={styles.selectBox}
        sx={{ fontSize: '0.5rem' }}
        displayEmpty
        onChange={handleChangeDepartment}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em css={styles.textSize}>----------------</em>;
          }
          return selected;
        }}
      >
        <MenuItem value="" sx={{ fontSize: '0.5rem' }}><em css={styles.textSize}>----------------</em></MenuItem>
        <MenuItem value={'OS'} sx={{ fontSize: '0.5rem' }}>OS</MenuItem>
        <MenuItem value={'NC'} sx={{ fontSize: '0.5rem' }}>NC</MenuItem>
      </Select>
    </FormControl>
  )
}