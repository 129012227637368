/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { styled } from "@mui/material";
import {
  Button,
  Tab,
  Typography,
  Box,
  Table,
  TableContainer,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Paper,
  Modal,
  Popover,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
} from "@mui/material";

export default function PopoverThreeRecord(props) {
  const { applicantBasicInfo, open, anchorEl, handlePopoverClose, ...other } =
    props;
  return (
    <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: "none",
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
          <TableRow>
            <TableCell
              align="left"
              sx={{ p: 1, fontSize: "0.5rem", color: "#4B576B" }}
            >
              質問
            </TableCell>
            <TableCell
              align="left"
              sx={{
                p: 1,
                fontSize: "0.5rem",
                fontWeight: "bold",
                color: "#0047A4",
              }}
            >
              {applicantBasicInfo.question}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              align="left"
              sx={{ p: 1, fontSize: "0.5rem", color: "#4B576B" }}
            >
              希望連絡時間
            </TableCell>
            <TableCell
              align="left"
              sx={{
                p: 1,
                fontSize: "0.5rem",
                fontWeight: "bold",
                color: "#0047A4",
              }}
            >
              {applicantBasicInfo.contactTime}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              align="left"
              sx={{ p: 1, fontSize: "0.5rem", color: "#4B576B" }}
            >
              希望連絡方法
            </TableCell>
            <TableCell
              align="left"
              sx={{
                p: 1,
                fontSize: "0.5rem",
                fontWeight: "bold",
                color: "#0047A4",
              }}
            >
              {applicantBasicInfo.contactMethod}
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </Popover>
  );
}
