/** @jsxImportSource @emotion/react */
import { styled } from '@mui/material/styles';
import TableHead from '@mui/material/TableHead';

const StyledSideToSideTableHead = styled(TableHead)(() => ({
  // width: '55%',
  padding: '5px',
  height: '25px',
}))

export default StyledSideToSideTableHead;