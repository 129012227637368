/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";

import TableContainer from '@mui/material/TableContainer';

import { FormControl, Select, MenuItem, Table, Box, Button, Grid, Typography } from '@mui/material';

import StyledMuiPaper from '../../parts/surface/StyledMuiPaper';
import StyledMuiTableCell from '../../parts/table/StyledMuiTable/StyledMuiTableCell';
import StyledMuiTableHead from '../../parts/table/StyledMuiTable/StyledMuiTableHead';
import StyledMuiTableRow from '../../parts/table/StyledMuiTable/StyledMuiTableRow';

import TextFieldWebEntry from '../forms/TextFieldWebEntry';
import CheckBoxWebEntry from '../forms/CheckBoxWebEntry';
import SelectBoxWebEntry from '../forms/SelectBoxWebEntry';

import DatepickerWebEntryHour from '../forms/DatepickerWebEntryHour';
import TextFieldNameKanji from '../forms/TextFieldNameKanji';
import TextFieldNameKana from '../forms/TextFieldNameKana';
import RadioSelectGender from '../forms/RadioSelectGender';
import TextFieldInputBirthday from '../forms/TextFieldInputBirthday';
import TextFieldInputPhoneNumber from '../forms/TextFieldInputPhoneNumber';
import TextFieldInputZipCode from '../forms/TextFieldInputZipCode';
import SelectBoxPrefecture from '../forms/SelectBoxPrefecture';
import TextFieldInputCity from '../forms/TextFieldInputCity';
import TextFieldInputAddress from '../forms/TextFieldInputAddress';

import Const from '../../../libs/Const';


const styles = {
  middleWidth: {
    width: '50%',
    "@media (max-width:1200px)":{
      width: '90%',
      padding: '2rem',
    },
  },
  middleTableWidth: {
    width: '80%',
    "@media (max-width:1200px)":{
      width: '100%',
      padding: '2rem',
    },
  },
  btnSizeSmall:{
    transform: 'scale(0.75)',
    transformOrigin: 'center bottom',
  },
  selectBox:{
    fontSize: '0.5rem',
    '& .MuiInputBase-input':{
      fontSize: '0.5rem',
      color: '#0047A4',
    },
    '& .MuiInputBase-root':{
      padding: '3px 0.5rem',
      fontSize: '0.5rem',
    },
    '& .MuiFormLabel-root':{
      fontSize: '0.5rem',
    },
    '& .MuiInputLabel-root':{
      fontSize: '0.5rem',
    },
    '& .MuiSelect-root':{
      fontSize: '0.5rem'
    }
  },
  smallTextSize:{
    fontSize: '0.5rem',
    color: '0047A4',
  },
}



export default function ApplicantsRegistForm({data, conflict}) {
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");

  const yearOptions = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= (currentYear - 100); i--) {
    yearOptions.push(i);
  }

  const monthOptions = [];
  for (let i = 1; i <= 12; i++) {
    monthOptions.push(i);
  }

  const dayOptions = [];
  for (let i = 1; i <= 31; i++) {
    dayOptions.push(i);
  }

  // Calculate age
  let age = "";
  if (year && month && day) {
    const today = new Date();
    const birthDate = new Date(year, month - 1, day);
    age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
  }

  //---------------郵便番号と住所---------------
  const [address, setAddress] = useState({
    address1: '',
    address2: '',
    address3: '',
  })


  return (
    <StyledMuiPaper css={styles.middleWidth} sx={{ overflow: 'hidden', py: 5, mb:3, textAlign: 'left', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      <TableContainer css={styles.middleTableWidth}>
        <Table sx={{ minWidth: 250 }} aria-label="simple table">
          <StyledMuiTableRow>
            <StyledMuiTableHead align="left">応募日時</StyledMuiTableHead>
            <StyledMuiTableCell>
              <Grid container spacing={2} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                <DatepickerWebEntryHour/>
              </Grid>
            </StyledMuiTableCell>
          </StyledMuiTableRow>
          <StyledMuiTableRow>
            <StyledMuiTableHead align="left">事業部</StyledMuiTableHead>
            <StyledMuiTableCell>
              <Grid container spacing={2} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                <SelectBoxWebEntry id="applicantBranch" width="8" textSize="0.5rem" items={[ 'NC', 'OS' ]}/>
              </Grid>
            </StyledMuiTableCell>
          </StyledMuiTableRow>
          <StyledMuiTableRow>
            <StyledMuiTableHead align="left">応募支店（応募時）</StyledMuiTableHead>
            <StyledMuiTableCell>
              <Grid container spacing={2} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                <SelectBoxWebEntry id="applicantBranch" width="12" textSize="0.5rem" items={Const.BRANCH_OPTIONS}/>
              </Grid>
            </StyledMuiTableCell>
          </StyledMuiTableRow>
          <StyledMuiTableRow>
            <StyledMuiTableHead align="left">応募経路</StyledMuiTableHead>
            <StyledMuiTableCell>
              <Grid container spacing={2} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                <SelectBoxWebEntry id="applicantRoute" width="12" textSize="0.5rem" items={Const.APPLICANT_ROUTE}/>
              </Grid>
            </StyledMuiTableCell>
          </StyledMuiTableRow>
          <StyledMuiTableRow>
            <StyledMuiTableHead align="left">応募媒体</StyledMuiTableHead>
            <StyledMuiTableCell>
              <Grid container spacing={2} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                <SelectBoxWebEntry id="applicantMedia" width="12" textSize="0.5rem" items={Const.APPLICANT_MEDIA_BY_REGIST}/>
              </Grid>
            </StyledMuiTableCell>
          </StyledMuiTableRow>
          <StyledMuiTableRow>
            <StyledMuiTableHead align="left">名前（漢字）</StyledMuiTableHead>
            <StyledMuiTableCell>
              <Grid container spacing={2} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                <TextFieldNameKanji textSize="0.5rem"/>
              </Grid>
            </StyledMuiTableCell>
          </StyledMuiTableRow>
          <StyledMuiTableRow>
            <StyledMuiTableHead align="left">名前（カナ）</StyledMuiTableHead>
            <StyledMuiTableCell>
              <Grid container spacing={2} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                <TextFieldNameKana textSize="0.5rem" />
              </Grid>
            </StyledMuiTableCell>
          </StyledMuiTableRow>
          <StyledMuiTableRow>
            <StyledMuiTableHead align="left">性別</StyledMuiTableHead>
            <StyledMuiTableCell>
              <Grid container spacing={2} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                <RadioSelectGender textSize="0.5rem" buttonSize="1rem"/>
              </Grid>
            </StyledMuiTableCell>
          </StyledMuiTableRow>
          <StyledMuiTableRow>
            <StyledMuiTableHead align="left">生年月日</StyledMuiTableHead>
            <StyledMuiTableCell>
              <Grid container spacing={2} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                <Grid item xs={8} sm={8} md={8} lg={8} xl={8} sx={{ display: 'flex', alignItems: 'center' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FormControl fullWidth size="small" sx={{ minWidth: '70px' }}>
                      <Select
                        labelId="year-select-label"
                        value={year}
                        css={styles.selectBox}
                        onChange={(e) => setYear(e.target.value)}
                      >
                        {yearOptions.map((year) => (
                          <MenuItem key={year} value={year} css={styles.smallTextSize}>{year}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Typography component="div" css={styles.smallTextSize} sx={{ ml: '3px' }}>年</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                    <FormControl fullWidth size="small" sx={{ minWidth: '60px' }}>
                      <Select
                        labelId="month-select-label"
                        value={month}
                        css={styles.selectBox}
                        onChange={(e) => setMonth(e.target.value)}
                      >
                        {monthOptions.map((month) => (
                          <MenuItem key={month} value={month} css={styles.smallTextSize}>{month}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Typography component="div" css={styles.smallTextSize} sx={{ ml: '3px' }}>月</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                    <FormControl fullWidth size="small" sx={{ minWidth: '60px' }}>
                      <Select
                        labelId="day-select-label"
                        value={day}
                        css={styles.selectBox}
                        onChange={(e) => setDay(e.target.value)}
                      >
                        {dayOptions.map((day) => (
                          <MenuItem key={day} value={day} css={styles.smallTextSize}>{day}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Typography component="div" css={styles.smallTextSize} sx={{ ml: '3px' }}>日</Typography>
                  </Box>
                  {age && (
                    <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                      <Typography component="p" css={styles.smallTextSize}>
                        &emsp;年齢: {age}&nbsp;歳
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </StyledMuiTableCell>
          </StyledMuiTableRow>
          <StyledMuiTableRow>
            <StyledMuiTableHead align="left">郵便番号</StyledMuiTableHead>
            <StyledMuiTableCell>
              <Grid container spacing={2} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                <TextFieldInputZipCode textSize="0.5rem" setAddress={setAddress} defaultZipcode=""/>
              </Grid>
            </StyledMuiTableCell>
          </StyledMuiTableRow>
          <StyledMuiTableRow>
            <StyledMuiTableHead align="left">住所（都道府県）</StyledMuiTableHead>
            <StyledMuiTableCell>
              <Grid container spacing={2} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                <SelectBoxPrefecture textSize="0.5rem" address={address} />
              </Grid>
            </StyledMuiTableCell>
          </StyledMuiTableRow>
          <StyledMuiTableRow>
            <StyledMuiTableHead align="left">住所（市区町村）</StyledMuiTableHead>
            <StyledMuiTableCell>
              <Grid container spacing={2} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                <TextFieldInputCity textSize="0.5rem" address={address} />
              </Grid>
            </StyledMuiTableCell>
          </StyledMuiTableRow>
          <StyledMuiTableRow>
            <StyledMuiTableHead align="left">住所（町域・番地）</StyledMuiTableHead>
            <StyledMuiTableCell>
              <Grid container spacing={2} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                <TextFieldInputAddress textSize="0.5rem" address={address} />
              </Grid>
            </StyledMuiTableCell>
          </StyledMuiTableRow>
          <StyledMuiTableRow>
            <StyledMuiTableHead align="left">住所（建物名など）</StyledMuiTableHead>
            <StyledMuiTableCell>
              <Grid container spacing={2} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                <TextFieldWebEntry width="12" textSize="0.5rem" id="building" label="建物名など" size="small" value="" />
              </Grid>
            </StyledMuiTableCell>
          </StyledMuiTableRow>
          <StyledMuiTableRow>
            <StyledMuiTableHead align="left">電話番号（携帯）</StyledMuiTableHead>
            <StyledMuiTableCell>
              <Grid container spacing={2} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                <TextFieldInputPhoneNumber width="12" textSize="0.5rem" id="phoneNumber1" label="携帯" size="small" />
              </Grid>
            </StyledMuiTableCell>
          </StyledMuiTableRow>
          <StyledMuiTableRow>
            <StyledMuiTableHead align="left">電話番号（その他連絡先）</StyledMuiTableHead>
            <StyledMuiTableCell>
              <Grid container spacing={2} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                <TextFieldInputPhoneNumber width="12" textSize="0.5rem" id="phoneNumber2" label="その他" size="small" />
              </Grid>
            </StyledMuiTableCell>
          </StyledMuiTableRow>
          <StyledMuiTableRow>
            <StyledMuiTableHead align="left">メールアドレス①</StyledMuiTableHead>
            <StyledMuiTableCell>
              <Grid container spacing={2} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                <TextFieldWebEntry width="12" textSize="0.5rem" id="mailaddress1" label="メールアドレス①" size="small" value="" />
              </Grid>
            </StyledMuiTableCell>
          </StyledMuiTableRow>
          <StyledMuiTableRow>
            <StyledMuiTableHead align="left">メールアドレス②</StyledMuiTableHead>
            <StyledMuiTableCell>
              <Grid container spacing={2} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                <TextFieldWebEntry width="12" textSize="0.5rem" id="mailaddress2" label="メールアドレス②" size="small" value="" />
              </Grid>
            </StyledMuiTableCell>
          </StyledMuiTableRow>
          <StyledMuiTableRow>
            <StyledMuiTableHead align="left">質問</StyledMuiTableHead>
            <StyledMuiTableCell>
              <Grid container spacing={2} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                <TextFieldWebEntry width="12" textSize="0.5rem" id="question" size="small" value="" multiline='true' lines='5'/>
              </Grid>
            </StyledMuiTableCell>
          </StyledMuiTableRow>
          <StyledMuiTableRow>
            <StyledMuiTableHead align="left">希望連絡時間</StyledMuiTableHead>
            <StyledMuiTableCell>
              <Grid container spacing={2} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                <TextFieldWebEntry width="12" textSize="0.5rem" id="prefferdContactTime" size="small" value="" placeholder="平日、土日祝　時間帯もいつでも可能です。" multiline='true' lines='5'/>
              </Grid>
            </StyledMuiTableCell>
          </StyledMuiTableRow>
          <StyledMuiTableRow>
            <StyledMuiTableHead align="left">連絡方法</StyledMuiTableHead>
            <StyledMuiTableCell>
              <Grid container spacing={2} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                <TextFieldWebEntry width="12" textSize="0.5rem" id="prefferdContactMethod" size="small" value="" placeholder="電話は11時から2時の間希望です。" multiline='true' lines='5'/>
              </Grid>
            </StyledMuiTableCell>
          </StyledMuiTableRow>

        </Table>
      </TableContainer>
      <Box sx={{ mt:5 }}>
        <Button variant="contained" css={styles.btnSizeSmall}>登録する</Button>
      </Box>
    </StyledMuiPaper>
  )

}