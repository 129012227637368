/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Const from '../../../libs/Const';

//------------コンポーネントのスタイル------------
const styles = {
  selectBox:{
    width: '90%',
    textAlign: 'left',
    '& .MuiInputBase-input':{
      fontSize: '0.5rem',
    },
    '& .MuiInputBase-root':{
      // padding: '3px 0.5rem',
      fontSize: '0.5rem',
    },
    '& .MuiFormLabel-root':{
      fontSize: '0.5rem',
    },
    '& .MuiInputLabel-root':{
      fontSize: '0.5rem',
    },
    '& .MuiSelect-root':{
      fontSize: '0.5rem'
    }
  },
  inputItemForm:{
    display:'flex',
    alignItems: 'center',
  },
  paddingLeft5:{
    paddingLeft: '5px',
  },
  textSize:{
    fontSize: '0.5rem'
  }
}
//------------コンポーネントのスタイル------------

const array = ['OS北海道', 'OS東北', 'OS関東', 'OS北陸', 'OS東海', 'OS近畿', 'OS四国', 'OS中国', 'OS九州'];


function SelectedOffice() {
  const [office, setOffice] = useState('');
  const handleChangeOffice = (event) => {
    setOffice(event.target.value);
  };
  
  return (
    <FormControl sx={{ minWidth: 120 }} size="small">
      <Select
        labelId="office"
        id="office"
        value={office}
        css={styles.selectBox}
        sx={{ fontSize: '0.5rem' }}
        displayEmpty
        onChange={handleChangeOffice}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em css={styles.textSize}>----------------</em>;
          }
          return selected;
        }}
      >
        <MenuItem value="" sx={{ fontSize: '0.5rem'  }}><em css={styles.textSize}>----------------</em></MenuItem>
        {array.map((option) => {
          return (
            <MenuItem value={option} sx={{ fontSize: '0.5rem' }}>{option}</MenuItem>
          )})
        }
      </Select>
    </FormControl>
  )
}

export default SelectedOffice;