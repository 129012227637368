/** @jsxImportSource @emotion/react */
import React from "react";
import { useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PrefCity from '../../../libs/Const/PrefCity';


const prefIndex = {
  0: "01",
  1: "02",
  2: "03",
  3: "04",
  4: "05",
  5: "06",
  6: "07",
  7: "08",
  8: "09",
  9: "10",
  10: "11",
  11: "12",
  12: "13",
  13: "14",
  14: "15",
  15: "16",
  16: "17",
  17: "18",
  18: "19",
  19: "20",
  20: "21",
  21: "22",
  22: "23",
  23: "24",
  24: "25",
  25: "26",
  26: "27",
  27: "28",
  28: "29",
  29: "30",
  30: "31",
  31: "32",
  32: "33",
  33: "34",
  34: "35",
  35: "36",
  36: "37",
  37: "38",
  38: "39",
  39: "40",
  40: "41",
  41: "42",
  42: "43",
  43: "44",
  44: "45",
  45: "46",
  46: "47",
}


function createPrefAndCity(prefecture, cities){
  return {prefecture, cities};
};

const prefDatas = PrefCity.PREF_CITY_OPTIONS;
const prefAndCitiesLists = [];

for(let i = 0; i < 47; i++){
  prefAndCitiesLists.push( createPrefAndCity(prefDatas[0][0][prefIndex[i]]["name"], prefDatas[0][0][prefIndex[i]]["cities"] ));
}



function getStyles(city, selectedCity, theme) {
  return {
    fontWeight:
    selectedCity.indexOf(city) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

//------------コンポーネントのスタイル------------
const styles = {
  selectBox:{
    '& .MuiInputBase-input':{
      fontSize: '0.5rem',
    },
    '& .MuiInputBase-root':{
      // padding: '3px 0.5rem',
      fontSize: '0.5rem',
    },
    '& .MuiFormLabel-root':{
      fontSize: '0.5rem',
    },
    '& .MuiInputLabel-root':{
      fontSize: '0.5rem',
    },
    '& .MuiSelect-root':{
      fontSize: '0.5rem'
    }
  },
  inputItemForm:{
    display:'flex',
    alignItems: 'center',
  },
  paddingLeft5:{
    paddingLeft: '5px',
  },
  textSize:{
    fontSize: '0.5rem',
  },
  chipSize: {
    fontSize: '0.5rem',
    height: '20px',
    "& .MuiChip-root-SelectInput": {
      height: '20px'
    },
  },
  citySelectBox:{
    "& .MuiSelect-select":{
      padding: '4px 12px',
    }
  }
}
//------------コンポーネントのスタイル------------

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};



export default function SelectedPrefectureAndCity() {
  const theme = useTheme();
  const [selectedPref, setSelectedPref] = React.useState('');
  const handleChangeSelectedPref = (event) => {
    setSelectedPref(event.target.value);
  };

  const [selectedCity, setSelectedCity] = React.useState([]);

  const handleChangeSelectedCity = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCity(
      typeof value === 'string' ? value.split(',') : value,
    );
  };


  const prefOptions = prefAndCitiesLists.map((pref, i) => (
    <MenuItem key={i} value={pref.prefecture} css={styles.textSize}>{pref.prefecture}</MenuItem>
  ));
  const cityOptions = selectedPref
  ? prefAndCitiesLists.find((pref) => pref.prefecture === selectedPref).cities.map((city, j) => (
      <MenuItem key={j} value={city.city} style={getStyles(city, selectedCity, theme)} css={styles.textSize}>{city.city}</MenuItem>
    ))
  : null;

  const handleDelete = (itemToDelete) => () => {
    setSelectedCity((prevItems) => prevItems.filter((item) => item !== itemToDelete));
  };



  return (
    <>
      <FormControl sx={{ width: 80, height: '28px' }} size="small" css={styles.selectBox}>
        <InputLabel id="prefecture" css={styles.textSize}>都道府県</InputLabel>
        <Select
          labelId="prefecture"
          id="prefecture"
          value={selectedPref}
          label="都道府県"
          onChange={handleChangeSelectedPref}
          
        >
          {prefOptions}
        </Select>
      </FormControl>
      <FormControl sx={{ ml: 2, width: 300, height: '28px' }} size="small" css={styles.selectBox}>
        <InputLabel id="cities" css={styles.textSize}>市区町村</InputLabel>
        <Select
          labelId="cities"
          id="cities"
          multiple
          value={selectedCity}
          onChange={handleChangeSelectedCity}
          css={styles.citySelectBox}
          input={<OutlinedInput id="select-multiple-chip" label="Chip" sx={{ padding: '0px 8px', height: '28px' }} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} css={styles.chipSize} onDelete={handleDelete(value)}/>
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {cityOptions === null ? <MenuItem value="hidden" css={styles.textSize}>都道府県を選択してください</MenuItem> : cityOptions}
        </Select>
      </FormControl>
    </>
  );
}