/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { 
  Box, Grid, Typography
} from '@mui/material';
import StyledMuiPaper from '../../parts/surface/StyledMuiPaper';
import StyledMuiBtnBlue from '../../parts/button/StyledMuiBtnBlue';
import MuiTextField from '../../parts/textField/MuiTextField';


const styles = {
  customTableHeader: {
    fontSize: '0.5rem',
    fontWeight: 'bold',
    color: '#0047A4',
    padding: '4px 10px',
    textAlign: 'center',
  },
  customTableRow: {
    fontSize: '0.5rem',
    color: '#0047A4',
  },
  ruby:{
    fontSize: '0.5rem',
  },
  flexSbC: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexCC: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

export default function SeacrhFormsKeyWord() {

  const navigate = useNavigate();

  const handleRegistTemplate = () => {
    navigate('/regist_template');
  };

  return (
    <StyledMuiPaper sx={{ width: '70%', overflow: 'hidden', mb:1 }}>
      <Grid container sx={{ mt:1 }}>
        <Grid item xs={9} sm={9} md={9} lg={9}>
          <Box css={styles.flexCC}>
            <Typography component="span" sx={{ fontSize: '0.5rem', pr: 2, display: 'inline-block', width: '200px' }}>キーワード検索</Typography>
            <MuiTextField
              ariaLabel="mail-content"
              multiline={false}
              minRows={1}
              placeholder="キーワードを入力してください。"
            />
          </Box>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <StyledMuiBtnBlue variant="contained" color="success">検索</StyledMuiBtnBlue>
        </Grid>
        {/* <Grid item xs={2} sm={2} md={2} lg={2}>
          <StyledMuiBtnBlue variant="contained" onClick={() => handleRegistTemplate()}>新規登録</StyledMuiBtnBlue>
        </Grid> */}
      </Grid>
    </StyledMuiPaper>
  )
}