import React from "react";
import { Chip } from "@mui/material";

export default function MuiChipProhibition() {
  return (
    <Chip
      label="反社"
      size="small"
      sx={{
        fontSize: "0.3rem",
        background: "#FFD966",
        color: "#333333",
      }}
    />
  );
}
