/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import { Grid, Typography } from '@mui/material';

import StyledMuiTableCell from '../../../parts/table/StyledMuiTable/StyledMuiTableCell';
import StyledMuiTableHead from '../../../parts/table/StyledMuiTable/StyledMuiTableHead';
import StyledMuiTableRow from '../../../parts/table/StyledMuiTable/StyledMuiTableRow';


import TextFieldWebEntry from "../../forms/TextFieldWebEntry";
import CheckBoxWebEntry from "../../forms/CheckBoxWebEntry";
import SelectBoxWebEntry from "../../forms/SelectBoxWebEntry";

import DatepickerWebEntry from "../../forms/DatepickerWebEntry";

import Const from "../../../../libs/Const";


export default function TabPanelOs2({data, conflict}) {

  const requirementsTableItems=[
    {
      'thead': '希望雇用形態',
      'component': <CheckBoxWebEntry textSize="0.5rem" buttonSize="1rem" width="12" items={Const.DESIRED_EMPLOYMENT_TYPE} disabled={ conflict ? 'disabled' : null }/> 
    },
    {
      'thead': '希望勤務時間',
      'component': <CheckBoxWebEntry textSize="0.5rem" buttonSize="1rem" width="12" items={Const.DESIRED_WORK_HOURS} disabled={ conflict ? 'disabled' : null }/> 
    },
    {
      'thead': '希望条件',
      'component': <TextFieldWebEntry width="12" textSize="0.5rem" id="hope" size="small" label="" value="" disabled={ conflict ? 'disabled' : null }/> 
    },
    {
      'thead': '【希望職種】',
      'component': <CheckBoxWebEntry textSize="0.5rem" buttonSize="1rem" width="12" items={[ '以下該当なし' ]} disabled={ conflict ? 'disabled' : null }/> 
    },
    {
      'thead': '【希望職種】事務職',
      'component': <CheckBoxWebEntry textSize="0.5rem" buttonSize="1rem" width="12" items={Const.DESIRED_JOB_TYPE_CLERICAL_WORK} disabled={ conflict ? 'disabled' : null }/> 
    },
    {
      'thead': '【希望職種】オペレーター職',
      'component': <CheckBoxWebEntry  textSize="0.5rem" buttonSize="1rem" width="12" items={Const.DESIRED_JOB_TYPE_OPERATOR_POSITION} disabled={ conflict ? 'disabled' : null }/> 
    },
    {
      'thead': '【希望職種】販売・接客・営業職・その他',
      'component': <CheckBoxWebEntry textSize="0.5rem" buttonSize="1rem" width="12" items={Const.DESIRED_JOB_TYPE_SALES_CUSTOMER_SERVICE_STAFF} disabled={ conflict ? 'disabled' : null }/> 
    },
    {
      'thead': '就業開始可能日',
      'component': <DatepickerWebEntry disabled={ conflict ? 'disabled' : null }/> 
    },
    {
      'thead': '勤務可能日',
      'component': <CheckBoxWebEntry textSize="0.5rem" buttonSize="1rem" width="12" items={Const.DAY_OF_WEEK} disabled={ conflict ? 'disabled' : null }/> 
    },
    {
      'thead': '希望勤務日数／週',
      'component':
      <>
        <TextFieldWebEntry width="3" textSize="0.5rem" id="workHourFrom1" size="small" label="From" value="" disabled={ conflict ? 'disabled' : null }/>
        <Typography component="span" sx={{ position: 'relative', top: '24px', right: '4px', fontSize: '0.5rem' }}>日／週</Typography>
      </>
    },
    {
      'thead': '希望勤務形態',
      'component': <CheckBoxWebEntry textSize="0.5rem" buttonSize="1rem" width="12" items={Const.DESIRED_WORK_TYPE} disabled={ conflict ? 'disabled' : null }/> 
    },
    {
      'thead': '希望勤務時間①',
      'component': 
      <>
        <TextFieldWebEntry width="3" textSize="0.5rem" id="workHourFrom1" size="small" label="From" value="" disabled={ conflict ? 'disabled' : null }/>
        <Typography component="span" sx={{ position: 'relative', top: '24px', right: '4px', fontSize: '0.5rem' }}>～</Typography>
        <TextFieldWebEntry width="3" textSize="0.5rem" id="workHourTo1" size="small" label="To" value="" disabled={ conflict ? 'disabled' : null }/>
      </>
    },
    {
      'thead': '希望勤務時間②',
      'component': 
      <>
        <TextFieldWebEntry width="3" textSize="0.5rem" id="workHourFrom2" size="small" label="From" value="" disabled={ conflict ? 'disabled' : null }/>
        <Typography component="span" sx={{ position: 'relative', top: '24px', right: '4px', fontSize: '0.5rem' }}>～</Typography>
        <TextFieldWebEntry width="3" textSize="0.5rem" id="workHourTo2" size="small" label="To" value="" disabled={ conflict ? 'disabled' : null }/>
      </> 
    },
    {
      'thead': '希望勤務時間③',
      'component': 
      <>
        <TextFieldWebEntry width="3" textSize="0.5rem" id="workHourFrom3" size="small" label="From" value="" disabled={ conflict ? 'disabled' : null }/>
        <Typography component="span" sx={{ position: 'relative', top: '24px', right: '4px', fontSize: '0.5rem' }}>～</Typography>
        <TextFieldWebEntry width="3" textSize="0.5rem" id="workHourTo3" size="small" label="To" value="" disabled={ conflict ? 'disabled' : null }/>
      </> 
    },
    {
      'thead': '希望勤務時間④',
      'component': 
      <>
        <TextFieldWebEntry width="3" textSize="0.5rem" id="workHourFrom4" size="small" label="From" value="" disabled={ conflict ? 'disabled' : null }/>
        <Typography component="span" sx={{ position: 'relative', top: '24px', right: '4px', fontSize: '0.5rem' }}>～</Typography>
        <TextFieldWebEntry width="3" textSize="0.5rem" id="workHourTo4" size="small" label="To" value="" disabled={ conflict ? 'disabled' : null }/>
      </> 
    },
    {
      'thead': '残業可否',
      'component': 
      <>
        <SelectBoxWebEntry id="overWork" width="4" textSize="0.5rem" items={Const.CAN_OVERTIME_WORK} disabled={ conflict ? 'disabled' : null }/> 
        <TextFieldWebEntry width="3" textSize="0.5rem" id="enableOver" size="small" label="" value="" disabled={ conflict ? 'disabled' : null }/>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography component="div" sx={{ fontSize: '0.5rem' }}>時まで可能</Typography>
        </Grid>
      </>
    },
    {
      'thead': '夜勤可否',
      'component': 
      <>
        <SelectBoxWebEntry id="midnightWork" width="4" textSize="0.5rem" items={Const.IS_IT_POSSIBLE} disabled={ conflict ? 'disabled' : null }/>
        <TextFieldWebEntry width="3" textSize="0.5rem" id="enableMidnightWork" size="small" label="" value="" disabled={ conflict ? 'disabled' : null }/>
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography component="div" sx={{ fontSize: '0.5rem' }}>時まで可能</Typography>
        </Grid>
      </>
    },
    {
      'thead': '希望条件(時給)',
      'component': <SelectBoxWebEntry id="offer" width="4" textSize="0.5rem" items={Const.HOURLY_WAGE_RANGE} disabled={ conflict ? 'disabled' : null }/>
    },
    {
      'thead': '希望条件(月給)',
      'component': 
      <>
        <TextFieldWebEntry width="3" textSize="0.5rem" id="monthlySalary" size="small" label="月給" value="" disabled={ conflict ? 'disabled' : null }/>
        <Typography component="span" sx={{ position: 'relative', top: '24px', right: '4px', fontSize: '0.5rem' }}>万円／月</Typography>
      </>
    },
    {
      'thead': '希望条件(勤務地)',
      'component': <TextFieldWebEntry width="6" textSize="0.5rem" id="preffedArea" size="small" label="勤務地" value="" disabled={ conflict ? 'disabled' : null }/> 
    },
    
  ];

  return (
    <>
      {requirementsTableItems.map((item, j) => {
        return(
          <StyledMuiTableRow>
            <StyledMuiTableHead align="left">{item.thead}</StyledMuiTableHead>
            <StyledMuiTableCell>
              <Grid container spacing={2} rowSpacing={2} columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}>
                {item.component}
              </Grid>
            </StyledMuiTableCell>
          </StyledMuiTableRow>
        )
      })}
    </>
  )
}