import { GlobalStyles } from "@mui/material";

const globalStyles = (
  <GlobalStyles
    styles={{
      ".react-datepicker-popper": {
        zIndex: "5",
      },
      ".react-datepicker__triangle": {
        transform: "translate3d(120px, 0px, 0px) !important",
      },
      ".react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after":
        {
          borderBottomColor: "#C6E0F7 !important",
        },
      ".react-datepicker": {
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontWeight: 400,
        fontSize: "0.5rem",
        border: "initial",
        boxShadow: "0px 0px 15px -5px #777777",
      },
      ".react-datepicker__header": {
        backgroundColor: "#C6E0F7 !important",
        borderBottom: "initial",
      },
      ".react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header":
        {
          color: "#0047A4",
        },
      ".react-datepicker__day--disabled": {
        color: "#CCC !important",
      },
      ".react-datepicker__day-name": {
        color: "#0047A4 !important",
      },
      ".react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name":
        {
          color: "#333",
        },
      ".react-datepicker__day--selected, .react-datepicker__day--keyboard-selected, .react-datepicker__day:hover":
        {
          backgroundColor: "#1976D2",
          color: "white",
        },
    }}
  />
);

export default globalStyles;
