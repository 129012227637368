import React, { useState, useEffect } from "react";
import StyledMuiAppBar from "../parts/AppBar/StyledMuiAppBar";
import { Box, Grid, Paper, Typography } from "@mui/material";
import CreateNewMail from "../feature/GenericMail/CreateNewMail";
import InsertionTagBlock from"../feature/GenericMail/InsertionTagBlock";
import { useLocation } from 'react-router-dom';



export default function SendGenericMailPage() {
  const location = useLocation();
  const datas = location.state ? location.state.datas : {};
  const [tagValue, setTagValue] = useState('');

  return (
    <>
      <StyledMuiAppBar headerTitle='汎用メール送信'/>
      <Box sx={{ width: '100%', mt: 7, mb: 3 }}>
        <Grid container spacing={2} rowSpacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <CreateNewMail datas={datas} tagValue={tagValue}/>
          </Grid>
        </Grid>
      </Box>
      <InsertionTagBlock onTagValueChange={setTagValue}/>
    </>
  )
}

