/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Const from "../../../libs/Const";

//------------コンポーネントのスタイル------------
const styles = {
  selectBox: {
    width: "100%",
    textAlign: "left",
    "& .MuiInputBase-input": {
      fontSize: "0.5rem",
      color: "#0047A4",
    },
    "& .MuiInputBase-root": {
      // padding: '3px 0.5rem',
      fontSize: "0.5rem",
    },
    "& .MuiFormLabel-root": {
      fontSize: "0.5rem",
    },
    "& .MuiInputLabel-root": {
      fontSize: "0.5rem",
    },
    "& .MuiSelect-root": {
      fontSize: "0.5rem",
    },
  },
  inputItemForm: {
    display: "flex",
    alignItems: "center",
  },
  paddingLeft5: {
    paddingLeft: "5px",
  },
  textSize: {
    fontSize: "0.5rem",
  },
};
//------------コンポーネントのスタイル------------

export default function SelectedBranch({ defaultValue }) {
  const [branch, setBranch] = useState(defaultValue || "");
  const handleChangeBranch = (event) => {
    setBranch(event.target.value);
  };

  return (
    <FormControl sx={{ minWidth: 120 }} size="small">
      <Select
        labelId="branch"
        id="branch"
        value={branch}
        css={styles.selectBox}
        sx={{ fontSize: "0.5rem" }}
        displayEmpty
        onChange={handleChangeBranch}
        renderValue={(selected) => {
          if (!selected || selected.length === 0) {
            return <em css={styles.textSize}>----------------</em>;
          }
          return selected;
        }}
      >
        <MenuItem value="" sx={{ fontSize: "0.5rem" }}>
          <em css={styles.textSize}>----------------</em>
        </MenuItem>
        {Const.BRANCH_OPTIONS.map((option) => {
          return (
            <MenuItem value={option} sx={{ fontSize: "0.5rem" }}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
