/** @jsxImportSource @emotion/react */
import React from 'react';
import { styled } from '@mui/material';
import { useForm, Controller } from "react-hook-form";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';


function InputNameKana(props) {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      checkBox: false,
      textBox: "",
      pullDown: "",
    },
  });

  const styles = {
    
    textarea:{
      width: '90%',
       mr: 5,
      '& .MuiInputBase-input':{
        fontSize: props.textSize,
      },
      '& .MuiInputBase-root':{
        // padding: '3px 0.5rem',
      },
      '& .MuiInputLabel-root':{
        fontSize: props.textSize,
      }
    },
  }
  return (
    <>
      <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Controller
          control={control}
          name="sei_kana"
          render={({ field }) => (
            <TextField
              {...field}
              label="セイ"
              id="sei_kana"
              size="small"
              disabled={props.disabled}
              css={styles.textarea}
              defaultValue={props.sei_kana}
            />
          )}
        />
      </Grid>
      <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Controller
          control={control}
          name="mei_kana"
          render={({ field }) => (
            <TextField
              {...field}
              label="メイ"
              id="mei_kana"
              size="small"
              disabled={props.disabled}
              css={styles.textarea}
              defaultValue={props.mei_kana}
            />
          )}
        />
      </Grid>
    </>
  )
}


export default InputNameKana;