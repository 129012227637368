function DummyEntryDataOs2() {
  const tableDataListsOS2 = [
    {
      id: "os-2-1",
      thead: "希望の雇用形態",
      tdata: ["直接雇用(正社員)"],
      history: true,
      historyData: [
        {
          editDate: "2022/12/15 10:30",
          editor: "応募　花子(応募者)",
          mode: "応募者保存",
          changes: "直接雇用(正社員)",
        },
        {
          editDate: "2022/12/15 10:30",
          editor: "応募　花子(応募者)",
          mode: "媒体データ取込",
          changes: "派遣",
        },
      ],
    },
    {
      id: "os-2-2",
      thead: "希望勤務期間",
      tdata: ["中期(3か月以上～6か月未満)", "長期(6か月以上)"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-2-3",
      thead: "希望条件",
      tdata: [""],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-2-4",
      thead: "希望職種",
      tdata: [""],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-2-5",
      thead: "【希望職種】事務職",
      tdata: ["一般事務", "金融事務（銀行・証券）", "総務・人事"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-2-6",
      thead: "【希望職種】オペレーター職",
      tdata: [
        "テレマーケティング（受信）",
        "カスタマーサポート（受電1次・受電2次）",
        "カスタマーサポート（メール対応）",
      ],
      history: false,
      historyData: [
        {
          editDate: "2022/12/15 10:30",
          editor: "応募　花子(応募者)",
          changes: "090-1234-5678",
        },
        {
          editDate: "2022/09/26 14:56",
          editor: "営業　太郎",
          changes: "090-7654-3210",
        },
        {
          editDate: "2022/07/04 09:05",
          editor: "媒体データ取込",
          changes: "090-1122-3344",
        },
      ],
    },
    {
      id: "os-2-7",
      thead: "【希望職種】販売・接客・営業職・その他",
      tdata: [
        "販売（アパレル・コスメ）",
        "営業（企画営業）",
        "営業（ルート営業）",
      ],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-2-8",
      thead: "就業開始可能日",
      tdata: ["2023/04/01"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-2-9",
      thead: "希望勤務可能日",
      tdata: ["月", "火", "水", "木", "金"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-2-10",
      thead: "希望勤務日数／週",
      tdata: ["5"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-2-11",
      thead: "希望勤務形態",
      tdata: ["フルタイム(時間固定・平日)", "シフト制(時間固定)"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-2-12",
      thead: "希望勤務時間①",
      tdata: ["08:00～17:00"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-2-13",
      thead: "希望勤務時間②",
      tdata: ["09:00～18:00"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-2-14",
      thead: "希望勤務時間③",
      tdata: ["10:00～19:00"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-2-15",
      thead: "希望勤務時間④",
      tdata: ["11:00～20:00"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-2-16",
      thead: "残業可否",
      tdata: ["場合により可"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-2-17",
      thead: "夜勤可否",
      tdata: ["不可"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-2-18",
      thead: "希望条件(時給)",
      tdata: ["1,500円～"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-2-19",
      thead: "希望条件(月給)",
      tdata: ["30万円"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-2-20",
      thead: "希望条件(勤務地)",
      tdata: ["首都圏"],
      history: false,
      historyData: [{}],
    },
  ];
  return tableDataListsOS2;
}

export default DummyEntryDataOs2;
