/** @jsxImportSource @emotion/react */
import React from "react";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Const from '../../../libs/Const';


//------------コンポーネントのスタイル------------
const styles = {
  selectBox:{
    width: '100%',
    textAlign: 'left',
    fontSize: '0.5rem',
    '& .MuiInputBase-input':{
      fontSize: '0.5rem',
      color: '#0047A4',
    },
    '& .MuiInputBase-root':{
      // padding: '3px 0.5rem',
      fontSize: '0.5rem',
    },
    '& .MuiFormLabel-root':{
      fontSize: '0.5rem',
    },
    '& .MuiInputLabel-root':{
      fontSize: '0.5rem',
    },
    '& .MuiSelect-root':{
      fontSize: '0.5rem'
    }
  },
  inputItemForm:{
    display:'flex',
    alignItems: 'center',
  },
  paddingLeft5:{
    paddingLeft: '5px',
  },
  textSize:{
    fontSize: '0.5rem'
  }
}
//------------コンポーネントのスタイル------------

export default function SelectedAppliMedia({fieldSize, defaultValue}) {
  const [appliMedia, setAppliMedia] = React.useState('');
  const handleChangeAppliMedia = (event) => {
    setAppliMedia(event.target.value);
  };
  return (
    <FormControl sx={{ minWidth: fieldSize }} size="small">
      <InputLabel id="appliMedia" css={styles.textSize}>応募媒体</InputLabel>
      <Select
        labelId="appliMedia"
        id="appliMedia"
        value={defaultValue}
        label="応募媒体"
        css={styles.selectBox}
        displayEmpty
        // defaultValue={value}
        onChange={handleChangeAppliMedia}
        // renderValue={(selected) => {
        //   if (selected.length === 0) {
        //     return <em css={styles.textSize}>----------------</em>;
        //   }
        //   return selected;
        // }}
      >
        {/* <MenuItem value="" sx={{ fontSize: '0.5rem'  }}><em css={styles.textSize}>----------------</em></MenuItem> */}
        {Const.APPLI_MEDIA_OPTIONS.map((option) => {
          return (
            <MenuItem value={option} sx={{ fontSize: '0.5rem' }}>{option}</MenuItem>
          )})
        }
      </Select>
    </FormControl>
  )
}


