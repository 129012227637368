/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from "react";
import { createContext } from "react";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";

import {
  Box,
  Paper,
  Typography,
  Grid,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";

import TextFieldNameKanji from "../forms/TextFieldNameKanji";
import TextFieldNameKana from "../forms/TextFieldNameKana";
import RadioSelectGender from "../forms/RadioSelectGender";
import TextFieldInputBirthday from "../forms/TextFieldInputBirthday";
import TextFieldInputAge from "../forms/TextFieldInputAge";

import StyledMuiPaper from "../../parts/surface/StyledMuiPaper";
import InputBirthdayAndAge from "../../parts/input/InputBirthdayAndAge";
import InputAddress from "../../parts/input/InputAddress";
import SelectedTransportation from "../../parts/select/SelectedTransportation";
import InputPhoneNumber from "../forms/InputPhoneNumber";

import logoImg from "../../../assets/images/neocareer-logo.svg";

export const UserCount = createContext();

const calculateAge = (year, month, day) => {
  const birthday = new Date(year, month - 1, day);
  const today = new Date();
  const age = today.getFullYear() - birthday.getFullYear();
  const m = today.getMonth() - birthday.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthday.getDate())) {
    return age - 1;
  }
  return age;
};

function WebEntryBasicInfo(props) {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      checkBox: false,
      textBox: "",
      pullDown: "",
    },
  });
  const onSubmit = () => {
    props.handleNext();
  };

  const handleClickBtn = () => {
    window.scroll({ top: 0 });
  };
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [day, setDay] = useState("");
  const [age, setAge] = useState("");

  useEffect(() => {
    if (year && month && day) {
      setAge(calculateAge(year, month, day));
    } else {
      setAge("");
    }
  }, [year, month, day]);

  const handleBirthdayChange = (year, month, day) => {
    setYear(year);
    setMonth(month);
    setDay(day);
  };

  return (
    <>
      <StyledMuiPaper css={styles.responsiveStyle}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mb: 3 }}>
              <Typography
                component="h1"
                sx={{ fontSize: "1.5rem", fontWeight: "bold" }}
              >
                Webエントリーシート
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mb: 3 }}>
              <img src={logoImg} alt="ふぁん君ロゴ" css={styles.logoImage} />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mb: 3 }}>
              <h2 className="contentBlockTitle">基本情報</h2>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div css={styles.inputItem}>
                <div css={styles.inputItemHeader}>名前（漢字）</div>
                <div className="requiredTag">必須</div>
              </div>
            </Grid>
            <TextFieldNameKanji />
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div css={styles.inputItem}>
                <div css={styles.inputItemHeader}>名前（カタカナ）</div>
                <div className="requiredTag">必須</div>
              </div>
            </Grid>
            <TextFieldNameKana />
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div css={styles.inputItem}>
                <div css={styles.inputItemHeader}>性別</div>
                <div className="requiredTag">必須</div>
              </div>
            </Grid>
            <RadioSelectGender />
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div css={styles.inputItem}>
                <div css={styles.inputItemHeader}>生年月日</div>
                <div className="requiredTag">必須</div>
              </div>
            </Grid>
            <TextFieldInputBirthday onBirthdayChange={handleBirthdayChange} />
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div css={styles.inputItem}>
                <div css={styles.inputItemHeader}>年齢</div>
                <div className="requiredTag">必須</div>
              </div>
            </Grid>
            <TextFieldInputAge age={age} />
            <InputAddress />
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div css={styles.inputItem}>
                <div css={styles.inputItemHeader}>最寄り駅①</div>
                <div className="optionalTag">任意</div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              css={styles.inputItemForm}
            >
              <span>沿線&emsp;</span>
              <TextField
                label="沿線"
                id="railway1"
                size="small"
                sx={{ width: "70%", mr: 5 }}
                placeholder="JR山手線"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ mb: 2 }}
              css={styles.inputItemForm}
            >
              <span>駅名&emsp;</span>
              <TextField
                label="駅名"
                id="station1"
                size="small"
                sx={{ width: "60%", mr: 1 }}
                placeholder="新宿"
              />
              <span>駅&emsp;</span>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div css={styles.inputItem}>
                <div css={styles.inputItemHeader}>最寄り駅①からの交通手段</div>
                <div className="optionalTag">任意</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mb: 2 }}>
              <SelectedTransportation />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div css={styles.inputItem}>
                <div css={styles.inputItemHeader}>最寄り駅①からの所要時間</div>
                <div className="optionalTag">任意</div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              css={styles.inputItemForm}
              sx={{ mb: 2 }}
            >
              <TextField
                id="howlongtime1"
                size="small"
                sx={{ width: "30%", mr: 1 }}
              />
              <span>分&emsp;</span>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div css={styles.inputItem}>
                <div css={styles.inputItemHeader}>最寄り駅②</div>
                <div className="optionalTag">任意</div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              css={styles.inputItemForm}
            >
              <span>沿線&emsp;</span>
              <TextField
                label="沿線"
                id="railway2"
                size="small"
                sx={{ width: "70%", mr: 5 }}
                placeholder="JR山手線"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ mb: 2 }}
              css={styles.inputItemForm}
            >
              <span>駅名&emsp;</span>
              <TextField
                label="駅名"
                id="station2"
                size="small"
                sx={{ width: "60%", mr: 1 }}
                placeholder="新宿"
              />
              <span>駅&emsp;</span>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div css={styles.inputItem}>
                <div css={styles.inputItemHeader}>最寄り駅②からの交通手段</div>
                <div className="optionalTag">任意</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mb: 2 }}>
              <SelectedTransportation />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div css={styles.inputItem}>
                <div css={styles.inputItemHeader}>最寄り駅②からの所要時間</div>
                <div className="optionalTag">任意</div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              css={styles.inputItemForm}
              sx={{ mb: 2 }}
            >
              <TextField
                id="howlongtime1"
                size="small"
                sx={{ width: "30%", mr: 1 }}
              />
              <span>分&emsp;</span>
            </Grid>

            <InputPhoneNumber />
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div css={styles.inputItem}>
                <div css={styles.inputItemHeader}>メールアドレス①</div>
                <div className="requiredTag">必須</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mb: 2 }}>
              <TextField
                label=""
                id="mail_address1"
                size="small"
                sx={{ width: "90%", mr: 5 }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div css={styles.inputItem}>
                <div css={styles.inputItemHeader}>メールアドレス②</div>
                <div className="optionalTag">任意</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mb: 2 }}>
              <TextField
                label=""
                id="mail_address1"
                size="small"
                sx={{ width: "90%", mr: 5 }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div css={styles.inputItem}>
                <div css={styles.inputItemHeader}>在留カード情報</div>
                <div className="requiredTag">必須</div>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ mb: 5 }}>
              <TextField
                label=""
                id="resident_info"
                size="small"
                sx={{ width: "50%", mr: 5 }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            sx={{ px: 5, pt: 5, display: "flex", justifyContent: "center" }}
          >
            <div css={styles.btnArea}>
              <Button
                variant="contained"
                color="inherit"
                sx={{ mr: 5 }}
                onClick={handleClickBtn}
              >
                戻る
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ ml: 5 }}
                onClick={handleClickBtn}
              >
                <Link to="/web_entry/nc_2" css={styles.link}>
                  次へ
                </Link>
              </Button>
            </div>
          </Grid>
        </form>
      </StyledMuiPaper>
    </>
  );
}

const styles = {
  responsiveStyle: {
    width: "100%",
    padding: "1rem",
    "@media (min-width:700px)": {
      width: "60%",
      padding: "1rem 5rem",
    },
    overflow: "hidden",
    textAlign: "left",
    marginTop: "3rem",
  },
  inputItem: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (min-width:700px)": {
      justifyContent: "flex-start",
      alignItems: "center",
    },
  },
  inputItemHeader: {
    fontWeight: "bold",
    fontSize: "1rem",
    textAlign: "left",
  },
  inputItemForm: {
    display: "flex",
    alignItems: "center",
  },
  btnArea: {
    display: "flex",
    justifyContent: "center",
  },
  link: {
    color: "#FFFFFF",
  },
  logoImage: {
    display: "inline-block",
    height: "50px",
  },
};
export default WebEntryBasicInfo;
