
function DummyEntryDataOs3(){

  const tableDataListsOS3 = [
    {
      id: 'os-3-1',
      thead: '【スキルチェック】Word',
      tdata: [''],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-2',
      thead: '文字入力',
      tdata: [
        '実務基本操作/受験のみ',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-3',
      thead: '文章作成',
      tdata: [
        '実務基本操作/受験のみ',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-4',
      thead: '編集・作表',
      tdata: [
        '実務基本操作/受験のみ',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-5',
      thead: '書式設定の応用',
      tdata: [
        '実務基本操作/受験のみ',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-6',
      thead: '図・表挿入',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-7',
      thead: '差込印刷',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-8',
      thead: 'マクロの作成',
      tdata: [
        '知識レベル',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-9',
      thead: '【スキルチェック】Excel',
      tdata: [

      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-10',
      thead: 'データ入力',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-11',
      thead: '表作成',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-12',
      thead: '表計算・四則演算',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-13',
      thead: 'グラフ作成',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-14',
      thead: 'セルの書式設定',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-15',
      thead: 'セルの書式設定・表示形式',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-16',
      thead: 'セルの書式設定・列幅・行高変更',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-17',
      thead: 'セルの書式設定・網掛け',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-18',
      thead: 'セルの書式設定・フォントスタイル変更',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-19',
      thead: 'セルの書式設定・配置設定',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-20',
      thead: 'セルの書式設定・小数点表示',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-21',
      thead: 'セルの書式設定・セル結合',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-22',
      thead: '関数/数式・SUM',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-23',
      thead: '関数/数式・AVERAGE',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-24',
      thead: '関数/数式・ROUND',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-25',
      thead: '関数/数式・COUNTIF',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-26',
      thead: '関数/数式・SUMIF',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-27',
      thead: '関数/数式・IF',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-28',
      thead: '関数/数式・VLOOKUP',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-29',
      thead: '関数/数式・ピポットテーブル',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-30',
      thead: '関数/数式・複数条件関数（OR,AND）',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-31',
      thead: 'マクロ作成',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-32',
      thead: 'VBAオブジェクト',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-33',
      thead: 'オブジェクト作成・編集',
      tdata: [
        '実務基本操作/受験のみ',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-34',
      thead: '新規スライド作成',
      tdata: [
        '実務基本操作/受験のみ',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-35',
      thead: 'スライドショー作成',
      tdata: [
        '実務基本操作/受験のみ',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-36',
      thead: 'マスタアニメーション作成',
      tdata: [
        '実務基本操作/受験のみ',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-37',
      thead: 'プレゼンテーション作成',
      tdata: [
        '実務基本操作/受験のみ',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-38',
      thead: '【スキルチェック】Access',
      tdata: [
        
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-39',
      thead: 'テーブル・データ入力',
      tdata: [
        '該当なし',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-40',
      thead: 'テーブル・インポート・エクスポート',
      tdata: [
        '該当なし',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-41',
      thead: 'テーブル・データ型 設定',
      tdata: [
      '該当なし',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-42',
      thead: 'テーブル・リレーションシップ設定',
      tdata: [
      '該当なし',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-43',
      thead: 'フォームウィザード',
      tdata: [
      '該当なし',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-44',
      thead: 'サブフォームの作成',
      tdata: [
      '該当なし',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-45',
      thead: 'クエリ・並べ替えの設定',
      tdata: [
      '該当なし',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-46',
      thead: 'クエリ・パラメータクエリ作成',
      tdata: [
      '該当なし',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-47',
      thead: 'クエリ・テーブル結合・データ抽出',
      tdata: [
      '該当なし',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-48',
      thead: 'クエリ・更新・削除・追加',
      tdata: [
        '該当なし',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-49',
      thead: 'レポート・ラベル用レポート作成',
      tdata: [
        '該当なし',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-50',
      thead: 'レポート・改ページ設定',
      tdata: [
      '該当なし',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-51',
      thead: 'レポート・印刷領域設定',
      tdata: [
      '該当なし',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-52',
      thead: 'レポート・マクロ設定',
      tdata: [
      '該当なし',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-53',
      thead: '【スキルチェック】OS環境',
      tdata: [

      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-54',
      thead: 'Windows　XP',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-55',
      thead: 'Windows　7',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-56',
      thead: 'Windows　8',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-57',
      thead: 'Mac',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-58',
      thead: 'その他',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-59',
      thead: 'その他の内容',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-60',
      thead: '【スキルチェック】ソフトウェアツール',
      tdata: [
        
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-61',
      thead: 'メーラー・Microsoft Outlook',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-62',
      thead: 'メーラー・Windows Live Mail',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-63',
      thead: 'メーラー・その他',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-64',
      thead: 'メーラー・その他・ソフト名',
      tdata: [

      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-65',
      thead: 'ASP・SAP',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-66',
      thead: 'ASP・ファイルメーカー',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-67',
      thead: 'ASP・Lotus Notes',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-68',
      thead: '【スキルチェック】プログラミング言語',
      tdata: [

      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-69',
      thead: 'HTML',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-70',
      thead: 'CSS',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-71',
      thead: 'javascript（Jquery）',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-72',
      thead: 'Anguler',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-73',
      thead: 'Ajax',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-74',
      thead: 'Java',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-75',
      thead: 'php',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-76',
      thead: 'ruby（on rails)',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-77',
      thead: 'C、C#、C++',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-78',
      thead: 'shell',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-79',
      thead: 'SQLServer',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-80',
      thead: 'MySQL',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-81',
      thead: 'PostgreSQL',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-82',
      thead: 'Oracle',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-83',
      thead: 'VB.net',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-84',
      thead: 'C#.net',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-85',
      thead: 'ObjectiveC',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-86',
      thead: 'SWIFT',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-87',
      thead: 'SQLite',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-88',
      thead: 'Apache、tomcat',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-89',
      thead: 'nginx',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-90',
      thead: 'WebLogic',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-91',
      thead: 'OracleServer',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-92',
      thead: 'その他',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-93',
      thead: 'その他・言語名',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },

    {
      id: 'os-3-94',
      thead: '【スキルチェック】制作ソフト',
      tdata: [

      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-95',
      thead: 'Illustrator',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-96',
      thead: 'Illustrator・Ver',
      tdata: [
        '27.4',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-97',
      thead: 'Photoshop',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-98',
      thead: 'Photoshop・Ver',
      tdata: [
        '24.4.1'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-99',
      thead: 'Dreamweaver',
      tdata: [
        '実務基本操作/受験のみ',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-100',
      thead: 'Fireworks',
      tdata: [
        '知識レベル',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-101',
      thead: 'Adobe Flash',
      tdata: [
        '知識レベル',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-102',
      thead: 'InDesign',
      tdata: [
        '知識レベル',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-103',
      thead: 'SAI',
      tdata: [
        '該当なし',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-104',
      thead: 'WordPress',
      tdata: [
        '実務応用操作/取得',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-105',
      thead: 'Maya',
      tdata: [
        '該当なし',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-106',
      thead: '3DS MAX',
      tdata: [
        '該当なし',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-107',
      thead: 'After Effects',
      tdata: [
        '該当なし',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-108',
      thead: 'その他/CMSソフト名',
      tdata: [
        '実務基本操作/受験のみ',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    
    {
      id: 'os-3-109',
      thead: 'その他/CMSソフト名・ソフト名',
      tdata: [
        'Movable Type'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-110',
      thead: '【スキルチェック】CAD',
      tdata: [
        
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-111',
      thead: 'CADソフト',
      tdata: [
        'プライベート利用中/勉強中',
      ],
      history : false,
      historyData: [
        {},
      ]
    },
    {
      id: 'os-3-112',
      thead: 'CADソフト・ソフト名',
      tdata: [
        'AutoCAD'
      ],
      history : false,
      historyData: [
        {},
      ]
    },
  ];
  return tableDataListsOS3;

}

export default DummyEntryDataOs3;