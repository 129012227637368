/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from "react";

import { Link, useLocation } from "react-router-dom";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Button,
} from "@mui/material";

import StyledMuiPaper from "../../parts/surface/StyledMuiPaper";
import MuiChipNew from "../../parts/Chip/MuiChipNew";
import MuiChipExisting from "../../parts/Chip/MuiChipExisting";
import MuiChipProhibition from "../../parts/Chip/MuiChipProhibition";

const styles = {
  header: {
    fontWeight: "bold",
    color: "#0047a4",
    textAlign: "left",
    paddingLeft: "1rem",
    fontSize: "0.5rem",
    padding: "0.5rem",
  },
  data: {
    color: "#0047a4",
    fontSize: "0.5rem",
    padding: "0.5rem",
  },
  ruby: {
    fontSize: "0.5rem",
  },
  fontSmall: {
    fontSize: "0.8rem",
  },
  link: {
    color: "#FFFFFF",
  },
  pagenation: {
    "& .MuiTablePagination-selectLabel": {
      fontSize: "0.5rem",
    },
    "& .MuiTablePagination-displayedRows": {
      fontSize: "0.5rem",
    },
    "& .MuiTablePagination-actions": {
      fontSize: "0.5rem",
    },
    fontSize: "0.5rem",
    "& .MuiTablePagination-toolbar": {
      minHeight: "40px",
    },
  },
  btnBlock: {
    display: "flex",
    justifyContent: "flex-end",
  },
  btnSize: {
    transform: "scale(0.75)",
    transformOrigin: "center left",
  },
  staffCodeRight: {
    paddingLeft: "0.5rem",
    // textAlign: "right"
  },
};

export default function ApplicantsList(props) {
  const { rows } = props;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);

  // useLocationを使う場合
  const location = useLocation();
  const pathArray = location.pathname.split("/");
  const lastPath = pathArray[pathArray.length - 1];
  console.log(lastPath); // これがURLの末尾のパスです。

  //ページネーション
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //表示件数切替
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const element = useRef(null);
  const [scrollDistance, setScrollDistance] = useState(0);

  useEffect(() => {
    const handleScrollCalcDistance = () => {
      const distanceFromTop = element.current;
      const { left, top, right, bottom } =
        distanceFromTop.getBoundingClientRect();

      setScrollDistance({ left, top, right, bottom });
    };

    window.addEventListener("scroll", handleScrollCalcDistance);

    return () => {
      window.removeEventListener("scroll", handleScrollCalcDistance);
    };
  }, []);

  const fixedStyles = {
    position: "initial",
    top: "initial",
  };

  const [dynamicStyles, setDynamicStyles] = useState(fixedStyles);

  useEffect(() => {
    const handleScrollChangeStyle = () => {
      if (scrollDistance.top <= 64) {
        setDynamicStyles({
          ...styles,
          position: "fixed",
          top: "2.5rem",
          left: "0",
          right: "0",
          margin: "0 24px",
          zIndex: "20",
          padding: "0",
        });
      } else {
        setDynamicStyles(fixedStyles);
      }
    };

    window.addEventListener("scroll", handleScrollChangeStyle);

    return () => {
      window.removeEventListener("scroll", handleScrollChangeStyle);
    };
  }, [scrollDistance.top]);

  const selectChipStyle = (newOrExi) => {
    if (newOrExi === "新規") {
      return <MuiChipNew />;
    } else if (newOrExi === "反社") {
      return <MuiChipProhibition />;
    } else {
      return <MuiChipExisting />;
    }
  };

  return (
    <StyledMuiPaper sx={{ width: "100%", overflow: "auto", mb: 3 }}>
      <Box css={styles.btnBlock}>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          css={styles.btnSize}
          sx={{ width: "150px" }}
        >
          応募者データCSV
        </Button>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          css={styles.btnSize}
          sx={{ width: "150px" }}
        >
          応募者対応履歴CSV
        </Button>
      </Box>
      <TableContainer sx={{}}>
        <Table ref={element} stickyHeader aria-label="determination list table">
          <TableHead style={dynamicStyles} sx={{ background: "#FFFFFF" }}>
            <TableCell
              align="center"
              css={styles.header}
              sx={{ width: "60px" }}
            >
              &nbsp;新規
              <br />
              &nbsp;&#047;既存
            </TableCell>
            <TableCell
              align="center"
              css={styles.header}
              sx={{ width: "70px" }}
            >
              応募日時
            </TableCell>
            <TableCell
              align="center"
              css={styles.header}
              sx={{ width: "106px" }}
            >
              応募者コード
              <br />
              &#047;CAERUS
            </TableCell>
            <TableCell
              align="center"
              css={styles.header}
              sx={{ width: "142px" }}
            >
              名前
            </TableCell>
            <TableCell
              align="center"
              css={styles.header}
              sx={{ width: "130px" }}
            >
              生年月日
              <br />
              &#047;年齢
            </TableCell>
            <TableCell
              align="center"
              css={styles.header}
              sx={{ width: "52px" }}
            >
              性別
            </TableCell>
            <TableCell
              align="center"
              css={styles.header}
              sx={{ width: "91px" }}
            >
              ステータス
            </TableCell>
            <TableCell
              align="center"
              css={styles.header}
              sx={{ width: "129px" }}
            >
              住所
            </TableCell>
            <TableCell
              align="center"
              css={styles.header}
              sx={{ width: "142px" }}
            >
              応募支店（担当）
            </TableCell>
            <TableCell
              align="center"
              css={styles.header}
              sx={{ width: "117px" }}
            >
              電話番号
            </TableCell>
            <TableCell
              align="center"
              css={styles.header}
              sx={{ width: "222px" }}
            >
              メールアドレス
            </TableCell>
            <TableCell
              align="center"
              css={styles.header}
              sx={{ width: "80px" }}
            >
              詳細
            </TableCell>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, k) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={k}>
                    <TableCell css={styles.data} sx={{ width: "4%" }}>
                      {selectChipStyle(row.newOrExi)}
                    </TableCell>
                    <TableCell css={styles.data} sx={{ width: "5%" }}>
                      {row.appli_date}
                    </TableCell>
                    <TableCell css={styles.data} sx={{ width: "8%" }}>
                      <p>{row.appli_id}</p>
                      <a
                        href="https://www.neo-career.co.jp/"
                        target="_blank"
                        style={styles.staffCodeRight}
                      >
                        {row.staffcode}
                      </a>
                    </TableCell>
                    <TableCell css={styles.data} sx={{ width: "11%" }}>
                      <p css={styles.ruby}>{row.name_kana}</p>
                      <p>{row.name_kanji}</p>
                    </TableCell>
                    <TableCell css={styles.data} sx={{ width: "10%" }}>
                      <p>{row.birthday}</p>
                      <p>{row.age}</p>
                    </TableCell>
                    <TableCell css={styles.data} sx={{ width: "4%" }}>
                      {row.gender}
                    </TableCell>
                    <TableCell css={styles.data} sx={{ width: "7%" }}>
                      {row.status}
                    </TableCell>
                    <TableCell css={styles.data} sx={{ width: "10%" }}>
                      {row.prefecture}
                    </TableCell>
                    <TableCell css={styles.data} sx={{ width: "11%" }}>
                      {row.branch}
                    </TableCell>
                    <TableCell css={styles.data} sx={{ width: "9%" }}>
                      <p>{row.tel1}</p>
                      <p>{row.tel2}</p>
                    </TableCell>
                    <TableCell css={styles.data} sx={{ width: "17%" }}>
                      <p>{row.mail_address1}</p>
                      <p>{row.mail_address2}</p>
                    </TableCell>
                    <TableCell
                      css={styles.data}
                      align="center"
                      sx={{ width: "4%" }}
                    >
                      <Button
                        component={Link}
                        to={`/oubo_detail/` + row.appli_id}
                        variant="contained"
                        size="small"
                        css={styles.btnSize}
                        align="center"
                      >
                        詳細
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        css={styles.pagenation}
      />
    </StyledMuiPaper>
  );
}
