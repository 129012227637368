/** @jsxImportSource @emotion/react */
import { Box, Grid, Paper, Typography, Button, Alert } from "@mui/material";
import SeacrhFormsThree from "../feature/SearchForms/SeacrhFormsThree";
import StyledMuiAppBar from "../parts/AppBar/StyledMuiAppBar";

const determineNewOrExisting = {
  title: "新・既 判定",
  buttonBlocks1: [{ label: "未判定", count: 0 }],
  buttonBlocks2: [],
  buttonBlocks3: [],
  buttonBlocks4: [],
  buttonBlocks5: [],
};
const newApplications = {
  title: "新規応募",
  buttonBlocks1: [{ label: "支店未設定", count: 0 }],
  buttonBlocks2: [
    { label: "コール（未接触）0回", count: 0 },
    { label: "コール（未接触）1回", count: 3 },
    { label: "コール（未接触）2回", count: 0 },
  ],
  buttonBlocks3: [
    { label: "未対応", count: 0 },
    { label: "呼び込み中（登録会予約あり）", count: 0 },
    { label: "呼び込み中（登録会予約なし）", count: 4 },
  ],
  buttonBlocks4: [
    { label: "在留資格未確認", count: 1 },
    { label: "本人確認書類未対応", count: 0 },
  ],
  buttonBlocks5: [{ label: "自動クローズ対象（本日OFF）", count: 17 }],
};

const existingApplications = {
  title: "既存応募",
  buttonBlocks1: [{ label: "支店未設定", count: 5 }],
  buttonBlocks2: [
    { label: "コール（未接触）0回", count: 0 },
    { label: "コール（未接触）1回", count: 7 },
    { label: "コール（未接触）2回", count: 2 },
  ],
  buttonBlocks3: [
    { label: "未対応", count: 0 },
    { label: "呼び込み中", count: 0 },
  ],
  buttonBlocks4: [],
  buttonBlocks5: [{ label: "自動クローズ対象（本日OFF）", count: 9 }],
};

export default function TopPage() {
  return (
    <>
      <StyledMuiAppBar headerTitle="TOP" />
      <Box sx={{ mt: 6, mb: 3, flexGrow: 1, mx: 14 }}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} xl={12} sx={{ p: 1 }}>
            <SeacrhFormsThree />
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ alignItems: "stretch", alignSelf: "stretch", height: "75vh" }}
        >
          <Grid item xs={4} sm={4} md={4} xl={4} sx={{ px: 1, flexGrow: 1 }}>
            <TopContent data={determineNewOrExisting} />
          </Grid>
          <Grid item xs={4} sm={4} md={4} xl={4} sx={{ px: 1, flexGrow: 1 }}>
            <TopContent data={newApplications} />
          </Grid>
          <Grid item xs={4} sm={4} md={4} xl={4} sx={{ px: 1, flexGrow: 1 }}>
            <TopContent data={existingApplications} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

function TopContent(props) {
  const { data } = props;
  return (
    <Paper sx={{ px: 2, py: 1, height: "100%", position: "relative" }}>
      <Typography css={styles.titleStyle}>{data.title}</Typography>
      <Box>
        <Box sx={{ height: "48px" }}>
          {data.buttonBlocks1.map((button, i) => {
            return <CutomAlert label={button.label} count={button.count} />;
          })}
        </Box>
        <Box sx={{ height: "128px" }}>
          {data.buttonBlocks2.map((button, i) => {
            return <CutomAlert label={button.label} count={button.count} />;
          })}
        </Box>
        <Box sx={{ height: "128px" }}>
          {data.buttonBlocks3.map((button, i) => {
            return <CutomAlert label={button.label} count={button.count} />;
          })}
        </Box>
        <Box sx={{ height: "88px" }}>
          {data.buttonBlocks4.map((button, i) => {
            return <CutomAlert label={button.label} count={button.count} />;
          })}
        </Box>
        <Box sx={{ height: "48px" }}>
          {data.buttonBlocks5.map((button, i) => {
            return <CutomAlert label={button.label} count={button.count} />;
          })}
        </Box>
      </Box>
    </Paper>
  );
}

function CutomAlert(props) {
  const { label, count } = props;
  return (
    <Alert
      // variant={count === 0 ? "standard" : "filled"}
      variant="standard"
      severity={count === 0 ? "success" : "error"}
      color={count === 0 ? "info" : "error"}
      action={
        <Typography component="span" sx={{ fontSize: "1rem" }}>
          {count}
          <Typography component="span" sx={{ fontSize: "0.5rem" }}>
            件
          </Typography>
        </Typography>
      }
      css={
        count === 0
          ? [styles.alertStyle, styles.alertColorBlue]
          : [styles.alertStyle, styles.alertColorRed]
      }
    >
      {label}
    </Alert>
  );
}

const styles = {
  titleStyle: {
    fontSize: "0.8rem",
    fontWeight: "bold",
    color: "#0047A4",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    marginBottom: "0.5rem",
  },
  alertStyle: {
    display: "flex",
    alignItems: "center",
    fontSize: "0.7rem",
    paddingTop: "0",
    paddingBottom: "0",
    marginBottom: "4px",
    "& .MuiAlert-action": {
      padding: "0",
      marginRight: "0",
    },
  },

  //==================パターン2==================
  alertColorBlue: {
    backgroundColor: "rgb(229, 246, 253, 0.5)",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#CAEDFC",
    },
  },
  alertColorRed: {
    backgroundColor: "#FC4C4C",
    color: "#FFF",
    "& .MuiSvgIcon-root": {
      color: "#FFF",
    },
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#FC1919",
    },
  },
};
