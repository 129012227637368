/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { styled } from '@mui/material';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Const from '../../../libs/Const';

function SelectBoxPrefecture({ address, textSize, disabled }) {
  //------------コンポーネントのスタイル------------
  const styles = {
    selectBox:{
      width: '90%',

      '& .MuiInputBase-input':{
        fontSize: textSize,
      },
      '& .MuiInputBase-root':{
        // padding: '3px 0.5rem',
        fontSize: textSize,
      },
      '& .MuiFormLabel-root':{
        fontSize: textSize,
      },
      '& .MuiInputLabel-root':{
        fontSize: textSize,
      },
      '& .MuiSelect-root':{
        fontSize: textSize
      }
    },
    inputItemForm:{
      display:'flex',
      alignItems: 'center',
    },
    paddingLeft5:{
      paddingLeft: '5px',
    }
  }
  //------------コンポーネントのスタイル------------

  const [prefecture, setPrefecture] = React.useState('');
  const handleChangePrefecture = (event) => {
    setPrefecture(event.target.value);
  };

  return (
    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
      <FormControl sx={{ mr: 2, minWidth: 120 }} size="small">
        <InputLabel id="prefecture" sx={{ fontSize: textSize }}>都道府県</InputLabel>
        <Select
          labelId="prefecture"
          id="prefecture"
          value={address.address1}
          label="都道府県"
          disabled={disabled}
          onChange={handleChangePrefecture}
          css={styles.selectBox}
          sx={{ fontSize: textSize }}
        >
          {Const.PREF_OPTIONS.map((option) => {
              return (
                <MenuItem value={option} sx={{ fontSize: textSize }}>{option}</MenuItem>
              )})
          }
        </Select>
      </FormControl>
    </Grid>
  );
}
export default SelectBoxPrefecture;