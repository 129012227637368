/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import StyledMuiTextField from "../../parts/textField/StyledMuiTextField";
import SelectedBranch from "../select/SelectedBranch";
import MuiSelectBox from "../../parts/select/MuiSelectBox";

export default function RadioButtonsProgresses({ defaultValue, editFlg }) {
  // const [disabled, setDisabled] = React.useState(true);
  const [selectedValue, setSelectedValue] = useState(defaultValue || "");
  const [isRefusalDisabled, setIsRefusalDisabled] = useState(true);
  const [isNotSubjectDisabled, setIsNotSubjectDisabled] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    setIsRefusalDisabled(event.target.value !== "refusal");
    setIsNotSubjectDisabled(event.target.value !== "notSubject");
    setShowDropdown(event.target.value === "registrationCompleted");
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
      <div>
        <FormControl sx={{ fontSize: "0.5rem" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="progresses"
            value={selectedValue}
            onChange={handleChange}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              css={styles.alignItemC}
            >
              <FormControlLabel
                value="未対応"
                control={
                  <Radio
                    size="small"
                    css={styles.inputRadio}
                    disabled={editFlg}
                  />
                }
                label="未対応"
                css={styles.radioLabel}
              />
              <FormControlLabel
                value="呼び込み中"
                control={
                  <Radio
                    size="small"
                    css={styles.inputRadio}
                    disabled={editFlg}
                  />
                }
                label="呼び込み中"
                css={styles.radioLabel}
              />
              <FormControlLabel
                value="仮登録"
                control={
                  <Radio
                    size="small"
                    css={styles.inputRadio}
                    disabled={editFlg}
                  />
                }
                label="仮登録"
                css={styles.radioLabel}
              />
              <FormControlLabel
                value="登録完了"
                control={
                  <Radio
                    size="small"
                    css={styles.inputRadio}
                    disabled={editFlg}
                  />
                }
                label="登録完了"
                css={styles.radioLabel}
              />
              {showDropdown && <SelectedBranch />}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              css={styles.alignItemC}
            >
              <FormControlLabel
                value="辞退"
                control={
                  <Radio
                    size="small"
                    css={styles.inputRadio}
                    disabled={editFlg}
                  />
                }
                label="辞退&emsp;"
                css={styles.radioLabel}
              />
              <MuiSelectBox
                items={[
                  "連絡あり（他社決や体調不良等の理由を連絡してくれる方）",
                  "連絡なし（無断キャンセル）",
                  "来社登録せず",
                ]}
                id="refusal"
                labelId="refusal-label"
                width="70%"
                disabled={isRefusalDisabled}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              css={styles.alignItemC}
            >
              <FormControlLabel
                value="対象外"
                control={
                  <Radio
                    size="small"
                    css={styles.inputRadio}
                    disabled={editFlg}
                  />
                }
                label="対象外"
                css={styles.radioLabel}
              />
              <MuiSelectBox
                items={[
                  "18歳未満",
                  "反社 (手動)",
                  "連絡禁止 (手動)",
                  "在留資格なし外国籍",
                  "その他",
                ]}
                id="notSubject"
                labelId="refusal-label"
                width="70%"
                disabled={isNotSubjectDisabled}
              />
            </Grid>
          </RadioGroup>
        </FormControl>
      </div>
      <Box sx={{ display: editFlg ? "block" : "none" }}>
        <Typography
          component="p"
          sx={{
            fontSize: "0.5rem",
            fontWeight: "bold",
            color: "rgb(211, 47, 47)",
            padding: "2px 0",
          }}
        >
          本画面ではステータスの訂正はできません。
        </Typography>
        <Typography
          component="p"
          sx={{
            fontSize: "0.5rem",
            fontWeight: "bold",
            color: "rgb(211, 47, 47)",
            padding: "2px 0",
          }}
        >
          ステータスを訂正する場合には、履歴削除を行い、新しい対応履歴で正しいステータスを入力してください。
        </Typography>
      </Box>
    </Grid>
  );
}

const styles = {
  inputRadio: {
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
    },
  },
  radioLabel: {
    "& .MuiFormControlLabel-label": {
      fontSize: "0.5rem",
    },
  },
  alignItemC: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  reasonTextField: {
    "& .MuiInputBase-input": {
      width: "20rem",
    },
  },
};
