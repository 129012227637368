/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import { styled } from '@mui/material/styles'
import { Box, Typography, Table, TableBody, TableRow, Paper, TableContainer, TableHead, IconButton, Badge, } from '@mui/material/'; 

import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import CancelIcon from '@mui/icons-material/Cancel';
import PopoverSevenRecord from '../../../parts/Popover/PopoverSevenRecord';

const StyledHistoryTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#0047a4',
    borderBottom: '2px solid #0047a4',
    fontSize: '0.5rem',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '0.5rem',
    color: '#0047a4',
  },
}));

const StyledHistoryTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 5,
    top: 1,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));



function createData(alive, oubo_date, oubo_shiten, media, yuukou, latest_status, name, birthday, address, tel, email, applicantId, productName, listingPlan, copyWriting, jobOfferTitle, selectionStatus, remarks ) {
  return { alive, oubo_date, oubo_shiten, media, yuukou, latest_status, name, birthday, address, tel, email, applicantId, productName, listingPlan, copyWriting, jobOfferTitle, selectionStatus, remarks };
}

const rows = [
  createData('', '2022/01/28', 'NC渋谷', 'ウェブアグレ', '無効(同時)', '', '応募花子(女性)', '1999/01/01', '東京都中央区日本橋人形町3-3-13人形町フォレストビル2F', '090-3333-3333', 'oubo@ilovex.co.jp', '8546947851', 'テスト商品名', 'テスト掲載プラン', 'テストキャッチコピー', 'テスト媒体の求人タイトル', 'テスト選考状況', 'これは備考のテストです。これは備考のテストです。これは備考のテストです。これは備考のテストです。'),
  createData('★', '2022/01/28', 'NC渋谷', 'バイトルドットコム', '有効', '登録完了', '応募花子(女性)', '1999/01/01', '東京都中央区日本橋人形町3-3-13人形町フォレストビル2F', '090-3333-3333', 'oubo@ilovex.co.jp', '5423729725', 'テスト商品名', 'テスト掲載プラン', 'テストキャッチコピー', 'テスト媒体の求人タイトル', 'テスト選考状況', 'これは備考のテストです。これは備考のテストです。これは備考のテストです。これは備考のテストです。'),
  createData('', '2021/12/13', 'NC渋谷', 'マイナビバイト', '無効', '', '応募花子(女性)', '1999/01/01', '新潟県新潟市中央区上大川前通三番町26-7BUENA上大川前通3-II307', '090-3333-3333', 'oubo_fake_dummy@ilovex.co.jp', '', '', '', '', '', '', ''),
  createData('', '2020/07/05', 'NC渋谷', 'おうぼ受ける君', '無効(同時)', '', '勅使河原花子(女性)', '1999/01/01', '東京都中央区日本橋人形町3-3-13人形町フォレストビル2F', '090-3333-3333', 'oubo_fake_dummy@ilovex.co.jp', '6547198521', 'テスト商品名', 'テスト掲載プラン', 'テストキャッチコピー', 'テスト媒体の求人タイトル', 'テスト選考状況', 'これは備考のテストです。これは備考のテストです。これは備考のテストです。これは備考のテストです。'),
  createData('', '2022/01/28', 'NC渋谷', 'ウェブアグレ', '無効(同時)', '', '応募花子(女性)', '1999/01/01', '東京都中央区日本橋人形町3-3-13人形町フォレストビル2F', '090-3333-3333', 'oubo@ilovex.co.jp', '', '', '', '', '', '', ''),
  createData('', '2022/01/28', 'NC渋谷', 'バイトルドットコム', '無効', '', '応募花子(女性)', '1999/01/01', '東京都中央区日本橋人形町3-3-13人形町フォレストビル2F', '090-3333-3333', 'oubo@ilovex.co.jp', '', '', '', '', '', '', ''),
  createData('', '2021/12/13', 'NC渋谷', 'マイナビバイト', '無効', '', '応募花子(女性)', '1999/01/01', '新潟県新潟市中央区上大川前通三番町26-7BUENA上大川前通3-II307', '090-3333-3333', 'oubo_fake_dummy@ilovex.co.jp', '4052586625', 'テスト商品名', 'テスト掲載プラン', 'テストキャッチコピー', 'テスト媒体の求人タイトル', 'テスト選考状況', 'これは備考のテストです。これは備考のテストです。これは備考のテストです。これは備考のテストです。'),
  createData('', '2020/07/05', 'NC渋谷', 'おうぼ受ける君', '無効(同時)', '', '勅使河原花子(女性)', '1999/01/01', '東京都中央区日本橋人形町3-3-13人形町フォレストビル2F', '090-3333-3333', 'oubo_fake_dummy@ilovex.co.jp', '', '', '', '', '', '', ''),

];

export default function ModalApplicantsHistory({onClose}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openExtraInfo = Boolean(anchorEl);

  return (
    <Box css={styles.modal}>
      <Typography id="modal-title" variant="h6" component="h2" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <p className="contentBlockTitle">応募履歴一覧</p>
        <IconButton aria-label="close-modal-view-more-contact" onClick={handleClose}>
          <CancelIcon sx={{ color: '#777' }}/>
        </IconButton>
      </Typography>
      <Typography id="modal-description" sx={{ mt: 2 }}>
        <TableContainer component={Paper} sx={{ maxHeight: '80vh' }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table" stickyHeader>
            <TableHead >
              <TableRow>
                <StyledHistoryTableCell>Alive</StyledHistoryTableCell>
                <StyledHistoryTableCell>応募日</StyledHistoryTableCell>
                <StyledHistoryTableCell>応募支店</StyledHistoryTableCell>
                <StyledHistoryTableCell>媒体</StyledHistoryTableCell>
                <StyledHistoryTableCell>有効・無効</StyledHistoryTableCell>
                <StyledHistoryTableCell>最終ステータス</StyledHistoryTableCell>
                <StyledHistoryTableCell>名前(性別)</StyledHistoryTableCell>
                <StyledHistoryTableCell>生年月日</StyledHistoryTableCell>
                <StyledHistoryTableCell>住所</StyledHistoryTableCell>
                <StyledHistoryTableCell>電話番号</StyledHistoryTableCell>
                <StyledHistoryTableCell>メールアドレス</StyledHistoryTableCell>
                <StyledHistoryTableCell>&nbsp;</StyledHistoryTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <StyledHistoryTableRow key={row.name}>
                  <StyledHistoryTableCell align="center">{row.alive}</StyledHistoryTableCell>
                  <StyledHistoryTableCell align="left">{row.oubo_date}</StyledHistoryTableCell>
                  <StyledHistoryTableCell align="left">{row.oubo_shiten}</StyledHistoryTableCell>
                  <StyledHistoryTableCell align="left">{row.media}</StyledHistoryTableCell>
                  <StyledHistoryTableCell align="left">{row.yuukou}</StyledHistoryTableCell>
                  <StyledHistoryTableCell align="left">{row.latest_status}</StyledHistoryTableCell>
                  <StyledHistoryTableCell align="left">{row.name}</StyledHistoryTableCell>
                  <StyledHistoryTableCell align="left">{row.birthday}</StyledHistoryTableCell>
                  <StyledHistoryTableCell align="left">{row.address}</StyledHistoryTableCell>
                  <StyledHistoryTableCell align="left">{row.tel}</StyledHistoryTableCell>
                  <StyledHistoryTableCell align="left">{row.email}</StyledHistoryTableCell>
                  <StyledHistoryTableCell align="center">
                  {(row.applicantId || row.productName || row.listingPlan || row.copyWriting || row.jobOfferTitle || row.selectionStatus || row.remarks) ? 
                    <Box>
                      <StyledBadge
                        badgeContent="!"
                        color="error"
                        aria-owns={openExtraInfo ? 'mouse-over-popover' : undefined}
                        aria-haspopup="true"
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                      />
                      <PopoverSevenRecord open={openExtraInfo} anchorEl={anchorEl} handlePopoverClose={handlePopoverClose} row={row}/>
                    </Box>
                    :
                    <Typography>&nbsp;</Typography>
                  }
                  </StyledHistoryTableCell>
                </StyledHistoryTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Typography>
    </Box>
  )
}

const styles = {
  modal:{
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    backgroundColor: '#FFF',
    borderRadius: '0.25rem',
    padding: '15px 20px',
    fontSize: '0.625rem',
  }
}
