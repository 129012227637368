/** @jsxImportSource @emotion/react */
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  ToggleButton,
} from "@mui/material";

import StyledMuiPaper from "../../parts/surface/StyledMuiPaper";

const styles = {
  customTableHeader: {
    fontSize: "0.5rem",
    fontWeight: "bold",
    color: "#4B576B",
    padding: "4px 10px",
  },
  customTableRow: {
    fontSize: "0.5rem",
    color: "#0047A4",
    padding: "2px 5px",
  },
  customTableData: {
    fontSize: "0.5rem",
    padding: "2px 8px",
  },
  ruby: {
    fontSize: "0.5rem",
    transform: "scale(0.75)",
    transformOrigin: "center left",
  },
  highlights: {
    background: "",
  },
};

export default function ApplicantsCaerusOptions({
  applicantDatas,
  caerusDatas,
}) {
  const isDuplicate = (key, optionData) => {
    return applicantDatas.some((data) => data[key] === optionData[key]);
  };

  function CustomTypography({ row, field }) {
    const background = isDuplicate(field, row) ? "rgb(255,255,125,.8)" : "";
    const content = row[field];

    // if (!content) {
    //   content = <br />;
    // }

    return (
      <Typography
        component="span"
        sx={{
          fontSize: "0.5rem",
          background: background,
        }}
      >
        {content ? content : <br />}
      </Typography>
    );
  }

  const isSameHuman = (optionData) => {
    const data = applicantDatas[0];

    const nameSeiKanaMatch = data.sei_kana === optionData.sei_kana;
    const nameMeiKanaMatch = data.mei_kana === optionData.mei_kana;
    const nameSeiKanjiMatch = data.sei_kanji === optionData.sei_kanji;
    const nameMeiKanjiMatch = data.mei_kanji === optionData.mei_kanji;
    const phoneNumber1Match = data.phone_number_1 === optionData.phone_number_1;
    const phoneNumber2Match = data.phone_number_2 === optionData.phone_number_2;
    const mailAddress1Match = data.mailaddress_1 === optionData.mailaddress_1;
    const mailAddress2Match = data.mailaddress_2 === optionData.mailaddress_2;
    const birthdayMatch = data.birthday === optionData.birthday;

    if (
      ((nameSeiKanaMatch && nameMeiKanaMatch) ||
        (nameSeiKanjiMatch && nameMeiKanjiMatch)) &&
      (phoneNumber1Match ||
        phoneNumber2Match ||
        mailAddress1Match ||
        mailAddress2Match)
    ) {
      return 1;
    }
    if (
      ((nameSeiKanaMatch && nameMeiKanaMatch) ||
        (nameSeiKanjiMatch && nameMeiKanjiMatch)) &&
      birthdayMatch
    ) {
      return 2;
    }
    if (
      phoneNumber1Match ||
      phoneNumber2Match ||
      mailAddress1Match ||
      mailAddress2Match
    ) {
      return 2;
    }

    return 3;
  };

  function colorKeying(optionData) {
    for (let i = 0; i < caerusDatas.length; i++) {
      if (
        caerusDatas[i].identify !== null &&
        optionData.identify !== null &&
        caerusDatas[i].identify === optionData.identify
      ) {
        switch (caerusDatas[i].identify && optionData.identify) {
          case 1:
            return "rgb(152,251,152,.4)";

          case 2:
            return "rgb(250,250,152,.4)";

          case 3:
            return "rgb(201,152,250,.4)";

          case 4:
            return "rgb(250,152,152,.4)";

          case 5:
            return "rgb(152,201,250,.4)";

          default:
            return null;
        }
      }
    }
    return null;
  }

  const [selected, setSelected] = useState(
    Array(caerusDatas.length).fill(false)
  );

  const handleToggle = (j) => {
    setSelected((prevState) => {
      const newState = [...prevState];
      newState[j] = !newState[j];
      return newState;
    });
  };

  const [selectedNoMatch, setSelectedNoMatch] = useState(false);

  return (
    <StyledMuiPaper sx={{ width: "100%", overflow: "hidden", mb: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          component="p"
          sx={{
            display: "inline-block",
            padding: "0 1rem",
            fontSize: "1rem",
            color: "#0047A4",
            backgroundColor: "#c6e0f7",
            height: "1.5rem",
          }}
        >
          CAERUS&emsp;同一人物判定・候補者一覧
        </Typography>

        <ToggleButton
          value="check"
          selected={selectedNoMatch}
          onChange={() => {
            setSelectedNoMatch(!selectedNoMatch);
          }}
          sx={{
            fontSize: "0.5rem",
            width: "100px",
            height: "24px",
            padding: "5px",
            "& .MuiToggleButton-root .Mui-selected": {
              backgroundColor: "#2e7d32 !important",
            },
            marginBottom: "6px",
          }}
        >
          該当なし
        </ToggleButton>
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell css={styles.customTableHeader} sx={{ width: "5%" }}>
                OS
              </TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "5%" }}>
                NC
              </TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "5%" }}>
                反社/連禁
              </TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "7.3%" }}>
                スタッフコード
              </TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "9.7%" }}>
                氏名(カナ)
              </TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "6%" }}>
                生年月日
              </TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "12%" }}>
                住所
              </TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "7.5%" }}>
                電話番号
              </TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "13%" }}>
                メールアドレス
              </TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "7%" }}>
                &emsp;
              </TableCell>
              <TableCell
                css={styles.customTableHeader}
                sx={{ width: "8%" }}
                align="center"
              >
                判定
              </TableCell>
              <TableCell css={styles.customTableHeader} sx={{ width: "5%" }}>
                &emsp;
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {caerusDatas.map((optionData, j) => (
              <TableRow
                key={j}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  width: "100%",
                  background: colorKeying(optionData),
                }}
              >
                <TableCell css={styles.customTableRow}>
                  <Typography
                    component="span"
                    sx={{ fontSize: "0.5rem", padding: "2px" }}
                  >
                    {optionData.os}
                  </Typography>
                </TableCell>
                <TableCell css={styles.customTableRow}>
                  <Typography
                    component="span"
                    sx={{ fontSize: "0.5rem", padding: "2px" }}
                  >
                    {optionData.nc}
                  </Typography>
                </TableCell>
                <TableCell css={styles.customTableRow} align="center">
                  <Typography component="span" css={styles.customTableData}>
                    {optionData.prohibition}
                  </Typography>
                </TableCell>
                <TableCell css={styles.customTableRow}>
                  <Link to="https://www.neo-career.co.jp/" target="_blank">
                    {optionData.caerus_code}
                  </Link>
                </TableCell>
                <TableCell css={styles.customTableRow}>
                  <Typography
                    component="p"
                    css={[styles.ruby, styles.customTableData]}
                  >
                    <CustomTypography row={optionData} field="sei_kana" />
                    &emsp;
                    <CustomTypography row={optionData} field="mei_kana" />
                  </Typography>
                  <Typography component="p" css={styles.customTableData}>
                    <CustomTypography row={optionData} field="sei_kanji" />
                    &emsp;
                    <CustomTypography row={optionData} field="mei_kanji" />
                  </Typography>
                </TableCell>
                <TableCell css={styles.customTableRow}>
                  <CustomTypography row={optionData} field="birthday" />
                </TableCell>
                <TableCell css={styles.customTableRow}>
                  <Typography component="p" css={styles.customTableData}>
                    <CustomTypography row={optionData} field="prefecture" />
                    <CustomTypography row={optionData} field="city" />
                  </Typography>
                  <Typography component="p" css={styles.customTableData}>
                    <CustomTypography row={optionData} field="address" />
                  </Typography>
                  <Typography component="p" css={styles.customTableData}>
                    <CustomTypography row={optionData} field="building" />
                  </Typography>
                </TableCell>
                <TableCell css={styles.customTableRow}>
                  <Typography component="p" css={styles.customTableData}>
                    <CustomTypography row={optionData} field="phone_number_1" />
                  </Typography>
                  <Typography component="p" css={styles.customTableData}>
                    <CustomTypography row={optionData} field="phone_number_2" />
                  </Typography>
                </TableCell>
                <TableCell css={styles.customTableRow}>
                  <Typography component="p" css={styles.customTableData}>
                    <CustomTypography row={optionData} field="mailaddress_1" />
                  </Typography>
                  <Typography component="p" css={styles.customTableData}>
                    <CustomTypography row={optionData} field="mailaddress_2" />
                  </Typography>
                </TableCell>
                <TableCell css={styles.customTableRow}>&emsp;</TableCell>
                <TableCell css={styles.customTableRow} align="center">
                  {isSameHuman(optionData) === 1 ? (
                    <Typography component="p">●</Typography>
                  ) : isSameHuman(optionData) === 2 ? (
                    <Typography component="p" sx={{ fontSize: "0.5rem" }}>
                      ―
                    </Typography>
                  ) : (
                    <Typography
                      component="p"
                      sx={{ fontSize: "0.5rem", color: "#777" }}
                    >
                      ×
                    </Typography>
                  )}
                </TableCell>
                <TableCell css={styles.customTableRow} align="center">
                  <ToggleButton
                    key={j}
                    value="check"
                    selected={selected[j]}
                    onChange={() => handleToggle(j)}
                    sx={{
                      fontSize: "0.5rem",
                      width: "50px",
                      height: "30px",
                      padding: "5px",
                      "& .MuiToggleButton-root .Mui-selected": {
                        backgroundColor: "#2e7d32 !important",
                      },
                    }}
                  >
                    紐付
                  </ToggleButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </StyledMuiPaper>
  );
}
