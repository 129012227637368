/** @jsxImportSource @emotion/react */
import StyledMuiAppBar from "../parts/AppBar/StyledMuiAppBar";
import ApplicantsSearch from "../feature/ApplicantsList/ApplicantsSearch";
import ApplicantsList from "../feature/ApplicantsList/ApplicantsList";
import StyledAnkerLink from "../parts/AnkerLink/StyledAnkerLink";
import { Box, Grid, Paper, Button, Typography } from "@mui/material";

import DummyAppliData from "../../libs/Const/DummyAppliData";

import { Link, useLocation } from "react-router-dom";

//ダミーの応募者データを生成
const rows = DummyAppliData();

export default function ApplicantsListPage() {
  // useLocationを使う場合
  const location = useLocation();
  const pathArray = location.pathname.split("/");
  const lastPath = pathArray[pathArray.length - 1];
  console.log(lastPath); // これがURLの末尾のパスです。

  return (
    <>
      <StyledMuiAppBar
        headerTitle={lastPath === "send_mail" ? "宛先選択" : "応募者一覧"}
      />
      <Box sx={{ width: "100%", mt: 7, mb: 3 }}>
        <Grid container spacing={2} rowSpacing={2} sx={{ px: 3 }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ApplicantsSearch />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <ApplicantsList rows={rows} />
          </Grid>
          {lastPath === "send_mail" ? (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ mb: 2, paddingTop: "0 !important" }}
            >
              <Paper sx={{ p: 2 }}>
                <Box>
                  <Typography sx={{ fontSize: "0.5rem" }} align="center">
                    次の画面で検索結果の応募者をさらに絞り込めます。
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/send_mail"
                    css={styles.backBtn}
                  >
                    戻る
                  </Button>
                  <Button
                    component={Link}
                    to="/mail_target_selection"
                    variant="contained"
                    color="primary"
                    css={styles.registBtn}
                  >
                    次へ
                  </Button>
                </Box>
              </Paper>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        <StyledAnkerLink />
      </Box>
    </>
  );
}

const styles = {
  registBtn: {
    width: "200px",
    transform: "scale(0.75)",
    transformOrigin: "center center",
  },
  backBtn: {
    width: "200px",
    transform: "scale(0.75)",
    transformOrigin: "center center",
    background: "#AAA",
    "&:hover": {
      background: "#999",
    },
  },
};
