/** @jsxImportSource @emotion/react */
import { FormControlLabel, Checkbox } from '@mui/material';


export default function MuiCheckbox({ items, disabled }) {
  //------------コンポーネントのスタイル------------
  const styles = {
    inputCheckBox:{
      '& .MuiSvgIcon-root':{
        fontSize: '1rem',
      },
    },
    checkBoxLabel:{
      '& .MuiFormControlLabel-label':{
        fontSize: '0.5rem',
      },
    },
  }
  //------------コンポーネントのスタイル------------

  return (
    <>
      {items.map((item) => {
        return <FormControlLabel control={<Checkbox css={styles.inputCheckBox} disabled={disabled} />} label={item} css={styles.checkBoxLabel} sx={{ mr:2 }}/>
      })}
    </>
  );
}