import React from "react";
import { Chip } from "@mui/material";

export default function MuiChipExisting() {
  return (
    <Chip
      label="既存"
      size="small"
      sx={{
        fontSize: "0.3rem",
        background: "#92D050",
        color: "#FFF",
      }}
    />
  );
}
