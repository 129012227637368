/** @jsxImportSource @emotion/react */
import axios from "axios";
import { useState } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

function TextFieldInputZipCode({ setAddress, defaultZipcode, textSize, disabled }) {

  //------------コンポーネントのスタイル------------
  const styles = {
    textarea:{
      '& .MuiInputBase-input':{
        fontSize: textSize,
      },
      '& .MuiInputBase-root':{
        // padding: '3px 0.5rem',
      },
      '& .MuiInputLabel-root':{
        fontSize: textSize,
      }
    },
    inputItemForm:{
      display:'flex',
      alignItems: 'center',
    },
    paddingLeft5:{
      paddingLeft: '5px',
    }
  }
  //------------コンポーネントのスタイル------------

  const defaultZipcodeMain = defaultZipcode.slice(0,3);
  const defaultZipcodeSub = defaultZipcode.slice(4,9);


  const [zipcode, setZipcodeMain] = useState({
    main: defaultZipcodeMain,
    sub: defaultZipcodeSub
  });

  const updateZipcodeMain = (e) => {
    setZipcodeMain({ ...zipcode, main: e.target.value });
  };
  const updateZipcodeSub = async (e) => {
    setZipcodeMain({ ...zipcode, sub: e.target.value });
    if (e.target.value.length === 4 && zipcode.main.length === 3) {
      try {
        const res = await axios.get(
          "https://zipcloud.ibsnet.co.jp/api/search",
          {
            params: {
              zipcode: zipcode.main + e.target.value
            }
          }
        );
        if (res.data.results) {
          const result = res.data.results[0];
          setAddress({
            address1: result["address1"],
            address2: result["address2"],
            address3: result["address3"]
          });
        }
      } catch {
        alert("住所の取得に失敗しました。");
      }
    }
  };

  return (
    <>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} css={styles.inputItemForm}>
        <TextField
          id="zipcode_main"
          size="small"
          onChange={updateZipcodeMain}
          value={zipcode.main}
          css={styles.textarea}
          sx={{ paddingRight: '5px' }}
          disabled={disabled}
        />
        <span> - </span>
        <TextField
          id="zipcode_sub"
          size="small"
          onChange={updateZipcodeSub}
          value={zipcode.sub}
          css={styles.textarea}
          sx={{ paddingLeft: '5px' }}
          disabled={disabled}
        />
      </Grid>
    </>
  );
}

export default TextFieldInputZipCode;








