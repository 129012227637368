/** @jsxImportSource @emotion/react */
import React from "react";
import {
  Popover,
  Paper,
  Table,
  TableContainer,
  TableCell,
  TableRow,
} from "@mui/material";

export default function PopoverSevenRecord(props) {
  const { row, open, anchorEl, handlePopoverClose } = props;
  return (
    <Popover
      id="mouse-over-popover"
      sx={{
        pointerEvents: "none",
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
          <TableRow>
            <TableCell
              align="left"
              sx={{
                p: 1,
                fontSize: "0.5rem",
                fontWeight: "bold",
                color: "#0047A4",
              }}
            >
              媒体別応募者ID
            </TableCell>
            <TableCell
              align="left"
              sx={{ p: 1, fontSize: "0.5rem", color: "#4B576B" }}
            >
              {row.applicantId}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              align="left"
              sx={{
                p: 1,
                fontSize: "0.5rem",
                fontWeight: "bold",
                color: "#0047A4",
              }}
            >
              商品名
            </TableCell>
            <TableCell
              align="left"
              sx={{ p: 1, fontSize: "0.5rem", color: "#4B576B" }}
            >
              {row.productName}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              align="left"
              sx={{
                p: 1,
                fontSize: "0.5rem",
                fontWeight: "bold",
                color: "#0047A4",
              }}
            >
              掲載プラン
            </TableCell>
            <TableCell
              align="left"
              sx={{ p: 1, fontSize: "0.5rem", color: "#4B576B" }}
            >
              {row.listingPlan}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              align="left"
              sx={{
                p: 1,
                fontSize: "0.5rem",
                fontWeight: "bold",
                color: "#0047A4",
              }}
            >
              キャッチコピー
            </TableCell>
            <TableCell
              align="left"
              sx={{ p: 1, fontSize: "0.5rem", color: "#4B576B" }}
            >
              {row.copyWriting}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              align="left"
              sx={{
                p: 1,
                fontSize: "0.5rem",
                fontWeight: "bold",
                color: "#0047A4",
              }}
            >
              媒体の求人タイトル
            </TableCell>
            <TableCell
              align="left"
              sx={{ p: 1, fontSize: "0.5rem", color: "#4B576B" }}
            >
              {row.jobOfferTitle}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              align="left"
              sx={{
                p: 1,
                fontSize: "0.5rem",
                fontWeight: "bold",
                color: "#0047A4",
              }}
            >
              選考（対応）状況
            </TableCell>
            <TableCell
              align="left"
              sx={{ p: 1, fontSize: "0.5rem", color: "#4B576B" }}
            >
              {row.selectionStatus}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              align="left"
              sx={{
                p: 1,
                fontSize: "0.5rem",
                fontWeight: "bold",
                color: "#0047A4",
              }}
            >
              備考
            </TableCell>
            <TableCell
              align="left"
              sx={{ p: 1, fontSize: "0.5rem", color: "#4B576B" }}
            >
              {row.remarks}
            </TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </Popover>
  );
}
