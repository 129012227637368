/** @jsxImportSource @emotion/react */
import {
  Grid,
  Typography,
  Box,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import StyledMuiTableCell from "../../../parts/table/StyledMuiTable/StyledMuiTableCell";
import StyledMuiTableHead from "../../../parts/table/StyledMuiTable/StyledMuiTableHead";
import StyledMuiTableRow from "../../../parts/table/StyledMuiTable/StyledMuiTableRow";
import SelectPreferredPriority from "../../forms/SelectPreferredPriority";

import Const from "../../../../libs/Const";

const styles = {
  requiredIcon: {
    fontSize: "0.5rem",
    color: "#FFF",
    background: "#FF008C",
    padding: "1px 5px",
    borderRadius: "5px",
    marginRight: "5px",
  },
  byApplicantIcon: {
    fontSize: "0.5rem",
    color: "#FFF",
    background: "#868CB7",
    padding: "1px 5px",
    borderRadius: "5px",
    marginRight: "5px",
  },
  selectBox: {
    fontSize: "0.5rem",
    "& .MuiInputBase-input": {
      fontSize: "0.5rem",
      color: "#0047A4",
    },
    "& .MuiInputBase-root": {
      padding: "3px 0.5rem",
      fontSize: "0.5rem",
    },
    "& .MuiFormLabel-root": {
      fontSize: "0.5rem",
    },
    "& .MuiInputLabel-root": {
      fontSize: "0.5rem",
    },
    "& .MuiSelect-root": {
      fontSize: "0.5rem",
    },
  },
  textField: {
    mt: 1,
    "& .MuiInputBase-input": {
      fontSize: "0.5rem",
      padding: "0.5rem",
      color: "#0047A4",
    },
  },
  radioButton: {
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
    },
  },
  radioButtonLabel: {
    "& .MuiFormControlLabel-label": {
      fontSize: "0.5rem",
    },
  },
  inputCheckBox: {
    "& .MuiSvgIcon-root": {
      fontSize: "1rem",
    },
  },
  checkBoxLabel: {
    "& .MuiFormControlLabel-label": {
      fontSize: "0.5rem",
    },
  },
  smallTextSize: {
    fontSize: "0.5rem",
    color: "0047A4",
  },
};

export default function TabPanelNc2({ data, conflict }) {
  return (
    <>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            希望の雇用形態
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {[
                "派遣",
                "紹介(パート)",
                "紹介(正社員)",
                "紹介予定派遣",
                "不問",
              ].map((item) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        css={styles.inputCheckBox}
                        disabled={conflict ? "disabled" : null}
                      />
                    }
                    label={item}
                    css={styles.checkBoxLabel}
                    sx={{ mr: 2 }}
                  />
                );
              })}
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            案内を控えてほしい施設・病院
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                size="small"
                defaultValue=""
                disabled={conflict ? "disabled" : null}
                css={styles.textField}
              />
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            就業開始可能日
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                size="small"
                defaultValue=""
                disabled={conflict ? "disabled" : null}
                css={styles.textField}
              />
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            勤務可能曜日
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {Const.DAY_OF_WEEK.map((item) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        css={styles.inputCheckBox}
                        disabled={conflict ? "disabled" : null}
                      />
                    }
                    label={item}
                    css={styles.checkBoxLabel}
                    sx={{ mr: 2 }}
                  />
                );
              })}
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            希望勤務日数
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small" sx={{ minWidth: "60px" }}>
                <Select id="availableDays" css={styles.selectBox}>
                  {Const.DESIRED_NUMBER_OF_WORKING_DAYS.map((item) => (
                    <MenuItem
                      key={item}
                      value={item}
                      css={styles.smallTextSize}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            希望勤務時間
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {Const.DESIRED_WORK_HOURS_NC.map((item) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        css={styles.inputCheckBox}
                        disabled={conflict ? "disabled" : null}
                      />
                    }
                    label={item}
                    css={styles.checkBoxLabel}
                    sx={{ mr: 2 }}
                  />
                );
              })}
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            具体的希望時間
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                size="small"
                defaultValue=""
                disabled={conflict ? "disabled" : null}
                css={styles.textField}
              />
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            残業可否
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small" sx={{ minWidth: "60px" }}>
                <Select id="availableDays" css={styles.selectBox}>
                  {Const.CAN_OVERTIME_WORK.map((item) => (
                    <MenuItem
                      key={item}
                      value={item}
                      css={styles.smallTextSize}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            希望条件
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                size="small"
                defaultValue=""
                disabled={conflict ? "disabled" : null}
                css={styles.textField}
              />
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            介護職・看護助手の経験年数
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                size="small"
                defaultValue=""
                disabled={conflict ? "disabled" : null}
                css={styles.textField}
              />
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            希望施設形態
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              {Const.DESIRED_FACILITY_TYPE.map((item) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        css={styles.inputCheckBox}
                        disabled={conflict ? "disabled" : null}
                      />
                    }
                    label={item}
                    css={styles.checkBoxLabel}
                    sx={{ mr: 2 }}
                  />
                );
              })}
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            介護職を選ばれた理由
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                size="small"
                defaultValue=""
                disabled={conflict ? "disabled" : null}
                css={styles.textField}
              />
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            仕事探しをしている理由
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                size="small"
                defaultValue=""
                disabled={conflict ? "disabled" : null}
                css={styles.textField}
              />
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            希望交通手段
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small" sx={{ minWidth: "60px" }}>
                <Select id="preferredTransportaion" css={styles.selectBox}>
                  {Const.PREFERRED_COMMUTING_METHOD.map((item) => (
                    <MenuItem
                      key={item}
                      value={item}
                      css={styles.smallTextSize}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            その他希望事項
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                size="small"
                defaultValue=""
                disabled={conflict ? "disabled" : null}
                css={styles.textField}
              />
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            三大介助の抵抗有無
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small" sx={{ minWidth: "60px" }}>
                <Select id="resistanceCareWorker" css={styles.selectBox}>
                  {Const.RESISTANCE_TO_THREE_MAJOR_TYPES_OF_ASSISTANCE.map(
                    (item) => (
                      <MenuItem
                        key={item}
                        value={item}
                        css={styles.smallTextSize}
                      >
                        {item}
                      </MenuItem>
                    )
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            レクリエーションの対応可否
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small" sx={{ minWidth: "60px" }}>
                <Select id="recreationSupport" css={styles.selectBox}>
                  {Const.RECREATION_SUPPORT_AVAILABLE_OR_NOT.map((item) => (
                    <MenuItem
                      key={item}
                      value={item}
                      css={styles.smallTextSize}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            調理補助の対応可否
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small" sx={{ minWidth: "60px" }}>
                <Select id="cookingSupport" css={styles.selectBox}>
                  {Const.COOKING_ASSISTANCE_AVAILABLE_OR_NOT.map((item) => (
                    <MenuItem
                      key={item}
                      value={item}
                      css={styles.smallTextSize}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            送迎業務の可否
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small" sx={{ minWidth: "60px" }}>
                <Select id="cookingSupport" css={styles.selectBox}>
                  {Const.AVAILABILITY_OF_TRANSPORTATION_SERVICES.map((item) => (
                    <MenuItem
                      key={item}
                      value={item}
                      css={styles.smallTextSize}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            希望条件の優先順位(1位)
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <SelectPreferredPriority
              id="preferredPriority1"
              width="6"
              textSize="0.5rem"
              disabled={conflict ? "disabled" : null}
            />
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            希望条件の優先順位(2位)
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <SelectPreferredPriority
              id="preferredPriority2"
              width="6"
              textSize="0.5rem"
              disabled={conflict ? "disabled" : null}
            />
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            希望条件の優先順位(3位)
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <SelectPreferredPriority
              id="preferredPriority3"
              width="6"
              textSize="0.5rem"
              disabled={conflict ? "disabled" : null}
            />
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            電話の繋がりやすい時間
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                size="small"
                defaultValue=""
                disabled={conflict ? "disabled" : null}
                css={styles.textField}
              />
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            車両任意保険加入状況
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small" sx={{ minWidth: "60px" }}>
                <Select id="cookingSupport" css={styles.selectBox}>
                  {["加入済み", "未加入"].map((item) => (
                    <MenuItem
                      key={item}
                      value={item}
                      css={styles.smallTextSize}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            最寄り駅①（沿線）
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  disabled={conflict ? "disabled" : null}
                  css={styles.textField}
                />
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            最寄り駅①（駅名）
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  disabled={conflict ? "disabled" : null}
                  css={styles.textField}
                />
              </FormControl>
              <Typography
                component="div"
                css={styles.smallTextSize}
                sx={{ ml: "3px" }}
              >
                駅
              </Typography>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            最寄り駅①からの交通手段
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small" sx={{ minWidth: "60px" }}>
                <Select labelId="transport-select-label" css={styles.selectBox}>
                  {["徒歩", "バス"].map((transport) => (
                    <MenuItem
                      key={transport}
                      value={transport}
                      css={styles.smallTextSize}
                    >
                      {transport}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            最寄り駅①からの所要時間
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              lg={2}
              xl={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  disabled={conflict ? "disabled" : null}
                  css={styles.textField}
                />
              </FormControl>
              <Typography
                component="div"
                css={styles.smallTextSize}
                sx={{ ml: "3px" }}
              >
                分
              </Typography>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            最寄り駅②（沿線）
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  disabled={conflict ? "disabled" : null}
                  css={styles.textField}
                />
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            最寄り駅②（駅名）
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  disabled={conflict ? "disabled" : null}
                  css={styles.textField}
                />
              </FormControl>
              <Typography
                component="div"
                css={styles.smallTextSize}
                sx={{ ml: "3px" }}
              >
                駅
              </Typography>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            最寄り駅②からの交通手段
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              xl={4}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small" sx={{ minWidth: "60px" }}>
                <Select labelId="transport-select-label" css={styles.selectBox}>
                  {["徒歩", "バス"].map((transport) => (
                    <MenuItem
                      key={transport}
                      value={transport}
                      css={styles.smallTextSize}
                    >
                      {transport}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
      <StyledMuiTableRow>
        <StyledMuiTableHead
          align="left"
          sx={{
            width: "300px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography component="p" sx={{ fontSize: "0.5rem" }}>
            最寄り駅②からの所要時間
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Typography component="span" css={styles.requiredIcon}>
              必須
            </Typography>
            <Typography component="span" css={styles.byApplicantIcon}>
              本人
            </Typography>
          </Box>
        </StyledMuiTableHead>
        <StyledMuiTableCell>
          <Grid
            container
            spacing={2}
            rowSpacing={2}
            columnSpacing={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2 }}
          >
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              lg={2}
              xl={2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <FormControl fullWidth size="small">
                <TextField
                  size="small"
                  disabled={conflict ? "disabled" : null}
                  css={styles.textField}
                />
              </FormControl>
              <Typography
                component="div"
                css={styles.smallTextSize}
                sx={{ ml: "3px" }}
              >
                分
              </Typography>
            </Grid>
          </Grid>
        </StyledMuiTableCell>
      </StyledMuiTableRow>
    </>
  );
}
