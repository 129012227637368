/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";

export default function MuiTextFieldInsertTags({
  ariaLabel,
  multiline,
  minRows,
  placeholder,
  defaultValue,
  tagValue,
  ...other
}) {
  const [value, setValue] = useState(defaultValue || "");
  const [cursorPosition, setCursorPosition] = useState(0);

  const styles = {
    fieldStyle: {
      mt: 1,
      width: "100%",

      "& .MuiInputBase-input": {
        fontSize: "0.5rem",
        padding: "0.5rem",
        color: "#0047A4",
      },
      "& .MuiInputBase-root": {
        padding: "3px 0.5rem",
      },
    },
  };

  const handleTextChange = (event) => {
    setValue(event.target.value);
    setCursorPosition(event.target.selectionStart);
  };

  const handleButtonClick = () => {
    const newText =
      value.slice(0, cursorPosition) +
      tagValue +
      value.slice(cursorPosition, value.length);
    setValue(newText);
  };

  useEffect(() => {
    handleButtonClick();
  }, [tagValue]);

  return (
    <TextField
      size="small"
      aria-label={ariaLabel}
      multiline={multiline}
      minRows={minRows}
      css={styles.fieldStyle}
      placeholder={placeholder}
      value={value}
      onChange={handleTextChange}
      {...other}
    />
  );
}
