/** @jsxImportSource @emotion/react */
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';




export default function MuiTextField(props) {
  const { ariaLabel,label, multiline, minRows, placeholder, defaultValue, disabled, ...other } = props;
  const styles = {
    fieldStyle:{
      mt: 1,
      width: '100%',
      
      '& .MuiInputBase-input': {
        fontSize: '0.5rem',
        padding: '0.5rem',
        color: '#0047A4',
      },
      '& .MuiInputLabel-root':{
        fontSize: '0.5rem',
      },
    }
  }

  return (
    <TextField
      size="small"
      label={label}
      aria-label={ariaLabel}
      multiline={multiline}
      minRows={minRows}
      css={styles.fieldStyle}
      placeholder={placeholder}
      value={defaultValue}
      disabled={disabled}
    />
  )
}