export default function DummyEntryDataOs1() {
  const tableDataListsOs1 = [
    {
      id: "os-1-1",
      thead: "各種同意",
      tdata: ["済"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-1-2",
      thead: "姓(漢字)",
      tdata: ["応募"],
      history: true,
      historyData: [
        {
          editDate: "2022/12/15 10:30",
          editor: "応募　花子(応募者)",
          mode: "応募者保存",
          changes: "応募",
        },
        {
          editDate: "2022/07/04 09:05",
          editor: "媒体データ取込",
          mode: "",
          changes: "旧姓",
        },
      ],
    },
    {
      id: "os-1-3",
      thead: "名(漢字)",
      tdata: ["花子"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-1-4",
      thead: "セイ(カナ)",
      tdata: ["オウボ"],
      history: true,
      historyData: [
        {
          editDate: "2022/12/15 10:30",
          editor: "応募　花子(応募者)",
          mode: "応募者保存",
          changes: "オウボ",
        },
        {
          editDate: "2022/07/04 09:05",
          editor: "媒体データ取込",
          mode: "",
          changes: "キュウセイ",
        },
      ],
    },
    {
      id: "os-1-5",
      thead: "メイ(カナ)",
      tdata: ["ハナコ"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-1-6",
      thead: "性別",
      tdata: ["女性"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-1-7",
      thead: "生年月日",
      tdata: ["2000年01月01日"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-1-8",
      thead: "住所(郵便番号)",
      tdata: ["103-0013"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-1-9",
      thead: "住所(都道府県)",
      tdata: ["東京都"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-1-10",
      thead: "住所(市区町村)",
      tdata: ["中央区日本橋人形町"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-1-numberrr0",
      thead: "住所(町域・番地)",
      tdata: ["3-3-13"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-1-numberrr1",
      thead: "住所(建物等)",
      tdata: ["人形町フォレストビル2F"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-1-numberrr2",
      thead: "最寄り駅①(沿線)",
      tdata: ["東京メトロ日比谷線"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-1-numberrr3",
      thead: "最寄り駅①(駅名)",
      tdata: ["人形町駅"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-1-numberrr4",
      thead: "最寄り駅からの交通手段①",
      tdata: ["徒歩"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-1-numberrr5",
      thead: "最寄り駅からの所要時間①",
      tdata: ["5分"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-1-numberrr6",
      thead: "最寄り駅②(沿線)",
      tdata: ["東京メトロ半蔵門線"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-1-numberrr7",
      thead: "最寄り駅②(駅名)",
      tdata: ["水天宮前駅"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-1-numberrr8",
      thead: "電話番号(携帯)",
      tdata: ["090-1234-5678"],
      history: true,
      historyData: [
        {
          editDate: "2022/12/15 10:30",
          editor: "応募　花子(応募者)",
          mode: "応募者保存",
          changes: "090-1234-5678",
        },
        {
          editDate: "2022/09/26 14:56",
          editor: "営業　太郎",
          mode: "代理",
          changes: "090-7654-3210",
        },
        {
          editDate: "2022/07/04 09:05",
          editor: "媒体データ取込",
          mode: "",
          changes: "090-1122-3344",
        },
      ],
    },
    {
      id: "os-1-numberrr9",
      thead: "電話番号(その他連絡先)",
      tdata: ["03-3333-3333"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-1-numberrr0",
      thead: "メールアドレス1",
      tdata: ["oubo@ilovex.co.jp"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-1-numberrr1",
      thead: "メールアドレス2",
      tdata: ["dammy-test-fake@docomo.co.jp"],
      history: false,
      historyData: [{}],
    },
    {
      id: "os-1-numberrr2",
      thead: "在留カード情報",
      tdata: ["在留資格区分"],
      history: false,
      historyData: [{}],
    },
  ];
  return tableDataListsOs1;
}
