/** @jsxImportSource @emotion/react */
import React, { useState } from "react";

import {
  Button, Tab, Typography, Box, Table, TableContainer, TableCell, TableRow, TableBody, TableHead, Paper, Popover,
  } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';


export default function PopoverEditHistory({ data }) {
    
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedHistory, setSelectedHistory] = useState([]);

  const handlePopoverOpen = (event, historyData) => {
    setAnchorEl(event.currentTarget);
    setSelectedHistory(historyData);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box sx={{ width: '20px' }}>
      <HistoryIcon
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={(event) => handlePopoverOpen(event, data.historyData)}
        onMouseLeave={handlePopoverClose}
        sx={{ fontSize: '1rem', color: '#333' }}
      />
      <Popover
        id={id}
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left" sx={{ p: 1, fontSize: '0.5rem', fontWeight: 'bold', color: '#0047A4' }}>変更日時</TableCell>
                <TableCell align="left" sx={{ p: 1, fontSize: '0.5rem', fontWeight: 'bold', color: '#0047A4' }}>編集者</TableCell>
                <TableCell align="left" sx={{ p: 1, fontSize: '0.5rem', fontWeight: 'bold', color: '#0047A4' }}>モード</TableCell>
                <TableCell align="left" sx={{ p: 1, fontSize: '0.5rem', fontWeight: 'bold', color: '#0047A4' }}>変更内容</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedHistory.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="left" sx={{ p: 1, fontSize: '0.5rem', color: '#4B576B' }}>{row.editDate}</TableCell>
                  <TableCell align="left" sx={{ p: 1, fontSize: '0.5rem', color: '#4B576B' }}>{row.editor}</TableCell>
                  <TableCell align="left" sx={{ p: 1, fontSize: '0.5rem', color: '#4B576B' }}>{row.mode}</TableCell>
                  <TableCell align="left" sx={{ p: 1, fontSize: '0.5rem', color: '#0047A4' }}>{row.changes}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Popover>
    </Box>
  )
}