import { Routes, Route } from "react-router-dom";
import LoginPage from "./components/pages/LoginPage";
import TopPage from "./components/pages/TopPage";
import ApplicantsListPage from "./components/pages/ApplicantsListPage";
import PhoneApplicantsListPage from "./components/pages/PhoneApplicantsListPage";
import ApplicantsDetailPage from "./components/pages/ApplicantsDetailPage";
import ApplicantsRegistPage from "./components/pages/ApplicantsRegistPage";
import DetermineApplicantsListPage from "./components/pages/DetermineApplicantsListPage";
import DetermineApplicantsDetailPage from "./components/pages/DetermineApplicantsDetailPage";
import DetermineApplicantsDetailPage20230824 from "./components/pages/DetermineApplicantsDetailPage20230824";
import DetermineApplicantsDetailPageAlt from "./components/pages/DetermineApplicantsDetailPageAlt";
import DetermineApplicantsDetailPage20230824Alt from "./components/pages/DetermineApplicantsDetailPage20230824Alt";
import WebEntryPage from "./components/pages/WebEntryPage";
import WebEntryBasicInfoPage from "./components/pages/WebEntryBasicInfoPage";
import WebEntryNc2Page from "./components/pages/WebEntryNc2Page";
import WebEntryConfirmPage from "./components/pages/WebEntryConfirmPage";
import WebEntryContentPage from "./components/pages/WebEntryContentPage";
import SendGenericMailPage from "./components/pages/SendGenericMailPage";
import RegistMailTemplatePage from "./components/pages/RegistMailTemplatePage";
import MailTemplateListPage from "./components/pages/MailTemplateListPage";
import MailTemplateSearchPage from "./components/pages/MailTemplateSearchPage";
import GenericMailTargetSelectionPage from "./components/pages/GenericMailTargetSelectionPage";
import BranchMasterSettingPage from "./components/pages/BranchMasterSettingPage";

function Routing() {
  return (
    <Routes>
      <Route path="/" exact element={<LoginPage />} />
      <Route path="/top" exact element={<TopPage />} />

      <Route path="/oubo_list" exact element={<ApplicantsListPage />}>
        <Route path=":send_mail" element={<ApplicantsListPage />} />
      </Route>
      <Route
        path="/phone_applicants_list"
        exact
        element={<PhoneApplicantsListPage />}
      />
      <Route path="/oubo_regist" exact element={<ApplicantsRegistPage />} />
      {/* -----------------------------------ユーザレビューのため便宜上作成----------------------------------- */}
      <Route path="/oubo_detail_nc" exact element={<ApplicantsDetailPage />} />
      <Route path="/oubo_detail_os" exact element={<ApplicantsDetailPage />} />
      {/* -----------------------------------ユーザレビューのため便宜上作成----------------------------------- */}
      <Route path="/oubo_detail" exact element={<ApplicantsDetailPage />}>
        <Route path=":appli_id" element={<ApplicantsDetailPage />} />
      </Route>
      <Route path="/oubo_regist" exact element={<ApplicantsRegistPage />} />
      <Route
        path="/determination_list"
        exact
        element={<DetermineApplicantsListPage />}
      />
      <Route
        path="/determination_detail"
        exact
        element={<DetermineApplicantsDetailPage />}
      >
        <Route
          path=":appli_id"
          exact
          element={<DetermineApplicantsDetailPage />}
        />
      </Route>
      {/* -----------------------------------ユーザレビューのため便宜上作成----------------------------------- */}
      <Route
        path="/determination_detail_20230824"
        exact
        element={<DetermineApplicantsDetailPage20230824 />}
      />
      <Route
        path="/determination_detail_alt"
        exact
        element={<DetermineApplicantsDetailPageAlt />}
      />
      <Route
        path="/determination_detail_20230824_alt"
        exact
        element={<DetermineApplicantsDetailPage20230824Alt />}
      />
      {/* -----------------------------------ユーザレビューのため便宜上作成----------------------------------- */}

      <Route path="/send_mail" exact element={<SendGenericMailPage />} />
      <Route
        path="/regist_template"
        exact
        element={<RegistMailTemplatePage />}
      />
      <Route
        path="/mail_template_search"
        exact
        element={<MailTemplateSearchPage />}
      />
      <Route
        path="/mail_template_list"
        exact
        element={<MailTemplateListPage />}
      />
      <Route
        path="/mail_target_selection"
        exact
        element={<GenericMailTargetSelectionPage />}
      />

      <Route path="/web_entry" exact element={<WebEntryPage />} />
      <Route
        path="/web_entry/basic_info"
        exact
        element={<WebEntryBasicInfoPage />}
      />
      <Route path="/web_entry/nc_2" exact element={<WebEntryNc2Page />} />
      <Route
        path="/web_entry/confirm"
        exact
        element={<WebEntryConfirmPage />}
      />
      <Route
        path="/web_entry/content"
        exact
        element={<WebEntryContentPage />}
      />

      <Route
        path="/branch_master_settings"
        exact
        element={<BranchMasterSettingPage />}
      />
    </Routes>
  );
}

export default Routing;
