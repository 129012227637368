/** @jsxImportSource @emotion/react */
import React, { useState } from "react";

import {
  Button, Tab, Typography, Box, Table, TableContainer, TableCell, TableRow, TableBody, TableHead, Paper, Popover,
  } from '@mui/material';

import HistoryIcon from '@mui/icons-material/History';
import StyledMuiTableCell from '../../../parts/table/StyledMuiTable/StyledMuiTableCell';
import StyledMuiTableHead from '../../../parts/table/StyledMuiTable/StyledMuiTableHead';
import StyledMuiTableRow from '../../../parts/table/StyledMuiTable/StyledMuiTableRow';
import PopoverEditHistory from "./PopoverEditHistory";
import Const from "../../../../libs/Const";

export default function PanelOs3({item}) {

  function getMatchStatus(arr1, arr2) {
    return arr1.map(item => ({
      label: item,
      isMatch: arr2.includes(item)
    }));
  }

  return(
    <>
    {item.map((data) => {
      return (
        <StyledMuiTableRow>
          <StyledMuiTableHead align="left" sx={{ width: '50%', pl: data.thead.charAt(0) === '【' ? '5px' : 6 }}>{data.thead}</StyledMuiTableHead> 
            
          <StyledMuiTableCell>
            <Box sx={{ display: 'flex', justifyContent: 'space-between',alignItems: 'center' }}>
              <Typography sx={{ fontSize: '0.5rem', fontWeight: 'bold' }}>{data.tdata}</Typography>
              {data.history ? <PopoverEditHistory data={data} /> : <Box sx={{ width: '20px' }}/>}
            </Box>
          </StyledMuiTableCell>
        </StyledMuiTableRow>
      )
    })}

    </>
  )
}

